import React from "react";
import lockPeriodLogo from "../../assets/lockPeriodLogo.png";
import startDateLogo from "../../assets/startDateLogo.png";
import pinkDotImgLogo from "../../../../assets/img/pinkDotImgLogo.png";

let ViewPropertySecondTenant = ({ propertyDeatils }) => {
  const propertyListing = propertyDeatils || [];
  return (
    <React.Fragment>
      <div className="container mb-5" id="tenant">
        {/*<div className="px-md-5 mx-md-5 px-2 mx-2">*/}
        <p
          className="text-center"
          style={{ fontSize: "35px", fontWeight: "700" }}
        >
          Tenant
        </p>
        <div className="p-md-5 p-3" style={{ border: "1px solid #FF8282" }}>
          <div className="mx-md-4 mx-1">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="px-4 py-3"
                  style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                >
                  <p className="mb-0" style={{ fontWeight: "400" }}>
                    Name
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {propertyListing.TenantName}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className="px-4 py-3"
                  style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                >
                  <p className="mb-0" style={{ fontWeight: "400" }}>
                    Country
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "18px", fontWeight: "500" }}
                  >
                    {propertyListing.Country}
                  </p>
                </div>
              </div>
            </div>
            <div className="my-4">
              <div
                className="px-4 py-3"
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                <p className="mb-0" style={{ fontWeight: "400" }}>
                  Description
                </p>
                <p
                  className="mb-0"
                  style={{ fontSize: "18px", fontWeight: "500" }}
                >
                  {propertyListing.Description}
                </p>
              </div>
            </div>
            <div className="">
              <p className="px-4">Time Period</p>
              <div className="d-flex mx-md-5 mx-auto justify-content-between ">
                <div className="text-center" style={{ position: "relative" }}>
                  <img height={25} src={startDateLogo} alt="" />
                  <p className="mb-0 mt-1 mb-0" style={{ fontSize: "14px" }}>
                    Lease Start date
                  </p>
                  <p
                    className="mb-0 my-1"
                    style={{ color: "rgba(255, 130, 130, 1)" }}
                  >
                    {propertyListing.LeaseStartDate}
                  </p>
                  <img
                    src={pinkDotImgLogo}
                    style={{
                      height: "10px",
                      width: "10px",
                      position: "relative",
                      zIndex: "2",
                    }}
                    alt=""
                  />
                  <hr
                    className="d-md-flex d-none"
                    style={{
                      position: "relative",
                      width: "200%",
                      top: "-19%",
                      border: "0.5px solid rgba(0, 0, 0, 0.4)",
                      left: "50%",
                    }}
                  />
                </div>
                <div className="text-center">
                  <img height={25} src={lockPeriodLogo} alt="" />
                  <p className="mb-0 mt-1 mb-0" style={{ fontSize: "14px" }}>
                    Lock in Period
                  </p>
                  <p
                    className="mb-0 my-1"
                    style={{ color: "rgba(255, 130, 130, 1)" }}
                  >
                    {propertyListing.LockInPeriod} YEAR
                  </p>
                  <img
                    src={pinkDotImgLogo}
                    style={{
                      height: "10px",
                      width: "10px",
                      position: "relative",
                      zIndex: "2",
                    }}
                    alt=""
                  />
                  <hr
                    className="d-md-flex d-none"
                    style={{
                      position: "relative",
                      width: "400%",
                      left: "-164%",
                      top: "-19%",
                      border: "0.5px solid rgba(0, 0, 0, 0.4)",
                    }}
                  />
                </div>
                <div className="text-center">
                  <img height={25} src={startDateLogo} alt="" />
                  <p className="mb-0 mt-1 mb-0" style={{ fontSize: "14px" }}>
                    Lease End Period
                  </p>
                  <p
                    className="mb-0 my-1"
                    style={{ color: "rgba(255, 130, 130, 1)" }}
                  >
                    {propertyListing.LeaseEndDate}
                  </p>
                  <img
                    src={pinkDotImgLogo}
                    style={{
                      height: "10px",
                      width: "10px",
                      position: "relative",
                      zIndex: "2",
                    }}
                    alt=""
                  />
                  <hr
                    className="d-md-flex d-none"
                    style={{
                      position: "relative",
                      width: "210%",
                      left: "-164%",
                      top: "-19%",
                      border: "0.5px solid rgba(0, 0, 0, 0.4)",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 mb-2">
              <p className="px-4">Tenant Highlights</p>
              <div className="row justify-content-between ">
                <div className="col-md-3">
                  <div
                    className="text-center p-4 h-100"
                    style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <p
                      className="mb-0"
                      style={{ color: "#FF8282", fontSize: "20px" }}
                    >
                      {propertyListing.PropertyAreaSFT}SF
                    </p>
                    <p className="mb-0" style={{ fontSize: "15px" }}>
                      {" "}
                      TOTAL AREA
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="text-center p-4 h-100"
                    style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <p
                      className="mb-0"
                      style={{ color: "#FF8282", fontSize: "20px" }}
                    >
                      ₹
                      {(
                        propertyListing.RentPerMonth /
                        propertyListing.PropertyAreaSFT
                      ).toFixed(2)}
                    </p>
                    <p className="mb-0" style={{ fontSize: "15px" }}>
                      RENT PER SF
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="text-center p-4 h-100"
                    style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <p
                      className="mb-0"
                      style={{ color: "#FF8282", fontSize: "20px" }}
                    >
                      {propertyListing.LeasePeriod} YEAR
                    </p>
                    <p className="mb-0" style={{ fontSize: "15px" }}>
                      {" "}
                      LEASE PERIOD{" "}
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="text-center p-4 h-100"
                    style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                  >
                    <p
                      className="mb-0"
                      style={{ color: "#FF8282", fontSize: "20px" }}
                    >
                      {propertyListing.Escalation} %
                    </p>
                    <p className="mb-0" style={{ fontSize: "15px" }}>
                      ESCALATION
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertySecondTenant;
