import React, { useState, useEffect } from "react";
import BgImage from "../viewProperties/assets/propertiesBuildingBgImg.png";
import style from "./purchaseBricks.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import TermsOfUse from "../footerLinks/TermsOfUse";
import Axios from "axios";
import { useSelector } from "react-redux";

let PurchaseBricksTitle = (props) => {
  let history = useHistory();
  let location = useLocation();
  let [accept, setAccept] = useState(false);
  let [termsError, setTermsError] = useState(false);
  let [message, setMessage] = useState(null);
  let [valuesObject, setValuesObject] = useState({
    Quantity: 100,
    AvgBrickPrice: 9500,
    TotalOrder: undefined,
    LegalProfessionalFees: props?.property?.LegalProfessionalFees,
    StampDuty: props?.property?.StampDuty,
    Brokerage: props?.property?.BuyerAgentFees,
    OtherCosts: props?.property?.OtherCosts,
    BonusBricks: 9500,
    OrderTotal: null,
    PropertyID:props?.property?.PropertyID
  });

  let acceptTerms = (e) => {
    if (e.target.checked) {
      setAccept(true);
      setTermsError(false);
    } else {
      setTermsError(true);
    }
  };

  
  let handleQuantity = (e) => {
    if (e.target.value > 0) {
      setValuesObject({
        ...valuesObject,
        Quantity: e.target.value,
        TotalOrder: e.target.value * valuesObject.AvgBrickPrice,
        OrderTotal: getOrderTotal(),
      });
    }
  };
  let getOrderTotal = () => {
    return (
      valuesObject.Quantity * valuesObject.AvgBrickPrice +
      valuesObject.LegalFee +
      valuesObject.StampDuty +
      valuesObject.Brokerage +
      valuesObject.OtherExpenses - valuesObject.BonusBricks * valuesObject.AvgBrickPrice
    );
  };
  const user_id = useSelector((state) => state.user.UserID) || null;
  useEffect(() => {
    console.log("localstorage", localStorage.getItem("user"));
    setValuesObject({
      ...valuesObject,
      UserID: localStorage.getItem("user"),
      Quantity: 100,
      AvgBrickPrice: props?.property?.InitialBrickPrice,
      TotalOrder: 100 * props?.property?.InitialBrickPrice,
      LegalFee: props?.property?.LegalProfessionalFees,
      StampDuty: props?.property?.StampDuty,
      Brokerage: props?.property?.BuyerAgentFees,
      OtherExpenses: props?.property?.OtherCosts,
      BonusBricks: props?.property?.bonusBricks,
      // OrderTotal: null,
      PropertyID:props?.property?.PropertyID,
      OrderTotal: getOrderTotal(),
      TotalOrder: valuesObject.Quantity * valuesObject.AvgBrickPrice,
    })
  }, [user_id,props]);

  let confirmPurchace = async () => {
    if (accept === true && termsError === false) {
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `${process.env.REACT_APP_API_URL}/purchaserequests`;
      await Axios.post(dataURL, JSON.stringify(valuesObject), config)
        .then((response) => {
          if (response.data.Status === "1" || response.data.Status === 1) {
            const purchaseID=response.data.PurchaseRequestID;
              history.push(`/properties/${valuesObject.PropertyID}/buy/confirm/${purchaseID}`);
              setMessage(response.data.Message)
          } else if (response.data.Status === "0" || response.data.Status === 0) {
            console.log(response.data.Message);
            setMessage(response.data.Message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else setTermsError(true);
  };

  return (
    <React.Fragment>
      <div className="div">
        <img
          src={BgImage}
          style={{ minHeight: "300px", maxHeight: "370px", width: "100%" }}
          alt=""
          className="img-fluid"
        />
      </div>

      <div className="container px-md-5 px-2  px-2 my-5">
        <div className="mx-md-5 mx-0">
          <p
            style={{ fontWeight: "600", fontSize: "22px" }}
            className="mb-0 text-center my-4"
          >
            {props?.property?.PropertyTitle} ({valuesObject?.PropertyID})
          </p>
          <div className="border">
            <div className="d-flex border-bottom py-2 px-5 justify-content-between">
              <p
                style={{ fontWeight: "600", fontSize: "20px" }}
                className="mb-0"
              >
                Place Your Order
              </p>
              <p
                style={{ fontWeight: "600", fontSize: "20px" }}
                className="mb-0 ml-auto"
              >
                Bonus Bricks Available: {valuesObject?.BonusBricks}
              </p>
            </div>
            <div className="d-flex flex-wrap mt-2 mb-0  px-5 justify-content-between">
              <div className="text-center">
                <p
                  style={{ fontWeight: "500", fontSize: "18px" }}
                  className="mb-0"
                >
                  Quantity
                </p>
                <input
                  type="number"
                  className="form-control text-center py-0  "
                  onChange={(e) => handleQuantity(e)}
                  value={valuesObject.Quantity}
                  style={{
                    borderRadius: "10px",
                    minWidth: "70px",
                    width: "auto",
                    maxWidth: "150px",
                    height: "35px",
                  }}
                />
              </div>
              <div className="text-center">
                <p
                  style={{ fontWeight: "500", fontSize: "18px" }}
                  className="mb-0"
                >
                  av.Brick Price
                </p>
                <p className="text-center text-muted mb-0">
                  ₹{valuesObject.AvgBrickPrice}
                </p>
              </div>
              <div className="text-center">
                <p
                  style={{ fontWeight: "500", fontSize: "18px" }}
                  className="mb-0"
                >
                  Total Order
                </p>
                <p className="text-center text-muted mb-0">
                  ₹{valuesObject.Quantity * valuesObject.AvgBrickPrice}
                </p>
              </div>
            </div>
            <div
              className="d-flex align-items-center mb-2 mt-3 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 text-muted">
                {valuesObject.Quantity} &nbsp;{" "}
                <span className="text-dark"> Bricks</span>&nbsp; @
                {valuesObject.AvgBrickPrice}
              </p>
              <p className="mb-0 ml-auto text-muted">
                ₹{valuesObject.Quantity * valuesObject.AvgBrickPrice}
              </p>
            </div>
            <div
              className="d-flex align-items-center my-2 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 ">Legal Fee</p>
              <p className="mb-0 ml-auto text-muted">
                ₹{valuesObject.LegalFee}
              </p>
            </div>
            <div
              className="d-flex align-items-center my-2 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 ">Stamp Duty</p>
              <p className="mb-0 ml-auto text-muted">
                ₹{valuesObject.StampDuty}
              </p>
            </div>
            <div
              className="d-flex align-items-center my-2 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 ">Brokerage</p>
              <p className="mb-0 ml-auto text-muted">
                ₹{valuesObject?.Brokerage}
              </p>
            </div>
            <div
              className="d-flex align-items-center my-2 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 ">Other Expenses</p>
              <p className="mb-0 ml-auto text-muted">
                ₹{valuesObject?.OtherExpenses}
              </p>
            </div>
            <div
              className="d-flex align-items-center my-2 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 ">Bonus Bricks</p>
              <p className="mb-0 ml-auto text-muted">
                ₹-{valuesObject.BonusBricks * valuesObject.AvgBrickPrice}
              </p>
            </div>
            <div
              className="d-flex align-items-center my-2 py-2 px-5"
              style={{ backgroundColor: "#FFE2DB" }}
            >
              <p className="mb-0 ">Order total</p>
              <p className="mb-0 ml-auto text-muted">₹{getOrderTotal()}</p>
            </div>
            <div className="my-4 mx-5 px-md-5 px-2">
              <div className="form-group textAreaScrollbar">
                <div
                  className=" textAreaScrollbar border "
                  style={{
                    minHeight: "300px",
                    height: "300px",
                    overflowY: "auto",
                  }}
                >
                  <TermsOfUse acceptTerms={true} />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center my-4">
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  onChange={acceptTerms}
                  required={true}
                  className="custom-control-input"
                  id="defaultUnchecked"
                />
                <label
                  style={{ fontSize: "17px" }}
                  className="custom-control-label"
                  htmlFor="defaultUnchecked"
                >
                  I agree to this transaction{" "}
                  <span style={{ color: "#f79883" }}> Terms & Conditions</span>
                </label>
              </div>
            </div>
            {termsError && (
              <p
                className="my-2 mx-auto text-center"
                style={{ color: "rgb(255, 130, 130)" }}
              >
                Accept terms first
              </p>
            )}
            <div className="text-center mb-4">
              {/*<Link to={"/properties/one/buy/confirm"}>*/}
              {message && 
              <p className="text-center my-2">{message}</p>}
              <div
                onClick={confirmPurchace}
                className="btn px-5 text-white py-2 text-center text-capitalize"
                style={{
                  backgroundColor: "#FF8282",
                  fontSize: "17px",
                  borderRadius: "8px",
                }}
              >
                Confirm Purchase
              </div>
              {/*</Link>*/}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PurchaseBricksTitle;
