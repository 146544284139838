import React from "react";
import resourcesBGImg from "./assets/resourcesBGImg.png";

let ResourseTitle=(props)=>{
    return(
        <React.Fragment>
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{minHeight:"200px",maxHeight:"250px"}} src={resourcesBGImg}/>
                    <p style={{fontWeight:"800",fontSize:"40"}}
                        className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        {props?.title}</p>
                </div>
            </div>
        </React.Fragment>
    )
};
export default ResourseTitle;