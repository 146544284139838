import React from "react";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import InvestorHome from "./InvestorHome";
import WhereOurUser from "./WhereISUser";
import OurMember from "../../homepage/homepageComponents/OurMembers";

let InvestorProfile=()=>{
    return(
        <React.Fragment>
            <Navbar activeDropdown={"investorProfile"}/>
            <InvestorHome/>
            <OurMember/>
            <WhereOurUser/>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default InvestorProfile;