import {
    LOGIN_USER_REQUEST,LOGIN_USER_SUCCESS,LOGIN_USER_FAILURE,LOGOUT_USER
} from "./users.actionTypes";

export const USER_FEATURE_KEY = 'user';

let initialState = {
    loading : false,
    UserID:null,
};

let userReducer = (state = initialState , action) => {
    let {type , payload} = action;
    switch(type) {
        // Login a user
        case LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case LOGIN_USER_SUCCESS:
            localStorage.setItem('user', JSON.stringify(payload));
            console.log("user login success");
            return {
                ...state,
                loading: false,
                UserID:payload,
            };
        case LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                UserID:null,
            };
        // Logout User
        case LOGOUT_USER :
            return  {
                ...state,
                UserID:null
            };
        default : return state;
    }
};
export {userReducer};
