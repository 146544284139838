import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/BrickberryLogo.png";

let NavbarPin = () => {
  let [active, setActive] = useState(1);
  let [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-white bg-white text-dark">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setOpen(!open)}
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarLeftAlignExample"
            aria-controls="navbarLeftAlignExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" />
          </button>

          <div
            className="collapse navbar-collapse py-2 mx-0 px-0"
            id="navbarLeftAlignExample"
          >
            {/*<a className="navbar-brand" href="#">*/}
            {/*    <img width={200} src={Logo}/>*/}
            {/*</a>*/}
            <ul className="navbar-nav w-100 d-md-flex d-block justify-content-around mx-auto">
              <div>
                <li className="nav-item ">
                  <Link to={"/"} className="navbar-brand mx-5 " href="">
                    <img alt="" width={200} src={Logo} />
                  </Link>
                </li>
              </div>
              <div className="d-flex justify-content-around align-items-center w-50">
                <li className="nav-item">
                  <Link
                    to="/"
                    className="nav-link pb-0 "
                    onClick={() => setActive(1)}
                    style={{ color: "black" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/HowItWorks"
                    className="nav-link pb-0"
                    onClick={() => setActive(2)}
                    // style={active === 2 ? {borderBottom:"2px solid #ef7a7a", color:"#ef7a7a"}:null}
                    style={{ color: "black" }}
                  >
                    How It Works
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/properties"}
                    className="nav-link pb-0"
                    onClick={() => setActive(3)}
                    style={{ color: "black" }}
                  >
                    Properties
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/dashboard/overview"}
                    className="nav-link pb-0"
                    onClick={() => setActive(4)}
                    style={{ color: "black" }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to={"/"}
                    className="nav-link text-dark dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Resources
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link to={"/"} className="dropdown-item">
                        Action
                      </Link>
                    </li>
                    <li>
                      <Link to={"/"} className="dropdown-item" href="#">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link to={"/"} className="dropdown-item" href="#">
                        Something else here
                      </Link>
                    </li>
                  </ul>
                </li>
              </div>
            </ul>
          </div>

          {open && (
            <div className=" py-2 mx-0 px-0" id="navbarLeftAlignExample">
              {/*<a className="navbar-brand" href="#">*/}
              {/*    <img width={200} src={Logo}/>*/}
              {/*</a>*/}
              <ul className="navbar-nav w-100 d-block justify-content-around mx-auto">
                <li className="nav-item ">
                  <Link className="navbar-brand mx-5" href="#">
                    <img alt="" width={200} src={Logo} />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/"}
                    className="nav-link pb-0"
                    onClick={() => setActive(1)}
                    style={{ color: "black" }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/HowItWorks"}
                    className="nav-link pb-0"
                    onClick={() => setActive(2)}
                    style={{ color: "black" }}
                  >
                    How It Works
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/properties"}
                    className="nav-link pb-0"
                    onClick={() => setActive(3)}
                    style={{ color: "black" }}
                  >
                    Properties
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link pb-0"
                    onClick={() => setActive(4)}
                    to={"/dashboard"}
                    style={{ color: "black" }}
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to={"/"}
                    className="nav-link text-dark dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Resources
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link to={"/"} className="dropdown-item" href="#">
                        Action
                      </Link>
                    </li>
                    <li>
                      <Link to={"/"} className="dropdown-item" href="#">
                        Another action
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link to={"/"} className="dropdown-item" href="#">
                        Something else here
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </React.Fragment>
  );
};
export default NavbarPin;
