import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./imageSlider.css";
// import bed from "../../../assets/bed.png";
import car from "../../../assets/img/car.png";
import bed from "../../../assets/bedLogo.png";
import floorNumber from "../../../assets/img/floorNumberImg.png";

// Responsive Carosel 
export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, 
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, 
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, 
  },
};
let ImageSlider = (props) => {
  let [imageModal, setImageModal] = useState();

  let openImageModal = (image) => {
    setImageModal(image);
  };
  let closeImageModal = () => {
    setImageModal(undefined);
  };
  return (
    <div style={{ position: "relative" }}>
      {props?.property?.PropertyImages?.length > 0 ? (
        <div className="propertyImageSlider">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}
            responsive={responsive}
            keyBoardControl={true}
          >
            {props?.property?.PropertyImages?.map((image) => {
              return (
                <div
                  key={image}
                  onClick={() =>
                    openImageModal(image)
                  }
                >
                  <img
                    style={{
                      minHeight: "300px",
                      maxHeight: "300px",
                      width: "100%",
                      cursor:"pointer"
                    }}
                    src={image}
                    alt=""
                  />
                </div>
              );
            })}
          </Carousel>
          <div
            className=" text-white rounded-pill px-3 py-1 carBedroomSection"
          >
            {props?.property?.Bedrooms >= 0 && (
              <div className="text-center">
                <p
                  className="font-weight-normal text-white mb-0 "
                  style={{ fontSize: "small" }}
                >
                  {props?.property?.Bedrooms}
                </p>
                <img
                  className="mx-2 carBedFloorImg"
                  src={bed}
                  alt=""
                />
              </div>
            )}
            {props?.property?.CarParking >= 0 && (
              <div className="text-center">
                <p
                  className="font-weight-normal text-white mb-0 "
                  style={{ fontSize: "small" }}
                >
                  {props?.property?.CarParking}
                </p>
                <img
                  className="mx-2 carBedFloorImg"
                  src={car}
                  alt=""
                />
              </div>
            )}
            {props?.property?.FloorNo >= 0 && (
              <div className="text-center">
                <p
                  className="font-weight-normal text-white mb-0 "
                  style={{ fontSize: "small" }}
                >
                  {props?.property?.FloorNo}
                </p>
                <img
                  className="mx-2 carBedFloorImg"
                  src={floorNumber}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="propertyImageSlider" onClick={()=>{setImageModal(props?.property?.PropertyImages)}}>
           <img
                    style={{
                      minHeight: "300px",
                      maxHeight: "300px",
                      width: "100%",
                      cursor:"pointer"
                    }}
                    src={props?.property?.PropertyImages }
                    alt=""
                  />
                  <div
            className=" text-white rounded-pill px-3 py-1 carBedroomSection"
          >
            {props?.property?.Bedrooms >= 0 && (
              <div className="text-center">
                <p
                  className="font-weight-normal text-white mb-0 "
                  style={{ fontSize: "small" }}
                >
                  {props?.property?.Bedrooms}
                </p>
                <img
                  className="mx-2 carBedFloorImg"
                  src={bed}
                  alt=""
                />
              </div>
            )}
            {props?.property?.CarParking >= 0 && (
              <div className="text-center">
                <p
                  className="font-weight-normal text-white mb-0 "
                  style={{ fontSize: "small" }}
                >
                  {props?.property?.CarParking}
                </p>
                <img
                  className="mx-2 carBedFloorImg"
                  src={car}
                  alt=""
                />
              </div>
            )}
            {props?.property?.FloorNo >= 0 && (
              <div className="text-center">
                <p
                  className="font-weight-normal text-white mb-0 "
                  style={{ fontSize: "small" }}
                >
                  {props?.property?.FloorNo}
                </p>
                <img
                  className="mx-2 carBedFloorImg"
                  src={floorNumber}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      )}

      {imageModal && (
        <div className="imagePopupModalProperty">
          <div style={{ position: "relative" }}>
            <i
              onClick={closeImageModal}
              className="fa fa-2x fa-times imgPopupCloseIcon"
            />
            <img src={imageModal} className="imageModalProperty" />
          </div>
        </div>
      )}
    </div>
  );
};
export default ImageSlider;
