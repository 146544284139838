import React, { useState } from "react";
import 'react-multi-carousel/lib/styles.css';
import ProfilePic1 from "../../../assets/img/profilePic1.png";
import ProfilePic2 from "../../../assets/img/profilePic2.png";
import ProfilePic3 from "../../../assets/img/profilePic3.png";
import LinkedIn from "../../../assets/img/linkedIn.png";
import PlayBtn from "../../../assets/img/video.png";
import blackRectangle from "../../../assets/img/blackRactangle.png";
import verifiedImg from "../../../assets/img/verifiedImg.png";
import redDot from "../../../assets/rredDot.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        // partialVisibilityGutter: 40
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,

    }
};
let memberArray = [
    {
        id: "1", name: "Miss.Ankita Thakur", designation: "Manager At Ansh", title: "The Brickberry team were really",
        info: "The Brickberry team were really helpful helpfuling me set up my SMSF And Explained",
        video: "https://www.youtube.com/embed/f_L_L_Dyfrs",
        img: ProfilePic1
    },
    {
        id: "2", name: "Mr.Sunil Patil", designation: "CO-Founder At Go2agree", title: "Impressive Platform!",
        info: "Recently joined BrickBerry and most impressed the platform.",
        video: "https://www.youtube.com/embed/tEv7ixEzjdQ",
        img: ProfilePic2
    },
    {
        id: "3", name: "Miss.Payal Shinde", designation: "Manager At Svr media", title: "Greatway of getting int....",
        info: "Great way to getting into property investment easy to use,low entry cost.",
        video: "https://www.youtube.com/embed/f_L_L_Dyfrs",
        img: ProfilePic3
    },
    {
        id: "4", name: "MR. Vishal Dilwale", designation: "Dev Community", title: "Awesome team",
        info: "The Brickberry team were really helpful helpfuling me set up my SMSF And Explained",
        video: "https://www.youtube.com/embed/f_L_L_Dyfrs",
        img: ProfilePic1
    },
    {
        id: "5", name: "Miss.Ankita Thakur", designation: "Manager At Ansh", title: "The Brickberry team were really",
        info: "The Brickberry team were really helpful helpfuling me set up my SMSF And Explained",
        video: "https://www.youtube.com/embed/tEv7ixEzjdQ",
        img: ProfilePic2
    },
];
const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group position-relative">
            <button aria-label="Go to next slide"
                onClick={() => next()}
                // style={{top:"-200px !important",right:"-100px"}}
                className={currentSlide === 0 ? 'disable react-multiple-carousel__arrow react-multiple-carousel__arrow--right selectListingNextBtn' : 'react-multiple-carousel__arrow react-multiple-carousel__arrow--right selectListingNextBtn'}
                type="button" />
            <button aria-label="Go to previous slide"
                onClick={() => previous()}
                className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left selectListingPrevBtn"
                type="button" />
            {/*<div style={{height:"20px",width:"20px",backgroundColor:"black",position:"relative"}}*/}
            {/*     className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />*/}
            {/*<div style={{height:"20px",width:"20px",backgroundColor:"black",position:"relative"}} onClick={() => next()} />*/}
            {/*<div style={{height:"20px",width:"20px",backgroundColor:"black",position:"relative"}}*/}
            {/*     onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </div>*/}
        </div>
    );
};


let OurMembers = () => {

    return (
        <React.Fragment>
            <div style={{ padding: "10px", marginTop: "30px" }}>
                <div className="container d-block">
                    <p className=" mx-auto" style={{ height: "5px", width: "50px", backgroundColor: "black" }} />
                    <h4 className="h1 text-center mb-0">What our Users are saying about us!</h4>
                    <div className='my-own-custom-container'>
                        <div className="ourMembers mt-5">
                            <Carousel arrows={false} renderButtonGroupOutside={true} customButtonGroup={<ButtonGroup />}
                                responsive={responsive}>
                                {memberArray.map((member) => {
                                    return (
                                        <React.Fragment key={member.id}>
                                            <div className="  h-100 m-2"  >
                                                <div className="card h-100 border-0 shadow-none mx-2">
                                                    <div style={{ position: "relative" }}>
                                                        {/*<img*/}
                                                        {/*    src={member.img}*/}
                                                        {/*    className="card-img-top"*/}
                                                        {/*    alt="..."*/}
                                                        {/*/>*/}
                                                        <iframe width="100%" height="100%"
                                                            className=""
                                                            style={{ height: "200px" }}
                                                            src={member.video}
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                            allowFullScreen />
                                                        {/*<img*/}
                                                        {/*    src={blackRectangle}*/}
                                                        {/*    style={{position:"absolute",zIndex:"2",top:"0",left:"0"}}*/}
                                                        {/*    className="card-img-top "*/}
                                                        {/*    alt="..."*/}
                                                        {/*/>*/}
                                                        {/*<img src={LinkedIn} height={28} width={28} style={{position:"absolute",backgroundColor:"white", top:"5%", left:"5%"}} alt="linkedIn"/>*/}
                                                        {/*<img src={PlayBtn} height={28} width={28} style={{position:"absolute",backgroundColor:"white",borderRadius:"50%", top:"5%", right:"5%"}} alt="play"/>*/}
                                                        <div className="w-100 text-center text-white membersName"
                                                            style={{ position: "absolute", bottom: "-2%", zIndex: "3" }}>
                                                            <p style={{ lineHeight: "1px" }} className="mb-0">{member.name}</p>
                                                            <p className="mt-1" >{member.designation}</p>
                                                        </div>
                                                    </div>
                                                    <div className="card-body border-0  shadow-none py-2 px-4 " style={{ backgroundColor: "#ededed", marginTop: "-2%" }}>
                                                        <h5 className="card-title text-center h5" style={{ fontWeight: "700", fontSize: "20px" }}>
                                                            {member.title}</h5>
                                                        <span className="card-text text-dark  mx-auto mb-2" style={{ fontWeight: "500", fontSize: "17px", lineHeight: "18px" }}>
                                                            {member.info}
                                                            {/*<div className="text-center mt-1">*/}
                                                            {/*    <img className="text-center" src={verifiedImg} height={18} width={100} alt=""/>*/}
                                                            {/*</div>           */}
                                                        </span>
                                                        <span className="d-flex my-2 justify-content-center align-items-center">
                                                            <div className="d-flex align-items-center justify-content-center">
                                                                <img src={redDot} height={16} style={{ position: "absolute", zIndex: "1" }} alt="" />
                                                                <i style={{ position: "relative", zIndex: "2", height: "5px", top: "-8px", left: "20%" }}
                                                                    className="fa fa-check text-black-50"
                                                                    aria-hidden="true" />
                                                            </div>
                                                            <small className=" mx-2 mb-0">Verified Investor</small>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>

                                    )
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
};
export default OurMembers;
