import React from "react";
import BrikBerryPropertiesImg from "../../../assets/img/OBJECTSladyStandingImg.png"
import { useHistory } from "react-router-dom";
let BrickBerryProperties = () => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div id="properties" style={{ padding: "10px", marginTop: "20px" }}>
                <div className="container-fluid d-block">
                    <p className=" mx-auto" style={{ height: "5px", width: "50px", backgroundColor: "rgb(22, 142, 186)" }}></p>
                    <h4 className="h1 text-center mb-0" style={{ fontWeight: "700", fontSize: "40px" }}> Brick Berry
                        Properties</h4>
                    <div className="row my-4 pt-2 d-md-flex px-4 justify-content-center mx-auto">
                        <div className="col-md-4 px-md-3 px-2 mr-2 ">
                            <div className="">
                                <div className=" text-center">
                                    <h5 className="d-flex justify-content-center mb-4 mt-2 align-baseline font-weight-bolder brickBeryPropertiesBar"
                                        style={{
                                            border: "1px solid #FF592D",
                                            borderRadius: "30px",
                                            minHeight: "60px",
                                            minWidth: "200px",
                                            alignItems: "center"
                                        }}>Historical suburb growth</h5>
                                    <h5 className=" d-flex justify-content-center my-4 align-baseline font-weight-bolder brickBeryPropertiesBar"
                                        style={{
                                            border: "1px solid #FFA800",
                                            borderRadius: "30px",
                                            minHeight: "60px",
                                            minWidth: "200px",
                                            alignItems: "center"
                                        }}>
                                        Forecasted cash flows</h5>
                                    <h5 className="d-flex justify-content-center mb-4 mt-2 align-baseline font-weight-bolder brickBeryPropertiesBar"
                                        style={{
                                            border: "1px solid #98BC56",
                                            borderRadius: "60px",
                                            minHeight: "60px",
                                            minWidth: "200px",
                                            alignItems: "center"
                                        }}>
                                        Independent property valuations</h5>
                                    <h5 className=" d-flex justify-content-center my-4 align-baseline font-weight-bolder brickBeryPropertiesBar"
                                        style={{
                                            border: "1px solid #DC264F",
                                            borderRadius: "60px",
                                            minHeight: "60px",
                                            minWidth: "200px",
                                            alignItems: "center",
                                            fontWeight: "10px"
                                        }}>
                                        Estimated net rental income
                                        and yield</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 ml-md-5 mx-2 ">
                            <h5 className="font-weight-bolder">
                                Our team of investment experts have personally researched each of these properties and hand-picked them specifically for you.
                            </h5>
                            <h5 className="font-weight-bolder my-4">
                                Your investment is backed by a strong underlying asset class. We make it easy for you to create a diversified portfolio that is not limited by geography or time. Brick Berry is a strategic platform for real estate investment. Our digital platform lists best of its class fractional ownership opportunities available in India.</h5>
                            <h5 className="font-weight-bolder">Only the top opportunities available are listed.
                                Our strategic platform team understands the importance of Regulatory compliance, Digital technology, and Secured transactions. Additionally, it provides an AI based smart dashboard to track your property investments & bricks appreciation every quarter.</h5>

                        </div>
                    </div>
                    <div className="text-center mt-4 mb-3">
                        <button
                            onClick={() => {
                                window.scrollTo(0, 0);
                                history.push("/dashboard/overview")
                            }}
                            className="btn btn-lg px-4 py-3 font-weight-bold text-capitalize text-white" style={{
                                background: 'linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)', borderRadius: "10px"
                            }}>
                            Explore AI Dashboard
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
};
export default BrickBerryProperties;
