import React from "react";
import whyFractionalOwnerImg from "../assets/whyFractionalOwner.png"
import spvImg from "../assets/SPVImg.png"
import circleImg from "../../../assets/img/circle.png"
import circleFaint from "../assets/circleFaint.png"
import buildMyOwnImg from "../assets/BuildMyOwnImg.png"
import AIImg from "../assets/girlStanding.png"
import capitalReturnImg from "../assets/capitalRetuenImg.png"
import MonthlyRentImg from "../assets/MonthlyRentimg.png"
let WhyFractionalOwner = () => {
    return (
        <React.Fragment>
            <div>
                {/*<p className="h2 font-weight-bold text-center my-4">Why Fractional Ownership?</p>*/}
                <div style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                    <div className="video-container">
                        <iframe style={{ width: "90vw", height: '600px', }} src="https://www.youtube.com/embed/n6X2D0tWgX0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
            <div>
                <p className="h2 font-weight-bold text-center my-4">SPV- Special Purpose Vehicle</p>
                <div className="text-center" >
                    <img src={spvImg} style={{ boxShadow: "1px 2px  #eaeaea", borderRadius: "30px" }} className="img-fluid" alt="img" />
                </div>
            </div>
            <div className="container-fluid my-5 position-relative">
                <img alt="img" className="position-absolute" height={100} style={{ top: "-20px", left: "9%" }} src={circleFaint} />
                <img alt="img" className="position-absolute" height={50} style={{ top: "100px", left: "7%" }} src={circleFaint} />
                <div className="container px-1">
                    <div className="row justify-content-around align-items-center mx-md-auto mx-4 ">
                        <div className="col-md-3 p-3 my-2 d-flex flex-column justify-content-center align-items-center" style={{ border: "1px solid rgb(155, 205, 42)", borderRadius: "10px", minHeight: "250px" }}>
                            <div className=" img-fluid text-center d-flex justify-content-center align-items-center">
                                <img src={circleImg} className="position-absolute text-center" height={25} alt="img" />
                                <p className="position-relative mb-0 text-center text-white">1</p>
                            </div>
                            <p className='my-auto font-weight-normal'>Monthly rent paid by Tenant to the  SPV, SPV then distributes the rent to the
                                Users in proportion of thier investment in the property.</p>
                        </div>
                        <div className="col-md-3 p-3 my-2 d-flex flex-column justify-content-center align-items-center" style={{ border: "1px solid rgb(155, 205, 42)", borderRadius: "10px", minHeight: "250px" }}>
                            <div className=" img-fluid text-center d-flex justify-content-center align-items-center">
                                <img src={circleImg} className="position-absolute text-center" height={25} alt="img" />
                                <p className="position-relative mb-0 text-center text-white">2</p>
                            </div>
                            <p className="my-auto font-weight-normal">If, SPV issues shares, then rental income will be considered as dividend.
                                If SPV issues debentures,then the rental income will be considered as interest.</p>
                        </div>
                        <div className="col-md-3 p-3 my-2 d-flex flex-column justify-content-center align-items-center" style={{ border: "1px solid rgb(155, 205, 42)", borderRadius: "10px", minHeight: "250px" }}>
                            <div className=" img-fluid text-center d-flex justify-content-center align-items-center">
                                <img src={circleImg} className="position-absolute text-center" height={25} alt="img" />
                                <p className="position-relative mb-0 text-center text-white">3</p>
                            </div>
                            <p className="my-auto h6 font-weight-normal">Since, divided distribution tax (DDT) has been abolished, the income received in
                                the form of dividend is taxable in the hands of  Users making it similar to taxation of interest income.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-5">
                        <p className="h2 text-md-left ml-md-5 text-center" style={{ fontWeight: "700" }}>Ways of Investing ? </p>
                        <div className="position-relative">
                            <img src={AIImg} alt="img" className="investingWaysImg" style={{ position: "relative", maxHeight: "500px", left: "-100px", width: "350px" }} />
                        </div>
                    </div>
                    <div className="col-md-7 px-4">
                        <p className="h2 mb-0" style={{ fontWeight: "700" }}>AI SMART</p>
                        {/* <p style={{ color: "#ff9f9f" }} className="font-weight-bold mb-2">( Platinum Membership )</p> */}
                        <p className="font-weight-bold mb-0" >Simple way to invest</p>
                        <p className="h6 font-weight-bolder">Our AI powered Dashboard will make the hard work of managing your real estate portfolio seem like a breeze.</p>


                        <p className="h6 font-weight-bolder mb-3">
                            Simply choose your city and an amount to invest and it will handle all the tedious tasks for you. Our unique Smart Invest solution will help you invest your distributions in properties that we expect to outperform the market. We measure this annually against the CoreLogic Home Value Index.
                        </p>

                    </div>
                </div>
            </div>
            <div className="container " style={{ marginTop: "50px" }}>
                <div className="row">
                    <div className="col-md-7 px-5">
                        <p className="h2 ml-md-5 mx-auto" style={{ fontWeight: "700" }}>Build My Own</p>
                        <p className="h6 font-weight-normal ml-md-4 mx-auto mb-3 mt-4">Be in control of the properties you invest in and make your own investment goals. Our hassle-free solutions help you build your portfolio and manage investments easily.</p>
                        <p className="h6 font-weight-normal ml-md-4 mx-auto mb-3">Our experts at Brick-Berry source properties, break them into 'Bricks' for you to Buy and Hold. Remember the value of your ‘ BRICK ’ changes according to your property SQ.FT prices.</p>

                    </div>
                    <div className="col-md-5 text-center">
                        <img alt="img" className="buildMyOwnImgStyles" style={{ right: "-200px", position: "relative" }} width={300} src={buildMyOwnImg} />
                    </div>
                </div>
            </div>
            <div className="container-fluid p-5 my-5" style={{ backgroundColor: "rgb(36, 43, 46)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 pl-md-5 px-auto d-flex justify-content-center align-items-center">
                            <p className="h2  px-auto text-center" style={{ fontWeight: "700", color: 'white' }}> How do I make money?</p>
                        </div>
                        <div className="col-md-9">
                            <div className="d-md-flex d-block ">
                                <div className="d-flex justify-content-center align-items-center mx-auto" style={{ height: "80px", width: "200px", borderRadius: "50%", backgroundColor: "#c4c4c4" }}>
                                    <img alt="capitalReturnImg" className="m-auto my-auto d-flex justify-content-center align-items-center" height={30} width={30} src={capitalReturnImg} />
                                </div>
                                <div className="ml-5">
                                    <p className="h6 mb-3" style={{
                                        color: 'white', fontSize: '1.2rem', fontWeight: 700
                                    }}>Monthly Rent Distributions</p>
                                    < p className="font-weight-normal font-small mb-3" style={{
                                        color: 'white', fontSize: '1rem'
                                    }}> AAn excellent way to diversify your portfolio and generate high rental income. With Brick-Berry, collect your monthly share of rental income from any type of Commercial property you have invested in! You can track your rentals & monitor the property growth using your AI Smart Dashboard.
                                    </p>
                                    <p className="font-weight-normal font-small" style={{
                                        color: 'white', fontSize: '1rem'
                                    }}>We aim to provide hassle-free solutions with best property managers located across the country.</p>
                                </div>
                            </div>
                            <div className="d-md-flex d-block mt-5">
                                <div className="d-flex justify-content-center mx-md-0 mx-auto align-items-center" style={{ height: "80px", width: "150px", borderRadius: "50%", backgroundColor: "#c4c4c4" }}>
                                    <img alt="capitalReturnImg" className="m-auto my-auto d-flex justify-content-center align-items-center"
                                        height={30} width={40} src={MonthlyRentImg} />
                                </div>
                                <div className="ml-5">
                                    <p className="h6 mb-3" style={{
                                        color: 'white', fontSize: '1.1rem', fontWeight: 700
                                    }}>CAPITAL RETURNS</p>
                                    <p className="font-weight-normal font-small mb-3" style={{
                                        color: 'white', fontSize: '1rem'
                                    }}>Brick-Berry allows you to bank on property as an investment that can be liquidated at any time.</p>
                                    <p className="font-weight-normal font-small" style={{
                                        color: 'white', fontSize: '1rem'
                                    }}>  The performance of your property is linked to actual market trends. Get a property valuation review every 6 months and keep track of performance. You can release capital by selling your bricks in the property’s value anytime.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment >
    )
};
export default WhyFractionalOwner;
