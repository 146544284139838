import React, { useState, useEffect, useMemo } from "react";
import SideNav from "../SideNav";
import NavbarDashboard from "../NavbardDashboard";
import documentUploadLogo from "../assets/documentUploadLogo.png";
import uploadLogo from "../assets/uploadLogo.png";
import { getBrickStatusbyUserId } from '../../../services/ApiServices';


let BricksStatusContent = () => {
    const [brickStatus, SetBrickStatus] = useState([]);

    useEffect(() => {
        console.log(localStorage.getItem('user'));
        getBrickStatusbyUserId(localStorage.getItem('user')).then((response) => {
            if (response && response.PurchaseRequests) {
                SetBrickStatus(response.PurchaseRequests);
            }
        });
        window.scrollTo(0, 0);
    }, []);
    console.log("brickStatus", brickStatus);


    return (
        <React.Fragment>
            <div className="">
                {/*--------------- BRICKS Alloted -----------------------*/}
                <div className="d-flex justify-content-center text-center mt-2 mb-0">
                    <p style={{ color: "rgb(22, 142, 186)", fontWeight: "400", fontSize: "16px" }}> BRICKS Alloted</p>
                </div>
                <div className="border mt-0 mb-3 pb-2" style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>
                    <div className="row justify-content-around px-4 py-2 my-2"
                        style={{ backgroundColor: "rgb(238, 255, 199)", marginLeft: "0.02%", marginRight: "0.02%" }}>
                        <div className=' py-1 col-md-5 text-center  text-center rounded'
                        >Property Name
                        </div>
                        <div className=' py-1  text-center col-md-2 text-center rounded'
                        >No of Bricks
                        </div>
                        <div className=' py-1 text-center col-md-3 text-center'
                        >Brick Price Quoted
                        </div>
                        <div className=' py-1 text-center col-md-2 text-center'
                        >Brick Status
                        </div>
                    </div>

                    {
                        brickStatus.map((item, i) =>
                            (item.Status === 1 || item.Status === 4) &&
                            <div key={i} className="row justify-content-around px-4 py-1 my-2"
                                style={{ backgroundColor: "#f8f8f8", marginLeft: "0.02%", marginRight: "0.02%" }}
                            >
                                <div className=' py-1 col-md-5 text-center  text-center rounded'
                                >{item.PropertyTitle}
                                </div>
                                <div className=' py-1  text-center col-md-2 text-center rounded'
                                >{item.Quantity}
                                </div>
                                <div className=' py-1 text-center col-md-3 text-center'
                                >{item.AvgBrickPrice}
                                </div>
                                <div className=' py-1 text-center col-md-2 text-center'
                                >{item.Status == 4 ? 'Alloted' : item.Status == 1 ? 'Waiting List' : ''}
                                </div>
                            </div>

                        )
                    }

                </div>

                {/*--------------- BRICKS for Sale -----------------------*/}
                <div className="d-flex justify-content-center text-center mt-2 mb-0">
                    <p style={{ color: "rgb(22, 142, 186)", fontWeight: "400", fontSize: "16px" }}> BRICKS for Sale</p>
                </div>
                {
                    brickStatus.map((item, i) =>
                        (item.Status === 0) &&
                        <div key={i} className="row justify-content-around px-4 py-1 my-2"
                            style={{ backgroundColor: "#f8f8f8", marginLeft: "0.02%", marginRight: "0.02%" }}
                        >
                            <div className=' py-1 col-md-5 text-center  text-center rounded'
                            >{item.PropertyTitle}
                            </div>
                            <div className=' py-1  text-center col-md-2 text-center rounded'
                            >{item.Quantity}
                            </div>
                            <div className=' py-1 text-center col-md-3 text-center'
                            >{item.AvgBrickPrice}
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >{item.Status == 0 ? 'Pending for Sale' : item.Status == 1 ? 'Waiting List' : ''}
                            </div>
                        </div>

                    )
                }
            </div>
        </React.Fragment>
    )
};
export default BricksStatusContent;