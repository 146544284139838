import React from "react";
import securePinImg from "./assets/securePinImg.png";

let SecurePinTitle=()=>{
    return(
        <React.Fragment>
            <div className="container-fluid p-0 m-0">
                <div className="position-relative">
                    <img src={securePinImg} style={{minHeight:"150px",maxHeight:"220px",width:"100%"}} className="img-fluid" alt="" />
                    <div className="card-img-overlay d-flex justify-content-end align-items-center mr-md-5 pr-md-5 mx-auto px-0">
                        <p className="mr-md-5 pr-md-5 mx-auto px-0"
                           style={{fontWeight:"900",fontSize:"40px",color:"white"}}>Secure Pin</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default SecurePinTitle;