import React, { useState, useEffect, useMemo } from "react";
import calendar from "../../assets/calendar.png";
import dropdownArrow from "../../assets/dropdownArrow.png";
import uploadLogo from "../../assets/uploadLogo.png";
import documentUploadLogo from "../../assets/documentUploadLogo.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getUserbyId, submitUserForm } from '../../../../services/ApiServices';
import { countries } from '../../../../services/country'




let PersonelDetails = (props) => {
    console.log('props', props);
    let [show, setShow] = useState("personnel");
    const [user, setUser] = useState([]);
    const [countryList, setcountryList] = useState(countries.countryList)
    let [confirmAccount, setConfirmAccount] = useState(true);
    let [previewDoc, setPreviewDoc] = useState({
        AddressProof: '',
        IDProof: '',
        PanCard: '',
    });


    useEffect(() => {
        console.log(localStorage.getItem('user'));
        console.log(countries.countryList);
        getUserbyId(localStorage.getItem('user')).then((response) => {
            if (response && response.User) {
                setUser(response.User);
                console.log(response.User.DOB);
                if (response.User.DOB != null)
                    setUserDOB(new Date(response.User.DOB));
                if (response.User.Nominee1DOB != null)
                    setNominee1DOB(new Date(response.User.Nominee1DOB));
                if (response.User.Nominee2DOB != null)
                    setNominee2DOB(new Date(response.User.Nominee2DOB));
                if (response.User.AddressProof)
                    setPreviewDoc({ AddressProof: response.User.AddressProof });
            }
        });
        window.scrollTo(0, 0);
    }, []);
    console.log("user", user);
    const [userFormData, setUserFormData] = useState({

    });

    const [userDOB, setUserDOB] = useState(new Date());
    const [nominee1DOB, setNominee1DOB] = useState(new Date());
    const [nominee2DOB, setNominee2DOB] = useState(new Date());

    const handleDate = (name, date) => {
        if (name == 'DOB')
            setUserDOB(date);
        else if (name == 'Nominee1DOB')
            setNominee1DOB(date);
        else if (name == 'Nominee2DOB')
            setNominee2DOB(date);
        setUser({
            ...user,
            ...{ [name]: moment(date).format("YYYY-MM-DD") },
        });
    }

    let handleImages = async (e) => {
        const file = e.target.files[0];
        const { name } = e.target;
        e.persist();
        let base64 = await toBase64(file);

        console.log(file);
        console.log(name);

        //console.log(url) // Would see a path?
        //setPreviewImage1(URL.createObjectURL(e.target.files[0]));
        setPreviewDoc({
            ...previewDoc,
            ...{ [name]: URL.createObjectURL(e.target.files[0]) }
        });
        //setPreviewImage1("Test URL");

        /*  let reader = new FileReader();
         //let file = e.target.files[0];
 
         reader.onloadend = () => {
         setPreviewImage1(reader.result);
         } */

        console.log(URL.createObjectURL(e.target.files[0]));

        setUser({
            ...user,
            ...{ [name]: base64 },
        });

    };

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleInput = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            ...{ [name]: value },
            //...{ DOB: moment(startDate).format("YYYY-MM-DD") },
        });
        //setUser({...user, name: e.target.value})
    };

    const handleConfirmAccountNmber = (event) => {
        console.log(user.AccountNumber);
        if (event.target.value !== user.AccountNumber) {
            setConfirmAccount(false);
        }
        else {
            setConfirmAccount(true);
        }
    }
    const submitUserData = (e) => {

        console.log(user);
        delete user.EmailID;
        submitUserForm(user).then((response) => {
            if (response.Message) {
                alert(response.Message);
            }
        });
    };

    return (
        <React.Fragment>
            <div>
                {show === "personnel" &&
                    <div className="border " style={{ borderRadius: "10px", backgroundColor: "white" }}>
                        <div className="d-flex flex-wrap justify-content-center px-4 py-2 my-2" style={{ backgroundColor: "#f0f0f1" }}>
                            <div className=' py-1  m-1 text-center rounded'
                                onClick={() => setShow("personnel")}
                                // style={{border:"1px solid rgb(22, 142, 186)",width:"180px",cursor:"pointer",backgroundColor:"rgb(22, 142, 186)",color:"white"}}
                                style={show === "personnel" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Personnel Details</div>
                            <div className=' py-1 m-1 text-center rounded'
                                onClick={() => setShow("professional")}
                                style={show === "professional" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Professional Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("KYC")}
                                style={show === "KYC" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>KYC Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("nominee")}
                                style={show === "nominee" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Nominee Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("bank")}
                                style={show === "bank" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Bank Details</div>
                        </div>
                        <div className="mx-md-5 mx-2 px-2">
                            <div className="row my-1 align-items-center">
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Full Name</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        name="UserName"
                                        value={user.UserName}
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Email ID</label>
                                    <input type="email" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.EmailID}
                                        name="EmailID"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >Secondary Email ID</label>
                                    <input type="email" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.SecondaryEmailID}
                                        name="SecondaryEmailID"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Country of Residence</label>
                                    {/* <input type="text"  style={{height:"35px",border:"1px solid rgb(22, 142, 186)"}} className="bg-transparent form-control py-1"
                            value={user.Country}
                            name="Country"
                             onChange={(e) => {
                                    handleInput(e);
                            }}
                            /> */}

                                    <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                        className="bg-transparent  form-control px-4 py-1"
                                        name="Country"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                        value={user.Country}
                                    >
                                        <option selected disabled>Select</option>
                                        {
                                            countryList.map((item, i) =>
                                                <option key={i} className="selectOption" value={item.name}>{item.name}</option>
                                            )
                                        }



                                    </select>
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Phone Number</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.MobileNumber}
                                        name="MobileNumber"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >Secondary Phone Number</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.SecondaryMobileNo}
                                        name="SecondaryMobileNo"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4 datePicker">
                                    <label className="ml-4">Date of Birth</label>
                                    <div className="position-relative d-flex">
                                        {/* <input type="date"  style={{height:"35px",border:"1px solid rgb(22, 142, 186)",position:"relative"}} className="bg-transparent form-control py-1"
                          selected={user.DOB}
                          onChange={(date) => setStartDate(date)}  /> */}



                                        {<DatePicker
                                            dateFormat="yyyy-MM-dd"
                                            selected={userDOB}
                                            yearDropdownItemNumber={15}
                                            showYearDropdown
                                            minDate={moment().subtract(500, "years")._d}
                                            maxDate={moment().subtract(18, "years")._d}
                                            showDisabledMonthNavigation
                                            //onChange={(date) => setStartDate(date) handleInput(e);}
                                            onChange={(date) => {
                                                handleDate('DOB', date);
                                            }}
                                            className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                                        />}
                                        {/*  <img alt="" style={{position:"absolute",right:"10%",top:"20%",height:"20px"}} src={calendar}/> */}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <label className="ml-4">Nationality</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.Nationality}
                                        name="Nationality"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group mx-3 my-0 py-0 w-100">
                                    <label className="ml-4">Correspondence Address</label>
                                    <textarea style={{ border: "1px solid rgb(22, 142, 186)" }}
                                        className="form-control bg-transparent rounded"
                                        rows="2"
                                        value={user.CorrespondenceAddress}
                                        name="CorrespondenceAddress"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="form-group mx-3 my-0 py-0 w-100">
                                    <label className="ml-4">Permanent Address</label>
                                    <textarea style={{ border: "1px solid rgb(22, 142, 186)" }}
                                        className="form-control bg-transparent rounded"
                                        rows="2"
                                        value={user.PermanentAddress}
                                        name="PermanentAddress"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row my-3">
                                <div className="col-md-4 selectBoxArrow">
                                    <label className="ml-4">Source</label>
                                    <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                        <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                            className="bg-transparent  form-control px-4 py-1"
                                            name="Source"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                            value={user.Source}
                                        >
                                            <option selected disabled>Select</option>
                                            <option className="selectOption" value="Salaried">Salaried</option>
                                            <option className="selectOption" value="Business">Business</option>
                                            <option className="selectOption" value="Professionals">Professionals</option>
                                            <option className="selectOption" value="Others">Others</option>
                                        </select>
                                        <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                {/*--------------- Professional ------------------------------*/}
                {show === "professional" &&
                    <div className="border " style={{ borderRadius: "10px", backgroundColor: "white" }}>
                        <div className="d-flex flex-wrap justify-content-center px-4 py-2 my-2" style={{ backgroundColor: "#f0f0f1" }}>
                            <div className=' py-1  m-1 text-center rounded'
                                onClick={() => setShow("personnel")}
                                // style={{border:"1px solid rgb(22, 142, 186)",width:"180px",cursor:"pointer",backgroundColor:"rgb(22, 142, 186)",color:"white"}}
                                style={show === "personnel" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Personnel Details</div>
                            <div className=' py-1 m-1 text-center rounded'
                                onClick={() => setShow("professional")}
                                style={show === "professional" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Professional Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("KYC")}
                                style={show === "KYC" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>KYC Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("nominee")}
                                style={show === "nominee" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Nominee Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("bank")}
                                style={show === "bank" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Bank Details</div>
                        </div>
                        <div className="mx-md-5 mx-2 px-2">
                            <div className="row my-1 align-items-center">
                                <div className="col-md-4 selectBoxArrow">
                                    <label className="ml-4">Industry</label>
                                    <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                        <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                            className="bg-transparent  form-control px-4 py-1"
                                            name="Industry"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                            value={user.Industry}
                                        >
                                            <option selected disabled>Select</option>
                                            <option className="selectOption" value="IT">IT</option>
                                            <option className="selectOption" value="Real Estate">Real Estate</option>
                                            <option className="selectOption" value="Retail">Retail</option>
                                            <option className="selectOption" value="Others">Others</option>
                                        </select>
                                        <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                    </div>
                                </div>
                                <div className="col-md-4 selectBoxArrow">
                                    <label className="ml-4">Occupation</label>
                                    <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                        <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                            className="bg-transparent  form-control px-4 py-1"
                                            name="Occupation"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                            value={user.Occupation}
                                        >
                                            <option selected disabled>Select</option>
                                            <option className="selectOption" value="Salaried">Salaried</option>
                                            <option className="selectOption" value="Business">Business</option>
                                            <option className="selectOption" value="Professionals">Professionals</option>
                                            <option className="selectOption" value="Others">Others</option>
                                        </select>
                                        <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                    </div>
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >Designation</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.Designation}
                                        name="Designation"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Current Organisation</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.CurrentOrganization}
                                        name="CurrentOrganization"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Total Experience</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.TotalExperience}
                                        name="TotalExperience"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >Income Range</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.Income}
                                        name="Income"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 mb-5 pb-2">
                                <div className="col-md-4 selectBoxArrow">
                                    <label className="ml-4">Political Exposure</label>
                                    <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                        <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                            className="bg-transparent  form-control px-4 py-1"
                                            name="PoliticalExposure"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                            value={user.PoliticalExposure}
                                        >
                                            <option selected disabled>Select</option>
                                            <option className="selectOption" value="yes">Yes</option>
                                            <option className="selectOption" value="no">No</option>
                                        </select>
                                        <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}

                {/*--------------- KYC Details ------------------------------*/}
                {show === "KYC" &&
                    <div className="">
                        <div className="border" style={{ borderRadius: "10px", backgroundColor: "white" }}>
                            <div className="d-flex flex-wrap justify-content-center px-4 py-2 my-2" style={{ backgroundColor: "#f0f0f1" }}>
                                <div className=' py-1  m-1 text-center rounded'
                                    onClick={() => setShow("personnel")}
                                    // style={{border:"1px solid rgb(22, 142, 186)",width:"180px",cursor:"pointer",backgroundColor:"rgb(22, 142, 186)",color:"white"}}
                                    style={show === "personnel" ? {
                                        border: "1px solid rgb(22, 142, 186)",
                                        width: "180px",
                                        cursor: "pointer",
                                        backgroundColor: "rgb(22, 142, 186)",
                                        color: "white"
                                    } :
                                        {
                                            border: "1px solid rgb(22, 142, 186)",
                                            width: "180px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                            color: "black"
                                        }
                                    }>Personnel Details
                                </div>
                                <div className=' py-1 m-1 text-center rounded'
                                    onClick={() => setShow("professional")}
                                    style={show === "professional" ? {
                                        border: "1px solid rgb(22, 142, 186)",
                                        width: "180px",
                                        cursor: "pointer",
                                        backgroundColor: "rgb(22, 142, 186)",
                                        color: "white"
                                    } :
                                        {
                                            border: "1px solid rgb(22, 142, 186)",
                                            width: "180px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                            color: "black"
                                        }
                                    }>Professional Details
                                </div>
                                <div className=' py-1 m-1 text-center'
                                    onClick={() => setShow("KYC")}
                                    style={show === "KYC" ? {
                                        border: "1px solid rgb(22, 142, 186)",
                                        width: "180px",
                                        cursor: "pointer",
                                        backgroundColor: "rgb(22, 142, 186)",
                                        color: "white"
                                    } :
                                        {
                                            border: "1px solid rgb(22, 142, 186)",
                                            width: "180px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                            color: "black"
                                        }
                                    }>KYC Details
                                </div>
                                <div className=' py-1 m-1 text-center'
                                    onClick={() => setShow("nominee")}
                                    style={show === "nominee" ? {
                                        border: "1px solid rgb(22, 142, 186)",
                                        width: "180px",
                                        cursor: "pointer",
                                        backgroundColor: "rgb(22, 142, 186)",
                                        color: "white"
                                    } :
                                        {
                                            border: "1px solid rgb(22, 142, 186)",
                                            width: "180px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                            color: "black"
                                        }
                                    }>Nominee Details
                                </div>
                                <div className=' py-1 m-1 text-center'
                                    onClick={() => setShow("bank")}
                                    style={show === "bank" ? {
                                        border: "1px solid rgb(22, 142, 186)",
                                        width: "180px",
                                        cursor: "pointer",
                                        backgroundColor: "rgb(22, 142, 186)",
                                        color: "white"
                                    } :
                                        {
                                            border: "1px solid rgb(22, 142, 186)",
                                            width: "180px",
                                            cursor: "pointer",
                                            backgroundColor: "white",
                                            color: "black"
                                        }
                                    }>Bank Details
                                </div>
                            </div>
                            <div className="mx-md-5 mx-2 px-2">
                                <div className="row mt-2 ">
                                    <div className="col-md-8   h-100">

                                        <div className="d-flex justify-content-between">
                                            <div className="col-md-6 ml-0 pl-0">
                                                <label className="ml-4">Father Name</label>
                                                <input type="text" style={{
                                                    height: "35px",
                                                    border: "1px solid rgb(22, 142, 186)"
                                                }} className="bg-transparent form-control py-1"
                                                    value={user.FatherName}
                                                    name="FatherName"
                                                    onChange={(e) => {
                                                        handleInput(e);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-6 mr-0 pr-0 h-100">
                                                <label className="ml-4">Tax ID / PAN Number</label>
                                                <input type="number" style={{
                                                    height: "35px",
                                                    border: "1px solid rgb(22, 142, 186)"
                                                }} className="bg-transparent form-control py-1"
                                                    value={user.TaxIDorPANNo}
                                                    name="TaxIDorPANNo"
                                                    onChange={(e) => {
                                                        handleInput(e);
                                                    }} />
                                            </div>
                                        </div>
                                        <p className="text-muted mt-3 text-center" style={{ fontSize: "12px" }}> Note:
                                            Father's name should match the one on your PAN card</p>
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Aadhar Card Number</label>
                                        <input type="number"
                                            style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }}
                                            className="bg-transparent form-control py-1"
                                            value={user.AadharNo}
                                            name="AadharNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />

                                        <div className="d-flex justify-content-center mt-3">
                                            <input type="checkbox" style={{ outline: "2px solid rgb(22, 142, 186)" }}
                                                className="mx-2 " height={20} />
                                            <p className="text-muted" style={{ fontSize: "12px" }}>Is this mobile number
                                                linked to your aadhar ? (*)</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center text-center mt-3 mb-0">
                            <img className="mx-2" src={documentUploadLogo} alt="" height={30} />
                            <p> Document Uplaod</p>
                        </div>
                        <div className="border my-2" style={{ borderRadius: "10px", backgroundColor: "white" }}>
                            <div className="row justify-content-around px-4 py-2 my-2"
                                style={{ backgroundColor: "rgb(238, 255, 199)", marginLeft: "0.02%", marginRight: "0.02%" }}>
                                <div className=' py-1 col-md-3 text-center  text-center rounded'
                                >Document Type
                                </div>
                                <div className=' py-1  text-center col-md-3 text-center rounded'
                                >Documents
                                </div>
                                <div className=' py-1 text-center col-md-3 text-center'
                                >Status
                                </div>
                                <div className=' py-1 text-center col-md-3 text-center'
                                >Remarks
                                </div>
                            </div>
                            <div className="row justify-content-around  px-5 py-2 my-2">
                                <div className='  py-1 col-md-3   rounded'
                                >
                                    <p>Select Address Proof</p>
                                    <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                        className="bg-transparent  form-control px-4 py-1"
                                        name="AddressProof"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                        value={user.addressProofDoc}
                                    >
                                        <option selected disabled>Select</option>
                                        <option className="selectOption" value="Aadhar Card">Aadhar Card</option>
                                        <option className="selectOption" value="Voter Card">Voter Card</option>
                                    </select>


                                </div>
                                <div className='  py-1 col-md-3  text-center rounded'>
                                    <label htmlFor='addressProof' style={{ cursor: "pointer" }}>
                                        <img height={20} src={uploadLogo} alt="" /></label>
                                    <input id="addressProof" type="file" className="d-none" name="AddressProof"
                                        onChange={(e) => {
                                            handleImages(e);
                                        }}
                                    />
                                    {/* previewImage1 */}
                                    {/* <img src={previewImage1} /> */}
                                    <br />
                                    <a href={previewDoc.AddressProof} target="_blank">Preview Document</a>
                                </div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                ><p>
                                        {user.IsAadharApproved == 1 ? 'Approved' : 'Pending'}
                                    </p></div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                >
                                    <div className=" h-100">
                                        <input type="text"
                                            style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }}
                                            className="bg-transparent form-control py-1"
                                            value={userFormData.remarks1}
                                            name="remarks1"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-around  px-5 py-2 my-2">
                                <div className='  py-1 col-md-3   rounded'
                                ><p>Select ID Proof</p>
                                    <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                        className="bg-transparent  form-control px-4 py-1"
                                        name="IdProof"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                        value={user.IdProofDoc}
                                    >
                                        <option selected disabled>Select</option>
                                        <option className="selectOption" value="Aadhar Card">Aadhar Card</option>
                                        <option className="selectOption" value="Voter Card">Voter Card</option>
                                    </select>
                                </div>
                                <div className='  py-1 col-md-3  text-center rounded'>
                                    <label htmlFor='idProof' style={{ cursor: "pointer" }}>
                                        <img height={20} src={uploadLogo} alt="" /></label>
                                    <input id="idProof" type="file" className="d-none" name="IDProof"
                                        onChange={(e) => {
                                            handleImages(e);
                                        }}
                                    />
                                    {/* previewImage1 */}
                                    {/* <img src={previewImage1} /> */}
                                    <br />
                                    <a href={previewDoc.IDProof} target="_blank">Preview Document</a>

                                </div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                ><p>{user.IsIDProofApproved == 1 ? 'Approved' : 'Pending'}</p></div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                >
                                    <div className=" h-100">
                                        <input type="text"
                                            style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }}
                                            className="bg-transparent form-control py-1"
                                            value={userFormData.remarks2}
                                            name="remarks2"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row justify-content-around  px-5 py-2 my-2">
                                <div className='  py-1 col-md-3   rounded'
                                ><p>Select Tax ID</p></div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                ><label htmlFor='TaxId' style={{ cursor: "pointer" }}><img height={20} src={uploadLogo} alt="" /></label>

                                    <input id="TaxId" type="file" className="d-none" name="PanCard"
                                        onChange={(e) => {
                                            handleImages(e);
                                        }}
                                    />
                                    {/* previewImage1 */}
                                    {/* <img src={previewImage1} /> */}
                                    <br />
                                    <a href={previewDoc.PanCard} target="_blank">Preview Document</a>
                                </div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                ><p>{user.IsPanCardApproved == 1 ? 'Approved' : 'Pending'}</p></div>
                                <div className='  py-1 col-md-3  text-center rounded'
                                >
                                    <div className=" h-100">
                                        <input type="text"
                                            style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }}
                                            className="bg-transparent form-control py-1"
                                            value={userFormData.remarks3}
                                            name="remarks3"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mt-3 mx-2">
                            <p className="mb-0">Finish your KYC to start investing<br />
                                Make sure you complete all the sections to submit your KYC details for review.<br />
                                Please keep scanned images of your Address proof, ID proof and a cancelled cheque/bank
                                statement ready</p>
                        </div>
                    </div>
                }


                {/*--------------- Nominee Details -----------------*/}
                {show === "nominee" &&
                    <div className="">
                        <div className="border" style={{ borderRadius: "10px", backgroundColor: "white" }}>
                            <div className="d-flex flex-wrap justify-content-center px-4 py-2 my-2" style={{ backgroundColor: "#f0f0f1" }}>
                                <div className=' py-1  m-1 text-center rounded'
                                    onClick={() => setShow("personnel")}
                                    // style={{border:"1px solid rgb(22, 142, 186)",width:"180px",cursor:"pointer",backgroundColor:"rgb(22, 142, 186)",color:"white"}}
                                    style={show === "personnel" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                        { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                    }>Personnel Details</div>
                                <div className=' py-1 m-1 text-center rounded'
                                    onClick={() => setShow("professional")}
                                    style={show === "professional" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                        { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                    }>Professional Details</div>
                                <div className=' py-1 m-1 text-center'
                                    onClick={() => setShow("KYC")}
                                    style={show === "KYC" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                        { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                    }>KYC Details</div>
                                <div className=' py-1 m-1 text-center'
                                    onClick={() => setShow("nominee")}
                                    style={show === "nominee" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                        { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                    }>Nominee Details</div>
                                <div className=' py-1 m-1 text-center'
                                    onClick={() => setShow("bank")}
                                    style={show === "bank" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                        { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                    }>Bank Details</div>
                            </div>
                            <div className="mx-md-5 mx-2 px-2">
                                <p className=" my-1" style={{ fontWeight: "500", fontSize: "20px", color: " rgb(22, 142, 186)" }}>Primary Nominee (*) </p>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <label className="ml-4">Nominee Name</label>
                                        <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.NomineeName1}
                                            name="NomineeName1"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }} />
                                    </div>
                                    <div className="col-md-4 datePicker">
                                        <label className="ml-4">Date of Birth</label>
                                        <div className="position-relative d-flex">
                                            <DatePicker
                                                yearDropdownItemNumber={15}
                                                showYearDropdown
                                                minDate={moment().subtract(500, "years")._d}
                                                maxDate={moment().subtract(0, "years")._d}
                                                showDisabledMonthNavigation
                                                dateFormat="yyyy-MM-dd"
                                                selected={nominee1DOB}
                                                onChange={(date) => {
                                                    handleDate('Nominee1DOB', date);
                                                }}
                                                className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                                            />

                                            {/*   <input type="date"  style={{height:"35px",border:"1px solid rgb(22, 142, 186)",position:"relative"}} className="bg-transparent form-control py-1"
                                        />

                                        <img alt="" style={{position:"absolute",right:"10%",top:"20%",height:"20px"}} src={calendar}/> */}
                                        </div>
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Nominee Email ID</label>
                                        <input type="email" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee1EmailID}
                                            name="Nominee1EmailID"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row my-1 align-items-center">
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Mobile Number</label>
                                        <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee1MobileNo}
                                            name="Nominee1MobileNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Nominee PAN Number</label>
                                        <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee1PANNo}
                                            name="Nominee1PANNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4" >Aadhar Card Number</label>
                                        <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee1AadharNo}
                                            name="Nominee1AadharNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-1 mb-4 align-items-center">
                                    <div className="col-md-4 selectBoxArrow">
                                        <label className="ml-4">Nominee Gender</label>
                                        <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                            <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                                className="bg-transparent  form-control px-4 py-1"
                                                name="Nominee1Gender"
                                                onChange={(e) => {
                                                    handleInput(e);
                                                }}
                                                value={user.Nominee1Gender}
                                            >
                                                <option selected disabled>Select</option>
                                                <option className="selectOption" value="Male">Male</option>
                                                <option className="selectOption" value="Female">Female</option>
                                                <option className="selectOption" value="Transgender">Transgender</option>
                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 selectBoxArrow">
                                        <label className="ml-4">Nominee Relation</label>
                                        <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                            <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                                className="bg-transparent  form-control px-4 py-1"
                                                name="Nominee1Relation"
                                                onChange={(e) => {
                                                    handleInput(e);
                                                }}
                                                value={user.Nominee1Relation}
                                            >
                                                <option selected disabled>Select</option>
                                                <option className="selectOption" value="Father">Father</option>
                                                <option className="selectOption" value="Mother">Mother</option>
                                                <option className="selectOption" value="Son">Son</option>
                                                <option className="selectOption" value="Daughter">Daughter</option>
                                                <option className="selectOption" value="Brother">Brother</option>
                                                <option className="selectOption" value="Sister">Sister</option>
                                                <option className="selectOption" value="Others">Others</option>
                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4" >No. of Bricks Assigned</label>
                                        <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee1Bricksassigned}
                                            name="Nominee1Bricksassigned"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="border my-4" style={{ borderRadius: "10px", backgroundColor: "white" }}>
                            <div className="mx-md-5 mx-2 px-2">
                                <p className=" mt-3" style={{ fontWeight: "500", fontSize: "20px", color: " rgb(22, 142, 186)" }}>Secondary Nominee (optional) </p>
                                <div className="row mt-2">
                                    <div className="col-md-4">
                                        <label className="ml-4">Nominee Name</label>
                                        <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.NomineeName2}
                                            name="NomineeName2"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 datePicker">
                                        <label className="ml-4">Date of Birth</label>
                                        <div className="position-relative d-flex">
                                            {/* <input type="date"  style={{height:"35px",border:"1px solid rgb(22, 142, 186)",position:"relative"}} className="bg-transparent form-control py-1"/>
                                        <img alt="" style={{position:"absolute",right:"10%",top:"20%",height:"20px"}} src={calendar}/> */}

                                            <DatePicker
                                                yearDropdownItemNumber={15}
                                                showYearDropdown
                                                minDate={moment().subtract(500, "years")._d}
                                                maxDate={moment().subtract(0, "years")._d}
                                                showDisabledMonthNavigation
                                                dateFormat="yyyy-MM-dd"
                                                selected={nominee2DOB}
                                                onChange={(date) => {
                                                    handleDate('Nominee2DOB', date);
                                                }}
                                                className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Nominee Email ID</label>
                                        <input type="email" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee2EmailID}
                                            name="Nominee2EmailID"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row my-1 align-items-center">
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Mobile Number</label>
                                        <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee2MobileNo}
                                            name="Nominee2MobileNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4">Nominee PAN Number</label>
                                        <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee2PANNo}
                                            name="Nominee2PANNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4" >Aadhar Card Number</label>
                                        <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee2AadharNo}
                                            name="Nominee2AadharNo"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-1 mb-4 align-items-center">
                                    <div className="col-md-4 selectBoxArrow">
                                        <label className="ml-4">Nominee Gender</label>
                                        <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                            <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                                className="bg-transparent  form-control px-4 py-1"
                                                name="Nominee2Gender"
                                                onChange={(e) => {
                                                    handleInput(e);
                                                }}
                                                value={user.Nominee2Gender}
                                            >
                                                <option selected disabled>Select</option>
                                                <option className="selectOption" value="Male">Male</option>
                                                <option className="selectOption" value="Female">Female</option>
                                                <option className="selectOption" value="Transgender">Transgender</option>
                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 selectBoxArrow">
                                        <label className="ml-4">Nominee Relation</label>
                                        <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                            <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                                className="bg-transparent  form-control px-4 py-1"
                                                name="Nominee2Relation"
                                                onChange={(e) => {
                                                    handleInput(e);
                                                }}
                                                value={user.Nominee2Relation}
                                            >
                                                <option selected disabled>Select</option>
                                                <option className="selectOption" value="Father">Father</option>
                                                <option className="selectOption" value="Mother">Mother</option>
                                                <option className="selectOption" value="Son">Son</option>
                                                <option className="selectOption" value="Daughter">Daughter</option>
                                                <option className="selectOption" value="Brother">Brother</option>
                                                <option className="selectOption" value="Sister">Sister</option>
                                                <option className="selectOption" value="Others">Others</option>
                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div>
                                    </div>
                                    <div className="col-md-4 h-100">
                                        <label className="ml-4" >No. of Bricks Assigned</label>
                                        <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                            value={user.Nominee2Bricksassigned}
                                            name="Nominee2Bricksassigned"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="mt-2 d-flex justify-content-center mx-2">
                            <p className="mb-0 text-center text-muted" style={{ letterSpacing: "0.04em" }}>Note : Kindly reach out to <strong className="text-dark">customer service team</strong> if there are more than 2 Nominee's</p>
                        </div>
                    </div>}

                {/*------------------- Bank ----------------------*/}
                {show === "bank" &&
                    <div className="border " style={{ borderRadius: "10px", backgroundColor: "white" }}>
                        <div className="d-flex flex-wrap justify-content-center px-4 py-2 my-2" style={{ backgroundColor: "#f0f0f1" }}>
                            <div className=' py-1  m-1 text-center rounded'
                                onClick={() => setShow("personnel")}
                                // style={{border:"1px solid rgb(22, 142, 186)",width:"180px",cursor:"pointer",backgroundColor:"rgb(22, 142, 186)",color:"white"}}
                                style={show === "personnel" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Personnel Details</div>
                            <div className=' py-1 m-1 text-center rounded'
                                onClick={() => setShow("professional")}
                                style={show === "professional" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Professional Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("KYC")}
                                style={show === "KYC" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>KYC Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("nominee")}
                                style={show === "nominee" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Nominee Details</div>
                            <div className=' py-1 m-1 text-center'
                                onClick={() => setShow("bank")}
                                style={show === "bank" ? { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                                    { border: "1px solid rgb(22, 142, 186)", width: "180px", cursor: "pointer", backgroundColor: "white", color: "black" }
                                }>Bank Details</div>
                        </div>
                        <div className="mx-md-5 mx-2 px-2">
                            <div className="row my-1 align-items-center">
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Email ID</label>
                                    <input type="email" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.EmailID}
                                        name="EmailID"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Mobile Number</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.MobileNumber}
                                        name="MobileNumber"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >Full Name (as per records)</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.UserName}
                                        name="UserName"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 mb-1 align-items-center">
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Bank Name</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.BankName}
                                        name="BankName"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 selectBoxArrow">
                                    <label className="ml-4">Account Type</label>
                                    <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                        <select style={{ height: "35px", border: "1px solid rgb(22, 142, 186)", position: "relative" }}
                                            className="bg-transparent form-control px-4 py-1"
                                            name="AccountType"
                                            onChange={(e) => {
                                                handleInput(e);
                                            }}
                                            value={user.AccountType}
                                        >
                                            <option selected disabled>Select</option>
                                            <option value="Savings">Savings</option>
                                            <option value="Current">Current</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                    </div>
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >IFSC Code</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.IFSCcode}
                                        name="IFSCcode"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row mt-3 align-items-center">
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Account Number</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.AccountNumber}
                                        name="AccountNumber"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4">Confirm Account Number</label>
                                    <input type="number" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.ConfirmAccountNumber}
                                        name="ConfirmAccountNumber"
                                        onChange={(e) => {
                                            handleConfirmAccountNmber(e);
                                        }}
                                    />
                                    <div className="text-danger">{confirmAccount == true ? '' : 'Account Number didn\'t match.'}</div>
                                </div>
                                <div className="col-md-4 h-100">
                                    <label className="ml-4" >City</label>
                                    <input type="text" style={{ height: "35px", border: "1px solid rgb(22, 142, 186)" }} className="bg-transparent form-control py-1"
                                        value={user.City}
                                        name="City"
                                        onChange={(e) => {
                                            handleInput(e);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex my-4 justify-content-md-end justify-content-center">
                            <div className="d-flex mx-md-5 mx-2">
                                <div className=' py-1 my-1 mx-3  text-center rounded'
                                    style={{ border: "1px solid rgb(22, 142, 186)", width: "140px", cursor: "pointer", backgroundColor: "white", color: "rgb(22, 142, 186)" }}>Cancel</div>
                                <div className=' py-1  my-1 text-center rounded'
                                    onClick={() => submitUserData()}
                                    style={{ border: "1px solid rgb(22, 142, 186)", width: "140px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" }}>Submit</div>
                            </div>
                        </div>
                    </div>}
            </div>
        </React.Fragment>
    )
};
export default PersonelDetails;
