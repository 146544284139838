import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as SecureLock } from '../assets/secureLock.svg';
import { ReactComponent as UserLogo } from '../assets/userLogo.svg';
import profilePic from "../assets/profileImgSetting.png";
import profilePicLogo from "../assets/uploadPicLogo.png";
import editProfileImg from "../assets/editProfileImg.png";
import changePasswordLogo from "../assets/passwordLockLogo.png";
import pinLogo from "../assets/pinLogo.png";
import secureLock from "../assets/secureLock.svg";
import userLogo from "../assets/userLogo.svg";
import styles from "../dashboard.css";
import { getUserbyId, submitUserForm, updatePassword, updatePin } from "../../../services/ApiServices"
let UserSettingContent = (props) => {
    let location = useLocation();
    let [show, setShow] = useState("account");
    const [base64, setBase64] = useState('')
    const [profile, setProfile] = useState()
    const [passwordData, setPasswordData] = useState({})
    const [pin, setPin] = useState({ OldPIN: '0000', NewPIN: '0000', confirmPin: '0000' })
    useEffect(() => {
        getUserbyId(localStorage.getItem('user')).then(res => {
            console.log(res)
            setProfile(res.User)
            setBase64(res?.ProfilePic)
        })
        if (location.active) {
            setShow(location.active)
        }
        else {
            setShow("account");
        }
    }, []);
    const inputchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setBase64(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    }
    const updateprofilePic = () => {
        let body = { UserID: profile.UserID, ProfilePic: base64 }
        submitUserForm(body).then(res => {
            setShow("password")

        })
    }
    const changePasswordHandler = () => {
        if (passwordData?.confirmPassword != passwordData?.NewPassword) {
            alert('confirm password must be same as new password')
            return
        }
        else {
            console.log(passwordData)
            updatePassword(passwordData, localStorage.getItem('user')).then(res => {
                if (res.Message) {
                    alert(res.Message)
                }
            })
        }
    }
    const pinChange = (e, position, pintype) => {
        e.persist()
        let value = e.target.value
        if (pintype == 'OldPIN') {
            let old = String(pin.OldPIN).split('')
            old[position] = value
            setPin(prev => { return { ...prev, OldPIN: old.join('') } })
        }
        else if (pintype == 'NewPIN') {
            let newpi = String(pin.NewPIN).split('')
            newpi[position] = value
            setPin(prev => { return { ...prev, NewPIN: newpi.join('') } })
        }
        else if (pintype == 'confirmPin') {
            let confirm = String(pin.confirmPin).split('')
            confirm[position] = value
            setPin(prev => { return { ...prev, confirmPin: confirm.join('') } })
        }


    }
    const changeinHandler = () => {
        console.log(pin)
        if (pin?.NewPIN != pin?.confirmPin) {
            alert('confirm Pin must be same as new Pin')
            return
        }
        else {
            updatePin(pin, localStorage.getItem('user')).then(res => {
                if (res.Message) {
                    alert(res.Message)
                }
            })
        }
    }
    return (
        <React.Fragment>
            <div>
                <div className="w-50 buttonBar  mx-auto d-flex px-2 justify-content-around  py-2" >
                    <div className=' py-1  m-1 text-center ded'
                        onClick={() => setShow("account")}
                        // style={{border:"1px solid #f8dbdb",width:"180px",cursor:"pointer",backgroundColor:"#ff8282",color:"white"}}
                        style={show === "account" ? { border: "1px solid rgb(22, 142, 186)", width: "150px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                            { border: "1px solid rgb(22, 142, 186)", width: "150px", cursor: "pointer", backgroundColor: "white", color: "black" }
                        }>Account</div>
                    <div className=' py-1 m-1 text-center rounded'
                        onClick={() => setShow("password")}
                        style={show === "password" ? { border: "1px solid rgb(22, 142, 186)", width: "150px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                            { border: "1px solid rgb(22, 142, 186)", width: "150px", cursor: "pointer", backgroundColor: "white", color: "black" }
                        }>Password</div>
                    <div className=' py-1 m-1 text-center'
                        onClick={() => setShow("pin")}
                        style={show === "pin" ? { border: "1px solid rgb(22, 142, 186)", width: "150px", cursor: "pointer", backgroundColor: "rgb(22, 142, 186)", color: "white" } :
                            { border: "1px solid rgb(22, 142, 186)", width: "150px", cursor: "pointer", backgroundColor: "white", color: "black" }
                        }>Secure Pin</div>
                </div>
            </div>
            {/*--------------- Account ------------------------------*/}
            {show === "account" &&
                <div className="col-md-10 my-4 mx-auto border-top-0" style={{ borderRadius: "10px", border: "1px solid rgb(238, 255, 199)", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                    <div className="row p-2 justify-content-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>
                        <div className="text-center d-flex align-items-center justify-content-center">
                            {/*<img src={editProfileImg} height={25} className="rounded-circle mx-2" alt=""/>*/}
                            <div className="mx-2"><UserLogo /></div>
                            Edit Profile
                        </div>
                    </div>
                    <div className="row pt-4 pb-2">
                        <div className="col-md-4">
                            <div >
                                <div className="d-flex justify-content-center align-items-center" style={{ position: "relative" }}>
                                    <img src={base64} height={120} className="rounded-circle" alt="" onError={() => setBase64(profilePic)} />
                                    <input type="file" id="actual-btn" hidden onChange={inputchange} />
                                    <label for="actual-btn" style={{ position: "absolute", right: "30%", bottom: "0" }} >  <img src={profilePicLogo} height={35}
                                        className="p-2 rounded-circle bg-white border shadow" alt="" /></label>

                                </div>
                                {/* <p className="text-center mt-3 mb-0" style={{ color: "#ff8282" }}>Member since 2019</p>
                                <p className="text-center my-1">Profile ID: #BB123</p> */}
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6 h-100">
                                    <label className="ml-4" >First Name</label>
                                    <input type="text" disabled value={profile?.FirstName} style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                                </div>
                                <div className="col-md-6 h-100">
                                    <label className="ml-4">Last Name</label>
                                    <input type="text" value={profile?.LastName} disabled style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 h-100">
                                    <label className="ml-4">EmailID</label>
                                    <input type="email" value={profile?.EmailID} disabled style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                                </div>
                                <div className="col-md-6 h-100">
                                    <label className="ml-4">Mobile Number</label>
                                    <input type="number" disabled value={profile?.MobileNumber} style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                                </div>
                            </div>
                            <div className='text-center mx-auto mt-4 mb-2 py-1 text-center rounded'
                                onClick={updateprofilePic}
                                style={{
                                    border: "1px solid rgb(238, 255, 199)", width: "200px", cursor: "pointer",
                                    background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)", color: "white"
                                }}>Save & Update</div>
                        </div>
                    </div>
                </div>
            }

            {/*--------------- Password ------------------------------*/}
            {show === "password" &&
                <div className="w-25  pinSectionStyles my-4 mx-auto border-top-0" style={{ borderRadius: "10px", border: "1px solid #ffcabc", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                    <div className="row w-100 mx-auto p-2 justify-content-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>
                        <div className="text-center d-flex align-items-center justify-content-center">
                            {/*<img src={changePasswordLogo} height={22} className="rounded-circle mx-2" alt=""/>*/}
                            <div className="mx-2"><SecureLock /></div>
                            Change Password
                        </div>
                    </div>

                    <div className="col-md-12 my-4 mx-auto">

                        <div className=" my-1">
                            <label className="ml-4">Current Password</label>
                            <input type="password" onChange={(e) => { e.persist(); setPasswordData(prev => { return { ...prev, OldPassword: e.target?.value } }) }} style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                        </div>
                        <div className=" my-1">
                            <label className="ml-4">New Password</label>
                            <input onChange={(e) => { e.persist(); console.log(e.target.value); setPasswordData(prev => { return { ...prev, NewPassword: e.target?.value } }) }} type="password" style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                        </div>
                        <div className=" my-1">
                            <label className="ml-4">Confirm Password</label>
                            <input type="password" onChange={(e) => { e.persist(); setPasswordData(prev => { return { ...prev, confirmPassword: e.target?.value } }) }} style={{ height: "35px", border: "1px solid green" }} className="bg-transparent form-control py-1" />
                        </div>


                        <div className='text-center mx-auto mt-4 mb-2 py-1 text-center rounded'
                            onClick={changePasswordHandler}
                            style={{
                                cursor: "pointer",
                                background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)", color: "white"
                            }}>Change Password</div>
                    </div>

                </div>
            }



            {/*--------------- Pin ------------------------------*/}
            {show === "pin" &&
                <div className="w-25 pinSectionStyles my-4 mx-auto border-top-0" style={{ borderRadius: "10px", border: "1px solid #ffcabc", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                    <div className="row w-100 mx-auto p-2 justify-content-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>
                        <div className="text-center d-flex align-items-center justify-content-center">
                            <img src={pinLogo} height={22} className="rounded-circle mx-2" alt="" />Change Pin
                        </div>
                    </div>

                    <div className="col-md-12 my-4 mx-auto">

                        <div className=" my-2">
                            <label className="mx-auto text-center">Current Pin</label>
                            <div className="d-flex align-items-center" style={{ justifyContent: "space-evenly" }}>
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 0, 'OldPIN')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 1, 'OldPIN')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 2, 'OldPIN')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 3, 'OldPIN')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                            </div>
                        </div>
                        <div className=" my-2">
                            <label className="mx-auto text-center">New Pin</label>
                            <div className="d-flex align-items-center" style={{ justifyContent: "space-evenly" }}>
                                <input type="number" onChange={(e) => pinChange(e, 0, 'NewPIN')} className="mx-2" style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" onChange={(e) => pinChange(e, 1, 'NewPIN')} className="mx-2" style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" onChange={(e) => pinChange(e, 2, 'NewPIN')} className="mx-2" style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" onChange={(e) => pinChange(e, 3, 'NewPIN')} className="mx-2" style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                            </div>                            </div>
                        <div className=" my-2">
                            <label className="mx-auto text-center">Confirm Pin</label>
                            <div className="d-flex align-items-center" style={{ justifyContent: "space-evenly" }}>
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 0, 'confirmPin')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 1, 'confirmPin')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 2, 'confirmPin')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                                <input type="number" className="mx-2" onChange={(e) => pinChange(e, 3, 'confirmPin')} style={{ height: "35px", width: "35px", border: "1px solid green" }}
                                    className="bg-transparent form-control py-1" />
                            </div>                            </div>


                        <div className='text-center mx-auto mt-4 mb-2 py-1 text-center rounded'
                            onClick={changeinHandler}
                            style={{
                                cursor: "pointer",
                                background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)", color: "white"
                            }} >Change Pin</div>
                    </div>

                </div>
            }










        </React.Fragment >
    )
};
export default UserSettingContent;
