import React, { useState, useEffect } from "react";
import careersImg from "../../assets/careersImg.png";
import careersRoleImg from "../../assets/careersRoleImg.png";
import careersLocationLogo from "../../assets/careersLocationLogo.png";
import careersExperienceImg from "../../assets/careersExperianceImg.png";
import JobForm from "./JobApplication";
import { JOBPOSTS } from '../../../../services/ApiServices'
let CareerJobs = () => {
    const [JobPosts, setJobPosts] = useState([])
    let [show, setShow] = useState(null);
    let [job, setJob] = useState("Fullstack");
    useEffect(() => {
        JOBPOSTS(1).then(res => {
            setJobPosts(res?.JobPosts)
        })
    }, [])
    console.log(job)
    return (
        <React.Fragment>
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{ minHeight: "300px", maxHeight: "250px" }} src={careersImg} />
                    {/*<p style={{fontWeight:"800",fontSize:"40"}}*/}
                    {/*   className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">*/}
                    {/*    Contact US</p>*/}
                </div>
            </div>
            <div className="container my-5">
                <div className="">
                    <p className="text-center" style={{ fontSize: "28px", fontWeight: "500" }}>Open Positions</p>
                </div>
                <div className="row justify-content-between my-4">

                    {JobPosts.map(item => <div className="col-md-4">
                        <div className="border-right border-top border-bottom p-2" style={{ borderLeft: "15px solid #FF8282" }}>
                            <div className="d-flex align-items-center mt-3 mb-2" >
                                <img src={careersRoleImg} className="mx-2" style={{ height: "14px" }} alt="" />
                                <p className="mb-0" style={{ fontSize: "16px", fontWeight: "400" }}>Role : <span style={{ color: "rgb(22, 142, 186)" }}>{item.JobTitle}</span></p>
                            </div>
                            {/* <div className="d-flex align-items-center  my-2" >
                                <img src={careersExperienceImg} className="mx-2" style={{height:"14px"}} alt=""/>
                                <p className="mb-0" style={{fontSize:"16px",fontWeight:"400"}}>
                                    Exp  :  <span style={{color:"#FF8282"}}>5+ years</span></p>
                            </div> */}
                            <div className="d-flex align-items-center  my-2" >
                                <img src={careersLocationLogo} className="mx-2" style={{ height: "14px" }} alt="" />
                                <p className="mb-0" style={{ fontSize: "16px", fontWeight: "400" }}>
                                    City : <span style={{ color: "rgb(22, 142, 186)" }} >{item.City}</span></p>
                            </div>
                            <div className="text-right ml-auto">
                                <button className="mb-0 p-0 ml-auto btn border-none shadow-none text-capitalize text-right"
                                    onClick={() => { setJob(item); setShow(true); }}
                                    style={{ color: "#034DDD" }}>+ View details</button>
                            </div>                        </div>
                    </div>)}
                </div>
            </div>
            {/*-------- Job Details -------------*/}
            {show === true &&
                <div className="container">
                    <div className="border">
                        <div className="py-1" style={{ backgroundColor: "rgb(22, 142, 186)" }}>
                            <p className="text-center mb-0" style={{ color: "white", fontSize: "25px", fontWeight: "500" }}> Roles and Responsibilities</p>
                        </div>
                        <div className="py-4 px-md-5 px-4" style={{ backgroundColor: "rgba(235, 235, 235, 0.5)" }}>
                            <div className="d-flex align-items-center mt-3 mb-2" >
                                <img src={careersRoleImg} className="mx-2" style={{ height: "14px" }} alt="" />
                                <p className="mb-0" style={{ fontSize: "16px", fontWeight: "400" }}>Role : <span style={{ color: "rgb(22, 142, 186)" }}>{job?.JobTitle}</span></p>
                            </div>
                            {/* <div className="d-flex align-items-center  my-2" >
                                <img src={careersExperienceImg} className="mx-2" style={{ height: "14px" }} alt="" />
                                <p className="mb-0" style={{ fontSize: "16px", fontWeight: "400" }}>
                                    Exp  :  <span style={{ color: "#FF8282" }}>5+ years</span></p>
                            </div> */}
                            <div className="d-flex align-items-center  my-2" >
                                <img src={careersLocationLogo} className="mx-2" style={{ height: "14px" }} alt="" />
                                <p className="mb-0" style={{ fontSize: "16px", fontWeight: "400" }}>
                                    City : <span style={{ color: "rgb(22, 142, 186)" }}>{job?.City}</span></p>
                            </div>
                        </div>
                        <div className="py-4 px-md-5 px-4 ">
                            <p className="mb-0"> {job.JobDescription}</p>





                            <div className="my-4">
                                <p className="my-2">Contract length: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2">6 months</span></p>
                                <p className="my-2">Job Types: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2"> {job?.JobType}</span></p>
                                <p className="my-2">Salary: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2">₹ {job.Salary}/ month</span></p>
                                <p className="my-2"> Benefits: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2">{job?.Benefits}</span></p>
                                <p className="my-2"> Schedule: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2">{job?.Schedule}</span></p>
                                <p className="my-2"> Education: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2"> {job?.Education}</span></p>
                                <p className="my-2">Work Remotely: <span style={{ color: "rgb(22, 142, 186)" }} className="mx-2">  {job?.WorkRemote == 1 ? 'YES' : 'NO'}</span></p>
                            </div>
                        </div>
                    </div>
                </div>}
            <div className="container">
                <JobForm />
            </div>

        </React.Fragment>
    )
};
export default CareerJobs;