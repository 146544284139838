import React, { useEffect, useState } from "react";
import checkImg from "../../assets/checkRightSign.png";

let ViewPropertyAmenities = ({ propertyDeatils }) => {
  const [amenities, setAmenities] = useState([]);
  useEffect(() => {
    setAmenities(propertyDeatils.Amenities);
  }, [propertyDeatils]);
  return (
    <React.Fragment>
      <div className="container px-0 my-5">
        <div className="mx-3 mx-md-auto">
          <p
            className="text-center"
            style={{ fontSize: "20px", fontWeight: "700" }}
          >
            Amenities
          </p>
          <div
            style={{
              backgroundColor: "#ffe2db",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            {amenities?.length > 0 ? (
              <div className="px-md-5 py-4 px-2 mx-md-5 mx-0">
                <div>
                  <div className="row my-md-4 my-0 ">
                    {amenities.map((amenity) => {
                      return (
                        <div className="col-md-4" key={amenity?.key}>
                          <div className="d-flex align-items-center">
                            <img
                              style={{ height: "10px", width: "18px" }}
                              src={checkImg}
                              alt=""
                            />
                            <p className="mb-0 ml-3">{amenity?.value}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <p
                style={{ color: "rgb(255, 130, 130)" }}
                className="text-center py-4"
              >
                No amenities found
              </p>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertyAmenities;
