import React, {useEffect} from "react";
import Navbar from "../../Navbar";
import NewsArticleHome from "./NewsArticleHome";
import PropertyAnnouncement from "./PropertyAnnouncement";
import ArticlesCard from "./ArticlesCards";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";

let NewsArticle=()=>{
    useEffect(()=>{
        window.scrollTo(0,0);
    },[ ]);
    return(
        <React.Fragment>
            <Navbar activeDropdown={"newsAndArticle"}/>
            <NewsArticleHome/>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default NewsArticle;
