import React from "react";
import { Doughnut } from "react-chartjs-2";

let ViewPropertyPricingDetails = ({propertyDeatils}) => {
    const propertyListing = propertyDeatils || [];
  const data = {
    labels: [
      "Purchase Price",
      "Stamp Duty",
      "Brokerage",
      "Legal Fee",
      "Reserves",
    ],
    datasets: [
      {
        data: [60, 30, 20, 5, 5],
        backgroundColor: [
          "#ff8282",
          "#ffe2db",
          "#b2df8a",
          "#53c54c",
          "#25911e",
        ],
        hoverBackgroundColor: [
          "#ff8282",
          "#ffe2db",
          "#b2df8a",
          "#53c54c",
          "#25911e",
        ],
      },
    ],
  };
  const legend = {
    display: false,
    position: "bottom",
    labels: {
      fontColor: "#323130",
      fontSize: 14,
    },
  };
  return (
    <React.Fragment>
      <div className="container my-4" id="pricingDetails">
        <p
          className="text-center"
          style={{ fontSize: "35px", fontWeight: "700" }}
        >
          Pricing Details
        </p>
        <div className="row justify-content-around my-5">
          <div className="col-md-4 px-0">
            <div className="border h-100">
              <p className="text-center mb-0 my-4" style={{ fontSize: "18px" }}>
                Historical Purchase
              </p>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Settlement Date
                </p>
                <p className="mb-0 ml-auto text-muted">{propertyListing.SettlementDate}</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Initial Equity
                </p>
                <p className="mb-0 ml-auto text-muted">-₹294,999</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Initial Debt
                </p>
                <p className="mb-0 ml-auto text-muted">₹295,000</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Acquisition Costs
                </p>
                <p className="mb-0 ml-auto text-muted">₹{propertyListing.TotalAcquisitionCosts}</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Cash Reserve
                </p>
                <p className="mb-0 ml-auto text-muted">₹8,993</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p
                  className="mb-0"
                  style={{ fontWeight: "600", color: "#FF8484" }}
                >
                  Total Purchase Price
                </p>
                <p className="mb-0 ml-auto text-muted">₹72,381</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p
                  className="mb-0"
                  style={{ fontWeight: "600", color: "#FF8484" }}
                >
                  Initial Brick Price
                </p>
                <p className="mb-0 ml-auto text-muted">₹52.00</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 px-0">
            <div className="border h-100">
              <p className="text-center mb-0 my-4" style={{ fontSize: "18px" }}>
                Acquisition Costs
              </p>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Stamp Duty
                </p>
                <p className="mb-0 ml-auto text-muted">₹{propertyListing.StampDuty}</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Legal & Professional Fees
                </p>
                <p className="mb-0 ml-auto text-muted">₹{propertyListing.LegalProfessionalFees}</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Buyers Agent Fees
                </p>
                <p className="mb-0 ml-auto text-muted">₹{propertyListing.BuyerAgentFees}</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Other Costs
                </p>
                <p className="mb-0 ml-auto text-muted">₹{propertyListing.OtherCosts}</p>
              </div>
              <div
                className="d-flex  py-1 my-2 px-4"
                style={{ backgroundColor: "#f8f8f8" }}
              >
                <p className="mb-0" style={{ fontWeight: "600" }}>
                  Total Acquisition Costs
                </p>
                <p className="mb-0 ml-auto text-muted">₹{propertyListing.TotalAcquisitionCosts}</p>
              </div>
              <p className="mb-0 font-small px-4 mt-2">
                Note: Acquisition Costs are amortised evenly over 60 month
                periods.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container my-4">
        <div
          className="row mx-md-2 p-4"
          style={{ border: "1px solid #FF8282" }}
        >
          <div className="col-md-6">
            <Doughnut
              data={data}
              legend={legend}
              options={{
                responsive: true,
                maintainAspectRatio: false,
              }}
            />
          </div>
          <div className="col-md-6 p-3">
            <div
              className="d-flex align-items-center py-1 my-2 px-4"
              style={{ backgroundColor: "#f8f8f8" }}
            >
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#ff8282",
                }}
              ></span>
              <p className="mb-0 mx-3" style={{ fontWeight: "400" }}>
                Purchase Price
              </p>
              <p
                className="mb-0 ml-auto text-muted font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                +
              </p>
            </div>
            <div
              className="d-flex align-items-center py-1 my-2 px-4"
              style={{ backgroundColor: "#f8f8f8" }}
            >
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#ffe2db",
                }}
              ></span>
              <p className="mb-0 mx-3" style={{ fontWeight: "400" }}>
                Stamp Duty
              </p>
              <p
                className="mb-0 ml-auto text-muted font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                +
              </p>
            </div>
            <div
              className="d-flex align-items-center py-1 my-2 px-4"
              style={{ backgroundColor: "#f8f8f8" }}
            >
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#b2df8a",
                }}
              ></span>
              <p className="mb-0 mx-3" style={{ fontWeight: "400" }}>
                Brokerage
              </p>
              <p
                className="mb-0 ml-auto text-muted font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                +
              </p>
            </div>
            <div
              className="d-flex align-items-center py-1 my-2 px-4"
              style={{ backgroundColor: "#f8f8f8" }}
            >
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#53c54c",
                }}
              ></span>
              <p className="mb-0 mx-3" style={{ fontWeight: "400" }}>
                Legal Fee
              </p>
              <p
                className="mb-0 ml-auto text-muted font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                +
              </p>
            </div>
            <div
              className="d-flex align-items-center py-1 my-2 px-4"
              style={{ backgroundColor: "#f8f8f8" }}
            >
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#25911e",
                }}
              ></span>
              <p className="mb-0 mx-3" style={{ fontWeight: "400" }}>
                Reserves
              </p>
              <p
                className="mb-0 ml-auto text-muted font-weight-bold"
                style={{ cursor: "pointer" }}
              >
                +
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertyPricingDetails;
