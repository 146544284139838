import axios from "axios";
import { config } from "../config";
import { apiuriConstants } from "../apiUrlConstants";

const base_url = config.URL.BASE_URL;

export function getPropertiesList() {
  const getPropListUrl =
    base_url +
    apiuriConstants.APIURI.PROPERTIES_LIST +
    apiuriConstants.APIURI.CUSTOMER_PROPERTIES;
  return axios.get(getPropListUrl);
}

export function submitAgentForm(agentData) {
  const agentFormSubmitUrl = base_url + apiuriConstants.APIURI.PROPERTIES_LIST;
  return axios
    .post(agentFormSubmitUrl, agentData)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error", error.response);
    });
}

export function getPropertiesListById(id) {
  const getListByIdUrl =
    base_url +
    apiuriConstants.APIURI.PROPERTIES_LIST +
    apiuriConstants.APIURI.BY_PROPERTy_ID +
    id;
  return axios
    .get(getListByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}

export function getUserbyId(id) {
  const getUserByIdUrl =
    base_url +
    apiuriConstants.APIURI.BY_USER_ID +
    id;
  return axios
    .get(getUserByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}

export function submitUserForm(user) {
  const userFormSubmitUrl = base_url + apiuriConstants.APIURI.UPDATE_USER + user['UserID'];
  console.log(userFormSubmitUrl);
  return axios
    .put(userFormSubmitUrl, user)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error", error.response);
    });

}

export function getBrickStatusbyUserId(id) {
  const getBrickStatusByIdUrl =
    base_url +
    apiuriConstants.APIURI.BRICK_STATUS +
    id;
  return axios
    .get(getBrickStatusByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}

export function getMyEarningsCurrentbyUserId(id) {
  const getMyEarningsCurrentByIdUrl =
    base_url +
    apiuriConstants.APIURI.MY_EARNINGS_CURRENT +
    id;
  return axios
    .get(getMyEarningsCurrentByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}

export function getMyEarningsSoldbyUserId(id) {
  const getMyEarningsSoldByIdUrl =
    base_url +
    apiuriConstants.APIURI.MY_EARNINGS_SOLD +
    id;
  return axios
    .get(getMyEarningsSoldByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}

export function getMyInvestmentsbyUserId(id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.MY_INVESTMENTS +
    id;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function getblockchaindoc(id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.BLOCK_CHAIN +
    id;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function SPVSTATEMENT(id, offset) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.SPV_STATEMENT +
    `offset=${offset}&UserID=${id}`;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function TdsTransactions(id, offset) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.TDS_TRANSACTIONS +
    `offset=${offset}&UserID=${id}`;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function SPVSTATEMENTDate(id, offset, fromdate, toDate) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.SPV_STATEMENT +
    `offset=${offset}&UserID=${id}&ToDate=${toDate}&FromDate=${fromdate}`;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function getCustomers() {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.Customers;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function USER_MANAGER(id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.USER_MANAGER + id;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function scheduleVisit(body) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.scheduleVisit;
  return axios
    .post(getMyInvestmentsByIdUrl, body)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function consensus(id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.consensus + id;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function updatePassword(body, id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.UPDATE_USER + 'password/' + id;
  return axios
    .put(getMyInvestmentsByIdUrl, body)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function updatePin(body, id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.UPDATE_USER + 'pin/' + id;
  return axios
    .put(getMyInvestmentsByIdUrl, body)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function JOBPOSTS(id) {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.JOB_POSTS + id;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function Get_PropertiesTypes() {
  const getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.GET_PROPERTY_TYPES;
  return axios
    .get(getMyInvestmentsByIdUrl)
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
export function Get_PropertiesFilter(body) {


  let getMyInvestmentsByIdUrl =
    base_url +
    apiuriConstants.APIURI.GET_PROPERTY_FILTER;


  return axios
    .get(getMyInvestmentsByIdUrl, { params: { ...body } })
    .then((response) => response.data)
    .catch((error) => {
      console.log("ERROR", error.response);
    });
}
