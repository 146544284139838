import React from "react";
import HowItWorksTitle from "./howItWorksComponents/HowItWorksTitle";
import HowItWorksTitleDetails from "./howItWorksComponents/HowitWorksTitleDetails";
import WhyFractionalOwner from "./howItWorksComponents/WhyFractionalOwnership";
import WhatAboutFee from "./howItWorksComponents/WhatAboutFee";
import BrickModel from "./howItWorksComponents/BrickModel";
import HowItWorksStyles from "./HowItWorksStyles.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Buildings from "../homepage/homepageComponents/Buildings";
import HowItWorksSteps from "./howItWorksComponents/HowItWorksSteps";
let HowItWorksMain=()=>{
    return(
        <React.Fragment>
            <Navbar active={"howItWorks"}/>
            <HowItWorksTitle/>
            <HowItWorksTitleDetails/>
            <HowItWorksSteps/>
            <WhyFractionalOwner/>
            <WhatAboutFee/>
            <BrickModel/>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default HowItWorksMain;