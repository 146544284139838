import React from "react";
import style from "../HowItWorksStyles.css";
import selectPropertyLogo from "../../../assets/img/selectPropertyLogo.png";
import talkAdviserLogo from "../../../assets/img/talkAdviserLogo.png";
import signLogo from "../../../assets/img/signLogo.png";
import fundInvestmentLogo from "../../../assets/img/fundInvestmentLogo.png";
import investmentCompletedLogo from "../../../assets/img/investmentCompletedLogo.png";
import sellInvestmentLogo from "../../../assets/img/sellInvestmentLogo.png";

let HowItWorksSteps = () => {
    return (
        <React.Fragment>
            <div className="container-fluid p-0 mx-0 my-5">
                <div className="row p-0 m-0">
                    <div className="col-md-4 p-0 m-0" style={{ backgroundColor: "rgb(22, 142, 186)", color: "white" }}>
                        <div className="p-5">
                            <p className="mt-1 mb-3 text-center" style={{ fontWeight: "600", fontSize: "18px" }}>SELECT
                                PROPERTY</p>
                            <div className="text-center my-1">
                                <img className="my-2" style={{ height: "50px", width: "50px" }} src={selectPropertyLogo}
                                    alt="" />
                                <p className="my-2" style={{ fontWeight: "500" }}>Step 01</p>
                            </div>
                            <p className="my-2" style={{ fontWeight: "500" }}>Browser through properties page where we
                                showcase available properties and select choose
                                the ones you are interested in investing. Invitation list may be full for some
                                properties due to demand. If that is the case, please join the wait list.</p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0 m-0">
                        <div className="p-5">
                            <p className="mt-1 mb-3 text-center" style={{ fontWeight: "600", fontSize: "18px" }}>TALK TO
                                ADVISER</p>
                            <div className="text-center my-1">
                                <img className="my-2" style={{ height: "50px", width: "50px" }} src={talkAdviserLogo}
                                    alt="" />
                                <p className="my-2" style={{ fontWeight: "500" }}>Step 02</p>
                            </div>
                            <p className="my-2" style={{ fontWeight: "500" }}>Our advisers will call you as soon as they
                                can. You can discuss the process, opportunity and ask any questions you may have. Once
                                you are fully satisfied, our advisers will guide you through rest of the process.</p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0 m-0" style={{ backgroundColor: "rgb(22, 142, 186)", color: "white" }}>
                        <div className="p-5">
                            <p className="mt-1 mb-3 text-center" style={{ fontWeight: "600", fontSize: "18px" }}>SIGN
                                AGREEMENTS</p>
                            <div className="text-center my-1">
                                <img className="my-2" style={{ height: "50px", width: "50px" }} src={signLogo}
                                    alt="" />
                                <p className="my-2" style={{ fontWeight: "500" }}>Step 03</p>
                            </div>
                            <p className="my-2" style={{ fontWeight: "500" }}>Each investment is made through a specific
                                entity and you need to sign relevant agreements for that specific property and entity.
                                This is for your own protection. You can sign these electronically - our team will guide
                                you through it.</p>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div className="col-md-4 p-0 m-0">
                        <div className="p-5">
                            <p className="mt-1 mb-3 text-center" style={{ fontWeight: "600", fontSize: "18px" }}>FUND YOUR
                                INVESTMENT</p>
                            <div className="text-center my-1">
                                <img className="my-2" style={{ height: "50px", width: "50px" }} src={fundInvestmentLogo}
                                    alt="" />
                                <p className="my-2" style={{ fontWeight: "500" }}>Step 04</p>
                            </div>
                            <p className="my-2" style={{ fontWeight: "500" }}>Your investment amount needs to be
                                transferred to the bank account of your investment entity - this entity is specifically
                                created for that specific investment. You should NEVER transfer the money to any other
                                account than this particular entity's bank account (and the name of that is mentioned in
                                the agreements that you sign).</p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0 m-0" style={{ backgroundColor: "rgb(22, 142, 186)", color: "white" }}>
                        <div className="p-5">
                            <p className="mt-1 mb-3 text-center"
                                style={{ fontWeight: "600", fontSize: "18px" }}>INVESTMENT COMPLETE</p>
                            <div className="text-center my-1">
                                <img className="my-2" style={{ height: "50px", width: "50px" }}
                                    src={investmentCompletedLogo}
                                    alt="" />
                                <p className="my-2" style={{ fontWeight: "500" }}>Step 05</p>
                            </div>
                            <p className="my-2" style={{ fontWeight: "500" }}>Once every fractional owners funds the
                                investment, Brickberry will complete the purchase, upload
                                documents to portal and notify you. at this point:</p>
                            <p className="mt-3" style={{ fontWeight: "500" }}>
                                (a) all your ownership records on Ministry of
                                Corporate Affairs (MCA) records and on Ethereum
                                Blockchain.
                                <br />
                                (b) You must have received an email notification with
                                your investment details
                                <br />
                                (c) Governance and compliance related documents
                                are available to you on BrickBerry portal. </p>
                        </div>
                    </div>
                    <div className="col-md-4 p-0 m-0" >
                        <div className="p-5">
                            <p className="mt-1 mb-3 text-center" style={{ fontWeight: "600", fontSize: "18px" }}>SELL YOUR
                                INVESTMENTS</p>
                            <div className="text-center my-1">
                                <img className="my-2" style={{ height: "50px", width: "50px" }} src={sellInvestmentLogo}
                                    alt="" />
                                <p className="my-2" style={{ fontWeight: "500" }}>Step 06</p>
                            </div>
                            <p className="my-2" style={{ fontWeight: "500" }}>You can sell your investment partially or
                                completely at any time - either to the existing platform users or outside users. If you
                                want to sell outside the platform, you just have to register that user in Brickberry
                                platform and we will take care of the ownership change on the platform so that we can
                                handle compliance and protect ownership. You can always sell on the platform and benefit
                                from demand</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default HowItWorksSteps;
