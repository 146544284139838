import React, { useEffect, useState } from "react";
import Parser from 'html-react-parser/dist/html-react-parser'
import parse from 'html-react-parser';
import moment from 'moment';
import ResourseTitle from "../ResourceTitle";
import Axios from "axios";
import PropertyAnnouncement from "./PropertyAnnouncement";
import ArticlesCard from "./ArticlesCards";

let NewsArticleHome = (props) => {
    let [active, setActive] = useState(1);
    let [blogs, setBlogs] = useState(null);
    let [blog, setBlog] = useState(null);

    useEffect(() => {
        fetchBlogs()
    }, []);
    let fetchBlogs = () => {

        let dataURL = `${process.env.REACT_APP_API_URL}/blog`;
        // let dataURL=`/.netlify/functions/blog`;
        Axios.get(dataURL).then((response) => {
            // reverse array to show latest blogs first
            let blogsArray = response.data.Blogs;
            if (blogsArray?.length > 0) {
                let reverseArray = [];
                for (let x = blogsArray.length - 1; 0 <= x; --x) {
                    reverseArray.push(blogsArray[x]);
                }
                setBlogs(reverseArray);
            }
            else setBlogs(response.data.Blogs);
            console.log("blog list");
        }).catch((err) => {
            console.log(err)
        });
    };
    let fetchblogsByCategory = (value, id) => {
        setActive(id);
        let dataURL = `${process.env.REACT_APP_API_URL}/userblog/${value}`;
        Axios.get(dataURL).then((response) => {

            // reverse array to show latest blogs first
            let blogsArray = response.data.Blog;
            let reverseArray = [];
            for (let x = blogsArray?.length - 1; 0 <= x; --x) {
                reverseArray.push(blogsArray[x]);
            }
            setBlogs(reverseArray);
            if (reverseArray?.length > 0) {
                setBlog(reverseArray[0])
            }
            else if (reverseArray) {
                setBlog(reverseArray)
            }
            else setBlog(null);
            console.log("success");
        }).catch((err) => {
            console.log(err)
        });
    };
    let handleAllBlogsClick = () => {
        setActive(1);
        fetchBlogs();
    };

    return (
        <React.Fragment>
            <ResourseTitle title={"News And Article"} />
            <div className="container text-center d-flex justify-content-center" style={{ position: "relative", zIndex: "3" }}>
                <div className="row d-flex justify-content-around align-items-center" style={{ maxWidth: "750px", marginTop: "-30px" }}>
                    <div className="mx-2">
                        <button
                            style={active === 1 ? { fontWeight: "700", fontSize: "17px", width: "120px", color: "white", background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)" }
                                : { fontWeight: "700", fontSize: "17px", width: "120px", color: "black", backgroundColor: "white" }}
                            // onClick={()=>{setActive(1)}}
                            onClick={() => handleAllBlogsClick(1)}
                            // onClick={fetchblogsByCategory("all",1)}
                            className="btn btn-md  text-center px-0 py-2">ALL</button>
                    </div>

                    <div className="mx-2">
                        <button
                            style={active === 3 ? { fontWeight: "700", fontSize: "17px", width: "120px", color: "white", background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)" }
                                : { fontWeight: "700", fontSize: "17px", width: "120px", color: "black", backgroundColor: "white" }}
                            // onClick={()=>{setActive(3)}}
                            onClick={() => fetchblogsByCategory("news", 3)}
                            className="btn btn-md px-0 py-2">News</button>
                    </div>
                    <div className="mx-2">
                        <button
                            style={active === 2 ? { fontWeight: "700", fontSize: "17px", width: "120px", color: "white", background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)" }
                                : { fontWeight: "700", fontSize: "17px", width: "120px", color: "black", backgroundColor: "white" }}
                            // onClick={()=>{setActive(2)}}
                            onClick={() => fetchblogsByCategory("articles", 2)}
                            className="btn btn-md px-0 py-2">ARTICLES</button>
                    </div>
                    <div className="mx-2">
                        <button
                            style={active === 4 ? { fontWeight: "700", fontSize: "17px", width: "120px", color: "white", background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)" }
                                : { fontWeight: "700", fontSize: "17px", width: "120px", color: "black", backgroundColor: "white" }}
                            // onClick={()=>{setActive(4)}}
                            onClick={() => fetchblogsByCategory("policies", 4)}
                            className="btn btn-md py-2 px-0 text-center">Policies</button>
                    </div>

                </div>
            </div>
            {blogs?.length > 0 && <PropertyAnnouncement blog={blogs?.length > 0 ? blogs[0] : blogs} />}
            {blogs?.length > 0 && <ArticlesCard blogs={blogs} />}

        </React.Fragment>
    )
};
export default NewsArticleHome;
