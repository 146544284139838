const apiuri = {
  PROPERTIES_LIST: "/properties",
  CUSTOMER_PROPERTIES: "/customer",
  BY_PROPERTy_ID: "/propertiesbyID/",
  BY_USER_ID: "/getusers/",
  UPDATE_USER: "/updateusers/",
  BRICK_STATUS: "/purchaserequests/byuserid/",
  MY_EARNINGS_CURRENT: "/myearnings/current/",
  MY_EARNINGS_SOLD: "/myearnings/sold/",
  MY_INVESTMENTS: "/myinvestments/",
  BLOCK_CHAIN: "/blockchaindocs/",
  SPV_STATEMENT: "/investortransaction?",
  TDS_TRANSACTIONS: "/tdstransactions?",
  Customers: "/properties/customer",
  USER_MANAGER: "/getuser_manager/",
  scheduleVisit: "/schedulevisits",
  consensus: "/consensus/",
  JOB_POSTS: "/jobposts/bystatus/",
  GET_PROPERTY_TYPES: "/getpropertytypes",
  GET_PROPERTY_FILTER: '/properties/filters'
};

export const apiuriConstants = {
  APIURI: apiuri,
};
