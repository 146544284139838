import React from "react";

let RepatriableRules = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>NRI Real Estate Investment Repatriable Rules</h2>
                    <div className="mt-4">

                        <p className="mb-0"> Repatriable Rules in Sales Proceeds in India:</p>
                        <p className="mb-0">The repatriation of NRI investments signifies that the sale proceedings or
                            rental income made
                            over investment are eligible for use outside of India after
                            the application of certain taxes.</p>
                        <div className="mx-md-5 mx-1 mt-3">
                            <p><span style={{fontWeight: "600"}}>• Repatriation on profits: </span>The rules that are to
                                be considered here are that the proceedings
                                from the NRI investments in India can be repatriated
                                abroad independent of the funds’ source. The income made from capital assets can be r
                                epatriated
                                to the extent of the investment made as
                                remittance of these assets.</p>

                            <p><span style={{fontWeight: "600"}}>• Repatriation</span> on rental income: The rental
                                income can be repatriated abroad irrespective of the
                                type of fund chosen for the investment.</p>
                        </div>

                        <p>NRI Investment on the Repatriable Basis:</p>

                        <p>NRI investors can select investment on the repatriable basis if they wish to repatriate the
                            proceedings directly outside of India.</p>
                        <div className="mx-md-5 mx-1 ">
                            <p className="mb-0">• Mode of investment: The NRI investments on the repatriable basis can
                                be made in the form of
                                FDI or Foreign Direct Investments.</p>
                            <p className="mb-0">• Modes of payment: The repatriable NRI, real estate investments,
                                require payments from NRE or
                                FCNR bank accounts.</p>
                            <p className="mb-0 mx-2">NRI Investment on the Non-Repatriable Basis:</p>
                            <p className="mx-2">An NRI real estate investment on a non-repatriable basis signifies that
                                the NRI investor cannot
                                take the proceedings from investments outside
                                of India. The investment on a Non-repatriable basis is more beneficial as the profits
                                earned
                                from an investment do not have to suffer tax impositions.
                                Also, these investments can be saved as domestic investments, extending their limit of
                                returns
                                and operation.</p>
                        </div>
                        <div className="mx-md-5 mx-2">
                            <p><span style={{fontWeight: "600"}}>• Modes of investment:</span> NRIs can invest on a
                                non-repatriable basis through the following modes.</p>
                            <div className="mx-md-5 mx-1">
                                <p className="mb-0">a. Equity shares</p>
                                <p className="mb-0">b. REIT, AIF, or INVIT issued units</p>
                                <p className="mb-0">c. Limited Liability partnership</p>
                                <p className="mb-0">d. Small scale industry</p>
                                <p className="mb-0">e. Convertible debentures</p>
                                <p className="mb-0">f. Shares in any Indian Company</p>
                            </div>
                            <p className="mt-3"><span style={{fontWeight: "600"}}>• Modes of payment:</span> The
                                contribution can be paid
                                by NRI investor in the modes of:</p>
                            <div className="mx-md-5 mx-1">
                                <p className="mb-0">a. Inward remittance</p>
                                <p className="mb-0">b. NRE account</p>
                                <p className="mb-0">c. FCNR account</p>
                                <p className="mb-0">d. NRO account</p>
                            </div>
                        </div>
                        <p className="mt-3">Prohibited Investment Sectors:</p>

                        <p>Out of all the investment options available to a citizen, there are some sectors into which
                            the
                            NRIs cannot make contributions. These sectors are:</p>
                        <div className="mx-md-5 mx-2">
                            <p className="mb-0">• Any company associated with agricultural or plantation activity.</p>
                            <p className="mb-0">• Nidhi company</p>
                            <p className="mb-0">• Any type of farmhouse construction</p>
                            <p className="mb-0">• A company dealing in the transfer of developmental rights.</p>
                            <p className="mb-0">• Any press or print media company</p>
                        </div>
                        <p className="mt-3">Transfer of shares:</p>

                        <p>The share transfer can also be weighed on the repatriation and non-repatriation basis.</p>
                        <div className="mx-md-5 mx-2">
                        <p><span style={{fontWeight:"600"}}>• For repatriation investments:</span> This transfer of share occurs as per the Schedule I of TISPRO
                        and is eligible for sectoral caps. The shares from NRI
                        investment can be transferred abroad.</p>

                            <p><span style={{fontWeight:"600"}}>• For non-repatriation investments:</span> These investments are considered domestic investments. The
                        shares under such investments can be transferred
                        by regular procedures to another NRI or any resident.</p>
                        </div>
                        <p className="mt-3">Remittances:</p>
                        <div className="mx-md-5 mx-2">
                        <p className="mb-0">• The earnings from capital instruments or contributions into investment firms are deposited
                        into the NRO account. Furthermore, the amount shall
                        not be eligible for repatriation abroad.</p>
                        <p className="mb-0">• The NRI investor can repatriate the interests and dividends of the investment outside of
                        India.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};
export default RepatriableRules;
