import React, { useState } from "react";
import rupeesText from "./assets/rupeesText.png";
import rupeesCircleProperties from "./assets/rupeesCircleProperties.png";
import carImg from "./assets/carImg.png";
import floorNumberImgProperties from "./assets/floorNumberImgProperties.png";
import brickBlue from "./assets/brickBlue.png";
import brickGreen from "./assets/brickGreen.png";
import brickPink from "./assets/brickPink.png";
import locationColoured from "./assets/locationColoured.png";
import bedRoomLogo from "./assets/bedroomLogo.png";
import Link from "react-router-dom/Link";
import moment from "moment";

let PropertiesCards = ({ properties }) => {
  const ListOfProperties = properties || [];
  return (
    <React.Fragment>
      <div className=" px-2 mt-5 mb-3 d-flex flex-column justify-content-center">
        <div className="d-flex mx-4 flex-wrap justify-content-center">
          {ListOfProperties.map((card) => {
            return (
              <React.Fragment key={card}>
                <div className="col-md-4 p-0 my-4 d-flex justify-content-center">
                  <div
                    className="shadow propertiesCard"
                    style={{ maxWidth: "320px", borderRadius: "20px", width: "380px" }}
                  >
                    <div className=" position-relative">
                      <img
                        className="img-fluid"
                        src={card.PropertyImages[0]}
                        style={{ maxWidth: "320px", height: "250px", objectFit: "contain" }}
                        alt="img"
                      />
                      <div
                        className="d-flex flex-wrap font-weight-bolder propertiesCardTopBar position-absolute"
                        style={{
                          fontSize: "12px",
                          // position: "absolute",
                          lineHeight: "14px",
                          maxWidth: "320px",
                          width: "320px",
                          top: 0,
                          backgroundColor: "white",
                          alignItems: "center",
                          maxHeight: "42px",
                          justifyContent: "space-around",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          opacity: "0.85",
                        }}
                      >
                        <div>
                          <p className="mb-0 ">
                            {card.PropertyTitle}{" "}
                            <span style={{ color: "rgb(155, 205, 42)" }}>Class '{card.BuildingClass}'</span>
                          </p>
                          <p className="mb-0 ">
                            <img
                              src={locationColoured}
                              height={12}
                              className="mr-2"
                              alt="locationLogo"
                            />
                            {card.Address}{" "}
                          </p>
                        </div>
                        <div
                          className="card brickTextBlock my-auto ml-4 bg-transparent shadow-none p-0 m-0 d-flex justify-content-center align-items-center"
                          style={{ width: "85px", right: "0" }}
                        >
                          {/*<img src={brickPink} style={{}}  className="card-img " height={50}  alt="img"/>*/}
                          {/* <img
                            src={
                              card === 2
                                ? brickBlue
                                : card === 3
                                  ? brickGreen
                                  : card === 5
                                    ? brickGreen
                                    : brickPink
                            }
                            style={{}}
                            className="card-img "
                            height={50}
                            alt="img"
                          /> */}
                          <div
                            className="position-absolute p-0 m-0"
                            style={{
                              fontSize: "10px",
                              fontWeight: "900",
                              lineHeight: "10px",
                            }}
                          >
                            {card.PercentageFunded === 100 ? (
                              <small className="mb-0 d-block font-weight-bold">
                                FULLY FUNDED
                              </small>
                            ) : (
                              <small className="mb-0 d-block font-weight-bold">
                                NEW
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="d-flex propertiesCardBottomBar justify-content-around px-2 py-1 flex-row position-absolute"
                        style={{
                          fontSize: "11px",
                          backgroundColor: "rgba(255,255,255,0.78)",
                          bottom: 0,
                          width: "webkit-fill-available",
                        }}
                      >
                        <div className="d-flex ">
                          {card?.PropertyType === "Commercial" ? (
                            <React.Fragment>
                              <div className="d-flex justify-content-center align-items-center">
                                <img height={12} src={rupeesCircleProperties} />
                                <img
                                  className="position-absolute"
                                  src={rupeesText}
                                />
                              </div>
                              <div
                                className="text-center mx-2"
                                style={{ lineHeight: "14px" }}
                              >
                                <small className="mb-0 d-block font-weight-bolder">
                                  Property Value{" "}
                                </small>
                                <small className="mb-0 d-block text-muted">
                                  4.5 cr
                                </small>
                              </div>
                              <div className="border-right border-dark my-2"></div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="d-flex justify-content-center align-items-center">
                                <img
                                  className="mx-2"
                                  height={12}
                                  src={carImg}
                                />
                              </div>
                              <div
                                className="text-center mr-2"
                                style={{ lineHeight: "14px" }}
                              >
                                <small className="mb-0 d-block font-weight-bolder">
                                  Car Parking
                                </small>
                                <small className="mb-0 d-block text-muted">
                                  {card.CarParking}
                                </small>
                              </div>
                              <div className="border-right border-dark my-2"></div>
                            </React.Fragment>
                          )}
                        </div>
                        <div className="d-flex ">
                          {card?.PropertyType === "Commercial" ? (
                            <React.Fragment>
                              <div className="d-flex justify-content-center align-items-center">
                                <img
                                  className="mx-2"
                                  height={12}
                                  src={carImg}
                                />
                              </div>
                              <div
                                className="text-center mr-2"
                                style={{ lineHeight: "14px" }}
                              >
                                <small className="mb-0 d-block font-weight-bolder">
                                  Car Parking{" "}
                                </small>
                                <small className="mb-0 d-block text-muted">
                                  {card.CarParking}
                                </small>
                              </div>
                              <div className="border-right border-dark my-2"></div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="d-flex justify-content-center align-items-center">
                                <img
                                  className="mx-2"
                                  height={12}
                                  src={bedRoomLogo}
                                />
                              </div>
                              <div
                                className="text-center mr-2"
                                style={{ lineHeight: "14px" }}
                              >
                                <small className="mb-0 d-block font-weight-bolder">
                                  {" "}
                                  Bedroom
                                </small>
                                <small className="mb-0 d-block text-muted">
                                  {card.Bedrooms}
                                </small>
                              </div>
                              <div className="border-right border-dark my-2"></div>
                            </React.Fragment>
                          )}
                        </div>
                        <div className="d-flex ">
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              height={12}
                              className="mx-2"
                              src={floorNumberImgProperties}
                            />
                          </div>
                          <div
                            className="text-center "
                            style={{ lineHeight: "14px" }}
                          >
                            <small className="mb-0 d-block font-weight-bolder">
                              Floor Number
                            </small>
                            <small className="mb-0 d-block text-muted">
                              {card.FloorNo}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex my-2 d-md-flex d-flex mx-1 text-center align-items-center justify-content-around">
                      <div
                        className=" col-md-5 mr-auto d-flex flex-column justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#fff9f8",
                          minHeight: "65px",
                          minWidth: "100px",
                        }}
                      >
                        <small className="mb-0">Area</small>
                        <h6
                          className=" font-weight-bold"
                          style={{ color: "#a1a09f", fontSize: "smaller" }}
                        >
                          {card.PropertyAreaSFT} SFT
                        </h6>
                      </div>
                      <div
                        className="col-md-6 ml-auto d-flex flex-column justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#fff9f8",
                          minHeight: "65px",
                          minWidth: "100px",
                        }}
                      >
                        <small className="mb-0 d-block">Brick Price</small>
                        <small className="mb-0 d-block">(Price / Sqft)</small>
                        <h6
                          className="font-weight-bold"
                          style={{ color: "#a1a09f", fontSize: "smaller" }}
                        >
                          <span>&#8377;</span>
                          {card.SFTValue}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex my-2 mx-1 text-center align-items-center justify-content-around">
                      <div
                        className=" col-md-5 mr-auto d-flex flex-column justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#fff9f8",
                          minHeight: "65px",
                          minWidth: "100px",
                        }}
                      >
                        <small className="mb-0">Yield</small>
                        <h6
                          className=" font-weight-bold"
                          style={{ color: "#a1a09f", fontSize: "smaller" }}
                        >
                          {card.Yield}
                        </h6>
                      </div>
                      <div
                        className="col-md-6 ml-auto d-flex flex-column justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#fff9f8",
                          minHeight: "65px",
                          minWidth: "100px",
                        }}
                      >
                        <small className="mb-0 d-block">Return Target</small>
                        <h6
                          className=" font-weight-bold"
                          style={{ color: "#a1a09f", fontSize: "smaller" }}
                        >
                          {card.ReturnTarget}%
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex my-2 mx-1 text-center align-items-center justify-content-around">
                      <div
                        className=" col-md-5 mr-auto d-flex flex-column justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#fff9f8",
                          minHeight: "65px",
                          minWidth: "100px",
                        }}
                      >
                        <small className="mb-0">Minimum Investment</small>
                        <h6
                          className="font-weight-bold"
                          style={{ color: "#a1a09f", fontSize: "smaller" }}
                        >
                          <span>&#8377;</span>
                          {card.MinimumInvestment}
                        </h6>
                      </div>
                      <div
                        className="col-md-6 ml-auto d-flex flex-column justify-content-center align-items-center"
                        style={{
                          backgroundColor: "#fff9f8",
                          minHeight: "65px",
                          minWidth: "100px",
                        }}
                      >
                        <small className="mb-0 d-block">
                          Minimum Bricks to Buy
                        </small>
                        <h6
                          className=" font-weight-bold"
                          style={{ color: "#a1a09f", fontSize: "smaller" }}
                        >
                          {card.MinimumBricks}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex my-2 mx-1 text-center align-items-center justify-content-around">
                      <div className=" col-md-4  d-flex flex-column justify-content-center align-items-center">
                        <small className="mb-0" style={{ fontWeight: "500" }}>
                          Investors
                        </small>
                        <small
                          className=" d-block"
                          style={{ color: "#a8c770", fontWeight: "400" }}
                        >
                          {card.NumberofInvestors}
                        </small>
                      </div>
                      <div className="col-md-4  d-flex flex-column justify-content-center align-items-center">
                        <small
                          className="mb-0 d-block"
                          style={{ fontWeight: "500" }}
                        >
                          Funded
                        </small>
                        <small
                          className=" d-block"
                          style={{ color: "#a8c770", fontWeight: "400" }}
                        >
                          {card.PercentageFunded} %
                        </small>
                      </div>
                      <div className="col-md-4 pl-0 d-flex flex-column justify-content-center align-items-center">
                        <small
                          className="mb-0 d-block "
                          style={{ fontWeight: "500" }}
                        >
                          Settlement
                        </small>
                        <small
                          className=" d-block"
                          style={{ color: "#a8c770", fontWeight: "400" }}
                        >
                          {/*{card.SettlementDate}*/}
                          {moment(card.SettlementDate)
                            .format("DD-MMM-YYYY")
                            .toUpperCase()}
                        </small>
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        color: "#a8c770",
                        marginLeft: "40px",
                        marginTop: "-4px",
                        marginRight: "30px",
                        borderBottom: "2px solid #a8c770",
                      }}
                    ></div>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        marginLeft: "35px",
                        marginRight: "25px",
                        borderRadius: "30px",
                      }}
                    >
                      {card.PropertyType === "Commercial" ? (
                        <React.Fragment>
                          <Link
                            to={{
                              // pathname: "/properties/one/commercial",
                              pathname: `/properties/${card.PropertyType == 6 ? 'residential' : 'commercial'}/${card.PropertyID}`,
                              param1: card.PropertyID,
                            }}
                          >
                            <button
                              className="btn py-2 mb-0 btn-lg w-100 text-white text-capitalize "
                              style={{
                                backgroundColor: "rgb(155, 205, 42)",
                                borderRadius: "10px",
                              }}
                            >
                              View Property
                            </button>
                          </Link>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Link
                            to={{
                              pathname: `/properties/${card.PropertyType == 6 ? 'residential' : 'commercial'}/${card.PropertyID}`,
                              param1: card.PropertyID,
                            }}
                          >
                            <button
                              className="btn py-2 mb-0 btn-lg w-100 text-white text-capitalize "
                              style={{
                                backgroundColor: "rgb(155, 205, 42)",
                                borderRadius: "10px",
                              }}
                            >
                              View Property
                            </button>
                          </Link>
                        </React.Fragment>
                      )}
                    </div>
                    <div className="text-center mt-0">
                      <small style={{ color: "rgb(155, 205, 42)" }}>
                        {card.belowValuation} Below Valuation
                      </small>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
        {/* <div
          className="mx-auto d-flex justify-content-around align-items-center my-5 py-2"
          style={{ width: "220px" }}
        >
          <div
            className="text-white btn p-0 m-0 shadow-none my-auto d-flex justify-content-center align-items-center "
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#ff8282",
            }}
          >
            <span className="font-small d-flex justify-content-center align-items-center">
              <img height={12} src={leftArrow} />
            </span>
          </div>
          <div
            className="text-white btn p-0 m-0 shadow-none my-auto d-flex justify-content-center align-items-center "
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#ff8282",
            }}
          >
            <span className="font-small">01</span>
          </div>
          <div
            className="text-white btn p-0 m-0 shadow-none my-auto d-flex justify-content-center align-items-center "
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#ff8282",
            }}
          >
            <span className="font-small">02</span>
          </div>
          <div
            className="text-white btn p-0 m-0 shadow-none my-auto d-flex justify-content-center align-items-center "
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#ff8282",
            }}
          >
            <span className="font-small">03</span>
          </div>
          <div
            className="text-white btn p-0 m-0 shadow-none my-auto d-flex justify-content-center align-items-center "
            style={{
              height: "25px",
              width: "25px",
              borderRadius: "50%",
              backgroundColor: "#ff8282",
            }}
          >
            <span className="font-small d-flex justify-content-center align-items-center">
              <img height={12} src={rightArrow} />
            </span>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default PropertiesCards;
