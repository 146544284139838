import React from "react";

import blockChainImg from "../../assets/blockchainImg.png";
import propertyProcess from "../../assets/propertyProcess.png";
import blockChainLeaseImg from "../../assets/blockchainLeaseImg.png";
let BlockchainCRELeasing = () => {
    return (
        <React.Fragment>
            <div className="container-fluid px-md-5 my-5 pt-3 px-2">
                <div className="mx-md-5 px-md-5 px-0 mx-2">
                    <div>
                        <p style={{ fontWeight: "400", fontSize: "25px" }}>How can Blockchain technology elevate CRE leasing, purchase and sale transactions?</p>

                        <p className=" my-3 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>Blockchain technology in CRE leasing processes</p>
                        <p style={{ fontWeight: "400", fontSize: "16px" }}>CRE owners have an opportunity to alleviate some of the existing challenges in their leasing transactions using
                            blockchain technology (also visualized in on the following page):</p>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-3 mx-md-auto mx-0 px-md-auto px-2 my-2">
                            <div className="m-2  p-4  text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#ff8282" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>CRE owners have an opportunity to alleviate some of the existing challenges in their leasing transactions using
                                    blockchain technology (also visualized in Figure 3 on the following page):</p>
                            </div>
                        </div>
                        <div className="col-md-3 mx-md-auto mx-0 px-md-auto px-2 my-2">
                            <div className="m-2  p-4  text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#98bc56" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>Time-consuming, paper-driven, predominantly
                                    offline due-diligence process</p>
                            </div>
                        </div>
                        <div className="col-md-3 mx-md-auto mx-0 px-md-auto px-2 my-2">
                            <div className="m-2  p-4  text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#1cc9be" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>Complexity in managing
                                    ongoing lease agreements,
                                    property operations, and
                                    cash flows</p>
                            </div>
                        </div>
                        <div className="col-md-3 mx-md-auto mx-0 px-md-auto px-2 my-2">
                            <div className="m-2  p-4  text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#009bc0" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>Absence of real-time rich data affects
                                    management’s decision-making capability</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <p style={{ fontWeight: "400", fontSize: "25px" }}> Blockchain technology in a CRE lease transaction</p>
                        <img src={blockChainLeaseImg} alt="" className="img-fluid text-center d-flex justify-content-center mx-auto" />
                    </div>
                    <div className="my-4">
                        <p style={{ fontWeight: "700", fontSize: "20px" }}>Property search through Blockchain-enabled MLS</p>
                        <p style={{ fontWeight: "400", fontSize: "16px" }}>The lessor and the lessee or their respective brokers list their requirements on the multiple-listing services (MLS).
                            A transparent MLS system enables all parties to view the available listings based on their requirements. </p>
                    </div>
                    <div className="my-5">
                        <p style={{ fontWeight: "700", fontSize: "20px" }}>Property visit and inspection</p>
                        <p style={{ fontWeight: "400", fontSize: "16px" }}>The brokers discuss their clients’ requirements and arrange for property visits and inspection. </p>
                    </div>
                    <div className="container-fluid px-0">
                        <div className=" py-0 pl-md-1 my-4 px-0" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">1</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Negotiation and signing of the letter of intent</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Both sides negotiate the terms and value of the deal.
                                            The lessee sends the letter of intent (LOI) to the lessor, expressing interest in the property </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">2</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Pre-lease due diligence by using smart identities</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Using blockchain-based digital identities of individuals and assets, the lessor conducts a background
                                            check on the lessee and the lessee checks the prior
                                            transactions and liens on the property. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">3</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Preparation of the heads of agreement</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            The heads of agreement, containing all the clauses and terms agreed between the two sides, is prepared
                                            and verified by the accounts and legal teams on both sides.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">4</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Lease agreement using smart contracts </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            The key terms of the agreement are recorded on the blockchain and this becomes the smart contract.
                                            The smart contract initiates payment of security deposit/advance
                                            rent either through Bitcoin wallets or
                                            bank accounts using a payment interface.
                                            The lessor then transfers the possession of the property to the lessee.
                                            The transaction agreement is officially recorded.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">5</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Automated payments and cash flow management using the smart contract</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2 mb-0  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Based on the terms of the agreement, the smart contract initiates the regular lease payments from the lessee
                                            to the lessor, after paying the outstanding maintenance expenses to the contractors, using the preferred
                                            mode of payment.</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2 mb-0  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>On completion of the lease term, the smart contract initiates the transfer of the security deposit to the lessor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">6</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Real-time data analysis</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            As several payments and transactions are recorded on the blockchain along with the digital identities of
                                            individuals, properties, and organizations, the lessor can perform real-time data analyses using appropriate
                                            analytics tools. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">7</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Blockchain in CRE purchase and sale transactions</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            The existing purchase and sale transaction process is complex and involves multiple steps. As such, identifying a
                                            property and closing a transaction can be a time-consuming affair and costly for both buyers and sellers, which
                                            may include CRE owners or institutional investors. Such impediments may include: </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col-md-3 my-2">
                            <div className="m-2 p-md-4 p-3 text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#ff8282" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>Inefficient property search process due to
                                    fragmented listings data </p>
                            </div>
                        </div>
                        <div className="col-md-3 my-2">
                            <div className="m-2 p-md-4 p-3 text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#98bc56" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>Time-consuming, paper-driven, predominantly
                                    offline due-diligence process</p>
                            </div>
                        </div>
                        <div className="col-md-3 my-2">
                            <div className="m-2 p-md-4 p-3 text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#1cc9be" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>High title insurance and related costs due to chain of title and
                                    lien recording issues, fraud risk, required
                                    diligence, and cumbersome clearance process
                                </p>
                            </div>
                        </div>
                        <div className="col-md-3 my-2">
                            <div className="m-2 p-md-4 p-3 text-white h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: "#009bc0" }}>
                                <p className="mb-0" style={{ fontSize: "14px " }}>Slow, expensive, and opaque financing
                                    mechanisms and payments, especially in
                                    cross-border transactions
                                </p>
                            </div>
                        </div>

                    </div>
                    <div className="text-center my-4 d-flex justify-content-center mx-md-4 mx-0">
                        <img className="img-fluid text-center" style={{ height: "80%", width: "100%" }} src={propertyProcess} alt="" />
                    </div>
                    <div className="container-fluid px-0 my-4">
                        <div className=" py-0 pl-md-1 my-4 px-0" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">1</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Property search process </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Buyer shortlists properties using a blockchain-enabled multiple listing service (MLS).  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">2</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Underwriting and financial evaluation </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Buyer creates a financial model of rents, occupancy, and costs, based on the data available from the property’s
                                            digital identity on blockchain.Buyer determines the bid price.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">3</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Property visit, broker meetings</p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Conducts a physical inspection of the property, and of similar properties for a relative comparison.Elicits broker’s opinion on the selected property. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">4</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Memorandum of Understanding (MOU) and loan process initiation
                                        </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Buyer and seller sign an MOU and the buyer transfers the earnest deposit into an escrow account.
                                            Buyer also initiates the loan process with the mortgage lender.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">5</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Pre-purchase due diligence using smart identities and blockchain-based title registry </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2 mb-0  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            Using blockchain-based digital identities and title registry, the buyer and/or the mortgage lender conduct several checks including
                                            environmental clearances, zoning permissions, tenant leases, and title clarity, among others.
                                            The mortgage lender also conducts a
                                            due diligence of the buyer (borrower) to assess the loan terms,including loan amount and loan-to-value. </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2 mb-0  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>On completion of the lease term, the smart contract initiates the transfer of the security deposit to the lessor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">6</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Final review and signing of the smart contract </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            On successful completion of the due diligence:
                                            A smart contract between the buyer and the seller is reviewed and executed by the deal principals and the
                                            advisors of both parties.A smart loan contract between the buyer and the mortgage lender is linked to the above contract between
                                            buyer and seller. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-0 pl-md-1 my-4 " >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex   align-items-center">
                                    <div className="my-auto">
                                        <p
                                            style={{
                                                fontSize: "28px", color: "rgb(22, 142, 186)", borderRadius: "50%", height: "100%",
                                                paddingTop: "12px", paddingBottom: "12px", paddingLeft: "25px", paddingRight: "25px"
                                            }}
                                            className="mb-0 border  ">7</p>
                                    </div>
                                    {/*<img className="text-md-left mx-md-3 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt=""/>*/}
                                    <div className="d-flex flex-column ">
                                        <p className=" mx-md-2 px-md-4 px-2 mb-1 text-left" style={{ fontWeight: "700", fontSize: "15px" }}>
                                            Execution of the sale </p>
                                        <p className="mx-md-2 text-left px-md-4 px-2  mx-1 " style={{ fontWeight: "400", fontSize: "16px" }}>
                                            The mortgage lender transfers the sale proceeds to the escrow account and the seller transfers the
                                            possession to the buyer.
                                            The smart contract between the buyer and the seller initiates the payment of sale proceeds from the
                                            escrow account to the seller’s bank account and notifies the registrar to initiate the property title transfer
                                            to the buyer with a lien on the property in favor of the mortgage lender.
                                            The registrar validates the transfer of the property title on blockchain and a new permanent block is
                                            created, making it official and irreversible.
                                            The smart loan contract between the buyer and the mortgage lender initiates regular loan repayments
                                            to the latter.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </React.Fragment>
    )
};
export default BlockchainCRELeasing;