import React, { useEffect, useState } from "react";
import Arrow1 from "../../../assets/img/arrow1.png";
import Arrow2 from "../../../assets/img/arrow2.png";
import Arrow3 from "../../../assets/img/arrow3.png";
import triangleVector from "../../../assets/img/triangleVector.png";
import redDot from "../../../assets/rredDot.png";
import darkDot from "../../../assets/darkDot.png";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import Fade from "react-reveal/Fade";
let QuickNEasy = () => {
    let commentsArray = [
        {
            id: 0,
            name: "Russell Sage , United States.",
            comment:
                "Real estate is an imperishable asset, ever increasing in value. It is the most solid security that human ingenuity has devised. It is the basis of all security and about the only indestructible security.",
        },
        {
            id: 1,
            name: "Ankita Thakur , India.",
            comment:
                " The real estate is an imperishable asset, ever increasing in value. It is the most solid security that human ingenuity has devised. It is the basis of all security and about the only indestructible security.",
        },
        {
            id: 2,
            name: "Sunil Patil , UAE.",
            comment:
                "Real estate is the most solid security that human ingenuity has devised. It is the basis of all security and about the only indestructible security.",
        },
        {
            id: 3,
            name: "Payal Shinde , United Kingdom.",
            comment:
                "Real estate is an imperishable asset, ever increasing in value. It is the most solid security that human ingenuity has devised. It is the basis of all security and about the only indestructible security.",
        },
    ];
    let [comments, setComments] = useState(0);
    // useEffect(()=>{
    //     setComments(comments++)
    // },[]);
    return (
        <React.Fragment>
            <Fade bottom>
                <div
                    style={{
                        padding: "10px",
                        marginTop: "30px",
                        position: "relative",
                    }}>
                    <div
                        className="container d-block"
                        style={{ position: "relative", zIndex: "2" }}>
                        <p
                            className=" mx-auto"
                            style={{
                                height: "5px",
                                width: "50px",
                                backgroundColor: "rgb(22, 142, 186)",
                            }}></p>
                        <ScrollAnimation
                            animateIn="fadeIn"
                        // animateOnce={true}
                        >
                            <h4 className="h1 text-center mb-0">
                                Quick & Easy
                            </h4>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeIn"
                            // animateOnce={true}
                            delay={"100"}>
                            <h6
                                className="text-center font-weight-bolder"
                                style={{ color: "rgb(22, 142, 186)" }}>
                                Process is simplified for quick & easy
                                investment
                            </h6>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="fadeIn"
                        // animateOnce={true}
                        >
                            <div className="row mt-5">
                                <div className="col-md-4 ">
                                    <div
                                        className="card h-100 mx-md-4 mx-sm-2"
                                        style={{ backgroundColor: "#f6f6f6" }}>
                                        <div className="mt-4">
                                            <img
                                                alt="img"
                                                style={{
                                                    marginLeft: "-15px",
                                                    height: "80px",
                                                    width: "170px",
                                                }}
                                                src={Arrow1}
                                            />

                                            <h4 className="h2 text-white card-img-overlay mt-4 ml-3">
                                                01
                                            </h4>
                                        </div>

                                        <div className="p-4">
                                            <p className="h4 text-center">
                                                Digital First
                                            </p>
                                            <p>
                                                All the data lives on BrickBerry
                                                platform so that your investment
                                                and sale experience is smooth.
                                                Digital First is still supported
                                                through government and
                                                regulatory compliance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 ">
                                    <div
                                        className="card h-100 mx-md-4 mx-sm-2"
                                        style={{ backgroundColor: "#f6f6f6" }}>
                                        <div className="mt-4">
                                            <img
                                                alt="img"
                                                style={{
                                                    marginLeft: "-15px",
                                                    height: "80px",
                                                    width: "170px",
                                                }}
                                                src={Arrow2}
                                            />
                                            <h4 className="h2 text-white card-img-overlay mt-4 ml-3">
                                                02
                                            </h4>
                                        </div>
                                        <div className="p-4 ">
                                            <p className="h4 text-center">
                                                Simplified
                                            </p>
                                            <p>
                                                We worked with world class legal
                                                professionals and digital
                                                experience designers to deliver
                                                a seamless investment experience
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4 ">
                                    <div
                                        className="card h-100 mx-md-4 mx-sm-2"
                                        style={{ backgroundColor: "#f6f6f6" }}>
                                        <div className="mt-4">
                                            <img
                                                alt="img"
                                                style={{
                                                    marginLeft: "-15px",
                                                    height: "80px",
                                                    width: "170px",
                                                }}
                                                src={Arrow3}
                                            />
                                            <h4 className="h2 text-white card-img-overlay mt-4 ml-3">
                                                03
                                            </h4>
                                        </div>
                                        <div className="p-4">
                                            <p className="h4 text-center">
                                                Compliant
                                            </p>
                                            <p>
                                                We handle all backend paperwork,
                                                compliance with various
                                                government agencies and private
                                                parties so that you don't have
                                                to run around.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <img
                        src={triangleVector}
                        height={700}
                        style={{
                            position: "absolute",
                            zIndex: "1",
                            top: "-25%",
                            right: "0%",
                            width: "25%",
                            minWidth: "200px",
                        }}
                        alt=""
                    />
                </div>
                <ScrollAnimation
                    animateIn="fadeIn"
                    // animateOnce={true}
                    delay={"100"}>
                    <div className="container">
                        <div className="row">
                            <div
                                className="mx-5 mt-5 p-5"
                                style={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius: "20px",
                                    fontSize: "16px",
                                    fontWeight: "700",
                                }}>
                                <p>“{commentsArray[comments].comment}”</p>
                                <p className="ml-auto text-right mb-0">
                                    - {commentsArray[comments].name}
                                </p>
                            </div>
                        </div>
                        <div className=" mb-3 mt-3">
                            <div className="d-flex justify-content-center align-items-center">
                                {commentsArray?.map((item) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <img
                                                className="mx-2"
                                                onClick={() =>
                                                    setComments(item.id)
                                                }
                                                key={item.comment}
                                                src={
                                                    comments === item.id
                                                        ? redDot
                                                        : darkDot
                                                }
                                                height={20}
                                                alt=""
                                            />
                                        </React.Fragment>
                                    );
                                })}
                                {/*<img src={redDot} height={20} alt=""/>*/}
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            </Fade>
        </React.Fragment>
    );
};
export default QuickNEasy;
