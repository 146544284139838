import React from "react";
import BricksImg from "../../../assets/img/bricks.png";
import { Doughnut } from 'react-chartjs-2';
import pinkTriangles from "../../../assets/img/pinkTriangles.png";
import verticleTriangle from "../../../assets/img/verticleTriangle.png";
import FaintTriangleImg from "../../../assets/img/FaintTriangleImg.png";

const data = {
    labels: [
        'Commercial',
        'Gold',
        'Residential',
        'Sensex'
    ],
    datasets: [{
        data: [40, 35, 13, 12],
        backgroundColor: [
            '#ff6363',
            '#ffb9b9',
            '#b2df8a',
            '#5bbc04'
        ],
        hoverBackgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#FFCE56'
        ]
    }]
};
const legend = {
    display: true,
    position: "bottom",
    labels: {
        fontColor: "#323130",
        fontSize: 14
    }
};

let CommercialRealEstate = () => {
    return (
        <React.Fragment>
            <div style={{ padding: "10px", marginTop: "30px", position: "relative" }}>
                <div className="container d-block" style={{ position: "relative", zIndex: "2", maxWidth: "1130px" }}>
                    <p className=" mx-auto" style={{ height: "5px", width: "50px", backgroundColor: "rgb(22, 142, 186)" }}></p>
                    <h4 className="h1 text-center mb-0"> Why Commercial Real Estate</h4>
                    <h6 className="text-center font-weight-bolder" style={{ color: "rgb(22, 142, 186)" }}>A stable asset class with regular cash flow , capital appreciations and shielded from a volatile market</h6>
                    <div className="row my-5 mx-4">
                        <div className="col-md-6 ">
                            <div className="ml-5" style={{ marginLeft: "700px" }}>
                                <h6 className="font-weight-bolder h5 text-center">Stable asset class</h6>
                                <p className="font-weight-bolder text-center px-4">Stay insulated from market fluctuations by investing in a stable asset class.</p>
                            </div>
                            {/*<div className="brickImgClass p-1">*/}
                            <div className=" p-1" style={{ position: "relative" }}>
                                <div className="my-2  d-flex justify-content-center" >
                                    <div className="text-center  " >
                                        <div className=" d-flex justify-content-between mx-0 px-1">
                                            <p className="font-weight-bold  " style={{ marginRight: "50px", fontSize: "18px" }}>FRACTIONAL REAL-ESTATE</p>
                                            <p className="ml-auto font-weight-bold text-muted">14.7%</p>
                                        </div>
                                        <div className="div d-flex justify-content-between mx-0 px-1">
                                            <p className="font-weight-bold mr-5">Equity</p>
                                            <p className="ml-auto text-muted h6">12.05%</p>
                                        </div>
                                        <div className="div d-flex justify-content-between mx-0 px-1">
                                            <p className="font-weight-bold mr-5">PPF</p>
                                            <p className="ml-auto text-muted h6">7-8%</p>
                                        </div>
                                        <div className="div d-flex justify-content-between mx-0 px-1">
                                            <p className="font-weight-bold mr-5">Debt Mutual Funds</p>
                                            <p className="ml-auto text-muted h6">7.5%</p>
                                        </div>
                                        <div className="div d-flex justify-content-between mx-0 px-1">
                                            <p className="font-weight-bold mr-5">Bank FD</p>
                                            <p className="ml-auto text-muted h6">6-8%</p>
                                        </div>
                                    </div>
                                </div>
                                {/*<img alt="img" style={{position:"absolute",maxHeight:"300px",width:"750px",top:"0",left:"-230px"}} src={BricksImg}/>*/}
                                <img alt="img" className="CommercialEstateBlockLeft" style={{ position: "absolute", maxHeight: "300px", width: "160%", top: "0", left: "-50%" }} src={BricksImg} />
                            </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="ml-5" style={{ marginLeft: "700px" }}>
                                <h6 className="font-weight-bolder h5 text-center">High Yields</h6>
                                <p className="font-weight-bolder text-center px-4">Historically commercial real estate has delivered market beating performance.</p>
                            </div>
                            {/*<div className="brickImgClass p-1">*/}
                            <div className=" p-1" style={{ position: "relative" }}>
                                <div className="my-2  d-flex justify-content-center" >
                                    <div className="text-center " style={{ height: "250px", width: "300px" }}>
                                        <Doughnut data={data} legend={legend}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                            }}
                                        />
                                    </div>
                                </div>
                                {/*<img alt="img" style={{position:"absolute",maxHeight:"300px",width:"138%",top:"0",left:"10%"}} src={BricksImg}/>*/}
                                <div className="">
                                    <img alt="img " className="CommercialEstateBlockRight"
                                        style={{ position: "absolute", maxHeight: "300px", maxWidth: "138%", width: "138%", top: "0", left: "10%" }} src={BricksImg} />
                                </div>
                                {/*<div className="d-md-none d-sm-block">*/}
                                {/*<img alt="img CommercialEstateBlockRight" style={{position:"absolute",maxHeight:"400px",maxWidth:"138%",width:"250px",top:"0",left:"10%"}}*/}
                                {/*     src={BricksImg}/>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>

                </div>
                {/*<img src={pinkCircles} height={50} style={{position:"absolute",zIndex:"1",top:"2%",left:"5%"}} alt=""/>*/}
                <img src={pinkTriangles} height={120} style={{ position: "absolute", zIndex: "1", top: "-4%", right: "10%" }} alt="" />
                <img src={pinkTriangles} height={120} style={{ position: "absolute", zIndex: "1", top: "5%", left: "5%" }} alt="" />
                <img src={verticleTriangle} height={50} style={{ position: "absolute", zIndex: "1", top: "30%", left: "55%" }} alt="" />
            </div>
        </React.Fragment>
    )
};
export default CommercialRealEstate;