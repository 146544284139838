import React, {useEffect, useRef, useState} from "react";
import SecurePinTitle from "./securePinTitle";
import reactangleImg from "./assets/reactangleImg.png";
import blackCircleImg from "./assets/blackCircleImg.png";
import blackTriangleImg from "./assets/blackTriangleImg.png";
import dotImage from "./assets/dotImage.png";
import {Link,useLocation,useHistory} from "react-router-dom";
import NavbarPin from "./NavbarPin";
import Axios from "axios";
import {useDispatch} from "react-redux";
import {loginUser} from "../../redux/users/users.actions";

let EnterPin=()=>{
    let dispatch = useDispatch();
    const location=useLocation();
    const history=useHistory();
    let input1=useRef(null);
    let input2=useRef(null);
    let input3=useRef(null);
    let input4=useRef(null);
    let submit=useRef(null);
    useEffect(()=>{
        input1.focus()
    },[])
    let [user,setUser]=useState({
        UserID:'',
        PIN:''
    });
    let[pinError,setPinError]=useState(null);
    let[pin1,setPin1]=useState('');
    let[pin2,setPin2]=useState('');
    let[pin3,setPin3]=useState('');
    let[pin4,setPin4]=useState('');
    useEffect(()=>{
        setUser({
            ...user,
            UserID:location.userId,
        })
    },[ ]);
    let handlePin1=(e)=>{
            const value = e.target.value.replace(/\D/g, "");
            setPin1(value);
            if(value.length ===1){
                input2.focus()
            }
    };
    let handlePin2=(e)=>{
        const value = e.target.value.replace(/\D/g, "");
        setPin2(value);
        if(value.length ===1){
            input3.focus()
        }
    };
    let handlePin3=(e)=>{
        const value = e.target.value.replace(/\D/g, "");
        setPin3(value);
        if(value.length ===1){
            input4.focus()
        }
    };
    let handlePin4=(e)=>{
        const value = e.target.value.replace(/\D/g, "");
        setPin4(value);
        if(value.length ===1){
            submit.focus()
        }
    };
    let enterPin=async (e)=>{
        e.preventDefault();
        let pin = pin1.concat(pin2).concat(pin3).concat(pin4);
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let userData={
            UserID:user.UserID,
            PIN:pin };
        let dataURL = `${process.env.REACT_APP_API_URL}/userlogin/login_with_securepin`;
        await Axios.post(dataURL, JSON.stringify(userData),config).then((response) => {
            console.log(response);
            if (response.data.Status ===1){
                dispatch(loginUser(user.UserID,history));
                // history.push({
                //     pathname: '/',
                // })
            }
            else if (response.data.Status ===0){
                setPinError(response.data.Message);
                setTimeout(()=>{
                    setPinError(null)
                },4000);
                console.log(response.data.Message);
            }
        }).catch((err) => {
            console.log(err)
        });
    };
  return(
      <React.Fragment>
          <NavbarPin/>
          <div >
              <SecurePinTitle/>
              <div className=" img-fluid " >
                  <img  src={reactangleImg} style={{height:"61%",width:"60%",position:"absolute",zIndex:"1"}} alt="" className="img-fluid rectangleSecureImg"/>
              </div>
              <div className="col-md-4 mx-auto" style={{position:"relative",zIndex:"2"}}>
                  <p className="text-center my-4" style={{fontWeight:"400",fontSize:"17px",color:"#FF8282"}}>Please enter your unique 4 digit pin for more secure log in</p>
                  <p className="text-center mb-4" style={{fontWeight:"700",fontSize:"40px"}}>Enter Your PIN</p>
                 <form onSubmit={enterPin}>
                  <div className="px-md-5 px-2 mx-md-4  mx-auto my-2">
                      <div className="d-flex justify-content-between my-3">
                          <div className="form-outline">
                              <input value={pin1} onChange={handlePin1} type="text"
                                     ref= {(input) => input1 = input}
                                     pattern="^[0-9]*$"  min="0" max="9" maxLength="1"
                                     style={{width:"50px", height:"50px"}} id="typePassword" className="form-control"/>
                          </div>
                          <div className="form-outline">
                              <input type="text" value={pin2} onChange={handlePin2}
                                     ref= {(input) => input2 = input}
                                     pattern="^[0-9]*$"  min="0" max="9" maxLength="1"
                                     style={{width:"50px", height:"50px"}} id="typePassword" className="form-control"/>
                          </div>
                          <div className="form-outline">
                              <input type="text"
                                     pattern="^[0-9]*$"  min="0" max="9" maxLength="1"
                                     ref= {(input) => input3 = input}
                                     value={pin3} onChange={handlePin3}
                                     style={{width:"50px", height:"50px"}} id="typePassword" className="form-control"/>
                          </div>
                          <div className="form-outline">
                              <input type="text" value={pin4} onChange={handlePin4}
                                     ref= {(input) => input4 = input}
                                     pattern="^[0-9]*$"  min="0" max="9" maxLength="1"
                                     style={{width:"50px", height:"50px"}} id="typePassword" className="form-control"/>
                          </div>
                      </div>
                      <p className="text-dark text-right my-0" style={{fontWeight:"400",fontSize:"16px"}}>Forgot PIN?</p>

                  </div>

                  <div className="text-center mt-5">
                      {pinError && <p className="text-center my-2" style={{color:"#FF8282"}}>{pinError}</p>}
                      {/*<Link to={"/"}>*/}
                          <button type="submit" className="btn btn-md py-2 px-5 text-capitalize text-white"
                                  ref= {(input) => submit = input}
                           onClick={(e) => {
                            e.preventDefault();
                            dispatch(loginUser(user.UserID,history));
                            history.push({
                                pathname:'/dashboard/overview',
                            userId:user.UserID});
                            window.location.reload();

                        }}
                              style={{backgroundColor:"#ff8282",fontSize:"20px",fontWeight:"600",borderRadius:"10px"}}>
                          Submit Pin
                      </button>
                      {/*</Link>*/}
                  </div>
                 </form>
              </div>
              <img  src={blackCircleImg} style={{position:"absolute",height:"80px",zIndex:"1",top:"65%",left:"62%"}} alt="" className="img-fluid"/>
              <img  src={blackTriangleImg} style={{position:"absolute",zIndex:"1",height:"75px",top:"45%",left:"72%"}} alt="" className="img-fluid"/>
              <img  src={blackCircleImg} style={{position:"absolute",height:"50px",zIndex:"1",top:"78%",left:"72%"}} alt="" className="img-fluid"/>
              <img  src={dotImage} style={{position:"absolute",height:"120px",zIndex:"1",top:"58%",left:"82%"}} alt="" className="img-fluid dotImageSecure"/>

          </div>
      </React.Fragment>
  )
};
export default EnterPin;
