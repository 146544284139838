import React, { useEffect, useRef, useState } from "react";
import Logo from "../assets/img/BrandLogo.png";
import BrickberryLogo from "../assets/img/brickBerryNavbarLogo.png";
import BrickberryLogoname from "../assets/BbrickberryLogo.svg";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { USER_FEATURE_KEY } from "../redux/users/users.reducers";
import { logOutUser } from "../redux/users/users.actions";
import { getUser, deleteUser } from "../utils/localStorage";
import logo from '../assets/assetspositive.svg'
let Navbar = (props) => {
  let history = useHistory();
  let [active, setActive] = useState(1);
  let [activeDropdown, setActiveDropdown] = useState(1);
  let [open, setOpen] = useState(false);
  let [dropdown, showDropdown] = useState(false);
  let [login, setLogin] = useState(false);
  let [mount, setMount] = useState(false);
  const dropDownRef = useRef();
  let dispatch = useDispatch();
  let userInfo = useSelector((state) => {
    return state[USER_FEATURE_KEY];
  });

  let { UserID } = userInfo;

  let [auth, setAuth] = useState(undefined);

  useEffect(() => {
    const user = getUser();
    setAuth(user);
    // add when mounted
    setMount(true);
    if (UserID !== null) {
      setLogin(true);
    }
    // if (localStorage.getItem("user")){
    //     setLogin(true);
    //     console.log(true);
    // }
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      setMount(false);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [UserID, auth]);
  const handleClickOutside = (e) => {
    if (!dropDownRef?.current?.contains(e.target)) {
      // inside click
      // setShowMenu(!showMenu);
      if (setMount) {
        showDropdown(false);
      }
    }
  };
  let logOut = () => {
    dispatch(logOutUser(history));
    deleteUser();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark " style={{ backgroundColor: "black", padding: "0.6rem 1rem" }}>
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setOpen(!open)}
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarLeftAlignExample"
            aria-controls="navbarLeftAlignExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars" />
          </button>

          <div
            className="collapse navbar-collapse  mx-0 px-0"
            id="navbarLeftAlignExample"
          >
            {/* <a className="navbar-brand" href="#">
              <img width={200} src={BrickberryLogo} />
            </a> */}
            <ul className="navbar-nav w-100 d-md-flex d-block justify-content-around mx-auto align-items-center">
              <li className="nav-item ">
                <Link
                  to={"/"}
                  className="navbar-brand d-flex align-items-center "
                  href=""
                >

                  <img alt="" width={50} src={BrickberryLogo} />
                  <img alt="" width={150} src={BrickberryLogoname} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link pb-0"
                  onClick={() => setActive(1)}
                  style={
                    props.active === "home"
                      ? { borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem" }
                      : { padding: "0 1rem" }
                  }
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/HowItWorks"
                  className="nav-link pb-0"
                  onClick={() => setActive(2)}
                  // style={active === 2 ? {borderBottom:"2px solid #ef7a7a", color:"#ef7a7a"}:{padding: "0 1rem"}}
                  style={
                    props.active === "howItWorks"
                      ? { borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem" }
                      : { padding: "0 1rem" }
                  }
                >
                  How It Works
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/properties"}
                  className="nav-link pb-0"
                  onClick={() => setActive(3)}
                  style={
                    props.active === "properties"
                      ? { borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem" }
                      : { padding: "0 1rem" }
                  }
                >
                  Properties
                </Link>
              </li>
              {auth && (
                <li className="nav-item">
                  <Link
                    to="/dashboard/overview"
                    className="nav-link pb-0"
                    onClick={() => setActive(4)}
                    style={
                      props.active === "dashboard"
                        ? {
                          borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                        }
                        : { padding: "0 1rem" }
                    }
                  >
                    Dashboard
                  </Link>
                </li>
              )}
              <li className="nav-item dropdown">
                <div ref={dropDownRef}>
                  <div
                    onClick={() => showDropdown(!dropdown)}
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{ padding: "0 1rem" }}
                  >
                    Resources
                  </div>
                  {dropdown && (
                    <React.Fragment>
                      <div
                        className="text-dark d-flex flex-column justify-content-center"
                        style={{
                          position: "absolute",
                          backgroundColor: "white",
                          fontWeight: "700",
                          zIndex: "10",
                          width: "170px",
                          top: "60px",
                        }}
                      >
                        {/*<div className="ml-auto text-right" style={{top:"-3%",right:"-3%",position:"absolute",fontSize:"18px"}}>*/}
                        {/*    <i className="fa fa-times-circle m-0 p-0 " onClick={()=>showDropdown(false)} style={{color:"#FF8282",cursor:"pointer"}}/>*/}
                        {/*</div>*/}
                        <div className="mx-auto d-flex flex-column justify-content-center">
                          <div>
                            <div className="my-2 ">
                              <Link
                                to={"/resources/newsAndArticle"}
                                onClick={() => setActiveDropdown(1)}
                                style={
                                  props?.activeDropdown === "newsAndArticle"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className=" ml-3 my-4"
                              >
                                News And Article
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/investorProfile"}
                                onClick={() => setActiveDropdown(2)}
                                style={
                                  props?.activeDropdown === "investorProfile"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className=" ml-3 "
                              >
                                Investor Profile
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/NRIInvesting"}
                                onClick={() => setActiveDropdown(3)}
                                style={
                                  props?.activeDropdown === "NRIInvesting"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                NRI Investing
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/ownershipModels"}
                                onClick={() => setActiveDropdown(8)}
                                style={
                                  props?.activeDropdown === "ownershipModels"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                Ownership Models
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/blockchainCRE"}
                                onClick={() => setActiveDropdown(4)}
                                style={
                                  props?.activeDropdown === "blockchainCRE"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Blockchain @CRE
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/FAQ"}
                                onClick={() => setActiveDropdown(5)}
                                style={
                                  props?.activeDropdown === "FAQ"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Help Center / FAQ
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/partnerWithUs"}
                                onClick={() => setActiveDropdown(6)}
                                style={
                                  props?.activeDropdown === "partnerWithUs"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Partner with us
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/careers"}
                                onClick={() => setActiveDropdown(7)}
                                style={
                                  props?.activeDropdown === "careers"
                                    ? { color: "rgb(155, 205, 42)" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Careers
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </li>
              <li className="nav-item">
                <Link
                  to={"/aboutUs"}
                  className="nav-link pb-0"
                  onClick={() => setActive(5)}
                  style={
                    props?.active === "contactUs"
                      ? { borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem" }
                      : { padding: "0 1rem" }
                  }
                >
                  Contact US
                </Link>
              </li>
              {!auth ? (
                <React.Fragment>
                  <li className="nav-item">
                    <Link
                      to={"/login"}
                      className="nav-link pb-0"
                      onClick={() => setActive(6)}
                      style={
                        props?.active === "signin"
                          ? {
                            borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                          }
                          : { padding: "0 1rem" }
                      }
                    >
                      SIGN IN
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/signup"}>
                      <button className="nav-link btn btn-sm  px-4 py-1 rounded-pill button-signup" style={{ padding: "0 1rem" }} >
                        SIGN UP
                      </button>
                    </Link>
                  </li>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <li className="nav-item">
                    <button
                      onClick={logOut}
                      className="nav-link btn btn-sm  px-4 py-1 rounded-pill " style={{ padding: "0 1rem", border: "1px solid  rgb(155, 205, 42)" }}
                    >
                      LOG OUT
                    </button>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>

          {open && (
            <div className=" py-2 mx-0 px-0" id="navbarLeftAlignExample">
              {/*<a className="navbar-brand" href="#">*/}
              {/*    <img width={200} src={Logo}/>*/}
              {/*</a>*/}
              <ul className="navbar-nav w-100 d-block justify-content-around mx-auto">
                <li className="nav-item ">
                  <Link className="navbar-brand mx-5" href="#">
                    <img alt="" width={200} src={Logo} />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/"}
                    className="nav-link pb-0"
                    onClick={() => setActive(1)}
                    style={
                      props?.active === "home"
                        ? {
                          borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                        }
                        : { padding: "0 1rem" }
                    }
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/HowItWorks"}
                    className="nav-link pb-0"
                    onClick={() => setActive(2)}
                    style={
                      props?.active === "howItWorks"
                        ? {
                          borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                        }
                        : { padding: "0 1rem" }
                    }
                  >
                    How It Works
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/properties"}
                    className="nav-link pb-0"
                    onClick={() => setActive(3)}
                    style={
                      props?.active === "properties"
                        ? {
                          borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                        }
                        : { padding: "0 1rem" }
                    }
                  >
                    Properties
                  </Link>
                </li>
                {auth && (
                  <li className="nav-item">
                    <Link
                      to={"/dashboard/overview"}
                      className="nav-link pb-0"
                      onClick={() => setActive(4)}
                      style={
                        props?.active === "dashboard"
                          ? {
                            borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                          }
                          : { padding: "0 1rem" }
                      }
                    >
                      Dashboard
                    </Link>
                  </li>
                )}
                <li className="nav-item ">
                  <div ref={dropDownRef}>
                    <div
                      onClick={() => showDropdown(!dropdown)}
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                      style={{ padding: "0 1rem" }}
                    >
                      Resources
                    </div>
                    {dropdown && (
                      <React.Fragment>
                        <div
                          className="text-dark d-flex flex-column justify-content-center"
                          style={{
                            position: "absolute",
                            backgroundColor: "white",
                            fontWeight: "700",
                            zIndex: "10",
                            width: "170px",
                            top: "60px",
                          }}
                        >
                          {/*<div className="ml-auto text-right" style={{top:"-3%",right:"-3%",position:"absolute",fontSize:"18px"}}>*/}
                          {/*    <i className="fa fa-times-circle m-0 p-0 " onClick={()=>showDropdown(false)} style={{color:"#FF8282",cursor:"pointer"}}/>*/}
                          {/*</div>*/}
                          <div className="mx-auto d-flex flex-column justify-content-center">
                            <div>
                              <div className="my-2 ">
                                <Link
                                  to={"/resources/newsAndArticle"}
                                  onClick={() => setActiveDropdown(1)}
                                  style={
                                    props?.activeDropdown === "newsAndArticle"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className=" ml-3 my-4"
                                >
                                  News And Article
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/investorProfile"}
                                  onClick={() => setActiveDropdown(2)}
                                  style={
                                    props?.activeDropdown === "investorProfile"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className=" ml-3 "
                                >
                                  Investor Profile
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/NRIInvesting"}
                                  onClick={() => setActiveDropdown(3)}
                                  style={
                                    props?.activeDropdown === "NRIInvesting"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  NRI Investing
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/ownershipModels"}
                                  onClick={() => setActiveDropdown(8)}
                                  style={
                                    props?.activeDropdown === "ownershipModels"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  Ownership Models
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/blockchainCRE"}
                                  onClick={() => setActiveDropdown(4)}
                                  style={
                                    props?.activeDropdown === "blockchainCRE"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className="mx-3 my-4 "
                                >
                                  {" "}
                                  Blockchain @CRE
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/FAQ"}
                                  onClick={() => setActiveDropdown(5)}
                                  style={
                                    props?.activeDropdown === "FAQ"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Help Center / FAQ
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/partnerWithUs"}
                                  onClick={() => setActiveDropdown(6)}
                                  style={
                                    props?.activeDropdown === "partnerWithUs"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Partner with us
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/careers"}
                                  onClick={() => setActiveDropdown(7)}
                                  style={
                                    props?.activeDropdown === "careers"
                                      ? { color: "rgb(155, 205, 42)" }
                                      : { color: "black" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Careers
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/contactUs"}
                    className="nav-link pb-0"
                    onClick={() => setActive(5)}
                    style={
                      props?.active === "contactUs"
                        ? {
                          borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                        }
                        : { padding: "0 1rem" }
                    }
                  >
                    Contact US
                  </Link>
                </li>
                {!auth ? (
                  <React.Fragment>
                    <li className="nav-item">
                      <Link
                        to={"/login"}
                        className="nav-link pb-0"
                        onClick={() => setActive(6)}
                        style={
                          props?.active === "signin"
                            ? {
                              borderBottom: "2px solid rgb(155, 205, 42)", color: "rgb(155, 205, 42)", padding: "0 1rem"
                            }
                            : { padding: "0 1rem" }
                        }
                      >
                        SIGN IN
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/signup"}>
                        <button className="nav-link btn btn-sm btn-outline-danger px-4 py-1 rounded-pill" style={{ border: "1px solid green", color: 'green', padding: "0 1rem" }}>
                          SIGN UP
                        </button>
                      </Link>
                    </li>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <li className="nav-item">
                      <button
                        onClick={logOut}
                        className="nav-link btn btn-sm btn-outline-danger px-4 py-1 rounded-pill"
                        style={{ padding: "0 1rem" }}
                      >
                        LOG OUT
                      </button>
                    </li>
                  </React.Fragment>
                )}
              </ul>
            </div>
          )}
        </div>
      </nav>
    </React.Fragment>
  );
};
export default Navbar;
