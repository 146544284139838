import React from "react";

let EligibilityNRI = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>Loan Eligibility for NRIs
                    </h2>
                    <div className="mt-4">
                        <p>There are also business loans for real estate investing, which can be opted by NRIs to support
                        their investments. Loans are functional for both
                            residential as well as commercial real estate properties.</p>

                        <p>NRIs are also eligible to claim for a home loan in India. This has to fall in line with the
                        guidelines provided by the Foreign Exchange Management Act,
                        1999. There are certain rules and regulations to be followed by NRIs while applying for a home
                        loan. For instance, an NRI can claim for only a single
                        home loan sanctioning at one time as they are prohibited from purchasing more than one or two
                        residential real estates. Therefore, the NRI can
                        request a home loan only for purposes like purchasing a new property or
                            renovating/reconstructing the existing one.</p>

                        <p>The procedures for loan applications are somewhat similar for both NRI and a citizen applicant.</p>

                        <p>The various types of specifications for a home loan are mentioned below.</p>

                        <p style={{fontWeight:"600"}}>Home Loan Eligibility:</p>

                        <p>Every home loan application process comes with an eligibility criterion that the candidate,
                        whether an NRI or a citizen, has to fulfill to avail of the loan.
                        These eligibility criteria can be education-based, where the minimum qualifications required are
                        a graduate degree, or the criteria can be age-related,
                        in which case the age limits are between 18-60 years. The NRI candidate’s income also plays a
                        role in the home loan application as it influences the
                        amount of loan that shall be sanctioned. Some banks also differentiate the income into earnings
                            made abroad and those made in India.<br/>
                        Documents Required:</p>

                        <p>The NRI candidate is required to present the following documents while applying for a home loan
                            in India:</p>
                        <div className="mx-md-5 mx-2">
                            <p className="mb-0">• Complete set of documents required to go through the KYC process.</p>
                            <p className="mb-0">• The candidate has to provide information about the residential place for which the loan
                        application is being filed.</p>
                            <p className="mb-0">• Documents providing information about the candidate’s employment, such as job appointment
                        letter, employment contract, experience
                        certificate, and work permit Home loans are sanctioned on the NRE and NRO accounts, and hence,
                        the candidate shall submit the
                                statements/passbook for these accounts.</p>
                            <p className="mb-0">• Candidate’s salary certificate.</p>
                            <p className="mb-0">• A documented proof of the candidate’s place of residence abroad that has been attested by the
                                respective employer.</p>
                            <p className="mb-0">• The income tax return statements are made abroad.</p>
                            <p className="mb-0">• Certificate showcasing qualifications.</p>
                        </div>
                        <p className="mt-3"> The NRI candidate has to notarize all the documents through the GPA.</p>

                        <p className="mb-0" style={{fontWeight:"600"}}>Tenor Period:</p>
                        <p >This is the point of segregation between a citizen and an NRI as the common tenure for a home
                        loan can go up to 30 years for a citizen, but an NRI
                        can avail of a home loan with a maximum tenor of 15 years. The minimum tenor can be 5 years.
                        This is because the loan payout potential of
                            an NRI candidate is generally higher than a citizen.</p>

                        <p className="mb-0" style={{fontWeight:"600"}}>Interest Rate:</p>
                        <p>There are similar interest rates for both citizens and NRIs loan applicants. These can range
                            between 10-13%.Minimum</p>

                        <p className="mb-0" style={{fontWeight:"600"}}>Income Norms:</p>
                        <p>The minimum income sum required for an NRI individual to be eligible for a home loan is
                        different among the different banks depending on
                            their loan scheme.</p>

                        <p className="mb-0" style={{fontWeight:"600"}}>Tax Benefits:</p>
                        <p >Home loans in India do not provide any form of tax exemptions/benefits to NRIs. However, an NRI
                        candidate can avail of a few tax exemptions if they
                        file for a return and possess eligibility for these benefits.
                        Repayment of Loan: Loan repayment is slightly different for an NRI because most of the bank
                        mandate loan repayment in Indian National Rupee only. They do not allow installments in any
                        other currencies. Thus, the candidate has to deposit the
                        timely loan repayment installments through NRE or NRO accounts. Failure of loan repayment will
                        result in the ceasing of the property by the bank. NRIs
                        should also note that most of the home loans require a co-applicant along with the property
                        owner while applying for home loans in India. These
                        co-applicants function as a guarantor for the loan that becomes vital in the absence of a
                            property owner.</p>
                            <p className="mb-0">This section is concerned with the NRIs point of view during a real-estate investment.</p>


                    </div>
                </div>
            </div>
        </div>
    )
};
export default EligibilityNRI;
