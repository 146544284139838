/* import React from "react";

import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
const mapKey="AIzaSyDEUzt-FcKtMJD4-vjEiMWaisKNlg8XLbs";
const location = {
    address: '1600 Amphitheatre Parkway, Mountain View, california.',
    lat: 37.42216,
    lng: -122.08427,
};

export class MapContainer extends React.Component {
    render() {
        return (
            <Map google={this.props.google}
                 center={{
                     lat: 40.854885,
                     lng: -88.081807
                 }}
                 location={location}
                 zoom={14}>

                <Marker
                        name={'Current location'} />

                <InfoWindow >
                    <div>
                        <h1></h1>
                    </div>
                </InfoWindow>
            </Map>
        );
    }
}
export default GoogleApiWrapper({
    apiKey: "AIzaSyDEUzt-FcKtMJD4-vjEiMWaisKNlg8XLbs"
})(MapContainer); */
import React from 'react'

function MapContainer({ location }) {
    return (
        <div>

            <div dangerouslySetInnerHTML={{ __html: location }} />
        </div>
    )
}

export default MapContainer