import React, {useState} from "react";
import { Bar } from "react-chartjs-2";
import checkImg from "../../assets/checkRightSign.png";
import downloadLogo from "../../assets/downloadLogoPink.png";
import floorPlan from "../../assets/floorPainDiagram.png";
import MapContainer from "../MapContainer";

let GrowthPlus=()=>{
    const BarData = {
        labels: [
            "0", "1", "2",
            "3", "4","5","6"
        ],
        datasets: [
            {
                label: "Growth",
                data: [100000, 200000, 250000, 300000,  350000,400000, 500000],
                fill: true,
                backgroundColor: "#ff8282",
                borderColor: "white"
            },
        ]
    };
    let [investment,setInvestment]=useState(10);
    let [annualAppreciation,setAnnualAppreciation]=useState(0.1);
    let [yieldValue,setYieldValue]=useState(0.19);
    let handleInvestment=(e)=>{
        if ((e.target.value%10).toFixed(0)){
            setInvestment(e.target.value);
            setAnnualAppreciation((e.target.value * 0.1).toFixed(2));
            setYieldValue((e.target.value * 0.19).toFixed(2))
        }
    };

    return(
        <React.Fragment>
            <div className="container mt-md-5 mt-1 pt-2">
                <p className="text-center" style={{fontSize:"35px",fontWeight:"700"}}>Growth Plus</p>
                <p className=" px-md-5 px-2 mx-md-5 mx-0" >Growth plus product is a fractional owershipinvestment,
                    helping our investors to invest with a minimum
                    ticket size. this product helps our investors to own a fraction og high net worth investors</p>
            </div>
            <div>
                <div className="container mb-4" style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                    <div className="px-md-5 my-4 px-2 mx-md-5 mx-0 border-bottom">
                    <div className="d-flex my-2 align-items-center">
                        <p className="text-center" style={{width:"300px",fontWeight:"600",fontSize:"15px"}}>Your investment</p>
                        <div className="w-100 ">
                            <input type="range" onChange={handleInvestment} className="custom-range w-100 pr-4"
                                   min="10" max="200" value={investment}  id="customRange1"/>
                                   <span className="mx-2" style={{color:"rgb(255, 130, 130)"}}>Rs.{investment} Lakhs</span>
                        </div>
                    </div>
                    <div className="d-flex my-2 align-items-center w-100">
                        <p className="text-center" style={{width:"300px",fontWeight:"600",fontSize:"15px"}}>Anuual Appreciation</p>
                        <div className='w-100'>
                            <input type="range" className="custom-range w-100 pr-4" value={annualAppreciation}  min="0.1" max="20"  id="customRange1"/>
                            <span className="mx-2" style={{color:"rgb(255, 130, 130)"}}>{annualAppreciation}</span>
                        </div>
                    </div>
                    <div className="d-flex my-2 align-items-center w-100">
                        <p className="text-center" style={{width:"300px",fontWeight:"600",fontSize:"15px"}}>Yeild</p>
                        <div className="w-100">
                            <input type="range" className="custom-range w-100 pr-4" value={yieldValue}  min="0.19" max="40"  id="customRange1"/>
                            <span className="mx-2" style={{color:"rgb(255, 130, 130)"}}>{yieldValue}</span>
                        </div>
                    </div>

                        <div className="my-5 ">
                            <Bar data={BarData}
                                 options={{
                                     title: {
                                         display: false,

                                     },
                                     maintainAspectRatio: false,
                                     legend: {
                                         display: false,

                                     },
                                     scales: {
                                         xAxes: [{
                                             gridLines: {
                                                 display: false
                                             },
                                             barPercentage: 0.8
                                         }],
                                         yAxes: [{
                                             gridLines: {
                                                 display: true
                                             },
                                             ticks: {
                                                 beginAtZero: true
                                             }
                                         }],
                                     }
                                 }}
                                 height={350}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/*--------------- Property Details -----------------*/}

            {/*--------------- Amenities -----------------*/}

            {/*--------------- About Builders -----------------*/}


            {/*--------------- Updates -----------------*/}


            {/*----------- maps ---------------*/}
        </React.Fragment>
    )
};
export default GrowthPlus;
