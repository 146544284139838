import React from "react";
import SideNav from "../SideNav";
import MyEarningsContent from "./MyEarningsContent";
import NavbarDashboard from "../NavbardDashboard";
import SideNavMobile from "../SideNavMobile";

let MyEarnings = () => {
    return (
        <React.Fragment>
            <NavbarDashboard active="dashboard" />
            <div style={{ position: "relative" }}>
                <SideNavMobile active="myEarnings" />
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-md-3 p-0 m-0">
                            <SideNav active="myEarnings" />
                        </div>
                        <div className="col-md-9 " >
                            <div className="m-md-5 m-2">
                                <MyEarningsContent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default MyEarnings;