import React from "react";

let WhyChoseBrickberryNRI = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>Why choose BrickBerry for NRI real estate
                        investment?

                    </h2>
                    <div className="mt-4">
                        <p>BrickBerry provides a quality investment platform to the individuals where they can find high
                        return generating real estate properties to invest in.
                        It brings a unique approach towards online real estate investments. One can find a blend of
                        valuable assets, investment return tracking, digital
                            security and an all-inclusive</p>

                        <p>The online investing website is a hub for NRI real estate investments as it simplifies the RE
                        investment procedures by giving quality real estate asset
                        options spread across the country at economically reasonable prices. This saves the NRIs some
                        time from the otherwise hectic investment procedure
                        and the mindless search for a real estate property in India. They can start their investment
                            journey within a matter of few clicks.</p>

                        <p>Purchasing Process:</p>

                        <p>The real-estate purchase and investment process at BrickBerry consists of just 3 steps:</p>
                        <div className="mx-md-5 mx-2">
                        <p><span style={{fontWeight:600}}>• Selection of Asset: </span>The individuals can browse the diverse display of assets or real estate
                        properties available on BrickBerry. The individual can
                        also apply filters for a more customized and accurate asset search. BrickBerry brings the
                        country’s most high-yielding assets and has been
                        identified by the watchful eyes of our team. The NRI investors can choose the best-suited
                        properties to invest in from all across the country.
                        The investors can select from plans such as Growth, Growth Plus or Yield. BrickBerry follows
                        diligence and dedication in bringing some of the
                        most profitable real estate investment options to the individuals.</p>

                            <p><span style={{fontWeight:600}}>• Invest: </span>After selecting the asset, the investor can complete the investment process. The
                        investors can take the help of capital and product solutions
                        provided at BrickBerry for a smarter investment. The selected real estate property can be
                        checked for its past performance and investment
                        return records. The NRI investor should then select the kind of investment premium he/she wants
                        and the periodicity of this investment. Next,
                        the investor should complete the property investment with the payment of the premium of the
                        property. This payment may be made in a
                                single or multiple installments.</p>

                            <p><span style={{fontWeight:600}}>• Avail the Earnings: </span>After investing, the individual can sit back and relax as the asset itself
                        generates income. The investor can also seek expert
                        guidance available at BrickBerry. The investor can keep a track of the investment returns and
                        profitability with the help of the intuitive
                        dashboard. The returns are deposited in the concerned bank account directly ensuring full
                        transparency.
                        As a smart investment goes a long way, BrickBerry guides the NRIs towards smarter investments
                                and brings them to their doorsteps.</p>
                        </div>
                        <p className="mt-3">NRI Real Estate Return on Investment:</p>

                        <p>The NRI investor can enjoy high returns when they choose to invest with BrickBerry. Real estate
                            investments generate returns in three ways:</p>
                        <div className="mx-md-5 mx-2">
                            <p className="mb-0"><span style={{fontWeight:600}}>• Purchase or sale: </span>The exchange or sale of real estate property provides the owner with sale
                        proceedings.</p>
                            <p className="mb-0"><span style={{fontWeight:600}}>• Capital Appreciation: </span>This is a unique income from real estate as the property itself serves
                                as an asset and shows an appreciation in value over time.</p>
                            <p className="mb-0"><span style={{fontWeight:600}}>• Rental income: </span>This income is made when the real estate property is raised on rent, and the
                                tenant makes regular payments.</p>
                        </div>
                        <p className="mt-3">Expected profits for the long term:</p>

                        <p>NRI investors need to have a long-term investment perspective as it provides the following
                            advantages:</p>
                        <div className="mx-md-4 mx-2">
                            <p className="mb-0">• The long-term investments help in compensating the initial cost of investment, which is not
                                possible with a short-term investment.</p>
                            <p className="mb-0">• Long-term investments have a greater risk resistance.</p>
                            <p className="mb-0">• Long-term investments also provide a higher margin of returns with higher interest rates.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default WhyChoseBrickberryNRI;
