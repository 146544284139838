import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePageMain from "./components/homepage/HomePageMain";
import HowItWorksMain from "./components/howItWorks/HowItWorksMain";
import Properties from "./components/properties/Properties";
import Buildings from "./components/homepage/homepageComponents/Buildings";
import LogIn from "./components/signInPages/Login";
import SignUp from "./components/signInPages/SignUp";
import NavbarPin from "./components/securePin/NavbarPin";
import SetPin from "./components/securePin/SetPin";
import ActivatePin from "./components/securePin/ActivatePin";
import EnterPin from "./components/securePin/EnterPin";
import Homepage from "./components/homepage/homepageComponents/Homepage";
import WhyFractionalOwner from "./components/howItWorks/howItWorksComponents/WhyFractionalOwnership";
import NewsArticle from "./components/resources/newsArticle/NewsArticle";
import NRIInvestmentHome from "./components/resources/nriInvest/NRIInvestmentHome";
import NRIInvestment from "./components/resources/nriInvest/NRIInvestment";
import InvestorProfile from "./components/resources/investerProfile/InvestorProfile";
import FAQ from "./components/resources/frequentlyAskedQuestion/FAQ";
import PartnerWithUs from "./components/resources/partnerWithUs/PartnerWithUs";
import BlockChain from "./components/resources/bloakChain/Blockchain";
import DashboardMain from "./components/dashboard/DashboardMain";
import BricksStatusContent from "./components/dashboard/bricksStatus/BricksStatusContent";
import BricksStatus from "./components/dashboard/bricksStatus/BricksStatus";
import MyDocuments from "./components/dashboard/myDocuments/MyDocuments";
import MyEarnings from "./components/dashboard/myEarnings/MyEarnings";
import MyInvestments from "./components/dashboard/myInvestments/MyInvestments";
import Overview from "./components/dashboard/overview/Overview";
import MyProfile from "./components/dashboard/profile/MyProfile";
import SPVStatement from './components/dashboard/spvStatement/SPVStatement';
import TaxCenter from "./components/dashboard/taxCenter/TaxCenter";
import Consensus from './components/dashboard/consensus/Consensus';
import UserSetting from "./components/dashboard/userSetting/UserSetting";
import RiskProfiling from "./components/dashboard/riskProfiling/RiskProfiling";
import BellNotification from "./components/dashboard/bellNotification/BellNotification";
import InvestmentManager from "./components/dashboard/investmentManager/InvestmentManager";
import TermsOfUse from "./components/footerLinks/TermsOfUse";
import PrivacyPolicy from "./components/footerLinks/PrivacyPolicy";
import ForgotPassword from "./components/securePin/ForgotPassword";
import HowItWorksTitleDetails from "./components/howItWorks/howItWorksComponents/HowitWorksTitleDetails";
import HowItWorksTitle from "./components/howItWorks/howItWorksComponents/HowItWorksTitle";
import WhatAboutFee from "./components/howItWorks/howItWorksComponents/WhatAboutFee";
import AgentPortal from "./components/agentPortal/AgentPortal";
import ViewProperty from "./components/viewProperties/viewPropertyFirst/ViewProperty";
import ViewPropertySecondPage from "./components/viewProperties/viewPropertySecond/ViewPropertySecondPage";
import PurchaseBricks from "./components/purchaseBricks/PurchaseBricks";
import PurchaseBricksConfirm from "./components/purchaseBricks/PurchaseBricksConfirm";
import FooterAboutUs from "./components/footerLinks/FooterAboutUs";
import CareerJobs from "./components/resources/careers/careerPages/CareerJobs";
import Careers from "./components/resources/careers/Careers";
import ContactWithUs from "./components/resources/ContactWithUs";
import AdminLogin from "./adminComponents/adminLogIn/AdminLogin";
import AdminNavbar from "./adminComponents/AdminNavbar";
import AdminMain from "./adminComponents/AdminMain";
import AdminPropertiesMain from "./adminComponents/adminProperty/adminPropertiesMain";
import AdminPropertyEdit from "./adminComponents/adminPropertyEdit/AdminPropertyEdit";
import OwnershipModel from "./components/resources/ownershipModel/OwnershipModel";
import Blogview from "./components/resources/newsArticle/BlogView";
import VerifyEmail from "./components/securePin/VerifyEmail";
import NoComponentFound from "./components/NoComponentFound";
import Auth from "./components/Auth";
import { store } from "../src/redux/store"
import { Provider } from "react-redux";
import { getUser } from "./utils/localStorage"
let App = () => {
    let [auth, setAuth] = useState(undefined);
    useEffect(() => {
        const user = (getUser());
        setAuth(user)
    }, [auth])

    return (
        <React.Fragment>
            <Provider store={store}>
                <Router>
                    {/* <Auth> */}
                    <Switch>
                        {auth && <React.Fragment>

                            <Route exact path='/' component={HomePageMain} />
                            <Route exact path='/howItWorks' component={HowItWorksMain} />
                            <Route exact path='/properties' component={Properties} />
                            <Route exact path='/properties/residential/:id' component={ViewProperty} />
                            <Route exact path='/properties/commercial/:id' component={ViewPropertySecondPage} />
                            <Route exact path='/properties/:id/buy' component={PurchaseBricks} />
                            <Route exact path='/properties/:id/buy/confirm/:id' component={PurchaseBricksConfirm} />
                            <Route exact path='/privacyPolicy' component={PrivacyPolicy} />
                            <Route exact path='/termsOfUse' component={TermsOfUse} />
                            <Route exact path='/FAQ' component={FAQ} />
                            <Route exact path='/aboutUs' component={FooterAboutUs} />
                            <Route exact path='/agentPortal' component={AgentPortal} />
                            <Route exact path='/dashboard/myProfile' component={MyProfile} />
                            <Route exact path='/dashboard/bricksStatus' component={BricksStatus} />
                            <Route exact path='/dashboard/overview' component={MyInvestments} />
                            <Route exact path='/dashboard/myEarnings' component={MyEarnings} />
                            <Route exact path='/dashboard/myDocuments' component={MyDocuments} />
                            <Route exact path='/dashboard/spvStatements' component={SPVStatement} />
                            <Route exact path='/dashboard/taxCenter' component={TaxCenter} />
                            <Route exact path='/dashboard/investmentManager' component={InvestmentManager} />
                            <Route exact path='/dashboard/consensus' component={Consensus} />
                            <Route exact path='/dashboard/riskProfiling' component={RiskProfiling} />
                            <Route exact path='/dashboard/user/setting' component={UserSetting} />
                            <Route exact path='/signup' component={SignUp} />
                            <Route exact path='/verifyEmail' component={VerifyEmail} />
                            <Route exact path='/login' component={LogIn} />
                            <Route exact path='/setPin' component={SetPin} />
                            <Route exact path='/activatePin' component={ActivatePin} />
                            <Route exact path='/enterPin' component={EnterPin} />
                            <Route exact path='/forgotPassword' component={ForgotPassword} />
                            <Route exact path='/resources/newsAndArticle' component={NewsArticle} />
                            <Route exact path='/resources/newsAndArticle/:id' component={Blogview} />
                            <Route exact path='/resources/investorProfile' component={InvestorProfile} />
                            <Route exact path='/resources/NRIInvesting' component={NRIInvestment} />
                            <Route exact path='/resources/ownershipModels' component={OwnershipModel} />
                            <Route exact path='/resources/blockchainCRE' component={BlockChain} />
                            <Route exact path='/resources/partnerWithUs' component={PartnerWithUs} />
                            <Route exact path='/resources/FAQ' component={FAQ} />
                            <Route exact path='/contactUs' component={ContactWithUs} />
                            <Route exact path='/careers' component={Careers} />
                            <Route exact path='/404' component={NoComponentFound} />
                            {/* <Redirect to="/404"  /> */}
                        </React.Fragment>}
                        {!auth && <React.Fragment>
                            <Route exact path='/' component={HomePageMain} />
                            <Route exact path='/howItWorks' component={HowItWorksMain} />
                            <Route exact path='/properties' component={Properties} />
                            <Route exact path='/properties/residential/:id' component={ViewProperty} />
                            <Route exact path='/properties/commercial/:id' component={ViewPropertySecondPage} />
                            <Route exact path='/privacyPolicy' component={PrivacyPolicy} />
                            <Route exact path='/termsOfUse' component={TermsOfUse} />
                            <Route exact path='/FAQ' component={FAQ} />
                            <Route exact path='/aboutUs' component={FooterAboutUs} />
                            <Route exact path='/agentPortal' component={AgentPortal} />
                            <Route exact path='/signup' component={SignUp} />
                            <Route exact path='/verifyEmail' component={VerifyEmail} />
                            <Route exact path='/login' component={LogIn} />
                            <Route exact path='/setPin' component={SetPin} />
                            <Route exact path='/activatePin' component={ActivatePin} />
                            <Route exact path='/enterPin' component={EnterPin} />
                            <Route exact path='/properties/:id/buy' component={PurchaseBricks} />
                            <Route exact path='/dashboard/overview' component={MyInvestments} />
                            <Route exact path='/forgotPassword' component={ForgotPassword} />
                            <Route exact path='/resources/newsAndArticle' component={NewsArticle} />
                            <Route exact path='/resources/newsAndArticle/:id' component={Blogview} />
                            <Route exact path='/resources/investorProfile' component={InvestorProfile} />
                            <Route exact path='/resources/NRIInvesting' component={NRIInvestment} />
                            <Route exact path='/resources/ownershipModels' component={OwnershipModel} />
                            <Route exact path='/resources/blockchainCRE' component={BlockChain} />
                            <Route exact path='/resources/partnerWithUs' component={PartnerWithUs} />
                            <Route exact path='/resources/FAQ' component={FAQ} />
                            <Route exact path='/contactUs' component={ContactWithUs} />
                            <Route exact path='/careers' component={Careers} />
                            <Route exact path='/404' component={NoComponentFound} />
                            {/* <Redirect to="/404"  /> */}
                        </React.Fragment>}

                        {/*<Route exact path='/admin/properties' component={AdminPropertiesMain}/>*/}
                        {/*<Route exact path='/admin/properties/1/edit' component={AdminPropertyEdit}/>*/}
                    </Switch>
                    {/* </Auth> */}

                </Router>
            </Provider>

            {/*<Router>*/}
            {/*    /!*<AdminLogin/>*!/*/}
            {/*    /!*<AdminNavbar/>*!/*/}
            {/*    /!*<AdminMain/>*!/*/}
            {/*    /!*<AdminPropertiesMain/>*!/*/}
            {/*    /!*<AdminPropertyEdit/>*!/*/}
            {/*</Router>*/}
        </React.Fragment>
    );
};
export default App;
