import React, { useState, useEffect } from "react";
import SideNav from "../SideNav";
import NavbarDashboard from "../NavbardDashboard";
import documentUploadLogo from "../assets/documentUploadLogo.png";
import downloadLogo from "../assets/downloadLogo.png";
import dropdownArrow from "../assets/dropdownArrow.png";
import { getblockchaindoc } from "../../../services/ApiServices"
let MyDocumentsContent = () => {
    let [documents, setDocuments] = useState([]);
    useEffect(() => {
        getblockchaindoc(localStorage.getItem('user')).then((response) => {
            console.log(response)
            setDocuments(response?.Properties);
        });
    }, [])
    return (
        <React.Fragment>
            <div className="">
                {/*--------------- BRICKS Alloted -----------------------*/}
                <div className="d-flex justify-content-center text-center my-3 mb-0">
                    <div className="d-flex align-items-center" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                        <select style={{ height: "30px", color: "white", order: "1px solid rgb(22, 142, 186)", position: "relative", backgroundColor: "rgb(22, 142, 186)", }}
                            className=" form-control px-4 py-0">
                            <option className="bg-white text-dark" selected >All Properties</option>
                        </select>
                        {/*<img alt="" src={dropdownArrow} style={{position:"absolute",right:"10%",width:"15px",height:"8px"}}/>*/}
                    </div>
                    {/*<p className="btn text-white text-capitalize" style={{color:"#FF8282",fontWeight:"400",backgroundColor: "#ff8282",fontSize:"16px"}}>*/}
                    {/*    All Properties</p>*/}
                </div>
                {documents.length > 0 ? documents.map(item => <div className="border mt-0 mb-3 pb-2" style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>
                    <div className="row justify-content-around px-4 py-2 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "rgb(238, 255, 199)" }}>

                        <div className=' py-1  text-center col-md-2 text-center rounded'
                        >Property Name
                        </div>

                        <div className=' py-1 text-center col-md-2 text-center'
                        >Download
                        </div>
                    </div>
                    <div className="row justify-content-around px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%" }}><React.Fragment>

                            <div className=' py-1 text-center col-md-2 text-center'
                            >{item.PropertyTitle}
                            </div>
                            <div >
                                {item.Documents.length > 0 ? item.Documents.map(item =>
                                    <div className=' py-1 text-center col-md-2 text-center'>
                                        <div className="d-flex my-2 align-items-center justify-content-between">
                                            <p className="mb-0 ml-md-4 mx-auto" style={{ fontWeight: "400", fontSize: "14px" }}>{item.DocumentName}</p>
                                            <a href={item.DocumentURL} target="_blank"> <img alt="" height={15} src={downloadLogo} /></a>
                                        </div>

                                    </div>
                                ) : <p>No Documents Found</p>}
                            </div>

                        </React.Fragment>


                    </div>

                </div>) : <div className="col-md-10 py-4 text-center">
                    <p className="text-center text-muted">There are no documents to display</p>
                </div>}


            </div>
        </React.Fragment>
    )
};
export default MyDocumentsContent;