import React, {useState} from "react";


let InvestmentAnalysis=()=>{

    return(
        <React.Fragment>
            <div className="container " id="investmentAnalysis">
                <p className="text-center" style={{fontSize:"35px",fontWeight:"700"}}>Investment analysis</p>
                <p className=" px-md-5 px-2 mx-md-5 mx-0" >Cash flow of the investor is mentioned below for a sample investment of 25 lakhs, that attracts 3% payout every quarter.
                    All figures above are in INR lakhs
                </p>
                <div className="border container-fluid text-center">
                    <div className="row py-2 " style={{backgroundColor:"#ffe2db"}}>
                        <div className="col-md-2">
                            Q0
                        </div>
                        <div className="col-md-1">Q1</div>
                        <div className="col-md-1">Q2</div>
                        <div className="col-md-1">Q3</div>
                        <div className="col-md-1">Q4</div>
                        <div className="col-md-1">Q5</div>
                        <div className="col-md-1">Q6</div>
                        <div className="col-md-1">Q7</div>
                        <div className="col-md-1">Q8</div>
                        <div className="col-md-1">Q9</div>
                        <div className="col-md-1">Q10</div>
                    </div>
                    <div className="row border-top py-1" >
                        <div className="col-md-2">
                            Investment	(25)
                        </div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                    </div>
                    <div className="row border-top py-1" >
                        <div className="col-md-2">
                            Quarterly returns
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                    </div>
                    <div className="row py-1 border-top" >
                        <div className="col-md-2">
                            Repayment of Investment
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1">25</div>
                    </div>
                    <div className="row border-top py-1" >
                        <div className="col-md-2">
                            Profit share of Investor
                            @ 5000 rs per sqft
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1">4.69</div>
                    </div>
                    <div className="row border-top py-1" >
                        <div className="col-md-2">
                            Investors Cashflow (25)
                        </div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">0.75</div>
                        <div className="col-md-1">30.44</div>
                    </div>
                    <div className="row py-2 border-bottom border-top" style={{backgroundColor:"#ff8282"}}>
                        <div className="col-md-2">
                            <p className="mb-0 font-weight-bold">IRR</p>
                        </div>
                        <div className="col-md-1">20%</div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </div>
            <div className="container my-5">
                <div className="p-4" style={{border:"1px solid #FF8282"}}>
                    <p className="mb-0">The investment is offered to the customer at the rate of 3100 per sqft.
                        Current retail price offered to the customer in the product is an all inclusive price of 4500 per sqft</p>
                    <p className="mb-0"> The benefits of the project are:</p>
                    <p className="mb-0"> 1.	Easy accessibility to IT offices</p>
                    <p className="mb-0">2.	Near to ORR</p>
                    <p className="mb-0">3.	Part of a 32 Acre gated community</p>
                    <p className="mb-0">4.	Accessibility to retail space coming in future</p>
                       <p className="mb-0"> The above benefits will ensure that the prices of the product will achieve a conservative mark of more than 5500 by the end of the product tenure.
                        While keeping in mind the continuous cash flow along with the profit share in the end we can expect a minimum IRR of more than 20%
                        IRR is nothing but the rate of return for the duration of the period.

                    </p>
                </div>
            </div>
            {/*-------------- Capital Return -------------*/}

            {/*--------------- Risk Analysis -----------------*/}

            {/*------------ Pricing Details --------------------*/}

        </React.Fragment>
    )
};
export default InvestmentAnalysis;
