import React, { useEffect, useRef, useState } from "react";
import { usehistory } from "react-router-dom";
import profilePic from "../assets/profilePicNotification.png";
import changePasswordLogo from "../assets/changePasswordLogo.png";
import { Link, useHistory } from "react-router-dom";
import settingLogo from "../assets/settingLogo.png";
import profileImg from "../assets/profileImg.png";
import redDot from "../../../assets/rredDot.png";
import pinkDotDashboard from "../assets/pinkDotDashboard.png";
import { getUser, deleteUser } from "../../../utils/localStorage";
import { useDispatch, useSelector } from "react-redux";
import { USER_FEATURE_KEY } from "../../../redux/users/users.reducers";
import { logOutUser } from "../../../redux/users/users.actions";

let UserSettingMenu = () => {
  const settingMenuRef = useRef();
  let dispatch = useDispatch();
  let history = useHistory();
  let [showMenu, setShowMenu] = useState(false);
  let [mount, setMount] = useState(false);
  let [auth, setAuth] = useState(undefined);
  let userInfo = useSelector((state) => {
    return state[USER_FEATURE_KEY];
  });
  useEffect(() => {
    // add when mounted
    const user = getUser();
    setAuth(user);
    setMount(true);
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      setMount(false);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const handleClickOutside = (e) => {
    if (!settingMenuRef?.current?.contains(e.target)) {
      // inside click
      // setShowMenu(!showMenu);
      if (setMount) {
        setShowMenu(false);
      }
    }
  };
  const logout = () => {
    dispatch(logOutUser(history));
    deleteUser();
    window.location.reload();
  }
  return (
    <React.Fragment>
      <div className="" ref={settingMenuRef}>
        <div className="d-flex  justify-content-between align-items-center">
          <img
            height={35}
            style={{ cursor: "pointer" }}
            onClick={(e) => setShowMenu(!showMenu)}
            className="rounded-circle ml-4 mr-3"
            src={profileImg}
            alt=""
          />
          <span className="d-flex my-1 justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center">
              <img
                src={pinkDotDashboard}
                height={16}
                style={{ position: "absolute", zIndex: "1" }}
                alt=""
              />
              <i
                style={{
                  position: "relative",
                  zIndex: "2",
                  height: "5px",
                  top: "-8px",
                  left: "20%",
                  fontSize: "15px",
                }}
                className="fa fa-check text-white"
                aria-hidden="true"
              />
            </div>
            <small className=" mx-2 mb-0 text-white">KYC Verified</small>
          </span>
        </div>
        {/*<div className="d-flex justify-content-center align-items-center "*/}
        {/*     onClick={(e)=>setShowMenu(!showMenu)}*/}

        {/*     style={{cursor:"pointer"}}>*/}
        {/*    <img height={30}  src={settingLogo} style={{}} alt=""/>*/}
        {/*</div>*/}
        {showMenu && (
          <div
            ref={settingMenuRef}
            className="w-25 pinSectionStyles my-4 mx-auto border-top-0"
            style={{
              borderRadius: "10px",
              width: "250px",
              minWidth: "250px",
              maxWidth: "250px",
              position: "absolute",
              zIndex: "5",
              right: "1%",
            }}
          >
            <div
              className="bg-white d-flex flex-column  text-left  m-2 align-items-start pt-3 pb-2  px-3"
              style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
            >
              <div
                ref={settingMenuRef}
                className="d-flex text-left m-1  align-items-center"
              >
                <img
                  src={profilePic}
                  style={{ height: "30px", borderRadius: "50%" }}
                  className=" mr-2"
                  alt=""
                />
                {/*<Link to={"/dashboard/user/setting"}><p className="mb-0 text-dark font-small ml-2" style={{fontSize:"14px"}}>Edit Profile</p></Link>*/}
                <Link
                  to={{
                    pathname: `/dashboard/user/setting`,
                    active: "account",
                  }}
                >
                  <p
                    className="mb-0 text-dark font-small ml-2"
                    style={{ fontSize: "14px" }}
                  >
                    Edit Profile
                  </p>
                </Link>
              </div>

              <div
                ref={settingMenuRef}
                className="d-flex text-left m-1  align-items-center"
              >
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "#c4c4c4",
                    position: "relative",
                    height: "28px",
                    width: "28px",
                  }}
                >
                  <img
                    src={changePasswordLogo}
                    style={{
                      height: "18px",
                      backgroundColor: "#c4c4c4",
                      position: "absolute",
                    }}
                    className="img-fluid "
                    alt=""
                  />
                </div>
                {/*<Link to={"/dashboard/user/setting"}><p className="mb-0 font-small text-dark ml-3" style={{fontSize:"14px"}}>Change Password</p></Link>*/}
                <Link
                  to={{
                    pathname: `/dashboard/user/setting`,
                    active: "password",
                  }}
                >
                  <p
                    className="mb-0 font-small text-dark ml-3"
                    style={{ fontSize: "14px" }}
                  >
                    Change Password
                  </p>
                </Link>
              </div>
              <div
                ref={settingMenuRef}
                className="d-flex text-left w-100  px-2  align-items-center text-center mx-auto"
              >
                <div
                  style={{
                    background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)",
                    width: "100%",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                  onClick={logout}
                  className="btn py-1 text-center text-white btn-md text-capitalize"
                >
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};
export default UserSettingMenu;
