import React, { useState } from "react";
import galleryLogo from "./assets/gallaryLogo.png";
import videosLogo from "./assets/videoImg.png";
import pinkCircleImg from "../resources/assets/pinkCircleImg.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector, useDispatch } from "react-redux";
import { saveSmsNotification } from "./agentPortalActionCreator";
import { submitAgentForm } from "../../services/ApiServices";
import S3FileUpload from "react-s3";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { States, Cities, amenitiesList } from "./AgentPortalFormConstants";
import { Multiselect } from "multiselect-react-dropdown";

const config = {
  bucketName: `brickberry`,
  dirName: "Blogs",
  region: "ap-south-1",
  accessKeyId: "AKIATPPMCW4BO6YDIJE6",
  secretAccessKey: "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",
};

const multi_style = {
  chips: {
    background: "#FF8282",
  },
};

let AgentPortalForms = () => {
  let [addFile, setAddFile] = useState(0);
  let [primaryImage, setPrimaryImage] = useState(0);
  let [floorImage, setFloorImage] = useState(0);
  let [keyDocuments, setKeyDocuments] = useState(0);
  let [amenities, setAmenities] = useState([1]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  let checkCaptcha = (value) => {
    console.log("Captcha value:", value);
  };
  const [agentFormData, setAgentFormData] = useState({
    PropertyImages: [],
    PropertyVideos: [],
    LocationImages: [],
    FloorPlanImages: [],
    Amenities: [],
  });

  const handleInput = (e) => {
    const { name, value } = e.target;
    setAgentFormData({
      ...agentFormData,
      ...{ [name]: value },
      ...{ Category: "test" },
      ...{ LeaseStartDate: moment(startDate).format("YYYY-MM-DD") },
      ...{ LeaseEndDate: moment(endDate).format("YYYY-MM-DD") },
      ...{ OwnershipModelID: 999 },
      ...{ MinimumInvestment: 500000 },
      ...{ MinimumBricks: 2 },
      ...{ Yield: "2" },
      ...{ ReturnTarget: 50000 },
      ...{ FloorNo: 5 },
      ...{ Bedrooms: 2 },
      ...{ CarParking: "YES" },
    });
  };
  console.log("AgentData", agentFormData);
  const deletePropertyVideo = (e) => {
    const { value } = e.target;
    const newPropertyVideos = agentFormData.PropertyVideos.splice(value, 1);
    setAgentFormData({
      ...agentFormData,
      ...{ PropertyVideos: newPropertyVideos },
    });
  };
  const deletePropertyImage = (e) => {
    const { value } = e.target;
    let newPropertyImages = agentFormData.PropertyImages.splice(value, 1);

    setAgentFormData({
      ...agentFormData,
      ...{ PropertyImages: newPropertyImages },
    });
  };
  const deleteFloorPlanImage = (e) => {
    const { value } = e.target;
    const newFloorPlanImages = agentFormData.FloorPlanImages.splice(value, 1);
    setAgentFormData({
      ...agentFormData,
      ...{ FloorPlanImages: newFloorPlanImages },
    });
  };
  const deleteLocationImage = (e) => {
    const { value } = e.target;
    const newLocationImages = agentFormData.LocationImages.splice(value, 1);
    setAgentFormData({
      ...agentFormData,
      ...{ LocationImages: newLocationImages },
    });
  };

  const onSelect = (selectedList) => {
    setAgentFormData({
      ...agentFormData,
      ...{ Amenities: selectedList },
    });
  };
  const onRemove = (selectedList) => {
    setAgentFormData({
      ...agentFormData,
      ...{ Amenities: selectedList },
    });
  };

  let handleImages = async (e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    e.persist();
    await S3FileUpload.uploadFile(file, config)
      .then((data) => {
        let url = data.location;
        setAgentFormData({
          ...agentFormData,
          [name]: [...[...agentFormData[name], url]],
        });
      })
      .catch((err) => console.error(err));
  };

  const submitAgentFormDetails = (e) => {
    submitAgentForm(agentFormData).then((response) => {
      if (response.Message) {
        alert(response.Message);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="container-fluid ">
        <div className="px-md-5 px-2 mx-md-4 mx-2">
          <form>
            <div className="row ">
              <div
                className="px-4 py-2 w-100"
                style={{ backgroundColor: "#FF8282" }}
              >
                <p
                  className="text-white mb-0 pl-md-4 px-auto py-2"
                  style={{ fontWeight: "400", fontSize: "30px" }}
                >
                  General Information
                </p>
              </div>
              <div className=" w-100 my-4">
                <div className="form-outline my-2 text-muted">
                  <input
                    type="text"
                    placeholder="Title of your property"
                    className="form-control border pl-4 text-muted"
                    value={agentFormData.PropertyTitle}
                    name="PropertyTitle"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </div>
                <div className="row my-4">
                  <div className="col-md-3">
                    <div className="d-block selectBox my-2">
                      <select
                        className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                        placeholder="Property Type"
                        name="PropertyType"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        value={agentFormData.PropertyType}
                      >
                        <option value="" className="disabled">
                          Property Type
                        </option>
                        <option value="Commercial">Commercial</option>
                        <option value="Retail space">Retail space</option>
                        <option value="Warehouse">Warehouse</option>
                        <option value="Vacation & Residential property">
                          Vacation & Residential property
                        </option>
                        <option value="Farm Land">Farm Land</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="d-block selectBox my-2">
                      <select
                        className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                        placeholder="Property Status"
                        name="PropertyStatus"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        value={agentFormData.PropertyStatus}
                      >
                        <option value="" className="disabled">
                          Property Status
                        </option>
                        <option value="New">New</option>
                        <option value="Resale">Resale</option>
                        <option value="Auction">Auction</option>
                        <option value="Lease">Lease</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-3 my-2">
                    <div className="form-outline mb-1 text-muted">
                      <input
                        type="text"
                        placeholder="Property SFT Value"
                        className="form-control border pl-4 text-muted"
                        value={agentFormData.SFTValue}
                        name="SFTValue"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 my-2">
                    <div className="form-outline mb-1 text-muted">
                      <input
                        type="text"
                        placeholder="Property Price"
                        className="form-control border pl-4 text-muted"
                        value={agentFormData.Price}
                        name="Price"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-outline my-4">
                  <textarea
                    className="form-control border text-muted rounded-0  pl-4"
                    placeholder="Overview"
                    rows="4"
                    value={agentFormData.Overview}
                    name="Overview"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-0 mb-2">
              <div
                className="px-4 py-2 w-100"
                style={{ backgroundColor: "#FF8282" }}
              >
                <p
                  className="text-white mb-0 pl-md-4 px-auto py-2"
                  style={{ fontWeight: "400", fontSize: "30px" }}
                >
                  Location and Map
                </p>
              </div>
              <div className=" w-100 my-4">
                <div className="form-outline my-2 text-muted">
                  <input
                    type="text"
                    placeholder="Address"
                    className="form-control border pl-4 text-muted"
                    value={agentFormData.Address}
                    name="Address"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </div>
                <div className="row my-4">
                  <div className="col-md-4">
                    <div className="d-block selectBox my-2">
                      <select
                        className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                        placeholder="City"
                        name="City"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        value={agentFormData.City}
                      >
                        <option value="" className="disabled">
                          Enter City
                        </option>
                        {Cities.map((city) => (
                          <option key={city.key} value={city.value}>
                            {city.value}
                          </option>
                        ))}
                        ;
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-outline my-2 text-muted">
                      <input
                        type="text"
                        placeholder="Zip/Postal Code"
                        className="form-control border pl-4 text-muted"
                        value={agentFormData.Pincode}
                        name="Pincode"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-block selectBox my-2">
                      <select
                        className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                        placeholder="State"
                        name="State"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        value={agentFormData.State}
                      >
                        <option value="" className="disabled">
                          {" "}
                          Select State
                        </option>
                        {States.map((state) => (
                          <option key={state.key} value={state.value}>
                            {state.value}
                          </option>
                        ))}
                        ;
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  <label htmlFor="photoUpload">
                    <div
                      className="p-1 ml-5 d-block text-center"
                      style={{ border: "1px dashed #FF8282" }}
                    >
                      <img height={20} src={galleryLogo} alt="" />
                      <p
                        className="text-center mb-0"
                        style={{ color: "#FF8282", fontSize: "12px" }}
                      >
                        Add Photos
                      </p>
                    </div>
                  </label>
                  <input
                    type="file"
                    style={{
                      opacity: "0",
                      zIndex: "-1",
                      position: "absolute",
                      width: "0px",
                    }}
                    accept="image/*"
                    id="photoUpload"
                    name="LocationImages"
                    onChange={(e) => {
                      handleImages(e);
                    }}
                  />
                </div>
                <div className=" my-3  d-flex">
                  <div className="border" style={{ width: "97%" }}>
                    <ul>
                      {agentFormData.LocationImages.map((value, index) => (
                        <li value={index}>
                          {value}
                          <button
                            style={{
                              color: "white",
                              padding: "2px",
                              background: "#FF8282",
                              border: "white",
                              borderRadius: "50%",
                              width: "50px",
                            }}
                            value={index}
                            onClick={(e) => {
                              deleteLocationImage(e);
                            }}
                          >
                            delete
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-0 mb-2">
              <div
                className="px-4 py-2 w-100"
                style={{ backgroundColor: "#FF8282" }}
              >
                <p
                  className="text-white mb-0 pl-md-4 px-auto py-2"
                  style={{ fontWeight: "400", fontSize: "30px" }}
                >
                  Property Details
                </p>
              </div>
              <p
                className="mb-0 ml-4 my-3"
                style={{ fontSize: "25px", fontWeight: "400" }}
              >
                Amenities
              </p>
              <div className=" w-100 mt-0 mb-4">
                <Multiselect
                  options={amenitiesList}
                  displayValue="key"
                  showCheckbox={true}
                  name="Amenities"
                  onSelect={(selectedList, selectedItem) => {
                    onSelect(selectedList, selectedItem);
                  }}
                  onRemove={(selectedList, selectedItem) => {
                    onRemove(selectedList, selectedItem);
                  }}
                  style={multi_style}
                />

                {/*---------- Primary Image -------------*/}

                <div className="mb-5">
                  <div className="border-top">
                    <p
                      className="mb-0 ml-4 my-3"
                      style={{ fontSize: "25px", fontWeight: "400" }}
                    >
                      Primary Image
                    </p>
                  </div>
                  <div className="d-flex my-2">
                    <div>
                      <label htmlFor="primaryImage">
                        <div
                          className="p-1 ml-5 d-block text-center"
                          style={{ border: "1px dashed #FF8282" }}
                        >
                          <img height={20} src={galleryLogo} alt="" />
                          <p
                            className="text-center mb-0"
                            style={{ color: "#FF8282", fontSize: "12px" }}
                          >
                            Add Photos
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        style={{
                          opacity: "0",
                          zIndex: "-1",
                          position: "absolute",
                          width: "0px",
                        }}
                        accept="image/*"
                        id="primaryImage"
                        name="PropertyImages"
                        onChange={(e) => {
                          handleImages(e);
                        }}
                      />
                    </div>
                    <div>
                      <label htmlFor="addAgentVideo">
                        <div
                          className="p-1 ml-5 d-block text-center"
                          style={{ border: "1px dashed #FF8282" }}
                        >
                          <img height={20} src={videosLogo} alt="" />
                          <p
                            className="text-center mb-0"
                            style={{ color: "#FF8282", fontSize: "12px" }}
                          >
                            Add Video
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        style={{
                          opacity: "0",
                          zIndex: "-1",
                          position: "absolute",
                          width: "0px",
                        }}
                        accept="video/mp4,video/x-m4v,video/*"
                        id="addAgentVideo"
                        name="PropertyVideos"
                        onChange={(e) => {
                          handleImages(e);
                        }}
                      />
                    </div>
                  </div>

                  <div className=" my-3  d-flex">
                    <div className="border" style={{ width: "97%" }}>
                      <React.Fragment>
                        <div className="mx-3 my-2 d-flex ">
                          <ul>
                            {agentFormData.PropertyImages.map(
                              (value, index) => (
                                <li value={index}>
                                  {value}
                                  <button
                                    style={{
                                      color: "white",
                                      padding: "2px",
                                      background: "#FF8282",
                                      border: "white",
                                      borderRadius: "50%",
                                      width: "50px",
                                    }}
                                    value={index}
                                    onClick={(e) => {
                                      deletePropertyImage(e);
                                    }}
                                  >
                                    delete
                                  </button>
                                </li>
                              )
                            )}
                          </ul>
                          <ul>
                            {agentFormData.PropertyVideos.map(
                              (value, index) => (
                                <li value={index}>
                                  {value}
                                  <button
                                    style={{
                                      color: "white",
                                      padding: "2px",
                                      background: "#FF8282",
                                      border: "white",
                                      borderRadius: "50%",
                                      width: "50px",
                                    }}
                                    value={index}
                                    onClick={(e) => {
                                      deletePropertyVideo(e);
                                    }}
                                  >
                                    delete
                                  </button>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                </div>
                {/*---------- Floor Image -------------*/}

                <div>
                  <div className="border-top">
                    <p
                      className="mb-0 ml-4 my-3"
                      style={{ fontSize: "25px", fontWeight: "400" }}
                    >
                      Floor Image
                    </p>
                  </div>
                  <div className="d-flex my-2">
                    <div>
                      <label htmlFor="floorImage">
                        <div
                          className="p-1 ml-5 d-block text-center"
                          style={{ border: "1px dashed #FF8282" }}
                        >
                          <img height={20} src={galleryLogo} alt="" />
                          <p
                            className="text-center mb-0"
                            style={{ color: "#FF8282", fontSize: "12px" }}
                          >
                            Add Photos
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        style={{
                          opacity: "0",
                          zIndex: "-1",
                          position: "absolute",
                          width: "0px",
                        }}
                        accept="image/*"
                        id="floorImage"
                        name="FloorPlanImages"
                        onChange={(e) => {
                          handleImages(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className=" my-3  d-flex">
                  <ul>
                    {agentFormData.FloorPlanImages.map((value, index) => (
                      <li value={index}>
                        {value}
                        <button
                          style={{
                            color: "white",
                            padding: "2px",
                            background: "#FF8282",
                            border: "white",
                            borderRadius: "50%",
                            width: "50px",
                          }}
                          value={index}
                          onClick={(e) => {
                            deleteFloorPlanImage(e);
                          }}
                        >
                          delete
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/*------------- Existing Tenant -----------------*/}
            <div className="row ">
              <div
                className="px-4 py-2 w-100"
                style={{ backgroundColor: "#FF8282" }}
              >
                <p
                  className="text-white mb-0 pl-md-4 px-auto py-2"
                  style={{ fontWeight: "400", fontSize: "30px" }}
                >
                  Existing or Previous Tenant details{" "}
                </p>
              </div>
              <div className=" w-100 my-4">
                <div className="d-flex">
                  <div className="col-md-3 pl-0 mx-0 pr-4  my-2">
                    <div className="form-outline mb-1 text-muted">
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control border pl-4 text-muted"
                        value={agentFormData.TenantName}
                        name="TenantName"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-3 ">
                    <div className="d-block selectBox my-2">
                      <select
                        className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                        placeholder="Country"
                        name="Country"
                        onChange={(e) => {
                          handleInput(e);
                        }}
                        value={agentFormData.Country}
                      >
                        <option value="" className="disabled" name="Country">
                          Country
                        </option>
                        <option value="INDIA">INDIA</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-outline my-4">
                  <textarea
                    className="form-control border text-muted rounded-0  pl-4"
                    placeholder="Description"
                    rows="4"
                    value={agentFormData.Description}
                    name="Description"
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </div>
                <div>
                  <p
                    className="mb-0 ml-4 my-3"
                    style={{ fontSize: "25px", fontWeight: "400" }}
                  >
                    Time Period
                  </p>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="d-block selectBox my-2">
                        <label
                          className="text-muted partnerContactSelect"
                          style={{ padding: "2%" }}
                        >
                          Lease start Date
                        </label>
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="w-100 py-2 border text-muted pl-4 partnerContactSelect"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className="d-flex selectBox my-2">
                        <label
                          className="text-muted partnerContactSelect"
                          style={{ padding: "2%" }}
                        >
                          Lock in period{" "}
                        </label>
                        <select
                          className="w-50  my-1 py-2 border text-muted pl-4 partnerContactSelect"
                          placeholder="Country"
                          name="LockInPeriod"
                          value={agentFormData.LockInPeriod || ""}
                          onChange={(e) => {
                            handleInput(e);
                            setEndDate(moment(startDate).add(e.target.value,"years"))
                          }}
                        >
                          <option value="" className="disabled" name="Month">
                            Lock in period
                          </option>
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                            (year) => {
                              return (
                                <option key={year} value={year}>
                                  {year} Year
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="d-flex align-items-center selectBox my-2">
                        <label
                          className="text-muted partnerContactSelect disabled"
                          style={{ padding: "2%" }}
                        >
                          Lease End Date{" "}
                        </label>
                        <input
                          type="text"
                          placeholder="Lease End Period"
                          className="form-control w-50  border pl-4 text-muted"
                          name="LockInPeriod"
                          value={ agentFormData.LockInPeriod? 
                            moment(endDate)
                             .format("YYYY-MM-DD"):null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p
                    className="mb-0 ml-4 my-3"
                    style={{ fontSize: "25px", fontWeight: "400" }}
                  >
                    Tenant Highlights
                  </p>
                  <div>
                    <div className="d-md-flex d-block">
                      <div className="col-md-3 pl-0 mx-0 pr-4  my-2">
                        <div className="form-outline mb-1 text-muted">
                          <input
                            type="text"
                            placeholder="Histrorical Suburb Growth"
                            className="form-control border pl-4 text-muted"
                            value={agentFormData.HistoricalSuburbGrowth}
                            name="HistoricalSuburbGrowth"
                            onChange={(e) => {
                              handleInput(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pl-0 mx-0 pr-4  my-2">
                        <div className="form-outline mb-1 text-muted">
                          <input
                            type="text"
                            placeholder="Rent Per Month"
                            className="form-control border pl-4 text-muted"
                            value={agentFormData.RentPerMonth}
                            name="RentPerMonth"
                            onChange={(e) => {
                              handleInput(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pl-0 mx-0 pr-4  my-2">
                        <div className="form-outline mb-1 text-muted">
                          <input
                            type="text"
                            placeholder="Lease Period"
                            className="form-control border pl-4 text-muted"
                            value={agentFormData.LeasePeriod}
                            name="LeasePeriod"
                            onChange={(e) => {
                              handleInput(e);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 pl-0 mx-0 pr-4  my-2">
                        <div className="form-outline mb-1 text-muted">
                          <input
                            type="text"
                            placeholder=" Escalation"
                            className="form-control border pl-4 text-muted"
                            value={agentFormData.Escalation}
                            name="Escalation"
                            onChange={(e) => {
                              handleInput(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*------------- Key Documents -------------*/}
              <div className="w-100">
                <div className="d-flex w-100 ">
                  <div
                    className="px-4 py-2 w-100"
                    style={{ backgroundColor: "#FF8282" }}
                  >
                    <p
                      className="text-white mb-0 pl-md-4 px-auto py-2"
                      style={{ fontWeight: "400", fontSize: "30px" }}
                    >
                      Key Documents{" "}
                    </p>
                  </div>
                </div>
                <div className=" my-3 w-100 d-flex ml-0 pl-0">
                  <div className="border w-100">
                    <label className="mb-0 pb-0" htmlFor="agentFileUpload">
                      <div className="p-1 ml-3 d-flex align-items-center text-center ">
                        <div
                          className="px-3 py-1 rounded ml-3 "
                          style={{
                            color: "#FF8282",
                            border: "1px solid #FF8282",
                          }}
                        >
                          <p
                            className="mb-0 font-small"
                            style={{ cursor: "pointer" }}
                          >
                            Choose file
                          </p>
                        </div>
                        <p className="mb-0 text-muted ml-5 ">No file choosen</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      style={{
                        opacity: "0",
                        zIndex: "-1",
                        position: "absolute",
                        width: "0px",
                      }}
                      accept="image/*"
                      id="agentFileUpload"
                      className=""
                    />
                  </div>
                  <div>
                    {" "}
                    <React.Fragment>
                      <div
                        className="position-relative mx-3 my-2 d-flex justify-content-center align-items-center"
                        onClick={() => {
                          setKeyDocuments(keyDocuments + 1);
                        }}
                      >
                        <img
                          alt=""
                          height={30}
                          className=""
                          style={{ position: "absolute", cursor: "pointer" }}
                          src={pinkCircleImg}
                        />
                        <p
                          className="mb-0 text-white"
                          style={{ position: "relative", cursor: "pointer" }}
                        >
                          +
                        </p>
                      </div>
                    </React.Fragment>
                  </div>
                </div>
                {keyDocuments === 1 && (
                  <div className="d-flex">
                    <div className="border w-100">
                      <label className="mb-0 pb-0" htmlFor="agentFileUpload">
                        <div className="p-1 ml-3 d-flex align-items-center text-center ">
                          <div
                            className="px-3 py-1 rounded ml-3 "
                            style={{
                              color: "#FF8282",
                              border: "1px solid #FF8282",
                            }}
                          >
                            <p
                              className="mb-0 font-small"
                              style={{ cursor: "pointer" }}
                            >
                              Choose file
                            </p>
                          </div>
                          <p className="mb-0 text-muted ml-5 ">
                            No file choosen
                          </p>
                        </div>
                      </label>
                      <input
                        type="file"
                        style={{
                          opacity: "0",
                          zIndex: "-1",
                          position: "absolute",
                          width: "0px",
                        }}
                        accept="image/*"
                        id="agentFileUpload"
                        className=""
                      />
                    </div>
                    <div>
                      {" "}
                      <React.Fragment>
                        <div
                          className="position-relative mx-3 my-2 d-flex justify-content-center align-items-center"
                          onClick={() => {
                            setKeyDocuments(keyDocuments - 1);
                          }}
                        >
                          <img
                            alt=""
                            height={30}
                            className=""
                            style={{ position: "absolute", cursor: "pointer" }}
                            src={pinkCircleImg}
                          />
                          <p
                            className="mb-0 text-white"
                            style={{ position: "relative", cursor: "pointer" }}
                          >
                            -
                          </p>
                        </div>
                      </React.Fragment>
                    </div>
                  </div>
                )}

                <div className="my-4 text-center mx-auto d-flex flex-column justify-content-center">
                  {/* <div className="my-2">
                    <ReCAPTCHA
                      sitekey="Your client site key"
                      onChange={checkCaptcha}
                    />
                  </div> */}
                  <div className="d-md-flex d-block my-2">
                    <div
                      className="btn px-3 ml-0 mr-2 text-white"
                      style={{
                        backgroundColor: "#FF8282",
                        width: "200px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "5px",
                      }}
                      onClick={() => {
                        submitAgentFormDetails();
                      }}
                    >
                      SUBMIT PROPERTY
                    </div>
                    <div
                      className="btn px-3 mx-3"
                      style={{
                        color: "#FF8282",
                        width: "200px",
                        border: "1px solid #FF8282",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      RESET
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AgentPortalForms;
