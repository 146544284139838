import React from "react";
import SideNav from "../SideNav";
import MyInvestmentContent from "./MyInvestmentContent";
import NavbarDashboard from "../NavbardDashboard";
import SideNavMobile from "../SideNavMobile";
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';

let MyInvestments = () => {
    const history = useHistory();
    console.log('localstorage', localStorage.getItem('user'))
    // const user_id = useSelector((state)=>state.user.UserID) || null
    const user_id = localStorage.getItem('user') || null
    return (
        <React.Fragment>
            {(!user_id) ?
                (<div className="dashboard-login dashboard-login-text">
                    <div><h3> Please sign in to continue </h3></div>
                    <button type="submit" style={{
                        backgroundColor: " #FF8282", fontWeight: "400",
                        fontSize: "18px", borderRadius: "10px"
                    }}
                        className="btn text-white text-capitalize btn-md py-2 px-5 mb-3"
                        onClick={() => { history.push('/login') }}>Log in</button>

                </div>) : ''
            }
            <NavbarDashboard active="dashboard" />
            <div style={{ position: "relative" }} className={(!user_id ? 'disableView' : '')}>
                <SideNavMobile active="myInvestments" />
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-md-3 p-0 m-0">
                            <SideNav active="myInvestments" />
                        </div>
                        <div className="col-md-9 " >
                            <div className="m-md-5 m-2">
                                <MyInvestmentContent />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default MyInvestments;