import React, { useState, useEffect } from "react";
import SideNav from "../SideNav";
import NavbarDashboard from "../NavbardDashboard";
import documentUploadLogo from "../assets/documentUploadLogo.png";
import downloadLogo from "../assets/downloadLogo.png";
import dropdownArrow from "../assets/dropdownArrow.png";
import calendar from "../assets/calendar.png";
import styles from "../dashboard.css";
import { TdsTransactions } from '../../../services/ApiServices'
let TaxCenterContent = () => {
    let [documents, setDocuments] = useState([]);
    useEffect(() => {
        TdsTransactions(localStorage.getItem('user')).then((response) => {
            console.log(response)
            setDocuments(response?.TDSTransactions);
        });
    }, [])
    return (
        <React.Fragment>
            <div className="">
                <div className="row justify-content-around px-4 py-1 mt-2 mb-0"
                    style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>

                    <p className="text-center py-2 mb-0" style={{ fontWeight: "700", fontSize: "18px" }}>Tax Center</p>

                </div>
                <div className="border mt-0 mb-3 pb-2" style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>

                    <div className="row justify-content-between px-4 py-0 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "rgb(238, 255, 199)" }}>
                        <div className="d-flex align-items-center">
                            {/*   <div className="d-flex justify-content-center text-center my-2 mb-0 mr-md-auto mx-auto">
                                <div className="d-flex align-items-center mx-2" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                                    <select style={{ height: "30px", color: "#ff8282", position: "relative", border: "1px solid #ff8282" }}
                                        className=" form-control px-4 py-0">
                                        <option className="bg-white text-dark" selected >Yogi Patil</option>
                                        <option className="bg-white text-dark" value="1">Name 1</option>
                                        <option className="bg-white text-dark" value="2">Name 2</option>
                                    </select>
                                </div>
                            </div> */}
                            <div className="d-flex justify-content-center text-center my-2 mb-0">
                                <div className="d-flex align-items-center mx-2" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                                    <select style={{ height: "30px", color: "rgb(155, 205, 42)", position: "relative", border: "1px solid rgb(155, 205, 42)" }}
                                        className=" form-control px-4 py-0">
                                        <option className="bg-white text-dark" selected >All Properties</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className=" d-flex align-items-center">
                            <p className="mb-0 font-small mx-2">Financial Year</p>
                            <div className="position-relative d-flex align-items-center">
                                <input type="text" value="2019-2020"
                                    style={{ height: "30px", width: "150px", border: "1px solid rgba(255, 130, 130, 0.3)", position: "relative" }}
                                    className="bg-white text-muted text-center form-control px-2 py-1" />

                            </div>
                        </div>
                    </div>



                    <div className="row justify-content-around px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>

                        <React.Fragment style={{ backgroundColor: "#ededed" }}>
                            <div className=' py-1 col-md-2 text-center  text-center rounded'
                            >Income
                            </div>
                            <div className=' py-1  text-center col-md-2 text-center rounded'
                            >TDS %
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            > &#8377; TDS
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Type
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Net Distribution
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Download TDS
                            </div>
                        </React.Fragment>

                    </div>



                    <div className="row justify-content-around px-4 py-1 my-2"
                    >
                        {documents.length > 0 ? documents.map(item => <>
                            <div className="py-1 text-center col-md-2 text-center">
                                {
                                    item.IncomeAmount
                                }
                            </div>
                            <div className="py-1 text-center col-md-2 text-center">
                                {
                                    item.TDSPercentage
                                }
                            </div>
                            <div className="py-1 text-center col-md-2 text-center">
                                {
                                    item.TDSAmount
                                }
                            </div>
                            <div className="py-1 text-center col-md-2 text-center">
                                {
                                    item.Type
                                }
                            </div>
                            <div className="py-1 text-center col-md-2 text-center">
                                {
                                    item.NetDistribution
                                }
                            </div>
                            <div className="py-1 text-center col-md-2 text-center">
                                <a target="_blank" href={item.TDSCertificateURL}>Download</a>
                            </div>
                        </>) :
                            <div className="col-md-10 py-4 text-center mx-auto">
                                <p className="text-center text-muted">Tax details not available</p>
                            </div>
                        }
                    </div>

                </div>


            </div>
        </React.Fragment>
    )
};
export default TaxCenterContent;