import React from "react";

let TaxForSelling = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>Applicable Tax for NRI for selling property in
                        India
                    </h2>
                    <div className="mt-4">

                        <p>There has been ambiguity regarding the taxes imposed on the sale of a property by NRIs as the
                            rules surrounding the procedure are different for a citizen and NRI.</p>

                        <p>For an NRI selling his/her property in India, the major portion of the taxes payable come
                            from
                            the gains or the capital gains. These taxes have a different rate depending on whether the
                            capital gains are made over the short-term or long-term.
                            NRIs also have to pay tax charges in case the property is a part of the inheritance. Hence,
                            the
                            individual should pay attention to the details of the capital gains and other impossible
                            taxes.
                            The common taxes which an NRI is liable to pay are listed below:</p>

                        <p>How much tax is payable for NRI?</p>

                        <p>Depending on the capital gains, the taxes on short-term capital gains are charged when the
                            NRI
                            is selling the property within 1-2 years of its purchase, and this type of tax is calculated
                            by
                            removing the cost of acquisition from the sale proceedings on the property.
                            Long-term capital gains are charged at the rate of 20% as TDS payable. The taxes are also
                            variable on the slab rates provided by the income tax department.</p>

                        <p>Deductible Tax on TDS</p>

                        <p>When an NRI sells his/her property in India, the TDS depends on the tenure between the
                            property’s purchase and sale. If the tenure is under 2 years, the NRI is charged with a 30%
                            rate
                            of TDS, and when the sale is made beyond that tenor, then TDS is imposed at the rate of
                            20%.</p>

                        <p>Saving Tax on Capital Gains</p>

                        <p>The state of taxes imposed is not all bad for NRI investors. They can cut down the expenses
                            on
                            taxes by gaining some tax benefits. These tax benefits function as exemptions that can be
                            claimed only over those property sales made over the long-term. Thus, NRIs can enjoy tax
                            benefits on long-term capital gains. These tax exemptions are described below:</p>

                        <div className="mx-md-5 mx-2">
                            <p style={{fontWeight: "600"}}>• Tax Exemption Under Section 54:</p>

                            <p>This tax exemption helps provide relief on long-term capital gains. This can be utilized
                                during the next purchase of the property and for any
                                reconstruction or renovation. It is mandated that an individual can utilize this
                                exemption to purchase only a single property, and the property
                                has to be in the country. If, however, an NRI is unable to purchase a new property by
                                the time of filing the income tax return, the capital gains
                                can be submitted into a Public Sector Undertaking or any other banks and can be utilized
                                later to get tax exemptions.</p>

                            <p style={{fontWeight:"600"}}>• Tax Exemption Under Section 54EC:</p>

                            <p>This type of tax exemption allows savings on tax expenses for an NRI when the individual
                            invests the long-term capital gain into a bond or any
                            other investment instrument with a fixed tenure of operation. NRIs can choose between the
                            NHAI or REC offered bonds and redeem these
                            investments within 5 years of the investment or selling the property. An important point to
                            note here is that the tax exemption is applicable
                                only when NRI invests before the income tax return filing date arrives.</p>

                            <p style={{fontWeight:"600"}}>• Tax Exemption Under Section 54F:</p>

                            <p>This tax exemption option is for those NRIs who have earned some long-term capital gains on
                            real estate properties other than residential ones.
                            The benefit can be availed when the NRI invests in the purchase of a residential property
                            using these long-term capital gains. The property must
                            be situated in India, and the NRI must purchase the new household property within 3 years of
                            generating long-term capital gains. The tax exemption
                            shall be applied only on the capital gains receipt and not the other investments. The tax
                            benefits are proportional to the capital gains, which are a
                            part of the sale receipt included under the income tax returns. The knowledge and awareness
                            of the tax benefits are vital as it can save the NRI
                            from bearing any costs of taxes, which can otherwise be avoided. Tax exemptions are an easy
                            and convenient way for the NRI to save some tax
                            expenses. The NRI real estate investment has been made easy as all the procedures can be
                            done online. The following section shall describe the
                                method of NRI real estate investment using BrickBerry.</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default TaxForSelling;
