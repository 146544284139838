import React from "react";
import pinkCircleImg from "../../assets/pinkCircleImg.png";
import FAQ from "../../frequentlyAskedQuestion/FAQ";
import row1Img from "../../assets/row1Img.png";
import row2Img from "../../assets/row2Img.png";
import row3Img from "../../assets/row3Img.png";
import row4Img from "../../assets/row4Img.png";
import row5Img from "../../assets/row5Img.png";
import row21Img from "../../assets/row21Img.png";
import row22Img from "../../assets/row22Img.png";
import row23 from "../../assets/row23.png";
import row24 from "../../assets/row24.png";
import row25 from "../../assets/row25.png";
import row3 from "../../assets/row3.png";
import blockChainImg from "../../assets/blockchainImg.png";
let BlockchainBenefits = () => {
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6  p-0 m-0  d-flex justify-content-center" >
                        <img className="img-fluid" src={blockChainImg} alt="" />
                    </div>
                    <div className="col-md-6 p-0 m-0 d-flex justify-content-center align-items-center px-md-5 px-2 py-4" style={{ backgroundColor: "rgb(22, 142, 186)" }}>
                        <p className="p-1 d-flex justify-content-center ml-md-5 mx-auto" style={{ fontWeight: "400", fontSize: "40px", lineHeight: "50px" }}>How Blockchain-based Smart contracts could Revolutionize Commercial Real-Estate</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-md-5 my-5 pt-3 px-1">
                <div className="mx-md-5 px-md-5 px-0 mx-2">
                    <div>
                        <h5 style={{ fontWeight: "400", fontSize: "100" }}>Benefits of Blockchain technology</h5>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-2 text-center" height={50} width={70} src={row1Img} alt="" />
                                    <div>
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Near real-time</p>
                                        <p className="mx-md-5 px-md-0 px-1 m-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>The blockchain enables near real-time settlement of recorded transactions,
                                            removing friction and reducing risk, but also limiting ability to charge back
                                            or cancel transactions.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-2 text-center" height={50} width={70} src={row2Img} alt="" />
                                    <div>
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Trustless environment</p>
                                        <p className="mx-md-5 px-md-0 px-1 m-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>Blockchain technology is based on cryptographic proof, allowing any two parties
                                            to transact directly with each other without the need for a trusted third party.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-2 text-center" height={50} width={70} src={row3Img} alt="" />
                                    <div>
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "14px" }}>Distributed ledger</p>
                                        <p className="mx-md-5 px-md-0 px-1 m-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>The peer-to-peer distributed network records a public history of transactions.
                                            The blockchain is distributed and highly available; it also retains a secure
                                            source of proof that the transaction occurred.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-2 text-center" height={50} width={70} src={row4Img} alt="" />
                                    <div>
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Irreversibility</p>
                                        <p className="mx-md-5 px-md-0 px-1 m-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>The blockchain contains a certain and verifiable record of every transaction
                                            ever made, which mitigates the risk of double spending, fraud, abuse, and manipulation of transactions.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-2 text-center" height={50} width={70} src={row5Img} alt="" />
                                    <div>
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Censorship resistant
                                        </p>
                                        <p className="mx-md-5 px-md-0 px-1 m-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>The crypto economics built into the blockchain model provide incentives for the
                                            participants to continue validating blocks, reducing the possibility of external
                                            influencers to modify previously recorded transaction records.
                                        </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-md-5 my-5 pt-3 px-1">
                <div className="mx-md-5 px-md-5 px-0 mx-2">
                    <div>
                        <h5 style={{ fontWeight: "400", fontSize: "100" }}>Why consider Blockchain for Real estate leasing and purchase and sale transactions? </h5>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-md-5 mx-1 my-auto text-center" height={45} width={45} src={row21Img} alt="" />
                                    <div className="d-flex flex-column mx-auto">
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Need for a common database</p>
                                        <p className="mx-md-5 px-md-0 px-1 mx-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>multiple listing service, which collates property-level information from private databases of brokers and agents. </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-md-5 mx-1 my-auto text-center" height={45} width={45} src={row22Img} alt="" />
                                    <div className="d-flex flex-column mx-auto">
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Multiple entities can modify database</p>
                                        <p className="mx-md-5 px-md-0 px-1 mx-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>
                                            operators, lenders, investors, and service providers, who provide, access, and modify a variety of information.    </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-md-5 mx-1 my-auto text-center" height={45} width={45} src={row23} alt="" />
                                    <div className="d-flex flex-column mx-auto">
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Lack of trust among entities</p>
                                        <p className="mx-md-5 px-md-0 px-1 mx-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>
                                            over cautious in due diligence and may even have data integrity concerns. However, blockchain can help reduce the risk
                                            through digital identities and more transparent record keeping systems for real estate titles, entitlement, liens, financing
                                            and tenancy. </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-md-5 mx-1 my-auto text-center" height={45} width={45} src={row24} alt="" />
                                    <div className="d-flex flex-column mx-auto">
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Opportunity for disintermediation</p>
                                        <p className="mx-md-5 px-md-0 px-1 mx-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>
                                            increased security and transparency in title management and auto-confirmation by government land registries.  </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="border py-0 pl-md-1 my-4 px-2" >
                            <div className=" my-auto pb-2 pt-1 pl-0  ">
                                <div className="d-flex px-2  align-items-center">
                                    <img className="text-md-left mx-md-5 mx-1 my-auto text-center" height={45} width={45} src={row25} alt="" />
                                    <div className="d-flex flex-column mx-auto">
                                        <p className="mb-0 m-auto text-center" style={{ fontWeight: "700", fontSize: "15px" }}>Transaction dependence</p>
                                        <p className="mx-md-5 px-md-0 px-1 mx-1 mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>
                                            instance, the conclusion of a puchase-sale transaction could be dependent on loan approvals or title clearances.     </p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-md-5 my-5 pt-3 px-1">
                <div className="mx-md-5 px-md-5 px-0 mx-2">
                    <div>
                        <h5 style={{ fontWeight: "400", fontSize: "16px" }}>
                            As set forth, we believe that among the core CRE processes, leasing and purchase and sale transaction processes are ripe for blockchain adoption, as it can take advantage of its inherent benefits and meets the
                            prerequisites for using the technology</h5>
                        <div className="text-center my-5" >
                            <img className="blockchainImg" style={{ height: "350px" }} src={row3} alt="" />
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};
export default BlockchainBenefits;