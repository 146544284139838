import React from "react";
import rightImg from "../assets/rightImg.png";
import documentStorageImg from "../assets/documentStorageImg.png";
import accountingImg from "../assets/accountingImg.png";
import decisionMaking from "../assets/decisionMaking.png";

let BrickModel = () => {
    return (
        <React.Fragment>
            <div className="container-fluid my-4">
                <p className="h2 font-weight-bold text-center">Brick Model</p>
                <div className="container-fluid p-1 my-3 "
                    style={{ backgroundImage: `url(${require("../assets/BrickModelImg.png")})` }}>
                    <div
                        className="container h6 my-2 px-4 font-weight-normal mx-auto d-flex justify-content-center brickModelBg "
                    >
                        <div className="mx-auto">
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">Each property we list will be divided into bricks.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">User should buy at least minimum number of bricks to own a fraction of the property.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">Total number of bricks and minimum number of bricks to buy depends upon property Size.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">Brick value is equal to 1SFT Value of that property and varies for each property.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">User can buy & sell bricks only through Brickberry platform.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">Bricks appreciation is updated every quarter and all the info is accessible through dashboard.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">User can sell bricks when ever he want benefit from demand & there is no lock in period.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">User can sell portion of their bricks (worth of atleast 10Lks) or complete bricks owned.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">User can sell their bricks through our AI-dashboard either to other existing members in the platform
                                    (or) New members.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">All brick transactions will be done only through Brickberry platform.</p></span>
                            <span className="d-flex h6 font-weight-normal"><img alt="img" height={15} width={18}
                                src={rightImg} />
                                <p className="mx-2">Incase of entire property sale, all bricks will be sold and settled at the end of the SPV.</p></span>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container-fluid">
                <p className="h2 font-weight-bold my-4 text-center"> AI Dashboard</p>
                <div className="row my-5 justify-content-center">
                    <div className="col-md-3 AIDashboard  ">
                        <div className="p-4 h-100 AIDashboardCard" style={{ border: "1px solid rgb(155, 205, 42)" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <div style={{
                                    height: '70px',
                                    width: "70px",
                                    backgroundColor: "#242b2e",
                                    borderRadius: "50%"
                                }}>
                                    <img alt="img" src={documentStorageImg} style={{ margin: "20px" }} height={30}
                                        width={30} />
                                </div>
                            </div>
                            <p className="my-4 text-center h6">Document Storage</p>
                            <p className="h6 font-weight-normal">All documents related to your ownership are stored
                                online and are Securely accessible
                                to you through our AI based Dashboard.</p>
                        </div>
                    </div>
                    <div className="col-md-3 AIDashboard ">
                        <div className="p-4 h-100 AIDashboardCard" style={{ border: "1px solid rgb(155, 205, 42)" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <div style={{
                                    height: '70px',
                                    width: "70px",
                                    backgroundColor: "#242b2e",
                                    borderRadius: "50%"
                                }}>
                                    <img alt="img" src={accountingImg} style={{ margin: "20px" }} height={30} width={30} />
                                </div>
                            </div>
                            <p className="my-4 text-center h6">Accounting</p>
                            <p className="h6 font-weight-normal">Income, Expense, Annual filings, Tax payments - all
                                ownership related accounting with
                                respective documentation is available to you on your dashboard.</p>
                        </div>
                    </div>
                    <div className="col-md-3 AIDashboard ">
                        <div className="p-4 h-100 px-5 AIDashboardCard" style={{ border: "1px solid rgb(155, 205, 42)" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <div style={{
                                    height: '70px',
                                    width: "70px",
                                    backgroundColor: "#242b2e",
                                    borderRadius: "50%"
                                }}>
                                    <img alt="img" src={decisionMaking} style={{ margin: "20px" }} height={30}
                                        width={30} />
                                </div>
                            </div>
                            <p className="my-4 text-center h6">Decision Making</p>
                            <p className="h6 font-weight-normal">The power of fractional ownership is in collective
                                decision making.
                                Through Blockchain powered governance protocol, you get to decide what is right for
                                you.</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default BrickModel;
