import React from "react";

let ViewPropertyDetails = ({propertyDeatils}) => {
    const propertyListing = propertyDeatils || [];
    console.log('view prperty deatil',propertyListing);
    return (
        <React.Fragment>
            <div className="container my-5" style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"}}>
                <p className="text-center" style={{fontSize: "35px", fontWeight: "700"}}>Property Details</p>
                <div className="px-md-5 px-2 mx-md-5 pb-1 mx-0">
                    <p style={{fontSize: "17px", fontWeight: "700"}}>Overview</p>
                    <p>{propertyListing.Overview}</p>
                    <div className="row my-5">
                        <div className="col-md-6" >
                            <div className="p-4 h-100" style={{boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)"}}>
                            <p className="my-2 ">Project Name: <span style={{color: "#ffafaf"}}>{propertyListing.PropertyTitle}</span>
                            </p>
                            <p className="my-2 ">Price Breakup: <span style={{color: "#ffafaf"}}>₹ 2.30 Cr ₹ 15,000/M Monthly</span>
                            </p>
                            <p className="my-2 ">Approved By : <span
                                style={{color: "#ffafaf"}}>{propertyListing.ApprovedBy}</span></p>
                            <p className="my-2 ">Facing: <span style={{color: "#ffafaf"}}>{propertyListing.Facing}</span></p>
                            <p className="my-2 ">Transaction Type: <span style={{color: "#ffafaf"}}>{propertyListing.PropertyStatus}</span></p>
                        </div>
                        </div>
                        <div className="col-md-6 ">
                            <div className="p-4 h-100" style={{boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)"}}>
                            <p className="my-2 ">Super Area/Carpet Area: <span style={{color: "#ffafaf"}}>{propertyListing.SuperArea_CarpetArea}</span>
                            </p>
                            <p className="my-2 ">
                                City: <span style={{color:"#ffafaf"}}>{propertyDeatils.City}
                                </span>
                            </p>
                            <p className="my-2 ">Society Landmarks: <span style={{color:"#ffafaf"}}>{propertyListing.Landmarks}</span></p>
                            <p className="my-2 ">
                                Furnishing : <span style={{color:"#ffafaf"}}>{propertyListing.FurnishingStatus}</span>
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default ViewPropertyDetails;
