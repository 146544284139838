import React from "react";
import { Bar } from "react-chartjs-2";

let ViewPropertyCapitalReturn = ({ propertyDeatils }) => {
  const propertyListing = propertyDeatils || [];
  const MOLCBarData = {
    labels: ["MOLC"],
    datasets: [
      {
        label: "2.06%",
        data: [2.06],
        fill: true,
        backgroundColor: "#ffe2db",
        borderColor: "white",
      },
    ],
  };
  const IRRBarData = {
    labels: ["Target IRR"],
    datasets: [
      {
        label: "18.08%",
        data: [18.068],
        fill: true,
        backgroundColor: "#ff8282",
        borderColor: "white",
      },
    ],
  };
  const SaleBarData = {
    labels: ["Sale Price PSF"],
    datasets: [
      {
        label: "22K",
        data: [22],
        fill: true,
        backgroundColor: "#8E0F45",
        borderColor: "white",
      },
    ],
  };
  const ProfitBarData = {
    labels: ["Profit Amount"],
    datasets: [
      {
        label: "26.5L",
        data: [26.5],
        fill: true,
        backgroundColor: "#98BC56",
        borderColor: "white",
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="container" id="capitalReturn">
        <p
          className="text-center"
          style={{ fontSize: "35px", fontWeight: "700" }}
        >
          Capital Return
        </p>
        <div className="row my-4">
          <div className="col-md-4">
            <div className="border text-center p-md-4 p-2">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                HISTORICAL SUBURB GROWTH
              </p>
              <p
                className="mb-0 mt-4"
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#ff8282",
                }}
              >
                {propertyListing.HistoricalSuburbGrowth}%
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border text-center p-md-4 p-2">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                TOTAL PURCHASE COST
              </p>
              <p
                className="mb-0 mt-4"
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#ff8282",
                }}
              >
                ₹{propertyListing.PropertyValue}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border text-center p-md-4 p-2">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                DEBT
              </p>
              <p
                className="mb-0 mt-4"
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#ff8282",
                }}
              >
                36%
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <p
          className="mb-0 text-center"
          style={{ fontSize: "20px", fontWeight: "600" }}
        >
          Capital Appreciation
        </p>
        <div className="p-2 border my-4">
          <input type="range" className="custom-range w-100" />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="m-2 border">
              <Bar
                data={MOLCBarData}
                options={{
                  title: {
                    display: true,
                  },
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        barPercentage: 0.4,
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                        },
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
                height={350}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="m-2 border">
              <Bar
                data={IRRBarData}
                options={{
                  title: {
                    display: true,
                  },
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        barPercentage: 0.4,
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                        },
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
                height={350}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="m-2 border">
              <Bar
                data={SaleBarData}
                options={{
                  title: {
                    display: true,
                  },
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        barPercentage: 0.4,
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                        },
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
                height={350}
              />
            </div>
          </div>
          <div className="col-md-3">
            <div className="m-2 border">
              <Bar
                data={ProfitBarData}
                options={{
                  title: {
                    display: true,
                  },
                  maintainAspectRatio: false,
                  legend: {
                    display: true,
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        barPercentage: 0.4,
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          display: true,
                        },
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
                height={350}
              />
            </div>
          </div>
        </div>
        <p className="mb-0 text-center my-4" style={{ fontSize: "" }}>
          Brickberry conducts Independent Valuations on a semi-annual basis for
          all properties on the platform. To learn more, see our FAQs.
        </p>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertyCapitalReturn;
