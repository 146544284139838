import React, { useState, useRef } from "react";
import Axios from "axios";
import eye from "./assets/GroupeyeImg.png";
import facebookImg from "./assets/facebookLogo.png";
import linkedInLogpo from "./assets/linkedInLogpo.png";
import googleLogo from "./assets/googleLogo.png";
import bricksBackgroundImgLogin from "./assets/bricksBackgroundImgLogin.png";
import logInTopImg from "./assets/logInTopImg.png";
import rightImg from "./assets/rightLogo.png";
import { Link } from "react-router-dom";
import logInBottomImg from "./assets/logInBottomImg.png";
import buildingLeftImg from "./assets/buildingLeftImgEdit.png";
import buildingRightImg from "./assets/buildingRightImgEdit.png";
import NavbarPin from "../securePin/NavbarPin";
import { useHistory } from 'react-router-dom';
import calendar from "../dashboard/assets/calendar.png";

let SignUp = () => {
    let [show, setShow] = useState(false);
    const history = useHistory();
    let [user, setUser] = useState({
        FirstName: '',
        LastName: '',
        EmailID: '',
        MobileNumber: '',
        Password: '',
        UserType: null,
        DOB: null
    });
    let [userExist, setUserExist] = useState(null);
    let [DOBField, setDOBField] = useState("text");
    let [confirmPasswordValue, setConfirmPasswordValue] = useState(null);
    let [passwordError, setPasswordError] = useState(false);

    let signUp = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    };
    let handleConfirmPassword = (e) => {
        setConfirmPasswordValue(e.target.value);
        if (user.Password === e.target.value) {
            setPasswordError(false)
        }
    };
    let handleUserType = (e) => {
        if (e.target.checked === true) {
            setUser({
                ...user,
                UserType: e.target.value
            });
        } else if (e.target.checked === false) {
            setUser({
                ...user,
                UserType: null
            });
        }

    };

    let sendUser = async (e) => {
        e.preventDefault();
        if (confirmPasswordValue !== user.Password) {
            setPasswordError(true);
        }
        else {
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            let dataURL = `${process.env.REACT_APP_API_URL}/usersignup`;
            const userInfo = {
                FirstName: user.FirstName,
                LastName: user.LastName,
                EmailID: user.EmailID,
                MobileNumber: user.MobileNumber,
                Password: user.Password,
                UserType: user.UserType,
                DOB: user.DOB,
                UserName: user?.FirstName + " " + user?.LastName
            };
            await Axios.post(dataURL, JSON.stringify(userInfo), config).then((response) => {
                console.log(response);
                if (response.data.Status === "1") {
                    console.log("signUpSuccess");
                    history.push({
                        pathname: '/setPin',
                        email: user.EmailID,
                    });
                } else if (response.data.Status === 0) {
                    setUserExist(response.data.Message);
                    setTimeout(() => {
                        setUserExist(null)
                    }, 4000);
                    console.log(response.data.Message)
                }

            }).catch((err) => {
                console.log(err)
            });
        }
    };
    return (
        <React.Fragment>
            <NavbarPin />
            <div className="container-fluid position-relative">
                <div className="row ">
                    <div
                        className="col-md-6 px-md-5 d-flex flex-column justify-content-around pt-md-5 pb-0 px-0 m-0 text-center ">
                        <div className="mt-2 pt-0">
                            <p className="h1 mb-0 text-center my-2"
                                style={{ fontWeight: "700", fontSize: "35px", color: "black" }}>
                                Sign up to Account
                            </p>
                            <p className=""
                                style={{ borderTop: "4px solid rgb(155, 205, 42)", width: "50px", margin: "auto" }}>
                            </p>
                        </div>
                        <div className="p-md-0 loginForm p-2 mt-md-0">
                            <form className="px-md-4  px-1 mt-4" onSubmit={sendUser}>
                                <div className="form-outline mb-4  ">
                                    <input type="text" onChange={signUp} name="FirstName" required={true}
                                        style={{ borderRadius: "12px" }} id="form2Example1" placeholder="First Name"
                                        className="form-control pl-5 text-muted" />
                                </div>
                                <div className="form-outline mb-4  ">
                                    <input type="text" onChange={signUp} name="LastName" required={true}
                                        style={{ borderRadius: "12px" }} id="form2Example1" placeholder="Last Name"
                                        className="form-control pl-5 text-muted" />
                                </div>
                                <div className="form-outline mb-4  ">
                                    <input type="email" onChange={signUp} required={true} name="EmailID"
                                        style={{ borderRadius: "12px" }} id="form2Example1" placeholder="Email"
                                        className="form-control pl-5 text-muted" />
                                </div>
                                <div className="form-outline mb-4  ">
                                    <input type="number" onChange={signUp} required={true} name="MobileNumber"
                                        style={{ borderRadius: "12px" }} id="form2Example1" placeholder="Mobile Number"
                                        className="form-control pl-5 text-muted" />
                                </div>
                                <div className=" datePicker mb-4">
                                    <div className="position-relative d-flex">
                                        <input
                                            required={true}
                                            type={DOBField === "date" ? "date" : "text"}
                                            onBlur={() => setDOBField("text")}
                                            onFocus={() => setDOBField("date")}
                                            name="DOB"
                                            onChange={signUp}
                                            placeholder="Date Of Birth" style={{
                                                height: "35px",
                                                position: "relative",
                                                borderRadius: "12px",
                                            }} className="bg-transparent form-control pl-5  text-muted py-1" />
                                        {DOBField === "date" &&
                                            <img alt=""
                                                style={{
                                                    position: "absolute",
                                                    filter: "brightness(0.25)",
                                                    right: "5.5%",
                                                    top: "20%",
                                                    height: "20px"
                                                }}
                                                src={calendar} />}
                                    </div>
                                </div>
                                <div
                                    className="form-outline mb-4 text-muted d-flex align-items-center justify-content-center position-relative">
                                    <input name="Password" required={true} type={show ? "text" : "password"}
                                        onChange={signUp}
                                        style={{ borderRadius: "12px" }}
                                        id="form2Example2" placeholder="Password"
                                        className="form-control pl-5  text-muted" />
                                    <div className="border-0 btn shadow-none p-0 m-0 bg-transparent my-auto"
                                        style={{ position: "absolute", right: "5%" }}>
                                        <img onClick={() => setShow(!show)} className=" my-auto" height={14} width={25}
                                            src={eye}
                                            alt="img" />
                                    </div>
                                </div>
                                <div
                                    className="form-outline mb-4 text-muted d-flex align-items-center justify-content-center position-relative">
                                    <input name="Password" required={true} type={show ? "text" : "password"}
                                        onChange={handleConfirmPassword}
                                        style={{ borderRadius: "12px" }}
                                        id="form2Example2" placeholder="Confirm Password"
                                        className="form-control pl-5  text-muted" />
                                    <div className="border-0 btn shadow-none p-0 m-0 bg-transparent my-auto"
                                        style={{ position: "absolute", right: "5%" }}>
                                        <img onClick={() => setShow(!show)} className=" my-auto" height={14} width={25}
                                            src={eye}
                                            alt="img" />
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-around px-md-4 px-1 "
                                    style={{ marginTop: "40px" }}>

                                    <div className="form-check mx-2">
                                        <input
                                            name="UserType"
                                            required={user.UserType !== "2" ? null : "true"}
                                            onChange={handleUserType}
                                            className="form-check-input "
                                            type="checkbox"
                                            disabled={user.UserType === "2" ? true : null}
                                            style={{ padding: "20px", transform: "scale(1.2)" }}
                                            value="1"
                                            id="forUser"
                                        />
                                        <label className="form-check-label text-dark"
                                            style={{ fontWeight: "400", fontSize: "16px" }} htmlFor="forUser">
                                            USER
                                        </label>
                                    </div>
                                    <div className="form-check mx-2">
                                        <input
                                            name="UserType"
                                            onChange={handleUserType}
                                            required={user.UserType === "1" ? null : "true"}
                                            className="form-check-input "
                                            type="checkbox"
                                            disabled={user.UserType === "1" ? true : null}
                                            style={{ transform: "scale(1.2)" }}
                                            value="2"
                                            id="forAgent"
                                        />
                                        <label className="form-check-label text-dark"
                                            style={{ fontWeight: "400", fontSize: "16px" }} htmlFor="forAgent">
                                            RERA AGENT
                                        </label>
                                    </div>

                                </div>
                                {userExist !== null && <div className="mt-3">
                                    <p className="text-center my-2 mb-0"
                                        style={{ fontSize: "16px", color: "#FF8282" }}> User is {userExist}</p>
                                </div>}
                                {passwordError === true && <div className="mt-3">
                                    <p className="text-center my-2 mb-0"
                                        style={{ fontSize: "16px", color: "#FF8282" }}> Password doesn't match</p>
                                </div>}
                                <button type="submit" style={{
                                    background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)",
                                    fontWeight: "400",
                                    fontSize: "18px",
                                    borderRadius: "10px"
                                }}
                                    className="btn text-white text-white text-capitalize btn-md py-2 px-5 mb-3 mt-5">Sign
                                    Up
                                </button>
                                <div
                                    className="d-flex text-center mx-auto justify-content-around align-items-center my-3"
                                    style={{ width: "150px" }}>
                                    <p className="m-0"
                                        style={{ borderTop: "3px solid rgb(155, 205, 42)", width: "30px", margin: "auto" }}></p>
                                    <p className="mb-0">Or With</p>
                                    <p className="m-0"
                                        style={{ borderTop: "3px solid rgb(155, 205, 42)", width: "30px", margin: "auto" }}></p>
                                </div>
                                <div className="d-flex text-center mx-auto justify-content-around align-items-center "
                                    style={{ width: "150px" }}>
                                    <button className="border-0 bg-transparent px-0 mx-0">
                                        <img height={30} className="p-1"
                                            style={{ borderRadius: "50%", border: "1px solid #C4C4C4" }}
                                            src={facebookImg} alt="" />
                                    </button>

                                    <button className="border-0 bg-transparent px-0 mx-0">
                                        <img height={30} className="p-1"
                                            style={{ borderRadius: "50%", border: "1px solid #C4C4C4" }} src={googleLogo}
                                            alt="" />
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="text-center mt-5">
                            <p style={{ letterSpacing: "0.16em", fontWeight: "300", fontSize: "13px" }}>
                                By clicking Sign Up, you agree to our
                                <Link to={"/termsOfUse"} target="_blank"><span className="mx-1"
                                    style={{ color: "rgb(155, 205, 42)" }}>Terms of Use</span></Link>
                                and confirm<br /> that you have read, understood, and agreed to our
                                <Link to={"/privacyPolicy"} target="_blank"> <span style={{ color: "rgb(155, 205, 42)" }}>Privacy Policy.</span></Link>.
                            </p>
                        </div>

                    </div>
                    <div className="col-md-6 m-0 p-0 logInPageBricksImg d-flex flex-column justify-content-between ">
                        <img alt="" style={{ position: "absolute", width: "100%", height: "100%", zIndex: "2" }}
                            src={bricksBackgroundImgLogin} />
                        <div className="position-relative">
                            <img className="img-fluid"
                                style={{ position: "absolute", zIndex: "1", height: "180px", width: "100%" }}
                                src={logInTopImg} alt="img" />
                            <div className=" d-flex justify-content-end mr-md-5 pr-md-2"
                                style={{ zIndex: "2", position: "relative" }}>
                                <p className="py-4 px-0 mt-md-2 my-0" style={{
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: "400",
                                    width: "280px"
                                }}>SignUp Early & Win a Free Brick
                                    (worth ₹10,000 approx)</p>
                            </div>
                        </div>
                        <div className="mt-3" style={{ position: "relative", zIndex: "2" }}>
                            <div className="text-center">
                                <p className="mx-auto text-center" style={{ color: "black" }}>Already have an
                                    account? </p>
                            </div>
                            <div className="text-center" style={{ position: "relative", zIndex: "2" }}>
                                <button onClick={() => {
                                    window.scrollTo(0, 0);
                                    history.push("/login")
                                }} className="btn text-capitalize shadow-none py-2 px-5 mx-auto text-dark"
                                    style={{
                                        border: "2px solid rgb(155, 205, 42)",
                                        borderRadius: "10px",
                                        fontWeight: "600",
                                        fontSize: "18px"
                                    }}>
                                    Log in
                                </button>
                            </div>
                        </div>
                        <div className="" style={{ bottom: "0px" }}>
                            <div className="">
                                <img alt="" style={{ width: "100%", position: "absolute", zIndex: "1", height: "150px" }}
                                    src={logInBottomImg} />
                            </div>
                            <div className='d-flex' style={{ position: "relative", zIndex: "2" }}>
                                <img src={buildingLeftImg} height={200} style={{ width: "50%" }} alt="" />
                                <img src={buildingRightImg} height={200} style={{ width: "50%" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default SignUp;
