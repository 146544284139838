import React, { useState } from "react";
import RERAImg from "../../assets/viewPropertyRERARegistered.png";
import HMDAImg from "../../assets/HMDAImg.png";
import brickLogo from "../../assets/brickLogo.png";
import LogoDark from "../../assets/LogoDark.png";
import calendar from "../../../dashboard/assets/calendar.png";
import viewPropertyLeftMan from "../../assets/viewPropertyLeftMan.png";
import viewPropertyRightImg from "../../assets/viewPropertyRightImg.png";
import gmail from "../../../../assets/img/gmailLogo.png";
import linkedIn from "../../../../assets/img/linkedIn.png";
import instagram from "../../../../assets/img/instagramLogo.png";
import whatsApp from "../../../../assets/img/whatsappLogo.png";
import signal from "../../../../assets/img/signalLogo.png";
import greatAPropertyLogo from "../../../../assets/img/greatAPropertyLogo.png";
import BestValueLogo from "../../../../assets/img/BestValueLogo.png";
import residentialPropertyLogo from "../../../../assets/img/residentialPropertyLogo.png";
import highReturnLogo from "../../../../assets/img/highReturnLogo.png";
import styles from "../../viewproperties.css";
import Link from "react-router-dom/Link";

let ViewPropertyTitleIntro = (props) => {
  const propertyListing = props.propertyDeatils || [];
  console.log("intro", propertyListing);
  let [active, setActive] = useState(1);

  return (
    <React.Fragment>
      <div
        className="container-fluid my-md-5 mt-4 mb-0"
        style={{ position: "relative" }}
      >
        <div className="row">
          <div className="col-md-3 ">
            <img
              src={RERAImg}
              alt=""
              style={{ height: "70px" }}
              className="mx-auto d-flex justify-content-center text-center"
            />
            <p className="mb-0 text-center">Plan Approval Number:</p>
            <p className="text-center mb-0 " style={{ color: "#FF8282" }}>
              {" "}
              {propertyListing.RERANumber}
            </p>
            <img
              src={HMDAImg}
              alt=""
              style={{ height: "50px", width: "250px" }}
              className="mx-auto d-flex my-2 justify-content-center text-center"
            />
            <p className="mb-0  mx-md-5 px-md-5 px-auto mx-auto ">Permit No:</p>
            <p className="text-center mb-0" style={{ color: "#FF8282" }}>
              {propertyListing.PermitNumber}
            </p>
          </div>
          <div className="col-md-3 pl-md-0 pr-md-3">
            <div
              className="p-3 h-100 mx-auto colSection">
              <p className="text-center">
                Ownership Model:{" "}
                <span style={{ color: props.color, fontWeight: "500" }}>
                  {props.model}
                </span>
              </p>
              <div className="text-center mx-auto d-flex justify-content-center ">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <img
                    src={LogoDark}
                    style={{ height: "20px" }}
                    alt=""
                    className="text-center mx-auto "
                  />
                </div>
              </div>
              <p className="text-center my-3">
                Total Purchase Cost:{" "}
                <span style={{ color: "#FF8282" }}>
                  &#8377; {propertyListing.PropertyValue}
                </span>
              </p>
              <div className="text-center mx-auto d-flex justify-content-center border-top pt-3">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    height: "35px",
                    width: "35px",
                    borderRadius: "50%",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <img
                    src={brickLogo}
                    style={{ height: "20px" }}
                    alt=""
                    className="text-center mx-auto "
                  />
                </div>
              </div>
              <p className="text-center my-3 border-bottom pb-3">
                1 BRICK ={" "}
                <span style={{ color: "#FF8282" }}>&#8377; {propertyListing.SFTValue} </span>(SFT
                Value)
              </p>
              <p className="text-center my-3">Schedule Property Visit</p>

              <div className="d-flex my-2 justify-content-center align-items-center">
                <div style={{ width: "140px" }}>
                  <div className="position-relative mx-2 datePicker d-flex">
                    <input
                      type="date"
                      style={{
                        height: "35px",
                        border: "1px solid rgba(255, 130, 130, 0.3)",
                        position: "relative",
                      }}
                      className="bg-transparent form-control py-1"
                    />
                    <img
                      alt=""
                      style={{
                        position: "absolute",
                        right: "10%",
                        top: "20%",
                        height: "20px",
                      }}
                      src={calendar}
                    />
                  </div>
                </div>
                <div style={{ width: "140px" }}>
                  <div className="position-relative mx-2 timePicker d-flex">
                    <input
                      type="time"
                      style={{
                        height: "35px",
                        border: "1px solid rgba(255, 130, 130, 0.3)",
                        position: "relative",
                      }}
                      className="bg-transparent form-control py-1"
                    />
                    {/*<img alt="" style={{position:"absolute",right:"10%",top:"20%",height:"20px"}} src={clockLogo}/>*/}
                  </div>
                </div>
              </div>
              <div className="d-flex border-top mt-4 pt-3  mb-0 justify-content-center ">
                <small className="" style={{ color: "#FF8282", width: "80px" }}>
                  Note:{" "}
                </small>
                <small className=" ">
                  {" "}
                  &nbsp;Please try to reach the property on time and our
                  Authorized Agent will be waiting for you
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-3 pr-md-0 pl-md-5 ml-2">
            <div
              className="p-3 h-100 mx-auto colSection" >
              <p
                className="text-center mb-0"
                style={{
                  fontWeight: "600",
                  fontSize: "20px",
                  color: "#FF8282",
                }}
              >
                {propertyListing.PropertyTitle}
              </p>
              <p
                className="text-center mb-0"
                style={{
                  fontWeight: "normal",
                  fontSize: "15px",
                  color: "black",
                }}
              >
                {" "}
                {propertyListing.Address}
              </p>
              <p
                className="text-center"
                style={{
                  fontWeight: "normal",
                  fontSize: "14px",
                  color: "#FF8282",
                }}
              >
                Class '{propertyListing.BuildingClass}'
              </p>

              <div className="mx-auto  d-flex flex-column justify-content-center">
                <div
                  className="py-2 mx-3 px-1"
                  style={{ border: "1px solid #FF8282" }}
                >
                  <p className="mb-0 text-center">
                    Property Analyst Rating :
                    <i
                      style={{ fontSize: "10px", marginLeft: "3px" }}
                      className="fa fa-star text-warning "
                    />
                    <i
                      style={{ fontSize: "10px", marginLeft: "3px" }}
                      className="fa fa-star text-warning "
                    />
                    <i
                      style={{ fontSize: "10px", marginLeft: "3px" }}
                      className="fa fa-star text-warning "
                    />
                    <i
                      style={{ fontSize: "10px", marginLeft: "3px" }}
                      className="fa fa-star text-warning "
                    />
                    <i
                      style={{ fontSize: "10px", marginLeft: "3px" }}
                      className="fa fa-star text-warning "
                    />
                  </p>
                  <p className="mb-0 text-center">
                    Property Valuation :{" "}
                    <span style={{ color: "#FF8282" }}>{propertyListing.belowValuation}% Below </span>
                  </p>
                </div>
              </div>
              <p
                className="text-center mt-4 mb-0"
                style={{ fontSize: "17px", fontWeight: "600" }}
              >
                Minimum Bricks to Buy :
                <span style={{ color: "#FF8282" }}> {propertyListing.MinimumBricks} </span>
              </p>
              <div className="text-center mt-1 border-bottom pb-2 mx-md-1 mx-auto">
                <Link to={`/properties/${propertyListing.PropertyID}/buy`} >
                  <div
                    className="btn px-5 py-3 text-white text-capitalize mx-auto text-center"
                    style={{
                      backgroundColor: "#642942",
                      width: "220px",
                      borderRadius: "18px",
                      fontSize: "16px",
                    }}
                  >
                    Buy Bricks
                  </div>
                </Link>
                <div
                  className="btn px-5 py-3 text-white text-capitalize"
                  style={{
                    backgroundColor: "#FF8282",
                    width: "220px",
                    borderRadius: "18px",
                    fontSize: "16px",
                  }}
                >
                  Sell Bricks <span></span>
                </div>
              </div>
              <div className="pt-3 text-center">
                <small className="text-center mt-4">
                  {" "}
                  <span style={{ color: "#FF8282" }}>2400 Bricks</span>{" "}
                  transacted in {propertyListing.PropertyTitle} over the last 30 day
                </small>
              </div>
            </div>
          </div>
          <div className="col-md-2 px-0 mx-0 ml-auto">
            <p
              className="text-dark text-center"
              style={{ fontWeight: "700", fontSize: "16px" }}
            >
              You can share via:{" "}
            </p>
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <img
                className="m-1"
                src={gmail}
                style={{ height: "28px", width: "28px" }}
                alt=""
              />
              <i
                style={{ color: "#4D6FA9" }}
                className="fab fa-2x fa-facebook-square m-1"
              />
              <img
                className="m-1"
                src={linkedIn}
                style={{ height: "30px", width: "30px" }}
                alt=""
              />
              <img
                className="m-1"
                src={instagram}
                style={{ height: "30px", width: "30px" }}
                alt=""
              />
              <img
                className="m-1"
                src={whatsApp}
                style={{ height: "32px", width: "30px" }}
                alt=""
              />
              <i
                style={{ color: "#76c4eb", backgroundColor: "white" }}
                className="fab fa-2x fa-telegram mx-1"
              />
              <img
                src={signal}
                className="m-1"
                style={{ height: "32px", width: "30px" }}
                alt=""
              />
            </div>
            <div className="my-4 d-flex flex-column justify-content-center  text-center">
              <div
                className="p-1 text-center mx-auto w-50 my-2"
                style={{
                  backgroundColor: "rgba(159, 255, 0, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <img
                  src={greatAPropertyLogo}
                  style={{ height: "25px", width: "25px" }}
                  alt=""
                />
                <p
                  className="mb-0 my-1"
                  style={{
                    color: "rgba(79, 205, 93, 1)",
                    fontWeight: 700,
                    fontSize: "13px",
                  }}
                >
                  Grate A Property
                </p>
              </div>
              <div
                className="p-1 text-center mx-auto w-50 my-2"
                style={{
                  backgroundColor: "rgba(255, 194, 0, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <img
                  src={BestValueLogo}
                  style={{ height: "25px", width: "25px" }}
                  alt=""
                />
                <p
                  className="mb-0 my-1"
                  style={{
                    color: "rgba(185, 146, 27, 1)",
                    fontWeight: 700,
                    fontSize: "13px",
                  }}
                >
                  Best Value
                </p>
              </div>
              <div
                className="p-1 text-center mx-auto w-50 my-2"
                style={{
                  backgroundColor: "rgba(255, 0, 0, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <img
                  src={props?.rightBarObject?.img}
                  style={{ height: "25px", width: "25px" }}
                  alt=""
                />
                <p
                  className="mb-0 my-1"
                  style={{
                    color: "rgba(255, 130, 130, 1)",
                    fontWeight: 700,
                    fontSize: "13px",
                  }}
                >
                  {props?.rightBarObject?.title}
                </p>
              </div>
              <div
                className="p-1 text-center mx-auto w-50 my-2"
                style={{
                  backgroundColor: "rgba(255, 0, 108, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <img
                  src={highReturnLogo}
                  style={{ height: "25px", width: "25px" }}
                  alt=""
                />
                <p
                  className="mb-0 my-1"
                  style={{
                    color: "rgba(142, 15, 69, 1)",
                    fontWeight: 700,
                    fontSize: "13px",
                  }}
                >
                  High Returns
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img
            className="d-md-block d-lg-block d-xl-block d-none"
            src={viewPropertyLeftMan}
            style={{
              height: "200px",
              position: "absolute",
              left: "15%",
              bottom: "-25%",
            }}
            alt=""
          />
        </div>
        <div>
          <img
            className="d-md-block d-lg-block d-xl-block d-none"
            src={viewPropertyRightImg}
            style={{
              height: "200px",
              position: "absolute",
              right: "12%",
              bottom: "-25%",
            }}
            alt=""
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertyTitleIntro;
