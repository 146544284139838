import React, {useEffect, useState} from "react";
import SecurePinTitle from "./securePinTitle";
import reactangleImg from "./assets/reactangleImg.png";
import blackCircleImg from "./assets/blackCircleImg.png";
import blackTriangleImg from "./assets/blackTriangleImg.png";
import dotImage from "./assets/dotImage.png";
import {Link,useLocation,useHistory} from "react-router-dom";
import NavbarPin from "./NavbarPin";
import Axios from "axios";

let ActivatePin=()=>{
    const location = useLocation();
    const history = useHistory();
    let[user,setUser]=useState({
        EmailID:'',
        PIN:'',
        ActivationCode:''
    });
    let[pinError,setPinError]=useState(null);
    useEffect(()=>{
        setUser({
            ...user,
            EmailID:location?.EmailID,
            PIN:location?.pin,
        });


    },[ ]);

    let sendPin = async (e) => {
        e.preventDefault();
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let dataURL = `${process.env.REACT_APP_API_URL}/securepin/activatepin`;
        await Axios.post(dataURL, JSON.stringify(user), config).then((response) => {
            if (response.data.Status ===1){
                console.log("pin activation success");
                history.push({
                    pathname: '/login',
                })
            }
            else  if (response.data.Status ===0){
                setPinError(response.data.Message);
                setTimeout(()=>{
                    setPinError(null)
                },4000);
                console.log(response.data.Message)
            }
        }).catch((err) => {
            console.log(err)
        });
    };

  return(
      <React.Fragment>
          <NavbarPin/>
          <div >
              <SecurePinTitle/>
              <div className=" img-fluid " >
                  <img  src={reactangleImg} style={{height:"61%",width:"60%",position:"absolute",zIndex:"1"}} alt="" className="img-fluid rectangleSecureImg"/>
              </div>
              <div className="col-md-4 mx-auto" style={{position:"relative",zIndex:"2"}}>
                  <p className="text-center my-4" style={{fontWeight:"700",fontSize:"40px"}}>Activate your PIN</p>
                  <p className="text-center my-0" style={{fontWeight:"400",fontSize:"17px"}}>Please enter your unique 4 digit pin for more secure log in</p>
                  <div className="px-md-5 px-2 mx-md-4  mx-auto my-3">
                      <p className=" text-center my-0" style={{fontWeight:"400",fontSize:"20px",color:"#FF8282"}}>Enter Activate Code</p>
                  </div>
                  <form onSubmit={sendPin}>
                  <div className="px-md-5 px-2 mx-md-4  mx-auto my-3">
                      <div className="d-flex justify-content-center my-3">
                          <div className="form-outline text-muted">
                              <input type="number" name="ActivationCode" onChange={(e)=>setUser({...user,ActivationCode:e.target.value})} style={{width:"300px", height:"50px"}} id="typePassword" placeholder="Enter PIN Activation Code" className="form-control"/>
                          </div>
                      </div>
                  </div>
                      {pinError !== null && <p className="text-center my-2" style={{color:"#FF8282"}}>{pinError}</p>}
                  <div className="text-center mt-4">
                      {/*<Link to={"/enterPin"}>*/}
                          <button type="submit" className="btn btn-md mb-2 px-5 text-capitalize text-white"
                              style={{backgroundColor:"#ff8282",fontSize:"20px",paddingBottom:"11px",paddingTop:"11px",fontWeight:"600",borderRadius:"10px"}}>
                          Activate PIN
                      </button>
                      {/*</Link>*/}
                  </div>
                  </form>
                  <p className="text-center my-2" style={{fontWeight:"400",fontSize:"17px"}}>The PIN activation code has been send to </p>
                  {/*<p className="text-center my-2" style={{fontWeight:"400",fontSize:"17px",color:"#FF8282"}}>satishpatil@gmail.com </p>*/}
                  <p className="text-center my-2" style={{fontWeight:"400",fontSize:"17px",color:"#FF8282"}}>{user?.EmailID} </p>
                  {/*<p className="text-center my-2" style={{fontWeight:"400",fontSize:"17px",color:"#FF8282"}}>{location?.state[0]?.email} </p>*/}
              </div>
              <img  src={blackCircleImg} style={{position:"absolute",height:"80px",zIndex:"1",top:"65%",left:"62%"}} alt="" className="img-fluid"/>
              <img  src={blackTriangleImg} style={{position:"absolute",zIndex:"1",height:"75px",top:"45%",left:"72%"}} alt="" className="img-fluid"/>
              <img  src={blackCircleImg} style={{position:"absolute",height:"50px",zIndex:"1",top:"78%",left:"72%"}} alt="" className="img-fluid"/>
              <img  src={dotImage} style={{position:"absolute",height:"120px",zIndex:"1",top:"58%",left:"82%"}} alt="" className="img-fluid dotImageSecure"/>

          </div>
      </React.Fragment>
  )
};
export default ActivatePin;
