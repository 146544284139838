import React from "react";

let GetInTouch=()=>{
    return(
        <React.Fragment>
            <div className="container my-5 p-5 border">
                <div className="row">
                    <div className="col-md-6">
                        <div className="px-md-5 px-2 mx-2">
                            <p className="mb-0 text-center" style={{fontWeight:"700",fontSize:"35px"}}>GET IN TOUCH </p>
                            <p className="mb-0 my-4" style={{fontWeight:"400",fontSize:"17px"}}>Were here to help if you have a specific quations ,
                                you can contact us via live chat or phone
                                monday - friday from 8:30am - 6:00pm</p>
                            <p className="mb-0 my-4" style={{fontWeight:"400",fontSize:"17px"}}>To speaack with us at a specific time,you can suchedule a call with or head of customer service</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className=" px-md-3 px-2" style={{backgroundColor:"#FF8282"}}>
                        <div className="py-4 px-md-5 px-4" >
                            <div className="text-center my-2 py-1" style={{fontWeight:"400",fontSize:"15px",backgroundColor:"#EDEDED"}}>
                                <p className="mb-0"> Live chat</p>
                                   <p className="mb-0"> (Mon-Fri,8:00am , 6:30pm)</p>
                            </div>
                            <div className="text-center my-2 py-3" style={{fontWeight:"400",fontSize:"15px",backgroundColor:"#EDEDED"}}>
                                <p className="mb-0"> Submit an enquiry</p>
                            </div>
                            <div className="text-center my-2 py-3" style={{fontWeight:"400",fontSize:"15px",backgroundColor:"#EDEDED"}}>
                                <p className="mb-0">Email us at info@brickberry.com</p>
                            </div>
                            <div className="text-center my-2 py-1" style={{fontWeight:"400",fontSize:"15px",backgroundColor:"#EDEDED"}}>
                                <p className="mb-0"> Call us on 02 8766 0566</p>
                                <p className="mb-0">(Mon-Fri,8:00am , 6:30pm)</p>
                            </div>
                            <div className="text-center my-2 py-3" style={{fontWeight:"400",fontSize:"15px",backgroundColor:"#EDEDED"}}>
                                <p className="mb-0">Schedule an introductory call </p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default GetInTouch;