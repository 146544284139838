import React from "react";
import googleWorkers from "../assets/googleWorkers.png";
import hsdbWorker from "../assets/hsdbWorker.png";
import BlueShield from "../../../assets/img/BlueShieldLogo.png";
import capitalOne from "../../../assets/img/capitalOneLogo.png";
import MicrosoftImg from "../assets/MicrosoftImg.png";
import CiscoImg from "../assets/CiscoImg.png";
import goldmanSachs from "../assets/goldmanSachs.png";
import JLLImg from "../assets/JLLImg.png";

let WhereOurUser=()=>{
    return(
        <React.Fragment>
            <div className="container-fluid my-4" style={{backgroundColor:"#ededed"}}>
                <div className="py-5">
                <p className="text-center mb-0" style={{fontWeight:"700",fontSize:"40px"}}>Where Our Users Work</p>
                <div className="d-md-block d-lg-block d-none">
                    <div className="d-flex flex-wrap px-md-5 px-1 mx-md-4 mx-2  my-4 justify-content-around align-items-center">
                            <div className="">
                                <img alt="" height={80} className="text-center ml-2" src={googleWorkers}/>
                            </div>
                            <div className="">
                                <img alt="" height={40} className="text-center" src={hsdbWorker}/>
                            </div>
                            <div className="">
                                <img alt="" height={50} className="text-center" src={BlueShield}/>
                            </div>
                            <div className="text-center ">
                                <img alt="" height={50} className="text-center " src={capitalOne}/>
                            </div>

                    </div>
                    <div className="d-flex flex-wrap px-md-5 px-1 mx-md-4 mx-2 my-4 justify-content-around align-items-center">
                        <div className="">
                            <img alt="" height={80} style={{marginLeft:"-25px"}} className="text-center" src={MicrosoftImg}/>
                        </div>
                        <div className="" style={{marginLeft:"-155px"}}>
                            <img alt="" height={30} className="text-center" src={JLLImg}/>
                        </div>
                        <div className="" style={{marginLeft:"-60px"}}>
                            <img alt="" height={30} className="text-center" src={goldmanSachs}/>
                        </div>
                        <div className="">
                            <img alt="" height={30} className="text-center" src={CiscoImg}/>
                        </div>

                    </div>
                </div>
                <div className="d-block  d-md-none">
                    <div className="d-flex flex-wrap px-md-5 px-1 mx-md-4 mx-2  my-4 justify-content-around align-items-center">
                        <div className="">
                            <img alt="" height={80} className="text-center m-2" src={googleWorkers}/>
                        </div>
                        <div className="">
                            <img alt="" height={40} className="text-center m-2" src={hsdbWorker}/>
                        </div>
                        <div className="">
                            <img alt="" height={40} className="text-center m-2" src={BlueShield}/>
                        </div>
                        <div className="">
                            <img alt="" height={40} className="text-center m-2" src={capitalOne}/>
                        </div>

                    </div>
                    <div className="d-flex flex-wrap px-md-5 px-1 mx-md-4 mx-2 my-4 justify-content-around align-items-center">
                        <div className="">
                            <img alt="" height={80} className="text-center m-2" src={MicrosoftImg}/>
                        </div>
                        <div className="" >
                            <img alt="" height={30} className="text-center m-2" src={JLLImg}/>
                        </div>
                        <div className="" >
                            <img alt="" height={30} className="text-center m-2" src={goldmanSachs}/>
                        </div>
                        <div className="">
                            <img alt="" height={30} className="text-center m-2" src={CiscoImg}/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default WhereOurUser;
