import React from "react";
import HomeImg from "../../../assets/img/homepageImg1.png";
import brickberryPuzzleLogo from "../../../assets/BbrickberryLogo.svg";

let FractionalOwnership = () => {
    return (
        <React.Fragment>
            <div className="container shadow">
                <div className=" pb-1">
                    <div className="text-center mb-0">
                        <div className="d-md-block d-none">
                            <img className="img-fluid mb-0" style={{ maxHeight: "280px", maxWidth: "800px" }} alt="img" src={HomeImg} />
                        </div>
                        <div className="d-md-none d-block">
                            <img className="img-fluid mb-0" style={{ maxHeight: "280px", maxWidth: "250px" }} alt="img" src={HomeImg} />
                        </div>
                    </div>
                    <div>
                        <div className="row text-center mt-0 mb-4 text-white">
                            <div className="col-md-4">
                                <div className="py-1 my-4" style={{ backgroundColor: "#f3655b" }}>
                                    <p className="mb-0">High Returns</p>
                                    <p className="font-small mb-0">Enjoy upto 9% income plus capital appreciation</p>
                                </div>
                                <div className="py-1 " style={{ backgroundColor: "#518fb9" }}>
                                    <p className="mb-0">Flexibility</p>
                                    <p className="font-small mb-0">Hold and sell the property share as and when you want</p>
                                </div>
                            </div>
                            <div className="col-md-4 mt-md-0 mt-4 d-flex justify-content-center align-items-center">
                                <img className=" houseImg" style={{ maxHeight: "200px", maxWidth: "330px", position: "relative", top: "-20px" }} alt="house" src={brickberryPuzzleLogo} />
                            </div>
                            <div className="col-md-4">
                                <div className="py-1 my-4" style={{ backgroundColor: "#f7a873" }}>
                                    <p className="mb-0">Diversed portfolio</p>
                                    <p className="font-small mb-0">Invest about 10 lakh in commercial,office space etc</p>
                                </div>
                                <div className="py-1 my-4" style={{ backgroundColor: "#6fcbd9" }}>
                                    <p className="mb-0">Easy to manage</p>
                                    <p className="font-small mb-0">Hasssle free rental collection and tenant management</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default FractionalOwnership;
