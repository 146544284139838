import React, { useState } from "react";
import BuildingLogo from "../../../properties/assets/propertiesBuildingLogo.png";
import riskAnalysis from "../../assets/riskAnalysis.png";
import capitalReturn from "../../assets/capitalReturn.png";
import investmentAnalysis from "../../assets/investmentAnalysis.png";
import pricingDetails from "../../assets/pricingDetails.png";
import propertyDetailsImg from "../../assets/propertyDetailsImg.png";
import viewPropertyLeftMan from "../../assets/viewPropertyLeftMan.png";
import viewPropertyRightImg from "../../assets/viewPropertyRightImg.png";
import floorPainDiagram from "../../assets/floorPainDiagram.png";
import ImageSlider from "../ImageSlider";

let ViewPropertyTitle = (props) => {
  let [active, setActive] = useState(1);
  return (
    <React.Fragment>
      <div className="div">
      <ImageSlider img={props.img} property={props?.property}/>
      </div>
      <div
        className="container bg-white  text-center mx-auto "
        style={{
          borderRadius: "30px",
          marginTop: "-30px",
          position: "relative",
          zIndex: "4",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="row mr-4 justify-content-around align-items-center text-center px-2 py-3">
          <div className="col-md-2   px-md-4 pl-2 d-flex ">
            <div
              className="pr-4 d-flex border-right"
              style={{ cursor: "pointer" }}
              onClick={() => setActive(1)}
            >
              <div className="my-md-auto mr-2 my-1">
                <img height={20} src={BuildingLogo} alt="BuildingLogo" />
              </div>
              <div className="d-block">
                <div>
                  <p
                    className="my-auto"
                    style={
                      active === 1
                        ? { fontSize: "16px", color: "#ff9797" }
                        : {
                            fontSize: "16px",
                            color: "black",
                          }
                    }
                  >
                    Summary
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-2 px-0 d-flex my-md-auto my-1">
            <a href="#propertyDetails">
              <div
                className="pr-md-2 pl-2 d-flex border-right"
                style={{ cursor: "pointer" }}
                onClick={() => setActive(2)}
              >
                <div className="my-auto mr-md-4 mr-2">
                  <img
                    height={20}
                    src={propertyDetailsImg}
                    alt="BuildingLogo"
                  />
                </div>
                <div className="d-block">
                  <div>
                    <p
                      className="my-auto"
                      style={
                        active === 2
                          ? { fontSize: "16px", color: "#ff9797" }
                          : {
                              fontSize: "16px",
                              color: "black",
                            }
                      }
                    >
                      {" "}
                      Property Details
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-2 px-0 d-flex my-md-auto my-1">
            <a href={props?.commercial ? "#monthlyDistributions":"#investmentAnalysis"}>
            
              <div
                className="pr-md-2 pl-md-0 pl-2 d-flex border-right"
                style={{ cursor: "pointer" }}
                onClick={() => setActive(3)}
              >
                <div className="my-auto mr-2">
                  <img
                    height={20}
                    src={investmentAnalysis}
                    alt="BuildingLogo"
                  />
                </div>
                <div className="d-block">
                  <div style={{marginTop:"3px"}}>
                    <p
                      className="my-auto mt-1"
                      style={
                        active === 3
                          ? { fontSize: "15px", color: "#ff9797" }
                          : {
                              fontSize: "15px",
                              color: "black",
                            }
                      }
                    >
                      {props?.commercial
                        ? "Monthly Distribution"
                        : "Investment Analysis"}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-2 pl-2 d-flex my-md-auto my-1">
            <a href="#capitalReturn">
              <div
                className="pr-4 d-flex border-right"
                style={{ cursor: "pointer" }}
                onClick={() => setActive(4)}
              >
                <div className="my-auto mr-2">
                  <img height={20} src={capitalReturn} alt="BuildingLogo" />
                </div>
                <div className="d-block">
                  <div>
                    <p
                      className="my-auto"
                      style={
                        active === 4
                          ? { fontSize: "16px", color: "#ff9797" }
                          : {
                              fontSize: "16px",
                              color: "black",
                            }
                      }
                    >
                      Capital returns
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-2 px-0 d-flex my-md-auto my-1">
            {" "}
            <a href={props?.commercial ? "#tenant":"#riskAnalysis"}>
              <div
                className="pr-md-4 pl-2 d-flex border-right"
                style={{ cursor: "pointer" }}
                onClick={() => setActive(5)}
              >
                <div className="my-auto mr-2">
                  <img height={20} src={riskAnalysis} alt="BuildingLogo" />
                </div>
                <div className="d-block">
                  <div>
                    <p
                      className="my-auto"
                      style={
                        active === 5
                          ? { fontSize: "16px", color: "#ff9797" }
                          : {
                              fontSize: "16px",
                              color: "black",
                            }
                      }
                    >
                      {props?.commercial ? "Tenant" : "Risk Analysis"}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="col-md-2 px-0 d-flex my-md-auto my-1">
            <a href="#pricingDetails">
              <div
                className=" d-flex px-md-auto pl-2 "
                style={{ cursor: "pointer" }}
                onClick={() => setActive(6)}
              >
                <div className="my-auto mr-2">
                  <img height={20} src={pricingDetails} alt="BuildingLogo" />
                </div>
                <div className="d-block">
                  <div>
                    <p
                      className="my-auto"
                      style={
                        active === 6
                          ? { fontSize: "16px", color: "#ff9797" }
                          : {
                              fontSize: "16px",
                              color: "black",
                            }
                      }
                    >
                      Pricing Details
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertyTitle;
