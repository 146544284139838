import React, { useEffect, useState } from "react";
import ProgressLine from "./ProgressLine";
import pinkCircleImg from "../../../resources/assets/pinkCircleImg.png";
import FAQ from "../../../resources/frequentlyAskedQuestion/FAQ";
import dropdownArrow from "../../../dashboard/assets/dropdownArrow.png";

let ViewPropertySecondPotentialReturn = () => {
  let [open, setOpen] = useState(true);
  return (
    <React.Fragment>
      <div className="container  my-4">
        {/*<div className="px-md-5 mx-md-5 px-2 mx-2">*/}
        <div className="">
          <p
            className="text-center"
            style={{ fontSize: "35px", fontWeight: "700" }}
          >
            Understand Potential Returns
          </p>
          <div>
            <div className=" my-3 p-4" style={{ border: "1px solid #FF8282" }}>
              <div className=" py-1 px-md-4 px-2">
                <div className="d-flex my-auto py-2 align-items-center justify-content-center px-4">
                  <div className="d-md-flex mx-auto d-block justify-content-center">
                    <div className=" selectBoxArrow mx-md-4 mx-auto">
                      <div
                        className="d-flex align-items-center"
                        style={{ position: "relative", width: "200px" }}
                      >
                        <select
                          style={{
                            height: "35px",
                            border: "1px solid #FF8282",
                            position: "relative",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                          className="bg-transparent form-control px-4 py-1"
                        >
                          <option selected disabled>
                            Investments
                          </option>
                          <option value="1">Option 1</option>
                          <option value="2">Option 2</option>
                        </select>
                        <img
                          alt=""
                          src={dropdownArrow}
                          style={{
                            position: "absolute",
                            right: "10%",
                            width: "15px",
                            height: "8px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center mx-md-4 mx-auto">
                      <div
                        style={{
                          height: "35px",
                          border: "1px solid #FF8282",
                          width: "200px",
                          fontSize: "17px",
                        }}
                        className="shadow-none rounded my-0 py-0 text-capitalize align-items-center  text-dark btn px-4 py-1"
                      >
                        Bricks
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center mx-md-4"
                    style={{ position: "relative" }}
                  >
                    {open !== true ? (
                      <React.Fragment>
                        <img
                          alt=""
                          height={30}
                          style={{ position: "absolute", cursor: "pointer" }}
                          onClick={() => {
                            setOpen(true);
                          }}
                          src={pinkCircleImg}
                        />
                        <p
                          className="mb-0 text-white"
                          onClick={() => {
                            setOpen(FAQ.id);
                          }}
                          style={{ position: "relative", cursor: "pointer" }}
                        >
                          +
                        </p>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <img
                          alt=""
                          height={30}
                          style={{ position: "absolute", cursor: "pointer" }}
                          onClick={() => {
                            setOpen(false);
                          }}
                          src={pinkCircleImg}
                        />
                        <p
                          className="mb-0 text-white"
                          onClick={() => {
                            setOpen(null);
                          }}
                          style={{ position: "relative", cursor: "pointer" }}
                        >
                          -
                        </p>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
              {open === true && (
                <div className="">
                  <div className="mx-md-5 my-4 px-md-3">
                    <ProgressLine
                      visualParts={[
                        {
                          percentage: "70%",
                          color: "#FF8282",
                        },
                        {
                          percentage: "30%",
                          color: "#FF9E9E",
                        },
                        {
                          percentage: "10%",
                          color: "#FFE2DB",
                        },
                      ]}
                    />
                  </div>
                  <div className="d-md-flex mt-5 mb-3 d-block justify-content-around">
                    <div className=" selectBoxArrow mx-md-0 mx-auto">
                      <div
                        className="d-flex align-items-center"
                        style={{ position: "relative", width: "200px" }}
                      >
                        <select
                          style={{
                            height: "35px",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                          className=" form-control  border-0  px-2 py-1"
                        >
                          <option
                            value="monthly"
                            className="my-2  bg-white"
                            selected
                          >
                            Monthly Cash Flow
                          </option>
                          <option value="yearly" className="my-2 bg-white">
                            Yearly Cash Flow
                          </option>
                        </select>
                        <img
                          alt=""
                          src={dropdownArrow}
                          style={{
                            position: "absolute",
                            right: "10%",
                            width: "15px",
                            height: "8px",
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <p
                        className="mb-0"
                        style={{ fontSize: "17px", fontWeight: "500" }}
                      >
                        Monthly Rent{" "}
                        <span className="mx-2" style={{ color: "#FF8282" }}>
                          ₹16,959
                        </span>
                      </p>
                    </div>
                    <div>
                      <p
                        className="mb-0"
                        style={{ fontSize: "17px", fontWeight: "500" }}
                      >
                        Management Fee{" "}
                        <span className="mx-2" style={{ color: "#FF8282" }}>
                          ₹2,083
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="mb-0">
                        {" "}
                        <span
                          className="mx-2"
                          style={{
                            color: "#FF8282",
                            fontSize: "17px",
                            fontWeight: "500",
                          }}
                        >
                          10%{" "}
                        </span>{" "}
                        TDS
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="" style={{ overflowX: "auto" }}>
            <table className="table  my-5 table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">
                    <div
                      className="px-3 text-center text-white py-1"
                      style={{ backgroundColor: "#FF8282" }}
                    >
                      <small className="mb-0">DAY 0</small>
                    </div>
                  </th>
                  <th scope="col">
                    <div
                      className="px-3 text-center text-white py-1"
                      style={{ backgroundColor: "#FF8282" }}
                    >
                      <small className="mb-0">YEAR 1</small>
                    </div>
                  </th>
                  <th scope="col">
                    <div
                      className="px-3 text-center text-white py-1"
                      style={{ backgroundColor: "#FF8282" }}
                    >
                      <small className="mb-0">YEAR 2</small>
                    </div>
                  </th>
                  <th scope="col">
                    <div
                      className="px-3 text-center text-white py-1"
                      style={{ backgroundColor: "#FF8282" }}
                    >
                      <small className="mb-0">YEAR 3</small>
                    </div>
                  </th>
                  <th scope="col">
                    <div
                      className="px-3 text-center text-white py-1"
                      style={{ backgroundColor: "#FF8282" }}
                    >
                      <small className="mb-0">YEAR 4</small>
                    </div>
                  </th>
                  <th scope="col">
                    <div
                      className="px-3 text-center text-white py-1"
                      style={{ backgroundColor: "#FF8282" }}
                    >
                      <small className="mb-0">YEAR 5</small>
                    </div>
                  </th>
                  <th scope="col" className="text-center ">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Total Investment
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center px-md-4 px-1"
                  >
                    ( ₹ 25,00,000)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹25,00,000
                  </td>
                </tr>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Rental Income
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹1,92,340
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹2,17,566
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹2,17,566
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹2,50,201
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹2,50,201
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹17,8,677
                  </td>
                </tr>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Prebooking offer
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center px-md-4 px-1"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹25,00,000
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹25,000
                  </td>
                </tr>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Interest on deposit
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center px-md-4 px-1"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹8,176
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹8,176
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹8,176
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹8,176
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹8,176
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹65,408
                  </td>
                </tr>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Management fee
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center px-md-4 px-1"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹25,00,000
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹25,000)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹25,000)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹25,000)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹25,000)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹2,00,000
                  </td>
                </tr>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Estimated Property Tax
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center  px-md-4 px-1"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹5,024
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹5,024)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹5,024)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹5,024)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    ( ₹5,024)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹40,192
                  </td>
                </tr>
                <tr className="py-1">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Sale Value
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center px-md-4 px-1"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    -
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹36,93,639
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹36,93,639
                  </td>
                </tr>
                <tr className="py-1 bg-light">
                  <th
                    scope="row py-1"
                    style={{ padding: "12px" }}
                    className="text-left"
                  >
                    Total{" "}
                  </th>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small text-center px-md-4 px-1"
                  >
                    ( ₹25,00,000)
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    1,92,340
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹1,95,718
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹1,95,718
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹2,28,353
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹39,21,992
                  </td>
                  <td
                    style={{ padding: "12px" }}
                    className="font-small  px-md-4 px-1 text-center"
                  >
                    {" "}
                    ₹28,25,532
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertySecondPotentialReturn;
