import React, { useEffect, useRef, useState } from "react";
import pinLogo from "../assets/pinLogo.png";
import bellLogo from "../assets/bellDashboardImg.png";
import chatIcon from "../assets/chatIcon.png";
import profilePic from "../assets/profilePicNotification.png";
import { Link } from "react-router-dom";

let BellNotification = () => {
    const notificationRef = useRef();
    let [showNotification, setShowNotification] = useState(false);
    let [mount, setMount] = useState(false);

    useEffect(() => {
        // add when mounted
        setMount(true);
        document.addEventListener("mousedown", handleClickOutside);
        // return function to be called when unmounted
        return () => {
            setMount(false);
            document.removeEventListener('click', handleClickOutside)
        };
    }, []);
    const handleClickOutside = e => {
        if (!notificationRef?.current?.contains(e.target)) {
            // inside click
            // setShowMenu(!showMenu);
            if (setMount) {
                setShowNotification(false);
            }
        }

    };


    // useEffect(() => {
    //     // add when mounted
    //     document.addEventListener("mousedown", handleClickOutside);
    //     // return function to be called when unmounted
    //     return () => {
    //         setShowNotification(false)
    //     };
    // }, []);
    // const handleClickOutside = e => {
    //     if (notificationRef?.current?.contains(e.target)) {
    //         // inside click
    //         // setShowNotification(!showNotification);
    //     }
    //     setShowNotification(false);
    // };
    return (
        <React.Fragment>

            <div ref={notificationRef}>
                <div className="d-flex justify-content-center align-items-center pt-0"
                    onClick={() => setShowNotification(!showNotification)}
                    style={{ cursor: "pointer" }}
                >
                    <img height={35} src={bellLogo} style={{}} alt="" />
                    <p className="text-white" style={{ marginLeft: "-12px", fontSize: "10px" }}>2</p>
                </div>
                {showNotification && <div ref={notificationRef} className="w-25 bg-white pinSectionStyles my-4 mx-auto border-top-0"
                    style={{
                        borderRadius: "10px",
                        border: "1px solid #ffcabc",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        width: "350px", minWidth: "250px", maxWidth: "350px",
                        position: "absolute", zIndex: "5", right: "10%"
                    }}>
                    <div className="d-flex mx-auto px-2 py-1 align-items-center" style={{ backgroundColor: "rgb(238, 255, 199)", borderRadius: "10px" }}>
                        <div className="text-center w-100">
                            <p className="mb-0 text-center">Notifications</p>
                        </div>
                        <div className=" ml-auto">
                            <img src={chatIcon} height={22} className="rounded-circle mx-2" alt="" />
                        </div>
                    </div>
                    <div className="bg-white">
                        <div>
                            <div className="d-flex m-md-4 m-2 align-items-center p-md-3 p-2" style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div>
                                    <img src={profilePic} style={{ height: "40px", borderRadius: "50%" }} className=" mr-2" alt="" />
                                </div>
                                <div className="" style={{ position: "relative" }}>
                                    <p className="mb-0 font-small ml-2" style={{ fontSize: "12px" }}>Kate Young</p>
                                    <p className="mb-0 mt-1 font-small ml-2 mr-md-4" style={{ fontSize: "12px" }}>Great Shot Adam! Really enjoying
                                        the compostion on this piece.</p>
                                    <div className="text-white shadow rounded-circle" style={{ position: "absolute", top: "2%", right: "0%" }}>
                                        <span style={{
                                            height: "50", width: "50", borderRadius: "50%", backgroundColor: "#ff8282",
                                            paddingTop: "4px", paddingBottom: "4px", paddingLeft: "6px", paddingRight: "6px", fontSize: "11px"
                                        }}>N</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex m-md-4 m-2 align-items-center p-md-3 p-2" style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div>
                                    <img src={profilePic} style={{ height: "40px", borderRadius: "50%" }} className=" mr-2" alt="" />
                                </div>
                                <div className="" style={{ position: "relative" }}>
                                    <p className="mb-0 font-small ml-2" style={{ fontSize: "12px" }}>Kate Young</p>
                                    <p className="mb-0 mt-1 font-small ml-2 mr-md-4" style={{ fontSize: "12px" }}>Great Shot Adam! Really enjoying
                                        the compostion on this piece.</p>
                                    <div className="text-white shadow rounded-circle" style={{ position: "absolute", top: "2%", right: "0%" }}>
                                        <span style={{
                                            height: "50", width: "50", borderRadius: "50%", backgroundColor: "#ff8282",
                                            paddingTop: "4px", paddingBottom: "4px", paddingLeft: "6px", paddingRight: "6px", fontSize: "11px"
                                        }}>N</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex m-md-4 m-2 align-items-center p-md-3 p-2" style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                                <div>
                                    <img src={profilePic} style={{ height: "40px", borderRadius: "50%" }} className=" mr-2" alt="" />
                                </div>
                                <div className="" style={{ position: "relative" }}>
                                    <p className="mb-0 font-small ml-2" style={{ fontSize: "12px" }}>Kate Young</p>
                                    <p className="mb-0 mt-1 font-small ml-2 mr-md-4" style={{ fontSize: "12px" }}>Great Shot Adam! Really enjoying
                                        the compostion on this piece.</p>
                                    <div className="text-white shadow rounded-circle" style={{ position: "absolute", top: "2%", right: "0%" }}>
                                        <span style={{
                                            height: "50", width: "50", borderRadius: "50%", backgroundColor: "#ff8282",
                                            paddingTop: "4px", paddingBottom: "4px", paddingLeft: "6px", paddingRight: "6px", fontSize: "11px"
                                        }}>N</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>}

            </div>
        </React.Fragment>
    )
};
export default BellNotification;
