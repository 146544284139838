import React, { useEffect } from "react";
import Navbar from "../Navbar";
// import resourcesBGImg from "../resources/assets/resourcesBGImg.png";
// import partnerImgLeft from "../resources/assets/partnerImgLeftNew.png";
import contactUsCircleImg from "../resources/assets/contactUsCircleImg.png";
import agentPortalImg from "./assets/agentPortalImg.png";
import agentPortalLeftImage from "./assets/agentPortalLeftImage.png";
import galleryLogo from "./assets/gallaryLogo.png";
import videosLogo from "./assets/videosLogo.png";
// import styles from "./partner.css";
import Buildings from "../homepage/homepageComponents/Buildings";
import Footer from "../Footer";
import AgentPortalForms from "./AgentPortalForms";

let AgentPortal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <Navbar activeDropdown={"partnerWithUs"} />
      <div className="container-fluid  img-fluid text-center m-0 p-0">
        <div className="card">
          <img
            alt="img"
            className="img-fluid "
            style={{ minHeight: "200px", maxHeight: "250px" }}
            src={agentPortalImg}
          />
          <p
            style={{ fontWeight: "800", fontSize: "40" }}
            className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto"
          >
            Agent Portal
          </p>
        </div>
      </div>
      <div className="container-fluid my-4">
        <div className="mx-md-5 mx-0 px-md-4 px-1">
          <p
            className="text-center"
            style={{ fontWeight: "700", fontSize: "35px" }}
          >
            {" "}
            Help yourself & post your Property
          </p>
          <p className="" style={{ fontWeight: "400", fontSize: "20px" }}>
            We are always looking to bring investment grade properties to the
            BrickBerry platform. Do you have a high potential property that
            meets our investment criteria? Want to offer one on the platform?
          </p>
          <p style={{ fontWeight: "400", fontSize: "20px" }}>
            Let's do this together on BrickBerry platform for greater benefit.
          </p>
        </div>
      </div>
      <div className="container-fluid px-md-5 px-0 mb-5">
        <div className="row mx-0 px-0">
          <div className="col-md-6 ml-0 pl-0 text-center my-auto">
            <img
              src={agentPortalLeftImage}
              style={{ height: "370px", width: "500px" }}
              alt=""
              className="img-fluid mx-auto my-auto"
            />
          </div>
          <div className="col-md-6 p-md-5 p-2">
            <div className="mt-0">
              <div className="text-center bg-white d-flex justify-content-center">
                <p
                  className="text-center bg-white py-2 px-4"
                  style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "20px",
                    position: "relative",
                    zIndex: "5",
                    color: "#FF8282",
                    fontWeight: "700",
                    fontSize: "20px",
                    width: "300px",
                  }}
                >
                  Quicker Sale
                </p>
              </div>
              <div
                className="py-2 px-4 "
                style={{
                  backgroundColor: "#ffba98",
                  borderRadius: "20px",
                  marginTop: "-30px",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <p className="mt-3 mb-0 p-2">
                  Our investors are always looking for great properties. If you
                  have one, demand for your property is already there on the
                  platform.
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="text-center bg-white d-flex justify-content-center">
                <p
                  className="text-center bg-white py-2 px-4"
                  style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "20px",
                    position: "relative",
                    zIndex: "5",
                    color: "#FF8282",
                    fontWeight: "700",
                    fontSize: "20px",
                    width: "300px",
                  }}
                >
                  Fully Transparent
                </p>
              </div>
              <div
                className="py-2 px-4 "
                style={{
                  backgroundColor: "#ffe2db",
                  borderRadius: "20px",
                  marginTop: "-30px",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <p className="mt-3 mb-0 p-2">
                  No more dealing with local brokers. Entire transaction is
                  transparent on our Platform.{" "}
                </p>
              </div>
            </div>
            <div className="mt-4">
              <div className="text-center bg-white d-flex justify-content-center">
                <p
                  className="text-center bg-white py-2 px-4"
                  style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "20px",
                    position: "relative",
                    zIndex: "5",
                    color: "#FF8282",
                    fontWeight: "700",
                    fontSize: "20px",
                    width: "300px",
                  }}
                >
                  Fractional Sale
                </p>
              </div>
              <div
                className="py-2 px-4 "
                style={{
                  backgroundColor: "#b4cce9",
                  borderRadius: "20px",
                  marginTop: "-30px",
                  position: "relative",
                  zIndex: "1",
                }}
              >
                <p className="mt-3 mb-0 p-2">
                  Don't want to sell the entire property? You can still keep a
                  fraction of your property and sell only what you want to sell.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AgentPortalForms />

      <Buildings />
      <Footer />
    </React.Fragment>
  );
};
export default AgentPortal;
