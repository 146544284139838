import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import parse from 'html-react-parser';
import moment from 'moment';
import propertyAnnouncementImg from "../assets/propertyAnnouncementImg.png";

let PropertyAnnouncement = (props) => {
    let [blogs, setBlogs] = useState(null);
    let [blog, setBlog] = useState(null);

    useEffect(() => {
        // fetchBlogs()
        setBlog(props?.blog);
    }, []);
    return (
        <React.Fragment>
            <div className="container-fluid px-md-5 px-0 my-5" >
                {/*<div className="row px-md-5 mx-auto px-0  w-md-50 w-100" style={{marginTop:"50px"}}>*/}
                {/*    <div className="col-md-6 m-0 p-0">*/}
                {/*        <img className="img-fluid " style={{height:"500px",width:"100%"}} src={propertyAnnouncementImg} alt=""/>*/}
                {/*    </div>*/}
                {/*    <div className="col-md-6  m-0 px-md-5 p-md-5"*/}
                {/*         style={{borderRight:"1px solid  #FF8282",borderBottom:"1px solid  #FF8282",borderTop:"1px solid  #FF8282"}}>*/}
                {/*        <p className="h4 px-md-3 px-1 mb-3" style={{color:"#FF8282",fontWeight:"700",fontSize:"20px"}}>PROPERTY, ANNOUNCEMENTS</p>*/}
                {/*        <p className="h2 px-md-3 px-1 mb-3" style={{fontWeight:"400",fontSize:"25px"}}>Valuations – June 2020</p>*/}
                {/*        <div className="px-md-3 px-1"><p>Brickberry properties are independently revalued every six months. The valuations for January 1,*/}
                {/*            2020 – June 30, 2020, are in. See our previous valuations here</p>*/}
                {/*        <p>Quick Snapshot</p>*/}
                {/*        <p>It’s been an unprecedented time for the Australian property market during Covid-19 and the associated lockdown with*/}
                {/*            reduced supply supporting values, noting the Government and Finance sector also supporting the...</p>*/}
                {/*        </div>*/}
                {/*        <div className="mt-5 px-md-3 px-1">*/}
                {/*          <div className="border-bottom my-1">*/}
                {/*        <p className="text-left " style={{color:"#784CFB"}}>Read more...</p>*/}
                {/*          </div>*/}
                {/*    <small className="text-left  mb-0 ">By BrickBerry - 4 Nov 2020</small>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {blog?.Description && <div style={{ position: "relative" }}>
                    <div className="bg-white px-md-5 px-3 my-4" style={{ paddingTop: "30px", paddingBottom: "30px", position: "relative" }} key={blog?.BlogID}>

                        <React.Fragment>
                            <div className="card shadow-none">
                                <div className="row p-0 m-0 align-items-center">
                                    <div className="col-md-6 text-center my-auto p-0 m-0 " >
                                        <div className="h-100 my-auto ">
                                            <img src={blog?.PreviewImageURL}
                                                style={{ maxHeight: "370px", width: "fit-content", height: "fit-content" }} alt=""
                                                className="img-fluid my-auto blogListImg" />
                                        </div>
                                    </div>
                                    <div className="col-md-6  m-0 px-0 " >
                                        <div className=" p-5 h-100" style={{ border: "1px solid rgb(22, 142, 186)" }}>
                                            <p className="h4 px-md-3 px-1 mb-3" style={{ color: "rgb(22, 142, 186)", fontWeight: "700", fontSize: "20px", textTransform: "uppercase" }}>{blog?.Title}</p>
                                            <p className="h2 px-md-3 px-1 mb-3" style={{ fontWeight: "400", fontSize: "25px" }}>{blog?.Category}</p>
                                            <div className="px-md-3 px-1" style={{ height: "200px", overflow: "hidden" }}>
                                                {/*{mdParser.render(blog.Description)}*/}
                                                {/*<div>{parse(blog?.Description.toString(),{ trim: true })}</div>*/}
                                                {/*{console.log(blog?.Description)}*/}
                                                {/*{parse(blog?.Description,{ trim: true })}*/}
                                                {parse(blog?.Description.toString(), { trim: true })}
                                            </div>
                                            <div className="mt-2 px-md-3 px-1">
                                                <div className="border-bottom my-1">
                                                    <Link to={{
                                                        pathname: `/resources/newsAndArticle/${blog?.BlogID}`,
                                                        state: blog
                                                    }}
                                                    // to={`/admin/blogs/${blog.BlogID}}`}
                                                    >
                                                        <p className="text-left " style={{ color: "#784CFB" }}>Read more...</p></Link>
                                                </div>
                                                <small className="text-left  mb-0 ">By {blog?.Author} - {moment(blog?.CreatedAt).format('LL')}</small>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>}

            </div>
        </React.Fragment>
    )
};
export default PropertyAnnouncement;
