import React from "react";
import pinkCircle from "../assets/pinkCircle.png";
import pinkTriangle from "../assets/pinkTriangle.png";
import blackCircle from "../assets/blackCircle.png";
import blackDotsGroupImg from "../assets/blackDotsGroupImg.png";
let WhatAboutFee = () => {
    return (
        <React.Fragment>
            <div style={{ position: "relative" }}>
                <div className="container my-5" style={{ position: "relative", zIndex: "2" }} >
                    <p className="h2 text-center mb-5" style={{ fontWeight: "700" }}>What about the Fee? </p>
                    <div className="d-md-flex d-block justify-content-center align-items-center pt-5 m-5 ">
                        <div className="py-5 px-3 mr-md-5 mx-auto bg-white" style={{
                            MozBoxShadow: "0px 0px 8px rgb(100,100,100)",
                            boxShadow: "0px 2px 2px rgb(200,200,200)", borderRadius: "20px"
                        }}>
                            <div className="border-bottom m-3">
                                <p className="h3 font-weight-bolder" style={{ color: "rgb(22, 142, 186)" }}>0.5%</p>
                                <p className="h5 font-weight-normal" style={{ color: "rgb(22, 142, 186)" }}>Service Fee</p>
                                <p className="h6 font-weight-normal">when Bricks are Bought (or) Sold..</p>
                            </div>
                        </div>
                        <div className="col-md-4 ml-md-5 mx-auto my-md-1 my-2">
                            <p className="h font-weight-normal mb-3">We charge a 0.5% service fee for each time you Buy, Sell or make a Transaction on any of your property. We don’t believe in hidden fees.</p>

                        </div>
                        <div className="d-md-none d-sm-block">
                            <hr />
                        </div>
                    </div>
                    <div className="d-md-flex d-block justify-content-center align-items-center m-5">
                        <div className="col-md-4 mr-md-5 mx-auto">
                            <p className="h font-weight-normal mb-3">You’ll pay a low and fair 0.9% annual fee as a simple and transparent fee for Property managers (they will handle all maintenance work and tenant management) . Don’t worry – we’ve got you covered. This fee will be deducted from your rent, which means it does not include any extra payments.</p>
                        </div>
                        <div className="py-5 px-3 ml-md-4 mx-auto" style={{
                            MozBoxShadow: "0px 0px 8px rgb(100,100,100)",
                            boxShadow: "0px 2px 2px rgb(200,200,200)", borderRadius: "20px"
                        }}>
                            <div className="border-bottom m-3">
                                <p className="h3 font-weight-bolder" style={{ color: "rgb(22, 142, 186)" }}>0.9%</p>
                                <p className="h5 font-weight-normal" style={{ color: "rgb(22, 142, 186)" }}>Annual Management<br /> Fee</p>
                                <p className="h6 font-weight-normal">shared with Property Managers & <br />Maintenance </p>
                            </div>
                        </div>
                        <div className="d-md-none d-sm-block">
                            <hr />
                        </div>
                    </div>
                    <div className="d-md-flex d-block justify-content-center align-items-center m-5">
                        <div className="py-5 px-3 mr-md-5 mx-auto" style={{
                            MozBoxShadow: "0px 0px 8px rgb(100,100,100)",
                            boxShadow: "0px 2px 2px rgb(200,200,200)", borderRadius: "20px"
                        }}>
                            <div className="border-bottom m-3">
                                <p className="h3 font-weight-bolder" style={{ color: "rgb(22, 142, 186)" }}>15%</p>
                                <p className="h5 font-weight-normal" style={{ color: "rgb(22, 142, 186)" }}>Brick Profit Fee </p>
                                <p className="h6 font-weight-normal"> over Hurdle rate (On Capital Gain)</p>
                            </div>
                        </div>
                        <div className="col-md-4 ml-md-5 mx-auto my-md-1 my-2">
                            <p className="h6 font-weight-normal mb-3">
                                We’ll waive our performance fees if you don’t achieve 15% ROI in the estimated time frame. When you do, we’ll charge a minimum of 15% on the profit you made additionally.</p>
                        </div>
                        <div className="d-md-none d-sm-block">
                            <hr />
                        </div>
                    </div>
                    <div className="d-md-flex d-block justify-content-center align-items-center m-5">
                        <div className="col-md-4 mr-md-5 mx-auto">
                            <p className="h font-weight-normal mb-3">In very few cases, We may charge a one-time set-up fee of up to 2% of the investment amount which will go towards our third-party property consultants. </p>
                        </div>
                        <div className="py-5 px-3 ml-md-4 mx-auto" style={{
                            MozBoxShadow: "0px 0px 8px rgb(100,100,100)",
                            boxShadow: "0px 2px 2px rgb(200,200,200)", borderRadius: "20px"
                        }}>
                            <div className="border-bottom m-3">
                                <p className="h3 font-weight-bolder" style={{ color: "rgb(22, 142, 186)" }}>2%</p>
                                <p className="h5 font-weight-normal" style={{ color: "rgb(22, 142, 186)" }}>3rd Party Fee</p>
                                <p className="h6 font-weight-normal">Sourcing Fee (on few cases) </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-md-block d-none">
                    <img src={blackDotsGroupImg} height={170} width={300} style={{ position: "absolute", top: "5%", left: "80px", overflow: "hidden", zIndex: "1" }} alt="" />
                </div>
                <img src={blackCircle} height={60} style={{ position: "absolute", top: "2%", right: "0%", overflow: "hidden", zIndex: "1" }} alt="" />
                <img src={pinkCircle} height={140} style={{ position: "absolute", top: "4%", right: "8%", overflow: "hidden", zIndex: "1" }} alt="" />
                {/* <img src={pinkTriangle} height={80} style={{position:"absolute",top:"24%",left:"260px",overflow:"hidden",zIndex:"1"}} alt=""/> */}
            </div>
        </React.Fragment>
    )
};
export default WhatAboutFee;
