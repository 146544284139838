import React, { useEffect } from "react";
import Navbar from "../Navbar";
import resourcesBGImg from "./assets/resourcesBGImg.png";
import partnerImgLeft from "./assets/partnerImgLeftNew.png";
import contactUsCircleImg from "./assets/contactUsCircleImg.png";
import contactUsImg from "../../assets/img/contactUsImage.png";
// import styles from "partner.css";
import Buildings from "../homepage/homepageComponents/Buildings";
import Footer from "../Footer";

let ContactWithUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <React.Fragment>
            <Navbar active={"contactUs"} />
            <div className="container my-md-5  mt-0 mb-2 pb-4">
                <div className="row px-4 pt-4 pb-3 " style={{ border: "1px solid #ffe2db", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                    <div className="col-md-6 h-100 d-flex flex-column ">
                        <div className="d-flex flex-wrap justify-content-center mt-5">
                            <p className="text-center mb-1" style={{ fontWeight: "700", fontSize: "35px" }}>Contact US</p>
                            <img className="text-center my-4" height={250}
                                style={{ width: "90%", margin: "auto", position: "relative", top: "-10%", zIndex: "1" }}
                                src={contactUsCircleImg} alt="" />
                            <img className="text-center" height={220}
                                style={{ width: "auto", height: "70%", margin: "auto", position: "absolute", zIndex: "2", top: "40%" }}
                                src={contactUsImg} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6 px-md-5 px-2 pt-2 pt-md-2 pt-5 mt-md-0 mt-4">
                        <form className="mx-md-4 mx-1">
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example1">Are You a Brickberry Investor</label>
                                <div className="d-block selectBox">
                                    <select className="w-100 py-2 partnerContactSelect" placeholder=" " style={{ border: "2px solid rgb(155, 205, 42)" }} name="" >
                                        <option value="" className="disabled"></option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-outline mb-1" >
                                <label className="form-label mx-2" htmlFor="form5Example2">Email Address</label>
                                <input type="email" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1"  >
                                <label className="form-label mx-2" htmlFor="form5Example2">Subject Line</label>
                                <input type="text" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example1">Select A Category</label>
                                <div className="d-block ">
                                    <select className="w-100 py-2 partnerContactSelect" placeholder=" " style={{ border: "2px solid rgb(155, 205, 42)" }} name="" >
                                        <option value="" className="disabled"></option>
                                        <option value="Option1">Option1</option>
                                        <option value="Option2">Option1</option>
                                        <option value="Option3">Option3</option>
                                        <option value="Option4">Option4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-outline mb-1"  >
                                <label className="form-label " htmlFor="form5Example2">Describe Your enquiry in as much details as Possible</label>
                                <textarea className="form-control rounded-0 mt-1" style={{ border: "2px solid rgb(155, 205, 42)" }} id="exampleFormControlTextarea1"
                                    rows="4" />
                            </div>
                            <input type="file" className="partnerFileIInput" />
                            <button type="submit" className="btn px-5 py-2 text-capitalize mr-0 float-md-right float-none text-white  mb-0"
                                style={{ background: "linear-gradient(264.04deg, rgb(155, 205, 42) -5.78%, rgb(22, 142, 186) 101.58%)", borderRadius: "10px", fontWeight: "700", fontSize: "15px" }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <Buildings />
            <Footer />
        </React.Fragment>
    )
};
export default ContactWithUs;
