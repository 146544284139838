import React, {useEffect} from "react";
import Navbar from "../Navbar";
import TermsOfUseImg from "./assets/termsOfUseBGImg.png";
import propertyBGImg from "../resources/assets/propertyBGImg.png";
import propertyImgLogo from "../resources/assets/propertyImgLogo.png";
import byuingBGImg from "../resources/assets/byuingBGImg.png";
import buyingImgLogo from "../resources/assets/buyingimgLogo.png";
import feesImg from "../resources/assets/feesImg.png";
import feesImgLogo from "../resources/assets/feesImgLogo.png";
import pinkCircleImg from "../resources/assets/pinkCircleImg.png";
import Buildings from "../homepage/homepageComponents/Buildings";
import Footer from "../Footer";
import {Link} from "react-router-dom";

const restrictionsOnUse = [
    {
        id: 1, data: "license, sell, rent, lease, transfer, assign, distribute, host, or otherwise" +
            "commercially exploit the website, without express written consent of the" +
            "company;"
    },
    {
        id: 2, data: "access the website in order to build a similar or competitive service;"
    },
    {
        id: 3, data: "copy, reproduce, distribute, republish, download, displayed, poste or transmit in" +
            "any form or by any means, any part of the website, for any commercial purpose or" +
            "otherwise, without express written consent of the company;"
    },
    {
        id: 4, data: "use the Covered Platforms in any way that causes, or may cause, damage to the" +
            "website, its Users or impair the availability or accessibility of the website or in any" +
            "way which is unlawful, illegal, fraudulent or harmful, or in connection with any" +
            "unlawful, illegal, fraudulent or harmful purpose or activity;"
    },
    {
        id: 5, data: "abuse, harass, impersonate, intimidate or threaten other Users;"
    },
    {
        id: 6, data: "post or transmit, or cause to be posted or transmitted, any content that is" +
            "harmful, infringing, libelous, defamatory, abusive, offensive, obscene," +
            "pornographic, pedophilic, invasive of another's privacy, hateful, or racially," +
            "ethnically objectionable, disparaging, relating or encouraging money laundering" +
            "or otherwise violates any Applicable Law or right of any third party; or that" +
            "threatens the unity, integrity, defense, security or sovereignty of India, friendly" +
            "relations with foreign states, or public order or causes incitement to the" +
            "commission of any cognizable offence or prevents investigation of any offence" +
            "or is insulting any other nations;"
    },
    {
        id: 7, data: "use the website for any unauthorized purpose, or in violation of any Applicable Law" +
            "or these Terms of Use;"
    },
    {
        id: 8,
        data: "post or transmit, or cause to be posted or transmitted, any communication or solicitation designed or intended to obtain password, access to another User’s account, or private information from any User"
    },
    {
        id: 9, data: "create or send unwanted email (spam) to any other User;"
    },
    {
        id: 10, data: "impersonate any person or entity, or falsely state or otherwise misrepresent your" +
            "affiliation with a person or entity;"
    },
    {
        id: 11, data: "infringe upon the Intellectual Property Rights of the company, other Users, or" +
            "any third party, while accessing or using the website;"
    },
    {
        id: 12, data: "submit comments linking to affiliate programs, multi-level marketing schemes," +
            "sites repurposing existing stories or off-topic content;"
    },
    {
        id: 13, data: "post, email, transmit, upload, or otherwise make available any material that" +
            "contains software viruses or any other computer code, Trojan horse or other" +
            "code with malicious, disruptive and/or destructive features, files or programs" +
            "designed or functioning to interrupt, destroy, or limit the functionality of any" +
            "computer software or hardware or telecommunications equipment, including" +
            "the website;"
    },
    {
        id: 14, data: "use any robot, spider, scraper, sniping software or other automated means to" +
            "access the website for any purpose;"
    },
    {
        id: 15, data: "conduct any systematic or automated data collection activities on or in relation" +
            "to the website without express written consent of the company;"
    },
    {
        id: 16, data: "use the website or any part of it to transmit or send unsolicited commercial" +
            "communications, or for any other purposes related to marketing, without" +
            "express written consent of the company;"
    },
    {
        id: 17, data: "take any action that imposes, or may impose, in our sole discretion, an" +
            "unreasonable or disproportionately large load/strain on our infrastructure;"
    },
    {
        id: 18, data: "sell or otherwise transfer your User Account/ profile, or allow any third party to use" +
            "or access your User Account; or"
    },
    {
        id: 19, data: "without our prior written consent, reverse engineer, frame, modify, copy," +
            "distribute, transmit, display, perform, reproduce, publish, license, create" +
            "derivative works from, transfer, or sell any information, software obtained from" +
            "or part of the website."
    },
];

let TermsOfUse = ({acceptTerms}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <React.Fragment>
            {!acceptTerms &&
            <React.Fragment>
                <Navbar/>
                <div className="container-fluid  img-fluid text-center m-0 p-0">
                    <div className="card">
                        <img alt="img" className='img-fluid ' style={{minHeight: "200px", maxHeight: "250px"}}
                             src={TermsOfUseImg}/>
                        <p style={{fontWeight: "800", fontSize: "40"}}
                           className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                            Terms Of Use</p>
                    </div>
                </div>
            </React.Fragment>}
            <div className={acceptTerms ? "container mt-2 mb-5":"container mt-5 mb-5"} style={{position: "relative"}}>
                <div className="">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>Terms of
                        Use</p>
                    <p className="text-center mx-auto mb-2 mt-0" style={{fontWeight: "400", fontSize: "18px"}}>UPDATED
                        March 2021</p>
                    <p className="mt-5" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>This document is an
                        electronic record in terms of Information Technology Act, 2000 and
                        rules there under as applicable and the amended provisions pertaining to electronic records
                        in various statutes as amended by the Information Technology Act, 2000. This electronic
                        record is generated by a computer system and does not require any physical or digital
                        signatures.
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>This document is published
                        in accordance with the provisions of Rule 3(1) of the Information
                        Technology (Intermediaries guidelines) Rules, 2011 that require publishing the rules and
                        regulations, privacy policy and Terms of Use for access or usage of the Covered Platforms
                        (as defined herein).
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Introduction</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>The covered platform(s)
                        i.e. <Link to={"/"} style={{textDecoration: "underline"}}>www.brick-berry.com</Link> is an
                        internet based portal that offers
                        Real-estate management, Electronic funds transfer, Arranging finance for construction
                        projects, Brokerage, rental and leasing of real estate, Rent collection services and any such
                        other related services, from time to time basis
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        These Terms and Conditions of Use (this” Agreement”) apply to all the products and services
                        offered on the www.brick-berry.com (herein referred as the <span
                        className="font-weight-bold">“website”</span> or “platform”),
                        owned and operated by <span className="font-weight-bold">M/s. MerkleHash Innovations Private Limited</span> (herein
                        referred as
                        the <span className="font-weight-bold">“company”</span>) located at Plot no 32 & 38, Kalyan
                        Nagar Ph-1, Central Bank road,
                        Hyderabad, PIN-500038, Telangana State, India; a web based platform(s), but not limited to,
                        including mobile-optimized versions of the website, digital applications and any other such
                        media formats for the purposes of use.
                    </p>
                    <p className="my-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        (Hereinafter the company and/or its offerings, referred to as “we”, “it”, “this”) are subject to
                        the
                        following terms and conditions (hereinafter the “TERMS OF SERVICE”), all parts and subparts of
                        which are specifically incorporated by reference herein. Following are the terms of
                        service governing your use of marketcentral.in all pages on the website and any services
                        provided by or on the website
                    </p>
                    <p className="my-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        By accessing either directly or through a hyperlink, the website and/or purchasing
                        something from us, you engage in our service(s) by agreeing to be bound by the Terms of
                        Service including those additional terms and conditions and policies referenced herein
                        and/or available by hyperlink. These Terms of service apply to all users of the website,
                        including without limitation vendors, buyers, customers, merchants, browsers and/or
                        contributors of any content herein.
                    </p>
                </div>
                <div className="my-1">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>Assent And
                        Acceptance</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>By accessing the website
                        and/or registering as a User with it and/or submitting any material
                        to the website or using any of the Services, you expressly agree to accept these Terms of
                        Use in full. Accordingly, if you disagree with these Terms of Use or any part of these Terms
                        of Use, you may not access or otherwise use the website or its services</p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Age Restriction </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>By accessing
                        the website and/or registering as a User with it and/or submitting any material
                        to the website or using any of the Services, you represent and warrant that you are an
                        individual of legal age or a HUF or a company or a trust or an LLP eligible to form a binding
                        contract as per the Applicable Laws of your domicile (or if not, you’ve received your
                        parent’s or guardian’s or governing body’s permission to use the Covered Platforms and
                        such approving authority has agreed to these Terms of Use on your behalf).</p>
                </div>
                <div className="mb-4 mt-5">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        About The Site</p>
                    <p className="text-center mx-auto mb-0 mt-2 " style={{fontWeight: "900", fontSize: "40px"}}>
                        License To Use The Website</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        Subject to your compliance with the herein Terms and Conditions of Use and payment of
                        applicable fees, if any, we grant you a limited license to access and make personal use of
                        this website, but not to download (other than page caching) or modify it, or any portion of
                        it, except with express written consent of the company and/or its affiliates, as may be
                        applicable. This license does not include any resale or commercial use of this website or its
                        contents; any collection and use of any product listings, descriptions, or prices; any
                        derivative use of this website or its contents; any downloading or copying of account
                        information for the benefit of another seller; or any use of data mining, robots, or similar
                        data gathering and extraction tools.
                    </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        This website or any portion of this website (including but not limited to any copyrighted
                        material, trademarks, or other proprietary information) may not be reproduced, duplicated,
                        copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose
                        without express written consent of the company and/or its affiliates, as may be applicable.</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        You may not frame or use framing techniques to enclose any trademark, logo, or other
                        proprietary and/or copyright information (including images, text, page layout, or form) of
                        the company and its affiliates without explicit written consent of the company. You may not
                        use any Meta tags or any other "hidden text" utilizing their names or trademarks, without
                        the explicit written consent of the company, as applicable. Any unauthorized use terminates
                        the permission or license granted by the company and/or its affiliates, as may be
                        applicable.</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        You are granted a limited, revocable and non-exclusive right to create a hyperlink to the
                        Welcome page as long as the link does not portray the company and their affiliates, or their
                        products or services in a false, misleading, derogatory, or otherwise offensive matter. You
                        may not use any logo or other proprietary graphic or trademark as part of the link without
                        express written consent of the company or its affiliates, as may be applicable.</p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Intellectual Property</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        All content included on the website, such as text, graphics, logos, button icons, images,
                        audio clips, digital downloads, data compilations, and software, is the property of the
                        company and its affiliates or its content suppliers and is protected by India and international
                        copyright, authors' rights, database right laws or any such other laws applicable in this
                        regard. The compilation of all content on this website is the exclusive property of the company
                        and its affiliates, and is protected by laws of India, international copyright, database right
                        laws
                        and any such other laws applicable. All software used on this website is the property of the
                        company and its affiliates or its software suppliers and is protected by India, international
                        copyright, author' rights laws and any such other laws applicable. All marks indicated on
                        the website are trademarks or registered trademarks of the company.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Restrictions on Use</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        By accessing the website and/or registering as a User with it and/or submitting any
                        material to the website or using any of the Services herein, you shall not:
                    </p>
                    {restrictionsOnUse.map((data => {
                        return (
                            <div key={data.id} className="mt-2 mx-md-5 mx-4 d-flex"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <p className="mb-0">{data.id}.</p>
                                <p className="ml-2 mb-0"> {data.data}</p>
                            </div>
                        )
                    }))}

                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Payment And Fees</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        While availing any of the payment method(s) available on the Website, we will not be
                        responsible or assume any liability, whatsoever in respect of any loss or damage arising
                        directly or indirectly to you due to:
                    </p>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0">Lack of authorization for any transaction(s), </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0">Exceeding the present limit mutually agreed by You and between
                            "Bank(s)", </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">3.</p>
                        <p className="ml-2 mb-0">Any payment issues arising out of the transaction, </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">4.</p>
                        <p className="ml-2 mb-0">Decline of transaction for any other reason(s). </p>
                    </div>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        All payments made against the purchases/services on Website by you shall be compulsorily
                        in Indian Rupees acceptable in the Republic of India. Website will not facilitate transaction
                        with respect to any other form of currency with respect to the purchases made on Website.
                        Before shipping and/or delivering your order to you, the website may request you to provide
                        supporting documents (including but not limited to Govt. issued ID and address proof(s)) to
                        establish the ownership of the payment instrument used by you for your purchase. This is
                        done in the interest of providing a safe online shopping environment to its users.
                    </p>
                    <p className="mt-2 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        Further:
                    </p>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0"> Transaction(s), Transaction Price and all commercial terms such as
                            Delivery,
                            Dispatch of products and/or services are as per principal to principal bipartite
                            contractual obligations between the website and its user(s); and payment facility
                            is merely used by the website and its user(s) to facilitate the completion of the
                            Transaction. Use of the payment facility, which is merely a transaction facility
                            provided by the website shall not render the company liable or responsible for
                            the non-delivery, non-receipt, non-payment, damage, breach of representations
                            and warranties, non-provision of after sales or warranty services or fraud as regards
                            the products and/or services listed on its Website.
                        </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0"> You have specifically authorized the company or its service
                            provider(s) to collect,
                            process, facilitate and remit payments and/or the Transaction Price electronically
                            or through Cash on Delivery to and from other Users in respect of transactions
                            through Payment Facility. Your relationship with the company is on a principal to
                            principal basis and by accepting these Terms of Use, you agree that the
                            company is an independent contractor for all purposes, and does not have control
                            of or liability for the products or services that are listed on the Website, that are
                            paid for by using the Payment Facility. The company does not guarantee the
                            identity of any User nor does it ensure that a website or a user will complete a
                            transaction. The Company and its Website is a mere common platform that
                            provides a facility to meet the company via the website and users online and
                            exchange their transaction. </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">3.</p>
                        <p className="ml-2 mb-0"> You understand, accept and agree that the payment facility provided by
                            the
                            company is neither a banking nor financial service but is merely a facilitator
                            providing an electronic, automated online electronic payment, receiving
                            payment only for its services through collection and remittance facility for the
                            Transactions on the Website using the existing authorized banking infrastructure
                            and Credit Card payment gateway networks. Further, by providing Payment
                            Facility, the company is neither acting as trustees nor acting in a fiduciary
                            capacity with respect to the Transaction or the Transaction Price</p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Minimum Booking Amount & Refund Policy</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        To participate in any open, active and offered investment option(s)/proposal(s) placed by the
                        Company on its website and to join the queue or hotlist for the same, any eligible, interested
                        and intended User(s) shall comply, agree, satisfy and exercise their own interest, at-least by
                        paying minimum 10% of the total quoted price for such proposal, within the given time
                        frame therein, provided the User(s) shall arrange balance 90% of the total quoted price,
                        before the date of property (defined or referred in the proposal) registration, as and when
                        duly informed by the company. And in the event of non-payment of such balance 90% with
                        the given time frame, then the User(s) shall be entitled for refund of their payment(s) till
                        then
                        to the company, with deduction of 2% (calculated at the total quoted proposal price) towards
                        the Platform Fee, out of all the total payments made by the User(s) to the company till then.
                    </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        And in case if the proposed property registration not happened and effected within the given
                        time frame due without any default by the User(s), then the User(s) shall be entitled for
                        complete payment(s) made by him/her/them (User(s)) to the company till then.
                    </p>

                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Communication</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        When you visit the website, you are availing the services rendered on the website and your
                        communication is either with the website/user, as the case may be, and is in done
                        electronically. For the purposes of identification, the website may require to have your
                        phone number and email id as a part of the registration process. We may communicate with
                        you by e-mail, SMS, Mobile app notifications, phone call, through social media or by
                        posting notices on the website or by any other mode of communication. For contractual
                        purposes, you consent to receive communications (including transactional, promotional
                        and/or commercial messages), from us with respect to your use of the website and/or your
                        activities on the website. However, the transactions being performed on our website will be
                        between you as a service-provider (website)/user and user/service-provider being the other
                        party. Our communications with you are with the objective of educating, informing, and
                        updating about our online business to make you aware and enable the use of the platform
                        to your advantage. If you need to give us notice of anything, including infringement of your
                        rights, or any other non- compliance with these Terms of Use, or you have any questions on
                        these Terms of Use, please write to us at <a
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=legal@brick-berry.com"
                        target="_blank">legal@brick-berry.com</a>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Privacy Information</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        We view protection of your privacy as a very important principle. We understand clearly
                        that You and Your {"{Personal & Business}"} Information is one of our most important assets.
                        We store and process Your Information including any sensitive financial information
                        collected (as defined under the Information Technology Act, 2000), if any, on computers
                        that may be protected by physical as well as reasonable technological security measures
                        and procedures in accordance with Information Technology Act 2000 and Rules there under.
                    </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        Please review our Privacy Policy incorporated herein by/for reference, which also governs
                        your visit to the website to understand our practices. The personal information/data
                        provided to us by users during the course of usage of the website will be treated as strictly
                        confidential and in accordance with the Privacy Policy and applicable laws and regulations. If
                        User(s) object to users’ information being transferred or used, please do not use the website
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Indemnification</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        You shall indemnify and hold harmless the company any/all of its representatives, licensee,
                        affiliates, subsidiaries, group companies (as applicable) and their respective officers,
                        directors, agents, and employees, from any claim or demand, or actions including
                        reasonable attorneys' fees, made by any third party or penalty imposed due to or arising
                        out of Your breach of this Terms of Use, privacy Policy and other Policies, or Your violation of
                        any law, rules or regulations or the rights (including infringement of intellectual property
                        rights) of a third party.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Spam Policy</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        The Company prohibits the use of language that is racist, hateful, sexual or obscene in nature
                        in
                        a public area. This policy extends to text within listings, on the website pages and all other
                        areas of the site that another User may view. If the profane words are part of a title for the
                        item being sold, we allow website to 'blur' out the bulk of the offending word with asterisks
                        (i.e., s*** or f***).
                    </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>Please report any
                        violations of this policy to the correct area for review:</p>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0">Report offensive Display Names </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0">Report offensive language in a listing or otherwise </p>
                    </div>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        If a feedback comment; or any communication made between Users on the Website; or
                        email, communication between Users in relation to transactions conducted on Website
                        contain profanity, please review Our feedback removal policy and submit a request for
                        action/removal.
                    </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        Disciplinary action may result in the indefinite suspension of a User's account, temporary
                        suspension, or a formal warning. We will consider the circumstances of an alleged policy
                        violation and the user's trading records before taking action.
                    </p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>Violations of this policy
                        may result in a range of actions, including:</p>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0">Limits placed on account privileges;</p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0">Loss of special status; </p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">3.</p>
                        <p className="ml-2 mb-0">Account suspension.</p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Service Interruptions</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        We will not be held responsible for any delay or failure to comply with our obligations
                        under these conditions if the delay or failure arises from any cause which is beyond our
                        reasonable control. This condition does not affect your statutory rights.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Term And Termination And Suspension</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>These Terms shall be
                        effective from the date of their publication on the Platform.</p>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0">The company reserves the right to suspend or terminate its Services in
                            the event
                            of breach of any terms contained in these Terms, misrepresentation of
                            information, any unlawful activity by you or if the company is unable to verify or
                            authenticate any information submitted by you.</p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0">You may terminate the Agreement at any time by discontinuing the use of
                            the
                            Platforms or Services.</p>
                    </div>
                    <div className="mt-2 mx-md-5 mx-4 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">3.</p>
                        <p className="ml-2 mb-0">By terminating the usage or the Agreement you will be obligated to pay
                            for a
                            Product purchased on the Platforms, or any other obligation which has accrued, or
                            is unfulfilled and relates to the period, prior to termination.</p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        User Restrictions</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        You may use the Platform for reasonable and lawful purposes only, and shall not indulge in
                        any activity that is unethical, unlawful or harmful to the Platform, the company’s business
                        activities, its intellectual property, or it’s content. You are provided with a limited license
                        to
                        access and use the Platform (website), solely for the purpose of availing the Services, subject
                        to these Terms.
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Use Of Cookies And Analytical Tools</p>
                    <div className="mt-4  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0">We use data collection devices such as “cookies” on certain pages of
                            the Covered
                            Platforms to manage our users’ sessions, store preferences, serve ads, track
                            information, help analyze our web page flow, measure promotional effectiveness,
                            and promote trust and safety.</p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0">The types of information cookies collect may include personal
                            information such
                            as your name, your IP address, contact details, personal preferences, and details
                            of your use of the Covered Platforms.</p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">3.</p>
                        <p className="ml-2 mb-0">You have the ability to either accept or decline the use of cookies on
                            your
                            computer / device, whether you are registered with us or not. Typically, you can
                            configure your browser to not accept cookies. However, declining the use of cookies
                            may limit your access to certain features of the Covered Platforms.</p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">4.</p>
                        <p className="ml-2 mb-0">In order to avail Services from us, we may share your personal
                            information to
                            third parties and/or regulatory bodies, subject to Applicable Law. We may also
                            allow third-party advertising companies and marketing services companies to
                            serve ads and other content when you visit the Covered Platforms and
                            elsewhere on the internet and in other media. These third-party service
                            providers may place third-party cookies and use the information about your
                            visits, buying behavior, browsing history, preferences, to this Covered Platforms
                            and elsewhere to serve ads and other content to you. We cannot control or access
                            cookies used by third-party service providers.</p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">5.</p>
                        <p className="ml-2 mb-0">In addition, we may provide your personal information to marketing
                            services
                            companies on an anonymous and aggregate basis to allow them to market their
                            products or services, or for other marketing purposes. This may be information
                            we received from you offline and online.</p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Limitations, Disclaimers And Exclusions Of Liability</p>
                    <div className="mt-4  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">1.</p>
                        <p className="ml-2 mb-0">All information, content, materials, products (including software) and
                            Services
                            included on or otherwise made available to you on or through the Covered
                            Platforms are provided on an “as is” without any implied or express
                            representations or warranties, and other terms which might otherwise be
                            implied by statute, common law or the law of equity including, but not limited
                            to, implied warranties of merchantability, suitability and fitness for a particular
                            purpose.</p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">2.</p>
                        <p className="ml-2 mb-0">The website does not warrant that the information, content, materials,
                            products
                            (including software) or other Services included on or otherwise made available to
                            you on or through the Covered Platforms (whether by the website, its affiliates,
                            suppliers, content providers and advertisers), website’s servers or electronic
                            communications sent by website are free of viruses or other harmful components</p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">3.</p>
                        <p className="ml-2 mb-0">The website shall not be liable for any damages whatsoever, including
                            but without
                            limitation to any direct, indirect, special, consequential, punitive or incidental
                            damages, or damages for business losses, loss of contracts or business
                            relationships, profits, data, database, software or other intangibles, damage to
                            goodwill or reputation, or the cost of procurement of substitute goods and
                            services, arising out of or related to the use, inability to use, performance or
                            failure of the Covered Platforms and any materials posted thereon, products
                            (including software) or other Services or otherwise made available to you on or
                            through the Covered Platforms, irrespective of whether such damages were
                            foreseeable or arise in contract, tort, equity, restitution, by statute, at common
                            law or otherwise</p>
                    </div>
                    <div className="mt-2 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">4.</p>
                        <p className="ml-2 mb-0">Without prejudice to the generality of the foregoing paragraph, the
                            website
                            does not warrant or represent:</p>
                    </div>
                    <div className="mx-md-5 mx-3 px-md-2 px-0">
                        <div className="mt-2 mx-md-5 mx-4 d-flex"
                             style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">a.</p>
                            <p className="ml-2 mb-0">the completeness or accuracy of the information published on
                                the Covered
                                Platforms;</p>
                        </div>
                        <div className="mt-2 mx-md-5 mx-4 d-flex"
                             style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">b.</p>
                            <p className="ml-2 mb-0">that the material on the Covered Platforms is up to date;</p>
                        </div>
                        <div className="mt-2 mx-md-5 mx-4 d-flex"
                             style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">c.</p>
                            <p className="ml-2 mb-0">that the Covered Platforms or any Service on the Covered
                                Platforms will
                                remain available.</p>
                        </div>
                    </div>
                    <div className="mt-2 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">5.</p>
                        <p className="ml-2 mb-0">You understand and acknowledge that an investment in real estate assets
                            is
                            speculative in nature and involves a high degree of risk, including the risk of loss
                            of capital. An investment in real estate asset is suitable only for sophisticated
                            investors who fully understand and are capable of bearing the risks of an
                            investment in real estate assets. An investor should only invest in real estate
                            assets as part of an overall investment strategy, and only if the investor is able to
                            withstand a total loss of its investment. No guarantee or representation is made
                            by us that you will achieve your investment objectives or will receive a return of
                            your capital. </p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">6.</p>
                        <p className="ml-2 mb-0">You confirm and acknowledge that by registering on the Covered
                            Platform, you
                            authorize and consent to website sending promotional and marketing content to
                            you through emails, phone calls, short message service or other electronic and
                            non-electronic mode of communication, and no such communication shall be
                            deemed to be in violation of law relating to unsolicited commercial
                            communication. </p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">7.</p>
                        <p className="ml-2 mb-0">You accept that we have an interest in limiting the personal liability
                            of our
                            officers and employees to the extent permitted by law and, having regard to
                            that interest, you agree that you will not bring any claim personally against our
                            officers or employees in respect of any losses you suffer in connection with the
                            Covered Platforms or these Terms of Use. </p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">8.</p>
                        <p className="ml-2 mb-0">The laws of certain jurisdictions do not allow limitations on implied
                            warranties or
                            the exclusion or limitation of certain damages. If these laws apply to you, some
                            or all of the above disclaimers, exclusions, or limitations may not apply to you,
                            and you might have additional rights. </p>
                    </div>
                    <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">9.</p>
                        <p className="ml-2 mb-0">We make no representation or warranty regarding the Covered
                            Platform:</p>
                    </div>
                    <div className="mx-md-5 mx-3 px-md-2 px-0">
                        <div className="mt-2 mx-md-5 mx-4 d-flex"
                             style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">a.</p>
                            <p className="ml-2 mb-0">meeting your requirements</p>
                        </div>
                        <div className="mt-2 mx-md-5 mx-4 d-flex"
                             style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">b.</p>
                            <p className="ml-2 mb-0">being accurate or reliable;</p>
                        </div>
                        <div className="mt-2 mx-md-5 mx-4 d-flex"
                             style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">c.</p>
                            <p className="ml-2 mb-0">being accessible in an uninterrupted, timely, secure or error-free
                                manner</p>
                        </div>
                    </div>
                    <div className="mt-2 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">10.</p>
                        <p className="ml-2 mb-0">Access to and use of the Covered Platforms by you is contingent on your
                            internet connection and we shall not be held liable for any loss that occurs due
                            to breaks in your internet accessibility. </p>
                    </div>
                    <div className="mt-2 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">11.</p>
                        <p className="ml-2 mb-0">In the event that the Covered Platforms or any portion thereof is not
                            accessible
                            due to technical problems or for any other reason, Users are not entitled to
                            claim any loss or damage from us, and we are not obligated to compensate any
                            such User </p>
                    </div>
                    <div className="mt-2 d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mb-0">12.</p>
                        <p className="ml-2 mb-0">If you are dissatisfied or harmed by the Covered Platforms or anything
                            related to
                            it, your sole remedy shall be to terminate these Terms of Use by closing your
                            account on the Covered Platforms.</p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Jurisdiction, Venue And Governing Law</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        These terms and conditions are governed by and construed in accordance with the laws of
                        India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at
                        Hyderabad, Telangana state, India only
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Changes To Terms And Conditions</p>
                    <p className="mt-4 " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        The Company reserves the right, at its sole discretion, to change, modify, add or remove any
                        portion of the Terms of Use or the Privacy Policy, in whole or in part, at any time. If we make
                        any substantial changes to these Terms of Use or the Privacy Policy, we may notify you by
                        posting notice of the changes on the website. In such cases, changes will be effective upon
                        expiry of seven (3) calendar days following our posting of notice of the changes on the
                        website unless expressed explicitly otherwise. However, these changes will be effective
                        immediately for new users of our website. In the event a User has any reservation or
                        objections to any change in the Terms of Use or the Privacy Policy, such User may exercise its
                        right to stop or terminate his/her/their relationship with the website and its ancillary
                        services. Your continued use of the website and/or the services made available on or
                        through the website after any changes to the Terms of Use or the Privacy Policy are posted
                        will be considered acceptance of such changes.
                    </p>
                </div>
            </div>
            {!acceptTerms &&
            <React.Fragment>
                <Buildings/>
                <Footer/></React.Fragment>}
        </React.Fragment>
    )
};
export default TermsOfUse;
