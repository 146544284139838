import React, { useState } from "react";
import facebookImg from "./assets/facebookLogo.png";
import linkedInLogpo from "./assets/linkedInLogpo.png";
import googleLogo from "./assets/googleLogo.png";
import bricksBackgroundImgLogin from "./assets/bricksBackgroundImgLogin.png";
import logInBottomImg from "./assets/logInBottomImg.png";
import logInTopImg from "./assets/logInTopImg.png";
import buildingLeftImg from "./assets/buildingLeftImgEdit.png";
import buildingRightImg from "./assets/buildingRightImgEdit.png";
import eye from "./assets/GroupeyeImg.png";
import rightImg from "./assets/rightLogo.png";
import { Link } from "react-router-dom";
import loginStyles from "./login.css";
import NavbarPin from "../securePin/NavbarPin";
import { useHistory } from 'react-router-dom';
import Axios from "axios";
let LogIn = () => {
    const history = useHistory();
    let [show, setShow] = useState(false);
    let [user, setUser] = useState({
        EmailID: '',
        Password: '',
    });
    let [loginError, setLoginError] = useState(null);
    let signIn = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    };
    let sendUser = async (e) => {
        e.preventDefault();
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let dataURL = `${process.env.REACT_APP_API_URL}/userlogin/login_with_email`;
        await Axios.post(dataURL, JSON.stringify(user), config).then((response) => {
            console.log("user logged in");
            if (response.data.Status === 1) {
                history.push({
                    pathname: '/enterPin',
                    userId: response.data.UserID,
                })
            }
            else if (response.data.Status === 0) {
                setLoginError(response.data.Message);
                setTimeout(() => {
                    setLoginError(null)
                }, 4000);
                console.log(response.data.Message);
            }
        }).catch((err) => {
            console.log(err)
        });
    };
    return (
        <React.Fragment>
            <NavbarPin />
            <div className="container-fluid position-relative">
                <div className="row ">
                    <div className="col-md-6 d-flex flex-column justify-content-around  px-md-5 pt-md-5 pb-0 px-0 m-0 text-center" >

                        <div className="mt-2 pt-5">
                            <p className="h1 mb-0 text-center my-2" style={{ fontWeight: "700", fontSize: "35px", color: "black" }}>
                                Sign in to Account
                            </p>
                            <p className=""
                                style={{ borderTop: "4px solid rgb(155, 205, 42)", width: "50px", margin: "auto" }}>
                            </p>
                        </div>
                        <div className="p-md-5 loginForm p-2 mt-md-4">
                            <form className="px-md-4  px-1 mt-2" onSubmit={sendUser}>
                                <div className="form-outline mb-4  ">
                                    <input type="email" required={true} onChange={signIn} name="EmailID" style={{ borderRadius: "12px" }} id="form2Example1" placeholder="Email"
                                        className="form-control pl-5 text-muted" />
                                </div>
                                <div className="form-outline mb-4 text-muted d-flex align-items-center justify-content-center position-relative">
                                    <input type={show ? "text" : "password"}
                                        name="Password" required={true} onChange={signIn}
                                        style={{ borderRadius: "12px" }} id="form2Example2" placeholder="Password"
                                        className="form-control pl-5  text-muted" />
                                    <div className="border-0 btn shadow-none p-0 m-0 bg-transparent my-auto" style={{ position: "absolute", right: "5%" }}>
                                        <img onClick={() => setShow(!show)} className=" my-auto" height={14} width={25} src={eye}
                                            alt="img" />
                                    </div>
                                </div>
                                <div className="row mb-4 justify-content-between">
                                    <div className="col d-flex justify-content-start">
                                        <div className="form-check ml-2">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="form2Example3"
                                            />
                                            <label className="form-check-label text-dark" style={{ fontWeight: "400", fontSize: "16px" }} htmlFor="form2Example3"> Remember
                                                me </label>
                                        </div>
                                    </div>

                                    <div className="col d-flex justify-content-end">
                                        <Link to={"/forgotPassword"}>
                                            <p className="text-right mr-2 mb-0 text-dark" style={{ fontWeight: "400", fontSize: "16px" }}>Forgot password?</p></Link>
                                    </div>
                                </div>
                                {loginError === "Invalid crediantials" && <p className="text-center my-2" style={{ color: "#FF8282" }}>{loginError}</p>}
                                {loginError === "User not verified" && <p className="text-center my-2 d-block" style={{ color: "#FF8282" }}>User not found. <span className="d-block">Please Sign Up first</span></p>}
                                <button type="submit" style={{ background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)", fontWeight: "400", fontSize: "18px", borderRadius: "10px" }}
                                    className="btn text-white text-capitalize btn-md py-2 px-5 mb-3">Log in</button>

                                <div className="d-flex text-center mx-auto justify-content-around align-items-center" style={{ width: "150px" }}>
                                    <p className="m-0" style={{ borderTop: "3px solid rgb(155, 205, 42)", width: "30px", margin: "auto" }}></p>
                                    <p className="mb-0">Or With</p>
                                    <p className="m-0" style={{ borderTop: "3px solid rgb(155, 205, 42)", width: "30px", margin: "auto" }}></p>
                                </div>
                                <div className="d-flex text-center mx-auto justify-content-around align-items-center my-3" style={{ width: "150px" }}>
                                    <button className="border-0 bg-transparent px-0 mx-0">
                                        <img height={30} className="p-1" style={{ borderRadius: "50%", border: "1px solid #C4C4C4" }}
                                            src={facebookImg} alt="" />
                                    </button>

                                    <button className="border-0 bg-transparent px-0 mx-0">
                                        <img height={30} className="p-1" style={{ borderRadius: "50%", border: "1px solid #C4C4C4" }} src={googleLogo} alt="" />
                                    </button>
                                </div>
                                {/* <div className="d-flex text-center mt-5  mx-auto justify-content-around align-items-center " style={{ width: "150px" }}>
                                    <Link to={"/agentPortal"}> <button className="btn btn-md py-2 px-3 text-white text-capitalize"
                                        style={{ backgroundColor: "#FF8282", borderRadius: "10px", fontSize: "15px" }}>
                                        Agent Login
                                    </button></Link>
                                </div> */}


                            </form>
                        </div>
                        <div className="text-center mt-2">
                            <p style={{ letterSpacing: "0.16em", fontWeight: "300", fontSize: "13px" }}>
                                By Signing in, you agree Brickberry
                                <Link to={"/termsOfUse"} target="_blank"><span className="mx-1" style={{ color: "rgb(155, 205, 42)s" }}>Term And Conditions</span></Link></p>
                        </div>

                    </div>
                    <div className="col-md-6 m-0 p-0 logInPageBricksImg d-flex flex-column justify-content-around "  >
                        <img alt="" style={{ position: "absolute", width: "100%", height: "100%", zIndex: "1" }} src={bricksBackgroundImgLogin} />
                        <div className="position-relative">
                            <img className="img-fluid" style={{ position: "absolute", zIndex: "1", height: "180px", width: "100%" }} src={logInTopImg} alt="img" />
                            <div className=" d-flex justify-content-end mr-md-5 pr-md-2" style={{ zIndex: "2", position: "relative" }}>
                                <p className="py-4 px-0 mt-md-2 my-0" style={{ color: "white", fontSize: "20px", fontWeight: "400", width: "280px" }}>SignUp Early & Win a Free Brick
                                    (worth ₹10,000 approx)</p>
                            </div>
                        </div>
                        <div className="mx-auto d-flex flex-column justify-content-center " style={{ marginTop: "50px" }}>
                            <div className="mx-auto" style={{ letterSpacing: "0.03em", fontSize: "18px", fontWeight: "400" }}>
                                <p className="my-3 py-2" style={{ color: "rgba(124, 124, 124, 1)" }}><img src={rightImg} alt="" height={15} width={20} className="mr-3" />Advanced AI Analytics & Blockchain Encrypted</p>
                                <p className="my-3 py-2" style={{ color: "rgba(124, 124, 124, 1)" }}><img src={rightImg} alt="" height={15} width={20} className="mr-3" />Buy Bricks , Hold Bricks & Sell Bricks Anytime</p>
                                <p className="my-3 py-2" style={{ color: "rgba(124, 124, 124, 1)" }}><img src={rightImg} alt="" height={15} width={20} className="mr-3" />Enjoy Brick Appreciation & Rentals every Month</p>
                                <p className="my-3 py-2" style={{ color: "rgba(124, 124, 124, 1)" }}><img src={rightImg} alt="" height={15} width={20} className="mr-3" />100% Hassle free Property Management</p>
                                <p className="my-3 py-2" style={{ color: "rgba(124, 124, 124, 1)" }}><img src={rightImg} alt="" height={15} width={20} className="mr-3" />Build a Diverse Property Portfolio</p>
                            </div>
                        </div>
                        <div className="text-center">
                            <p className="mx-auto text-center" style={{ color: "black" }}>New to BRICKBERRY? </p>
                        </div>
                        <div className="text-center " style={{ position: "relative", zIndex: "2" }}>
                            <button onClick={() => {
                                window.scrollTo(0, 0);
                                history.push("/signup")
                            }} className="btn text-capitalize shadow-none py-2 px-5 mx-auto text-dark"
                                style={{ border: "2px solid rgb(155, 205, 42)", borderRadius: "10px", fontWeight: "600", fontSize: "18px" }}>
                                Sign Up
                            </button>
                        </div>
                        <div>
                            <div className="">
                                <img alt="" style={{ width: "100%", position: "absolute", zIndex: "1", height: "150px" }} src={logInBottomImg} />
                            </div>
                            <div className='d-flex' style={{ position: "relative", zIndex: "2" }}>
                                <img src={buildingLeftImg} height={200} style={{ width: "50%" }} alt="" />
                                <img src={buildingRightImg} height={200} style={{ width: "50%" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default LogIn;
