import React, { useState, useEffect } from "react";
import faintCircle from "../assets/faintCircleLogo.png";
import investmentLogoOverview from "../assets/investmentLogoOverview.png";
import propertiesBuildingOverview from "../assets/propertiesBuildingOverview.png";
import expectedLogo from "../assets/expectedLogo.png";
import currentValueLogo from "../assets/currentValueLogo.png";
import { Pie, Line, Bar } from 'react-chartjs-2';
import dropdownArrow from "../assets/dropdownArrow.png";
import { Chart } from "react-google-charts";
import downloadLogo from "../assets/downloadLogo.png";
import { getMyEarningsCurrentbyUserId, getMyEarningsSoldbyUserId } from '../../../services/ApiServices';


let MyEarningsContent = () => {

    let [show, setShow] = useState("current");
    let [currentEarnings, setCurrentEarnings] = useState([]);
    let [soldEarnings, setSoldEarnings] = useState();

    useEffect(() => {
        if (show === "current") {
            getMyEarningsCurrentbyUserId(localStorage.getItem('user')).then((response) => {

                setCurrentEarnings(response.EarningsDashboard);
            });
        } else {
            getMyEarningsSoldbyUserId(localStorage.getItem('user')).then((response) => {
                console.log(response)
                setSoldEarnings(response["EarningsDashboard"]);
            });
        }


        window.scrollTo(0, 0);
    }, [show]);

    const graphData = (type) => {
        let label = [];
        let points = [];
        for (let i = 0; i <= currentEarnings?.TotalRentDistributed?.length; i++) {
            label.push(currentEarnings?.TotalRentDistributed[i]?.month)
            points.push(currentEarnings?.TotalRentDistributed[i]?.RentAmount)
        }
        if (type == "label") {
            return label;
        } else {
            return points;
        }
    }

    const color = ['rgba(255, 130, 130, 1)', 'rgba(120, 76, 251, 1)', 'rgba(168, 224, 255, 1)']

    const formatTime = (time) => {
        let date = new Date(time).toLocaleDateString();
        var datearray = date.split("/");
        let dd = datearray[1].length == 1 ? '0' + datearray[1] : datearray[1];
        let mm = datearray[0].length == 1 ? '0' + datearray[0] : datearray[0];
        var newdate = dd + '/' + mm + '/' + datearray[2];
        return newdate;
        // return date;
    }

    const data = (canvas) => {
        const ctx = canvas.getContext("2d");
        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        // gradient.addColorStop(0.4, 'rgba(168, 224, 255, 1)'); //sky
        // gradient.addColorStop(0.60, 'rgba(120, 76, 251, 1)'); //blue
        // gradient.addColorStop(0.70, 'rgba(255, 130, 130, 1)'); //pink
        // gradient.addColorStop(0.85, 'rgba(120, 76, 251, 1)');//blue
        // gradient.addColorStop(1, 'rgba(120, 76, 251, 1)');
        gradient.addColorStop(0.2, 'rgba(255, 130, 130, 1)'); //pink
        gradient.addColorStop(0.5, 'rgba(120, 76, 251, 1)'); //blue
        gradient.addColorStop(0.8, 'rgba(168, 224, 255, 1)'); //sky
        // gradient.addColorStop(1, 'rgba(168, 224, 255, 1)'); //sky
        // gradient.addColorStop(1, 'rgba(168, 224, 255, 1)');//blue
        // gradient.addColorStop(1, 'rgba(168, 224, 255, 1)');
        // gradient.addColorStop(2, 'rgba(250,174,50,0)');
        // gradient.addColorStop(3, 'rgba(250,174,50,0)');
        // const arbitraryStackKey = "stack1";

        return {

            labels: graphData("label"),
            datasets: [
                // {
                //     stack: arbitraryStackKey,
                //     label: 'data1',
                //     data: [1, 2, 3],
                //     backgroundColor : gradient,
                //     fill:'start',
                // },
                // {
                //     stack: arbitraryStackKey,
                //     label: 'data2',
                //     data: [5,  2, 1],
                //     backgroundColor : gradient,
                //     fill:'start',
                // },
                // {
                //     stack: arbitraryStackKey,
                //     label: 'data3',
                //     backgroundColor : gradient,
                //     fill:'start',
                //     data: [5, 4, 3, 2, 1],
                // },
                {
                    backgroundColor: gradient, // Put the gradient here as a fill color
                    // borderColor : "#ff6c23",
                    borderWidth: 2,
                    // pointColor : "#fff",
                    fill: 'start',
                    // pointStrokeColor : "#ff6c23",
                    // pointHighlightFill: "#fff",
                    // pointHighlightStroke: "#ff6c23",
                    label: "Earnings",
                    data: graphData("points"),
                }
            ]
        }
    };
    const BarData = {
        labels: [
            "Dec'19", "Jan'20", "Feb'20",
            "Mar'20", "Apr'20", "May'20",
            "Jun'20", "Jul'20", "Aug'20",
            "Sep'20", "Oct'20", "Nov'20"
        ],
        datasets: [
            {
                label: "Earnings",
                data: [2, 8, 8, 8, 8, 12, 14, 10, 9, 13, 12, 15],
                // fill: true,
                // backgroundColor: "#ff8282",
                fill: 'start',
                // backgroundColor: "linear-gradient(180deg, #FF8282 14.58%, #784CFB 57.29%, #A8E0FF 98.95%, #784CFB 98.96%)",
                // backgroundColor: "rgb("255,130,130,1","120,76,251,1")",
                // backgroundColor: "linear-gradient(180deg, #FF8282 14.58%, #784CFB 57.29%, #A8E0FF 98.95%, #784CFB 98.96%)",
                // backgroundColor: ["rgba(255, 130, 130, 1)","rgba(120, 76, 251, 1)","rgba(168, 224, 255, 1)","rgba(120, 76, 251, 1)" ],
                backgroundColor: ["rgba(255, 130, 130, 1)", "rgba(120, 76, 251, 1)", "rgba(168, 224, 255, 1)", "rgba(120, 76, 251, 1)"],
                borderColor: "white",
                // fillColor: ["rgba(0,10,220,0.5)","rgba(220,0,10,0.5)","rgba(220,0,0,0.5)","rgba(120,250,120,0.5)" ],
                // strokeColor: "rgba(220,220,220,0.8)",
                // highlightFill: "rgba(220,220,220,0.75)",
                // highlightStroke: "rgba(220,220,220,1)",
            },
        ]
    };

    return (
        <React.Fragment>
            <div className="row text-center mb-3" >
                <div className="d-flex mx-auto text-center justify-content-center">
                    <div className="btn px-3 py-1 text-capitalize mx-2  rounded"
                        onClick={() => setShow("current")}
                        style={show === "current" ?
                            { backgroundColor: "rgb(22, 142, 186)", width: "120px", fontSize: "14px", color: "white" } :
                            { border: "1px solid rgb(22, 142, 186)", width: "120px", fontSize: "14px", color: "black" }}
                    >Current</div>
                    <div className="btn px-3 py-1 text-capitalize mx-2 rounded "
                        onClick={() => setShow("sold")}
                        style={show === "sold" ?
                            { backgroundColor: "rgb(22, 142, 186)", width: "120px", fontSize: "14px", color: "white" } :
                            { border: "1px solid rgb(22, 142, 186)", width: "120px", fontSize: "14px", color: "black" }}
                    >Sold</div>
                </div>
            </div>
            {show === "current" &&

                <React.Fragment>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center ml-md-5 mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{currentEarnings !== undefined && currentEarnings !== null ? currentEarnings.TotalRent : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Total Rent</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center ml-md-4 mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{currentEarnings !== undefined && currentEarnings !== null ? currentEarnings.OtherIncome : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Other Income</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center ml-md-4 mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{currentEarnings !== undefined && currentEarnings !== null ? currentEarnings.CapitalAppreciation : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Capital Appreclation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center ml-md-4 mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}><span className="mr-2">&#8377;</span>{currentEarnings !== undefined && currentEarnings !== null ? currentEarnings.TotalEarning : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Total Earning</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row my-4 ">
                        <div className="col-md-12">
                            <div className="bg-white shadow p-2">
                                <div>
                                    <p className="text-center my-2 ">Total Rent Distributed</p>

                                </div>
                                <div className="mt-2" >
                                    <Bar id="BardChart" data={data}
                                        options={{
                                            title: {
                                                display: false,

                                            },
                                            maintainAspectRatio: false,
                                            legend: {
                                                display: false,

                                            },
                                            scales: {
                                                xAxes: [{
                                                    // stacked: true,
                                                    gridLines: {
                                                        display: false
                                                    },
                                                    barPercentage: 0.4
                                                }],
                                                yAxes: [{
                                                    // stacked: true,
                                                    gridLines: {
                                                        display: true
                                                    },
                                                    ticks: {
                                                        beginAtZero: true,
                                                        min: 0,
                                                        stepSize: 5000,
                                                        callback: function (value, index, values) {
                                                            return value + "K";
                                                        },
                                                    },
                                                }],
                                            }
                                        }}
                                        height={350}
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-center mt-3 mb-2">
                                    {currentEarnings !== undefined && currentEarnings !== null
                                        && currentEarnings?.CurrentProperties?.length < 4 &&
                                        currentEarnings.CurrentProperties.map((item, index) => {
                                            return (
                                                <div className="d-flex align-items-center mx-2">
                                                    <span style={{ height: "15px", width: "15px", borderRadius: "50%", backgroundColor: color[index] }}> </span>
                                                    <p style={{ fontSize: "14px" }} className="mb-0 mx-2 text-muted">{item.PropertyName}</p>
                                                </div>
                                            );
                                        })}
                                    {/* <div className="d-flex align-items-center mx-2">
                                <span style={{height:"15px",width:"15px",borderRadius:"50%",backgroundColor:"rgba(255, 130, 130, 1)"}}> </span>
                                <p style={{fontSize:"14px"}} className="mb-0 mx-2 text-muted">{currentEarnings !== undefined && currentEarnings.CurrentProperties[0].PropertyName}</p>
                            </div>
                            <div className="d-flex align-items-center mx-2">
                                <span style={{height:"15px",width:"15px",borderRadius:"50%",backgroundColor:"rgba(120, 76, 251, 1)"}}> </span>
                                <p style={{fontSize:"14px"}} className="mb-0 mx-2 text-muted">{currentEarnings !== undefined && currentEarnings.CurrentProperties[1].PropertyName}</p>
                            </div> */}
                                    {/* <div className="d-flex align-items-center mx-2">
                                <span style={{height:"15px",width:"15px",borderRadius:"50%",backgroundColor:"rgba(168, 224, 255, 1)"}}> </span>
                                <p style={{fontSize:"14px"}} className="mb-0 mx-2 text-muted">Property 3</p>
                            </div> */}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="my-5">
                        <div className="border mt-0 mb-3 pb-2 bg-white" style={{ borderRadius: "10px", minHeight: "200px" }}>
                            <p className="text-center my-3" style={{ fontWeight: "700", fontSize: "18px" }}>Total Return Table</p>
                            <div style={{ overflowX: "auto" }}>
                                <table style={{ width: "100%", color: "black" }}>
                                    <thead>
                                        <tr >
                                            <th className="border" style={{ backgroundColor: "rgb(238, 255, 199)", verticalAlign: 'middle', paddingTop: '0.8rem' }}>Date of Investment</th>
                                            <th className=" text-center border" style={{ backgroundColor: "rgb(238, 255, 199)", verticalAlign: 'middle', paddingTop: '0.8rem' }}>Property Name</th>
                                            <th className=" text-center border" style={{ backgroundColor: "rgb(238, 255, 199)", verticalAlign: 'middle', paddingTop: '0.8rem' }}>Type</th>
                                            <th className=" text-center border" style={{ backgroundColor: "rgb(238, 255, 199)", verticalAlign: 'middle', paddingTop: '0.8rem' }}>Investment Amount</th>
                                            <th colSpan="2" style={{ backgroundColor: "#98bc56", verticalAlign: 'top' }} className="border">
                                                <tr className="text-center border border-top-0" style={{ display: "flex", justifyContent: "center", paddingTop: '0.5rem' }}>
                                                    <th colSpan="3" >Rental Return</th>
                                                </tr>
                                                <tr style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <th colSpan="1" style={{ display: "table" }} className="mx-1 text-center px-1" >Monthly</th>
                                                    <th colSpan="1" style={{ display: "table" }} className="mx-1  text-center px-1">Commulative</th>
                                                    <th colSpan="1" style={{ display: "table" }} className="ml-3 text-center px-1">A <br />  Return</th>
                                                </tr>
                                            </th>
                                            <th colSpan="2" style={{ backgroundColor: "rgb(249, 200, 0)", verticalAlign: 'top', paddingTop: '0.8rem' }} >
                                                <tr className="text-center pl-2 border border-top-0" style={{ display: "flex", justifyContent: "center" }}>
                                                    <th colSpan="2" className="text-center" style={{ display: "table" }} >Capital Appreclation</th>
                                                </tr>
                                                <tr style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <th colSpan="1" style={{ display: "table" }} className="mx-1 text-center" >Current <br /> Valuation</th>
                                                    <th colSpan="1" style={{ color: "#784CFB", display: "table" }} className=" text-center mx-1">B <br />  Return</th>
                                                </tr>
                                            </th>

                                            <th className=" text-center"
                                                style={{ color: "#784CFB", backgroundColor: "rgb(238, 255, 199)", verticalAlign: 'top', paddingTop: '0.8rem' }}>A+B<br /> Return</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentEarnings !== undefined && currentEarnings !== null &&
                                            currentEarnings?.CurrentProperties?.map(item => {
                                                return (
                                                    <tr key={item.PropertyID} className="my-2" style={{ backgroundColor: "rgba(237, 237, 237, 0.4)", margintop: "10px" }}>
                                                        <td className="text-muted text-center border p-2"> {formatTime(item.InvestmentDate)}</td>
                                                        <td className="text-muted text-center border p-2">{item.PropertyTitle}</td>
                                                        <td className="text-muted text-center border p-2">{item.PropertyType}</td>
                                                        <td className="text-muted text-center border p-2"> &#8377; {item.InvestmentAmount}</td>
                                                        <td className="text-muted text-center border p-2"> {item.MonthlyCommulative}</td>
                                                        {/* <td></td> */}
                                                        <td className="text-muted text-center border p-2"> {item.AReturn} </td>
                                                        <td className="text-muted text-center border p-2"> {item.CurrentValuation} </td>
                                                        <td className="text-muted text-center border p-2"> {item.BReturn} </td>
                                                        <td className="text-muted text-center border p-2">{item.AReturn + item.BReturn}</td>

                                                    </tr>
                                                );
                                            })}
                                    </tbody>



                                </table>
                            </div>

                        </div>

                    </div>

                </React.Fragment>}



            {show === "sold" &&
                <React.Fragment>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center ml-md-5 mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{soldEarnings !== undefined && soldEarnings !== null ? soldEarnings.PropertiesSold : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Properties Sold</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center  mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{soldEarnings !== undefined && soldEarnings !== null ? soldEarnings.NetIRR : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Net IRR</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{soldEarnings !== undefined && soldEarnings !== null ? soldEarnings.ReturnMultiple : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mx-auto" style={{ fontSize: "17px" }}>Return Multiple</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                                <div className="text-center  mx-auto" >
                                    <div className=" text-center">
                                        <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{soldEarnings !== undefined && soldEarnings !== null ? soldEarnings.SaleValue : "-"}</p>
                                    </div>
                                    <div className="text-center">
                                        <p className="mb-0 mx-auto" style={{ fontSize: "17px" }}>Sale Value</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="my-5">
                        <div className="border mt-0 mb-3 pb-2 bg-white" style={{ borderRadius: "10px", minHeight: "200px" }}>
                            <p className="text-center my-3" style={{ fontWeight: "700", fontSize: "18px" }}>Sale Table</p>
                            <div style={{ overflowX: "auto" }}>
                                <table style={{ width: "100%", color: "black" }}>
                                    <tr >
                                        <th className=" text-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>Property Name</th>
                                        <th className=" text-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>Type</th>
                                        <th className=" text-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>Date of Investment</th>
                                        <th className=" text-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>Investment Amount</th>
                                        <th className=" text-center" style={{ backgroundColor: "rgb(238, 255, 199)" }}>Total Rents <br /> Distributed</th>
                                        <th className=" text-center" style={{ backgroundColor: "#98bc56" }}>Sale<br /> Date</th>
                                        <th className=" text-center" style={{ backgroundColor: "#98bc56" }}>Sale <br /> Proceeds</th>
                                        <th className=" text-center" style={{ backgroundColor: "#98bc56", color: "#784CFB" }}>Profit on Sale</th>
                                        <th colSpan="3" style={{ backgroundColor: "#f9c800" }} >
                                            <tr className="text-center border" style={{ display: "flex", justifyContent: "center" }}>
                                                <th colSpan="3" >Return Matrix</th>
                                            </tr>
                                            <tr style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <th colSpan="1" style={{ display: "table" }} className="mx-1 text-center px-1" >IRR</th>
                                                <th colSpan="1" style={{ display: "table" }} className="mx-1  text-center px-2">MOIC</th>
                                                <th colSpan="1" style={{ display: "table", color: "#784CFB" }} className="ml-3 text-center px-1">Total <br />  Return</th>
                                            </tr>
                                        </th>


                                        <th className=" text-center"
                                            style={{ color: "#784CFB", backgroundColor: "rgb(238, 255, 199)" }}>A+B<br /> Return</th>
                                    </tr>
                                    {soldEarnings !== undefined && soldEarnings !== null &&
                                        soldEarnings?.SoldProperties.map(item => {
                                            return (
                                                <tr key={item.PropertyID} className="my-2" style={{ backgroundColor: "rgba(237, 237, 237, 0.4)", margintop: "10px" }}>
                                                    <td className="text-muted text-center">{item.PropertyTitle}</td>
                                                    <td className="text-muted text-center">{item.PropertyType}</td>
                                                    <td className="text-muted text-center">  {formatTime(item.InvestmentDate)}</td>
                                                    <td className="text-muted text-center"> &#8377; {item.InvestmentAmount}</td>
                                                    <td className="text-muted text-center"> {item.TotalRentsDistributed}</td>
                                                    <td className="text-muted text-center"> {item.SaleDate}</td>
                                                    <td className="text-muted text-center"> {item.SaleProceeds} </td>
                                                    <td className="text-muted text-center"> {item.ProfitonSale} </td>
                                                    <td className="text-muted text-center"> {item.IRRMOIC} </td>
                                                    <td className="text-muted text-center"></td>
                                                    <td className="text-muted text-center"> {item.TotalReturn}</td>
                                                    <td className="text-muted text-center"></td>


                                                </tr>
                                            );
                                        })}


                                </table>

                            </div>
                        </div>

                    </div>

                </React.Fragment>
            }



        </React.Fragment>
    )
};
export default MyEarningsContent;