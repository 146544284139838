import {
    LOGIN_USER_REQUEST,LOGIN_USER_SUCCESS,LOGIN_USER_FAILURE,LOGOUT_USER
} from "./users.actionTypes";

import Axios from "axios";

// login user
let loginUser = (UserID,history) =>
{
    return async (dispatch) => {
        try {
            dispatch({type : LOGIN_USER_REQUEST});

            dispatch({type : LOGIN_USER_SUCCESS , payload : UserID});
            history.push('/');
        }
        catch (error) {
            console.log(error);
            dispatch({type : LOGIN_USER_FAILURE, payload: error});
        }
    }
};

let logOutUser = (history) => {
    return async (dispatch) => {
        try {
            dispatch({type : LOGOUT_USER});
            history.push('/');
        }
        catch (error) {
            console.error(error);
        }
    };
};


export {loginUser,logOutUser};

