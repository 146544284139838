import React, {useState} from "react";
import myProfileImg from "./assets/myProfileLogo.png";
import myDocumentsLogo from "./assets/myDocumentsLogo.png";
import myEarningLogo from "./assets/myEarningLogo.png";
import brickStatusLogo from "./assets/brickStatusLogo.png";
import taxCenterLogo from "./assets/taxCenterLogo.png";
import spvStatementLogo from "./assets/spvStatementLogo.png";
import riskProfilingLogo from "./assets/riskProfilingLogo.png";
import overviewLogo from "./assets/overviewLogo.png";
import investmentLogo from "./assets/investmentLogo.png";
import consensusLogo from "./assets/consensusLogo.png";
import investmentManagerLogo from "./assets/ManagerLogo.png";
import sideNavBuildingImg from "./assets/sideNavBuildingImgNew.png";
import Link from "react-router-dom/Link";
import myProfilePinkLogo from "./assets/myProfilePinkLogo.png";
import myProfileLogoDashboard from "./assets/myProfileLogoDashboard.png";
import bricksStatusPinkLogo from "./assets/bricksStatusLogo.png";
import overviewPinkLogo from "./assets/overviewPinkLogo.png";
import investmentsPinkLogo from "./assets/investmentsPinkLogo.png";
import earningsPinkLogo from "./assets/earningsPinkLogo.png";
import documentsPinkLogo from "./assets/documentsPinkLogo.png";
import SPVPinkLogo from "./assets/SPVPinkLogo.png";
import taxCenterPinkLogo from "./assets/taxCenterPinkLogo.png";
import investmentManagerPinkLogo from "./assets/investmentManagerPinkLogo.png";
import consensusPinkLogo from "./assets/consensusPinkLogo.png";
import riskProflingPinkLogo from "./assets/riskProflingPinkLogo.png";


let SideNavMobile=(props)=>{
    let [show,setShow]=useState(false);
    return(
        <React.Fragment>
            <div className=" d-block d-md-none" style={{top:"50px",left:"18px",zIndex:"5",position:"fixed"}}>
                <div className={!show ? "d-flex p-2 shadow border bg-white align-items-center":"d-flex p-2  shadow white border border-danger align-items-center"}
                     onClick={()=>setShow(!show)} style={{borderRadius:"50%",cursor:"pointer",width:"fit-content"
                }} >
                    {!show ? <img height={18}  src={myProfileImg} alt=""/> : <img height={20}  src={myProfileImg} alt=""/>}
                </div>
            </div>
            {show &&
            <div className="container-fluid  h-100 p-0 m-0 sideNavbar"
                 style={{
                     position:"fixed",zIndex:"3",width:"80%",right:"0",
                     background:"linear-gradient(180deg, #F77062 0%, rgba(247, 112, 98, 0.946392) 65.5%, rgba(247, 112, 98, 0.971116) 68.07%, rgba(247, 112, 98, 0.883026) 72.08%, rgba(247, 112, 98, 0.835535) 75.13%, rgba(247, 112, 98, 0.768256) 78.02%, rgba(247, 112, 98, 0.603274) 99.98%, rgba(255, 24, 0, 0) 100%)"}}>
                <div className=" py-1">
                    <div className="ml-md-5 my-4">
                        <Link to={"/dashboard/myProfile"}>
                            <div className="my-2" style={props?.active === "myProfile" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:
                                {backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "myProfile" ? <img height={18}  src={myProfilePinkLogo} alt=""/> :
                                        <img height={18}  src={myProfileLogoDashboard} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>My Profile</p>
                                </div>

                            </div> </Link>
                        <Link to={"/dashboard/bricksStatus"}>
                            <div className="my-2" style={props?.active === "bricksStatus" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "bricksStatus" ? <img height={18}  src={bricksStatusPinkLogo} alt=""/> :
                                        <img height={20}  src={brickStatusLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Bricks Status</p>
                                </div>

                            </div>
                        </Link>
                        <Link to={"/dashboard/overview"}>
                            <div className="my-2" style={props?.active === "overview" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "overview" ? <img height={18}  src={overviewPinkLogo} alt=""/> :
                                        <img height={20}  src={overviewLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Overview</p>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/dashboard/myInvestments"}>
                            <div className="my-2" style={props?.active === "myInvestments" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "myInvestments" ? <img height={18}  src={investmentsPinkLogo} alt=""/> :
                                        <img height={20}  src={investmentLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>My Investments</p>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/dashboard/myEarnings"}>
                            <div className="my-2" style={props?.active === "myEarnings" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "myEarnings" ? <img height={18}  src={earningsPinkLogo} alt=""/> :
                                        <img height={20}  src={myEarningLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>My Earnings</p>
                                </div>
                            </div></Link>
                        <Link to={"/dashboard/myDocuments"}>
                            <div className="my-2" style={props?.active === "myDocuments" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "myDocuments" ? <img height={18}  src={documentsPinkLogo} alt=""/> :
                                        <img height={20}  src={myDocumentsLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Blockchain Doc's</p>
                                </div>
                            </div></Link>
                        <Link to={"/dashboard/spvStatements"}>
                            <div className="my-2" style={props?.active === "spvStatements" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "spvStatements" ? <img height={18}  src={SPVPinkLogo} alt=""/> :
                                        <img height={20}  src={spvStatementLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>SPV / Statements</p>
                                </div>
                            </div></Link>
                        <Link to={"/dashboard/taxCenter"}>
                            <div className="my-2" style={props?.active === "taxCenter" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "taxCenter" ? <img height={18}  src={taxCenterPinkLogo} alt=""/> :
                                        <img height={20}  src={taxCenterLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Tax Center</p>
                                </div>
                            </div></Link>
                        <Link to={"/dashboard/investmentManager"}>
                            <div className="my-2" style={props?.active === "investmentManager" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "investmentManager" ? <img height={18}  src={investmentManagerPinkLogo} alt=""/> :
                                        <img height={20}  src={investmentManagerLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Investment Manager</p>
                                </div>
                            </div></Link>
                        <Link to={"/dashboard/consensus"}>
                            <div className="my-2" style={props?.active === "consensus" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>
                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "consensus" ? <img height={18}  src={consensusPinkLogo} alt=""/> :
                                        <img height={20}  src={consensusLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Consensus</p>
                                </div>
                            </div></Link>
                        <Link to={"/dashboard/riskProfiling"}>
                            <div className="my-2" style={props?.active === "riskProfiling" ?
                                {backgroundColor:"#fff7f7", color:"#F77062",borderTopLeftRadius:"20px",borderBottomLeftRadius:"20px"}:{backgroundColor:"transparent", color:"white"}}>

                                <div className="d-flex py-1 px-3 align-items-center" >
                                    {props?.active === "riskProfiling" ? <img height={18}  src={riskProflingPinkLogo} alt=""/> :
                                        <img height={20}  src={riskProfilingLogo} alt=""/>}
                                    <p className="mb-0 mx-3" style={{fontWeight:"400"}}>Risk Profiling</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div>
                        <img className="img-fluid" src={sideNavBuildingImg} alt=""/>
                    </div>
                </div>
            </div>}
        </React.Fragment>
    )
};
export default SideNavMobile;

