import React, {useEffect} from "react";
import Navbar from "../Navbar";
import TermsOfUseImg from "./assets/termsOfUseBGImg.png";
import Buildings from "../homepage/homepageComponents/Buildings";
import Footer from "../Footer";

let PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const blackDotStyles={
        minWidth: "8px",
        minHeight: "8px",
        height: "8px",
        width: "8px",
        borderRadius: "50%",
        marginLeft:"10px",
        marginRight:"10px",
        backgroundColor: "black"
    };
    return (
        <React.Fragment>
            <Navbar/>
            <div className="container-fluid  img-fluid text-center m-0 p-0">
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{minHeight: "200px", maxHeight: "250px"}}
                         src={TermsOfUseImg}/>
                    <p style={{fontWeight: "800", fontSize: "40"}}
                       className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        Privacy Policy</p>
                </div>
            </div>
            <div className="container mt-5 mb-5" style={{position: "relative"}}>
                <div className="">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>Terms of
                        Use</p>
                    <p className="text-center mx-auto mb-2 mt-0" style={{fontWeight: "400", fontSize: "18px"}}>UPDATED
                        March 2021</p>
                    <p className="mt-5" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        Reviewed and/or updated as referred herein above. For the purpose of this document, all
                        capitalized terms used herein and not specifically defined herein, shall have the meaning
                        assigned
                        to them under our 'Terms of Use'.

                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>I.
                        Introduction and Applicability of the Policy</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0">This policy describes the types of information which the company
                                may collect from User(s)
                                or that User(s) may provide in relation to the use or access of the Website and the
                                manner
                                in which such information is collected used, processed, disclosed and maintained.</p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0">Please read and understand the policy carefully. If User(s) do not
                                agree with the herein
                                policies and practices in the way the company treat User(s) information (<span
                                    className="font-weight-bold">“User(s)
                        Information”</span> which may include User’s name, nationality, date of birth, telephone number,
                                email address, IP address, physical address, financial information, or any other
                                information
                                and/or documents submitted by User(s) through the website), User(s) should immediately
                                stop accessing and using the website. User(s) continued use or access of the website
                                shall
                                constitute their agreement to herein Privacy Policy and by accepting this Privacy
                                Policy,
                                User(s) expressly consent to the company’s use and disclosure of their personal
                                information
                                in accordance with this privacy policy.</p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">3.</p>
                            <p className="ml-2 mb-0">This policy shall apply to all information which User(s) provide on
                                the Website and all
                                information that the company collects on the Website including but not limited to any
                                information User(s) upload, emails that User(s) exchange with the company and other
                                Users
                                and any information submitted by User(s) to us</p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">4.</p>
                            <p className="ml-2 mb-0">The policy does not apply to, nor does the company take any
                                responsibility for, any
                                information that is collected by any third-party either using the website or through any
                                links on the website or through any advertisements on the website. </p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>II.
                        Nature of Information Collected and Manner of Collection</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0">During User(s) use and access to the website, the company may
                                collect different types of
                                information, directly or through third parties. This may include User Information,
                                information that is personally identifiable, other information which may not be
                                personally
                                identifiable, information on the usage patterns of any User including you, searches that
                                you
                                have done on the website, advertisements or third-party links that User(s) have visited,
                                any
                                emails or other correspondence User(s) have exchanged on the website or with the
                                company. The company may use third-party cookies to collect information, which will be
                                governed by the terms specified by such third parties and we do not have control over
                                such
                                cookies once User(s) leave the website.
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0">User(s) understand that the information collected by the company,
                                may be collected
                                directly or through tracking of User(s) usage of the website. The usage details may
                                include
                                IP Addresses, details of User(s) computer equipment, browser, location, connections, any
                                information that company may collect through the use of cookies and other tracking
                                technologies. The collection of data may in most cases be automatic</p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">3.</p>
                            <p className="ml-2 mb-0"> Advertisements on the website may be posted by third-party
                                advertisers, and such third
                                parties may use cookies and other automatic tracking technologies to collect information
                                about User(s), including but not limited to web behavioural information and patterns.
                                The
                                company do not control nor take any responsibility for such third-parties, their
                                collection
                                and use of information or their tracking technologies or how they may be used.</p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">4.</p>
                            <p className="ml-2 mb-0">User(s) also may provide information to be published or displayed
                                or posted on the website
                                or transmitted to other Users or third parties. Any such information is posted or
                                transmitted
                                to others at User(s) own risk. Please be aware that the company cannot control the
                                actions
                                of other Users with whom User(s) of the website, may choose to share information with.
                                If
                                User(s) provide any User Information to the company concerning someone else, concerned
                                user(s) are requested to ensure that User(s) have obtained consent from such third party
                                to
                                disclose that User Information to the company and that such User Information may be
                                collected or used as described in this Privacy Policy.</p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>III.
                        Purpose of Collection and Use of User(s) Information: </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0"> The information that the company collect on the website will be
                                used for the purposes of
                                operating the website, facilitating User(s) use of the website, facilitating the
                                transactions
                                between the Users, studying User behavior, preferences and for other business purposes
                                of
                                the company.
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0"> Except as otherwise provided herein, no personally identifiable
                                information will be
                                disclosed or shared with any third-party without User(s) express consent. For the
                                purposes
                                of this Policy, personally identifiable information shall mean name, age, gender, bank
                                account information, telephone numbers, location data, email addresses, payment, billing
                                or shipping information, if any
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">3.</p>
                            <p className="ml-2 mb-0"> Please do not include any personal information, personally
                                identifiable information or
                                sensitive personal information unless specifically requested by the company as part of
                                the
                                registration or other applicable processes. The company determines that any information
                                User(s) have provided or uploaded violates the terms of this Privacy Policy, the company
                                has the right, in its absolute discretion, to delete or destroy such information without
                                incurring any liability to User(s), subject to Appropriate and Applicable Laws.

                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">4.</p>
                            <p className="ml-2 mb-0"> The company will not publish, sell or rent user(s) personal
                                information to third parties for
                                any marketing purposes without User(s) explicit consent. The Company may also use the
                                information for analytical purposes, including but not limited to assessing usage data,
                                usage patterns, estimate audience sizes and other similar activities. If User(s) choose
                                to
                                discontinue the company’s use of User(s)’ Information for any of the aforementioned
                                purposes at any time in the future, User(s) must notify us in writing to withdraw your
                                consent. Please be advised that User(s) withdrawal of consent may result in our
                                inability to
                                provide User(s) with the services and may result in the termination of Users
                                relationship
                                with the company. If the data protection laws of the relevant jurisdictions allow us to
                                collect, use or disclose the User Information without user(s) consent, such permission
                                granted by law will continue to apply.
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">5.</p>
                            <p className="ml-2 mb-0">User(s) agree that their personal information may be used to
                                contact them and deliver
                                information or targeted advertisements, administrative notices and any other
                                communication relevant to their use of the Website irrespective of their registration on
                                any
                                DND lists
                            </p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>IV.
                        Disclosure of collected information</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0"> The User Information User(s) provide on the Website may be
                                disclosed by the company to
                                its agents, employees, third party advertisers, subsidiaries and affiliates, or to other
                                third
                                party service providers of the company who require the information for the purposes of
                                operating and maintaining the website. The company may use the User Information users
                                provide for Know Your Customer (KYC) verification or other similar processes as required
                                under Appropriate and Applicable Law(s), which may include sharing User(s) Information
                                with regulatory bodies such as CERSAI
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0">The company will comply with requests and directions of all
                                Governmental, law
                                enforcement or regulatory authorities, which it believes in good faith to be in
                                accordance
                                with any Appropriate and Applicable Law(s). Such compliance may include providing User
                                Information, personally identifiable information or any other information to such agency
                                or
                                authority. By providing any information on the website, Users consent to the company
                                providing such information to any Governmental, law enforcement or regulatory
                                authorities
                                who exercise jurisdiction over the company and the website.
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">3.</p>
                            <p className="ml-2 mb-0"> If User(s) Information is required to be transferred outside the
                                relevant jurisdiction, the
                                company shall do so in accordance with the User Information protection law(s) of that
                                jurisdiction to provide a standard of protection to the User Information so transferred
                                that
                                is comparable to the data protection laws under that jurisdiction.
                            </p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>V.
                        Data Security and Data Retention</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0"> The information that Users provide, subject to disclosure in
                                accordance with this Privacy
                                Policy shall be maintained in a safe and secure manner. The company’s databases and
                                information are stored on secure servers with appropriate firewalls owned by the company
                                or by third parties. User(s) Information shall not be retained for no longer than is
                                necessary
                                for the purposes for which it was processed
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0"> Given the nature of internet transactions, the company does not
                                take any responsibility for
                                the transmission of information including User Information to the website. Any
                                transmission of User Information on the internet is done at User(s) risk. The company
                                does
                                not take any responsibility for Users or any third party circumventing the privacy
                                settings or
                                security measures contained on the website
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">3.</p>
                            <p className="ml-2 mb-0"> While the company will use all reasonable efforts to ensure that
                                User(s) Information and
                                other information submitted by User(s) is safe and secure, the company offer no
                                representation, warranties or other assurances that the security measures taken by the
                                company are adequate, safe, fool proof or impenetrable.
                            </p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>VI.
                        Accessing and Updating User(s) Information</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0"> User(s) can change, alter or otherwise modify or update users’
                                information at any time by
                                accessing the website using your User(s) Account. Users may request for erasure of
                                User’s
                                Information, restrict processing of User’s Information or seek a transfer of User’s
                                Information to another organization or to users, subject to reasonable conditions,
                                Appropriate and Applicable Law(s) and applicable exemptions. If users make a request,
                                the
                                company will respond to users at the earliest, but no later than 1 (one) month. Where
                                the
                                requests are complex or numerous, the company may extend the deadline to three months.
                                If users would like to exercise any of these rights, please contact us at <a
                                    href="https://mail.google.com/mail/?view=cm&fs=1&to=legal@brick-berry.com"
                                    target="_blank">legal@brick-berry.com</a>

                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0">Users may also change and/or delete any of the information users’
                                have submitted.
                                However, Property Share reserves the rights to save any usage information, subject to
                                Applicable Law
                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">3.</p>
                            <p className="ml-2 mb-0">The company may, at its sole discretion, permit or deny the change
                                of any information by a
                                User, if it believes the same is required to comply with Applicable Laws.
                            </p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>V.
                        Contact Us:</p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">1.</p>
                            <p className="ml-2 mb-0">
                                If users have any questions about the company’s privacy policy, the information the
                                company hold on users, or if users would like to exercise one of their data protection
                                rights,
                                please do not hesitate to contact us at legal@brick-berry.com <a
                                href="https://mail.google.com/mail/?view=cm&fs=1&to=legal@brick-berry.com"
                                target="_blank">legal@brick-berry.com</a>

                            </p>
                        </div>
                    </p>
                    <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <div className="mt-2  d-flex" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <p className="mb-0">2.</p>
                            <p className="ml-2 mb-0">The Company i.e. M/s. MerkleHash Innovations Pvt Ltd (a registered
                                company under the
                                Indian Companies Act bearing CIN No. U72900TG2019PTC136512 ("we" or "us") dealing in
                                Fractional Ownership, Co-Shared products and services is committed to protecting and
                                respecting your privacy.
                            </p>
                        </div>
                    </p>
                </div>
                <div className="my-4">
                    <p className="text-center mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Types of data may be collected by the company</p>
                    <table className="table my-4 table-responsive table-bordered">
                        <thead>
                        <tr>
                            <th scope="col" className="text-center font-weight-bold" style={{width: "200px", maxWidth: "40%"}}>Type of
                                Information
                            </th>
                            <th scope="col" className="text-center font-weight-bold" style={{width: "300px"}}>Description</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">Contact details and
                                other information to
                                confirm your identity
                            </th>
                            <td>Your name, address, email address, phone number, date of birth, log-in
                                information and password (we never see your password as it is
                                encrypted)
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Nationality and
                                National identifier
                            </th>
                            <td>Your nationality and an identifying number or code given to you by a
                                government i.e. your National Insurance Number
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Financial information</th>
                            <td colSpan="2">Details about financial transactions from your account to us, and your
                                bank account and card details in order to process payments to you
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Transactions</th>
                            <td colSpan="2">Details about Co-Owned and Co-Shared products and services you have
                                dealt with via our platform
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Communications</th>
                            <td colSpan="2">Your communications with us, including emails, phone calls and webchats
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">KYC’ information</th>
                            <td colSpan="2">Details about you collected by us to identify and verify the identity of
                                our customers, including a copy of your passport or identity card and a
                                utility bill noting your home address
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Anti-money laundering
                                verification
                            </th>
                            <td colSpan="2">As a result of money laundering regulations, we may need to verify the
                                source of the funds which you are dealing on our platform. We may
                                therefore collect from you any of the following as evidence - a copy of
                                your bank statement / savings account statement, payslip or completion
                                statement from a house sale, for example

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Technical information</th>
                            <td colSpan="2">The Internet protocol (IP) address used to connect your computer to the
                                Internet, browser type and version, time zone setting, browser plug-in
                                types and versions, operating system and platform
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Behaviour</th>
                            <td colSpan="2">Details about how you use our website and services, including
                                information about your visit, including the full Uniform Resource
                                Locators (URL) clickstream to, through and from our site (including date
                                and time); products you viewed or searched for; page response times,
                                download errors, length of visits to certain pages, page interaction
                                information (such as scrolling, clicks, and mouse-overs), and methods
                                used to browse away from the page
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">Consents</th>
                            <td colSpan="2">Any permissions, consents or preferences that you give us, including
                                communications that you would like to receive from us and how you
                                would like us to contact you
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="my-4">
                    <p className="text-center mt-2 mx-auto mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Modes of data collection:</p>
                    <div className="mx-md-5 mx-2  " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Via our website;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Via live chat;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Via the internet;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Via customer surveys;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Via face to face meetings with members of the Property Partner team;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">By email and letters; and
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">By phone.
                                </p>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center  mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Data collection from third parties the company work with: </p>
                    <div className="mx-md-5 mx-2  " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Our ‘KYC’ service provider;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Our payment service provider;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Credit reference agencies;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Financial advisers; and
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">Companies that introduce you to us
                                </p>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="my-4">
                    <p className="text-center my-4 mb-0 " style={{fontWeight: "900", fontSize: "40px"}}>
                        Usage of collected data/information:  </p>
                    <div className="mx-md-5 mx-2  " style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                        <p>The company is allowed to use collected information only for lawful reason(s) for doing so, if the
                            company can satisfy that processing it is necessary: </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">to provide its product and/or services to users;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">in order for use to comply with a legal or regulatory obligation;
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">when it is in its ‘legitimate interests’ (i.e. the company have a business or commercial
                                    interest to use users’ information as long as it does not overly prejudice users); and/or
                                </p>
                            </div>
                        </p>
                        <p className="mt-4" style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                            <div className="mt-2  d-flex align-items-center"
                                 style={{lineHeight: "25px", letterSpacing: "0.02em"}}>
                                <div
                                    style={blackDotStyles}/>
                                <p className="ml-2 mb-0">when users’ consent to it;
                                </p>
                            </div>
                        </p>
                    </div>
                </div>
                <div className="my-4 pb-4">
                    <p className="text-center my-4 mb-0 " style={{fontWeight: "900", fontSize: "40px",lineHeight:"1.5"}}>
                        The company use collected information in the following ways: </p>
                    <table className="table my-4 table-responsive table-bordered">
                        <thead>
                        <tr>
                            <th scope="col" className="text-center font-weight-bold" style={{width: "400px", maxWidth: "50%"}}>Type of
                                Usage for
                            </th>
                            <th scope="col" className="text-center font-weight-bold" style={{width: "400px",maxWidth:"50%"}}>Basis for Usage</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">To manage our relationship with users:<br/>
                                1. To carry out marketing activities<br/>
                                2. To understand how our customers use our website
                                and services<br/>
                                3. To provide guidance on our website and services
                            </th>
                            <td>1. Provision of our product and services users consent<br/>
                                2. Compliance with legal and regulatory obligations<br/>
                                3. Our legitimate interests - developing products and
                                services; keeping records and contact details up to
                                date; determining which of our services may interest
                                users
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">To deliver our product and services to users:<br/>
                                1. To report transaction activity to users<br/>
                                2. To help you manage users’ subscriptions.<br/>
                                3. To manage and provide subscription options and
                                services<br/>
                                4. To adhere to our obligations set out in
                                agreements or contracts

                            </th>
                            <td>1. Provision of our product and services<br/>
                                2. Compliance with legal and regulatory obligations<br/>
                                3. Our legitimate interests - being efficient with how
                                we fulfil our legal and contractual obligations

                            </td>
                        </tr>
                        <tr>
                            <th scope="row">To develop our products and services:<br/>
                                1. To test new products and/or services or iterations
                                of existing products and/or services<br/>
                                2. To administer our website and for internal
                                operations, including troubleshooting, data analysis,
                                testing, research, statistical and survey purposes<br/>
                                3. To manage how we work with third parties that
                                provide services to us
                            </th>
                            <td colSpan="2">1. Provision of our product and services<br/>
                                2. Compliance with legal and regulatory obligations<br/>
                                3. Our legitimate interests – developing products
                                and/or services; being efficient with how we fulfil our
                                legal and contractual obligations
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">To detect, investigate and report financial crime:<br/>
                                1. To comply with laws and regulations<br/>
                                2. To manage risk<br/>
                                3. To monitor and prevent fraud<br/>
                                4. To verify a customer’s identity<br/>
                                5. To respond to and resolve complaints</th>
                            <td colSpan="2">1. Compliance with legal and regulatory obligations<br/>
                                2. Our legitimate interests – develop and improve how
                                we deal with financial crime; being efficient with how
                                we fulfil our legal and regulatory obligations
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">To run our business efficiently and in compliance
                                with applicable regulatory standards:<br/>
                                1. Corporate Governance<br/>
                                2. Financial and regulatory audits
                                3. Regulatory reporting and filings<br/>
                                4. Business, financial and strategic planning
                            </th>
                            <td colSpan="2">1. Compliance with legal and regulatory obligations<br/>
                                2. Our legitimate interests – develop and improve our
                                business operations; being efficient with how we fulfil
                                our legal and regulatory obligations
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>


            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default PrivacyPolicy;
