import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import PurchaseBricksTitle from "./PurchaseBricksTitle";
import GetInTouch from "./GetInTouch";
import Navbar from "../Navbar";
import axios from "axios";

let PurchaseBricks = () => {
  const location = useLocation();
  let [propertyObject, setPropertyObject] = useState(undefined);
  useEffect(() => {
    window.scrollTo(0, 0);
    getProperty();
  }, []);
  let getProperty = async () => {
    const PropertyID = location.pathname.split("/")[2];
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let dataURL = `${process.env.REACT_APP_API_URL}/properties/propertiesbyID/${PropertyID}`;
    await Axios.get(dataURL, config)
      .then((response) => {
        if (response.data.Status === "1" || response.data.Status === 1) {
          setPropertyObject(response.data.Property);
        } else if (response.data.Status === "0" || response.data.Status === 0) {
          console.log(response.data.Message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <React.Fragment>
      <Navbar active={"properties"} />
      <PurchaseBricksTitle property={propertyObject ? propertyObject : null} />
      <GetInTouch />
    </React.Fragment>
  )
};
export default PurchaseBricks;