import React, { useState, useEffect } from "react";
import { consensus } from '../../../services/ApiServices'
import RedDotCheckBox from "../assets/redDotCheckbox.png";
import RedDotEmptyCheckBox from "../assets/RedDotEmptyCheckbox.png";
let ConsensusContent = () => {
    let [agree, setAgree] = useState([]);
    let [tenant, setTenant] = useState([1]);
    let [property, setProperty] = useState([1]);
    const [TenantPoll, setTenantPoll] = useState([]);
    const [PropertyPoll, setPropertyPoll] = useState([])
    useEffect(() => {
        consensus(localStorage.getItem('user')).then((response) => {
            console.log(response)
            setPropertyPoll(response?.PropertyPoll)
            setTenantPoll(response?.TenantPoll)
        });
    }, [])
    let addTenant = (id) => {
        if (tenant.includes(id)) {
            let newTenant = tenant.filter((value) => { return (value !== id) });
            setTenant(newTenant);
        }
        else {
            setTenant([...tenant, id]);
        }
    };
    let addProperty = (id) => {
        if (property.includes(id)) {
            let newProperties = property.filter((value) => { return (value !== id) });
            setProperty(newProperties);
        }
        else {
            setProperty([...property, id]);
        }
    };
    return (
        <React.Fragment>
            <div className="">
                <div className="row justify-content-around px-4 py-1 mt-2 mb-0"
                    style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "rgb(238, 255, 199)" }}>

                    <p className="text-center py-2 mb-0" style={{ fontWeight: "700", fontSize: "18px" }}>Tenant Poll</p>

                </div>
                <div className="border mt-0 mb-3 pb-2 " style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>
                    <div className="row justify-content-around px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "white" }}>

                        <React.Fragment style={{ backgroundColor: "white" }}>
                            <div className=' py-1 col-md-1 text-center  text-center rounded'
                            >Vote
                            </div>
                            <div className=' py-1  text-center col-md-2 text-center rounded'
                            >Property Name
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            > Tenant Name
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Rental Period
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Rental Quote
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >% of Brick Voted
                            </div>
                            <div className=' py-1 text-center col-md-1 text-center'
                            >Status
                            </div>
                        </React.Fragment>

                    </div>


                    {TenantPoll.length > 0 && TenantPoll.map(item => <div className="row justify-content-around  align-items-center px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>

                        <div className='py-1 col-md-1 text-center  text-center rounded ' style={{ cursor: "pointer" }} onClick={() => addTenant(1)}>
                            {/*<input defaultChecked aria-selected type="checkbox" id="1" name="tenant" value="1"/>*/}
                            {tenant.includes(1) ?
                                <img src={RedDotCheckBox} alt="" height={10} />
                                : <img src={RedDotEmptyCheckBox} alt="" height={10} />}
                            {/*<img src={RedDotCheckBox} onClick={()=>addTenant(1)}  alt=""  height={10}/>*/}
                        </div>
                        <div style={{ fontSize: "13px" }} className=' py-1  text- col-md-2 mx-0 px-0 text-center '
                        ><label htmlFor="1"> {item.PropertyTitle}</label>
                        </div>
                        <div style={{ fontSize: "13px" }} className=' py-1 text-center col-md-2 mx-0 px-0 text-center'
                        > {item.TenantName}
                        </div>
                        <div style={{ fontSize: "13px" }} className=' py-1 text-center col-md-2 mx-0 px-0 text-center'
                        >{item.RentalPeriod}
                        </div>
                        <div style={{ fontSize: "13px" }} className=' py-1 text-center col-md-2 mx-0 px-0 text-center'
                        >{item.RentalQuote}
                        </div>
                        <div style={{ fontSize: "13px" }} className=' py-1 text-center col-md-2 mx-0 px-0 text-center'
                        >{item.BrickVotePercentage}
                        </div>
                        <div style={{ fontSize: "13px" }} className=' py-1 text-center col-md-1 mx-0 px-0 text-center'
                        >Agreed
                        </div>


                    </div>)}




                </div>


            </div>
            <div className="">
                <div className="row justify-content-around px-4 py-1 mt-2 mb-0"
                    style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "rgb(238, 255, 199)" }}>

                    <p className="text-center py-2 mb-0" style={{ fontWeight: "700", fontSize: "18px" }}>Property Poll</p>

                </div>
                <div className="border mt-0 mb-3 pb-2" style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>
                    <div className="row justify-content-around px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "white" }}>

                        <React.Fragment style={{ backgroundColor: "white" }}>
                            <div className=' py-1 col-md-3 text-center  text-center rounded'
                            >
                            </div>
                            <div className=' py-1 col-md-3 text-center  text-center rounded'
                            >Extend 3 Years
                            </div>
                            <div className=' py-1  text-center col-md-3 text-center rounded'
                            >Extend 5 Years
                            </div>
                            <div className=' py-1 text-center col-md-3 text-center'
                            >Liquidate the Property
                            </div>

                        </React.Fragment>

                    </div>


                    <div className="row justify-content-around align-items-center px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>

                        <div style={{ fontSize: "15px" }} className='py-1 col-md-3 text-center  text-center '>
                            <label htmlFor="5"> Time Square Hyderabad</label>
                        </div>
                        <div className=' py-1  text- col-md-3 mx-0 px-0 text-center roundCheckbox'
                            style={{ cursor: "pointer" }} onClick={() => addProperty(1)}
                        >
                            {/*<input aria-selected type="checkbox" id="5" name="property" value="5"/>*/}
                            {property.includes(1) ?
                                <img src={RedDotCheckBox} alt="" height={10} />
                                : <img src={RedDotEmptyCheckBox} alt="" height={10} />}
                        </div>
                        <div className=' py-1 text-center col-md-3 mx-0 px-0 text-center roundCheckbox'
                            style={{ cursor: "pointer" }} onClick={() => addProperty(2)}
                        >
                            {/*<input aria-selected type="checkbox" id="6" name="property" value="6"/>*/}
                            {property.includes(2) ?
                                <img src={RedDotCheckBox} alt="" height={10} />
                                : <img src={RedDotEmptyCheckBox} alt="" height={10} />}
                        </div>
                        <div className=' py-1 text-center col-md-3 mx-0 px-0 text-center roundCheckbox'
                            style={{ cursor: "pointer" }} onClick={() => addProperty(3)}
                        >
                            {/*<input aria-selected type="checkbox" id="7" name="property" value="7"/>*/}
                            {property.includes(3) ?
                                <img src={RedDotCheckBox} alt="" height={10} />
                                : <img src={RedDotEmptyCheckBox} alt="" height={10} />}
                        </div>



                    </div>

                    <div className="row justify-content-around align-items-center px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>

                        <div style={{ fontSize: "15px" }} className='py-1 col-md-3 text-center  text-center '>
                            <label htmlFor="6"> %  of Bricks Voted</label>
                        </div>
                        <div style={{ fontSize: "15px" }} className=' py-1  text- col-md-3 mx-0 px-0 text-center '
                        > 51% Bricks
                        </div>
                        <div style={{ fontSize: "15px" }} className=' py-1 text-center col-md-3 mx-0 px-0 text-center'
                        > 15% Bricks
                        </div>
                        <div style={{ fontSize: "15px" }} className=' py-1 text-center col-md-3 mx-0 px-0 text-center'
                        >34% Bricks
                        </div>
                    </div>
                    <div className="row justify-content-around align-items-center px-4 py-1 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>

                        <div style={{ fontSize: "15px" }} className='py-1 col-md-3 text-center  text-center '>
                            Status
                        </div>
                        <div style={{ fontSize: "15px" }} className=' py-1  text- col-md-3 mx-0 px-0 text-center '
                        >{property.includes(1) && <span>Agreed</span>}
                        </div>
                        <div style={{ fontSize: "15px" }} className=' py-1 text-center col-md-3 mx-0 px-0 text-center'
                        >{property.includes(2) && <span>Agreed</span>}
                        </div>
                        <div style={{ fontSize: "15px" }} className=' py-1 text-center col-md-3 mx-0 px-0 text-center'
                        >{property.includes(3) && <span>Agreed</span>}
                        </div>
                    </div>

                </div>


            </div>
        </React.Fragment>
    )
};
export default ConsensusContent;
