import React from "react";
import Navbar from "../../Navbar";
import resourcesBGImg from "../assets/resourcesBGImg.png";
import rightTickLogo from "../assets/rightTickpinkLogo.png";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";

let OwnershipModel = () => {
    return (
        <React.Fragment>
            <Navbar activeDropdown={"ownershipModels"} />
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{ minHeight: "200px", maxHeight: "250px" }} src={resourcesBGImg} />
                    <p style={{ fontWeight: "800", fontSize: "40" }}
                        className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        Ownership Models</p>
                </div>
            </div>
            <div className="container-fluid my-4">
                <div className="mx-md-5 mx-0 px-md-4 px-1">
                    <p className="text-center" style={{ fontWeight: "700", fontSize: "40px" }}>Ownership Models</p>
                </div>
            </div>
            <div className="container my-2 pb-4">
                <div style={{ overflowX: "auto" }}>
                    <table className="table w-100 " style={{ border: "1px solid rgb(22, 142, 186)" }}>
                        <thead className="" style={{ backgroundColor: "rgb(22, 142, 186)" }}>
                            <tr >
                                <th scope="col" className="py-0 my-0"></th>
                                <th scope="col" className=" " style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> LLP/PVT</span></th>
                                <th scope="col" className="" style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> TRADITIONAL</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Govt. Ownership Record</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Ministry of Corporate Affairs LLP Filings</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>State Registration Department / SRO</p> </td>
                            </tr>
                            <tr >
                                <th scope="row"><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Verification</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Online at mca.gov.in</p></td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>Encumbrance Certificate & Registration Documents</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="container my-2 pb-4">
                <div style={{ overflowX: "auto" }}>
                    <table className="table w-100 " style={{ border: "1px solid rgb(22, 142, 186)" }}>
                        <thead className="" style={{ backgroundColor: "rgb(22, 142, 186)" }}>
                            <tr >
                                <th scope="col" className="py-0 my-0"></th>
                                <th scope="col" className=" " style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> LLP/PVT</span></th>
                                <th scope="col" className="" style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> TRADITIONAL</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <th scope="row " style={{ width: "300px" }}><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Govt. Transaction Charges</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Minimal</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>High. Currently at 11% total for stamp duty and registration charges.</p> </td>
                            </tr>
                            <tr >
                                <th scope="row"><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Transaction Mode</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Online. Transactions can be completed without physically being present.</p></td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>Offline. Transactions need to be completed at the local Sub-Registrar's Office (power can be assigned).</p></td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Resale Transaction Time</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Days</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}> Weeks </p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Liquidity</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>High</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}> Moderate </p> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="container my-2 pb-4">
                <div style={{ overflowX: "auto" }}>
                    <table className="table w-100 " style={{ border: "1px solid rgb(22, 142, 186)" }}>
                        <thead className="" style={{ backgroundColor: "rgb(22, 142, 186)" }}>
                            <tr >
                                <th scope="col" className="py-0 my-0"></th>
                                <th scope="col" className=" " style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> LLP/PVT</span></th>
                                <th scope="col" className="" style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> TRADITIONAL</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <th scope="row " style={{ width: "300px" }}><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Low Minimum Investment</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Minimal</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>

                                    High. Currently at 11% total for stamp duty and registration charges. </p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Invest in High Potential Real Estate</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Online. Transactions can be completed without physically being present.</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>Offline. Transactions need to be completed at the local Sub-Registrar's Office (power can be assigned).</p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Transparency</p></th>
                                <td><p className="mb-0 ml-md-5 ml-2" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                                <td><p className="mb-0 ml-md-5 text-center" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Curated Properties</p></th>
                                <td><p className="mb-0 ml-md-5 ml-2" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                                <td><p className="mb-0 ml-md-5 text-center" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Fully Managed Properties</p></th>
                                <td><p className="mb-0 ml-md-5 ml-2" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                                <td><p className="mb-0 ml-md-5 text-center" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Sell Anytime</p></th>
                                <td><p className="mb-0 ml-md-5 ml-2" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                                <td><p className="mb-0 ml-md-5 text-center" style={{ fontSize: "16px" }}><img src={rightTickLogo} style={{ height: "20px" }} alt="" /></p> </td>
                            </tr>
                            <tr >
                                <th scope="row "><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Simplified Real Estate Investments</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Highly Simplified</p> </td>
                                <td><p className="mb-0 pr-md-4 text-center" style={{ fontSize: "16px" }}>Simplified</p> </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="container my-2 pb-4">
                <div style={{ overflowX: "auto" }}>
                    <table className="table w-100 " style={{ border: "1px solid rgb(22, 142, 186)" }}>
                        <thead className="" style={{ backgroundColor: "rgb(22, 142, 186)" }}>
                            <tr >
                                <th scope="col" className="py-0 my-0"></th>
                                <th scope="col" className=" " style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> LLP/PVT</span></th>
                                <th scope="col" className="" style={{ fontWeight: "700", fontSize: "17px", paddingTop: "12px", paddingBottom: "12px" }}><span className="ml-2" style={{ color: "white" }}> TRADITIONAL</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <th scope="row " style={{ width: "300px" }}><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Decision Making</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Through Voting</p> </td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>Through Voting</p> </td>
                            </tr>
                            <tr >
                                <th scope="row"><p className="mb-0 pl-md-4" style={{ fontSize: "16px" }}>Rental Distribution</p></th>
                                <td><p className="mb-0" style={{ fontSize: "16px" }}>Through LLP - as interest on capital contribution</p></td>
                                <td><p className="mb-0 pr-md-4" style={{ fontSize: "16px" }}>BrickBerry  as Property Manager and distributes rent through platform</p></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <Buildings />
            <Footer />
        </React.Fragment>
    )
};
export default OwnershipModel;
