import React from "react";
import MailImg from "../assets/img/email.svg"
import LogoImg from "../assets/img/2020Logo.png"
import BrandLogo from "../assets/img/BrandLogo.png"
import Brickberry from "../assets/img/brickBerryNavbarLogo.png"
import ReraRegistered from "../assets/img/ReraRegistered.png";
import inTheNewsImg from "../assets/img/inTheNewsImg.png";
import investingImgs from "../assets/img/investingImgs.png";
import forbesImg from "../assets/img/forbesImg.png";
import companiesImage from "../assets/img/companiesImage.png";
import brandLogo from "../assets/assetspositive.svg";
import whatsappLogoImg from "../assets/whatsappLogoImg.png";
import footerFacebookLogo from "../assets/footerFacebookLogo.png";
import footerLinkedInLogo from "../assets/footerLinkedInLogo.png";
import footerTwitterLogo from "../assets/twitter.png";
import footerYoutubeLogo from "../assets/footerYoutubeLogo.png";
import Link from "react-router-dom/Link";
let Footer = () => {
    return (
        <React.Fragment>
            <div className="container-fluid text-white " style={{ backgroundColor: "#41414b" }}>
                <div className="row py-4">
                    <div className="col-md-5 my-auto">
                        <div className="mx-md-5 emailSection mx-2 border border-white">
                            <div className="mx-2 d-flex flex-wrap justify-content-around align-items-center mx-4">
                                <div>
                                    <img src={MailImg} height={120} width={120} alt="mailImg" className="mx-auto text-center" />
                                </div>
                                <div> <p className="h5 mb-0">Follow Our News and Updates</p>
                                    <p>Get interesting articles in your Mail!</p>
                                </div>
                            </div>
                            <form>
                                <div className="form-outline mx-md-5 mx-4 d-md-flex mb-3 d-sm-block emailInputField" style={{ marginTop: "-20px" }}>
                                    <input type="mail" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px" }} id="form1Example2"
                                        className="form-control my-md-auto my-2 text-muted w-md-75 w-100 " placeholder="Enter your mail" />
                                    <label className="form-label text-center my-auto mailBtn" style={{ marginLeft: "-10px" }} htmlFor="form1Example2">
                                        <button style={{ borderRadius: "10px", background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)", marginLeft: "-100px" }}
                                            className="btn mx-0 btn-md  mx-auto text-white text-center text-capitalize" htmlFor="form1Example2">
                                            Submit</button>
                                    </label>
                                </div>
                            </form>
                        </div>
                        <div className="d-flex flex-wrap mt-4 px-4 justify-content-around">
                            <img height={20} src={inTheNewsImg} alt="" />
                            <img height={20} src={investingImgs} alt="" />
                            <img height={20} src={forbesImg} alt="" />
                            {/*<InvestingCom/>*/}
                            {/*<ForbesFooter/>*/}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex groupImges  justify-content-between">
                            <div className=" mx-0 px-0 d-flex flex-column justify-content-center my-auto" >
                                <img src={LogoImg} height={100} width={100} className="img-fluid mt-0 mx-auto" alt="logoImg" />

                            </div>
                            <div className=" my-auto ">
                                <div className="row  justify-content-around align-items-center " style={{ marginRight: 0, marginLeft: '0' }}>
                                    <div className="col-md-6 pl-md-5 p-0 mx-auto text-center  p-0" >
                                        <div className="d-flex g-1 align-items-center justify-content-center" style={{ gap: "0.5rem", marginLeft: '0.5rem' }}>
                                            <img alt="Brickberry " src={Brickberry} className="mt-5 footerLogo" height={50} />
                                            <img alt="brandLogo " src={BrandLogo} className="mt-5 footerLogo" style={{ width: '100%', height: '100%' }} />
                                        </div>
                                        {/*<BbrickberryLogo/>*/}
                                        <div className="d-flex justify-content-center mt-3 align-items-center  mx-auto text-center">
                                            <a target="_blank" href="https://www.facebook.com/MerkleHash/"><img style={{ height: "25px" }} className="mx-2" src={footerFacebookLogo} alt="" /></a>
                                            <a target="_blank" href=" https://www.linkedin.com/company/4brickberry/about/"> <img style={{ height: "25px" }} className="mx-2" src={footerLinkedInLogo} alt="" /></a>
                                            <a target="_blank" href="https://www.youtube.com/@brickberry3361"><img style={{ height: "25px" }} className="mx-2" src={footerYoutubeLogo} alt="" /></a>
                                            <a target="_blank" href=" https://twitter.com/HashMerkle"><img style={{ height: "25px", borderRadius: "50%", }} className="mx-2" src={footerTwitterLogo} alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 m-0 p-0 text-center px-5 mt-2" style={{ marginTop: "70px" }} >
                                        <img alt="brandLogo" src={ReraRegistered} className="mt-2 mr-2" width={100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            <img className="companyImg" src={companiesImage} height={40} alt="" />
                            {/*<GroupLogo/>*/}
                        </div>
                    </div>
                </div>

                <hr className="border-white my-0 py-0" />
                <div className="row mx-5 mt-5">
                    <div className="col-md-2 mx-0 px-0">
                        <h5 className="font-weight-bold mb-4" style={{ color: "#9bcd2a" }}>Quick Links</h5>
                        <Link to={"/privacyPolicy"} target="_blank"><p className="h6 text-white font-weight-normal ml-1" style={{ marginBottom: "10px" }}>
                            <i className="fa fa-caret-right mr-3" />Privacy Policy</p></Link>
                        <Link to={"/FAQ"} target="_blank"><p className="h6 font-weight-normal ml-1 text-white" style={{ marginBottom: "10px" }}><i className="fa fa-caret-right mr-3" />FAQ</p></Link>
                        <Link to={"/termsOfUse"} target="_blank"><p className="h6 text-white font-weight-normal ml-1" style={{ marginBottom: "10px" }}>
                            <i className="fa fa-caret-right mr-3" />Terms of Use</p></Link>
                        <p className="h6 font-weight-normal ml-1" style={{ marginBottom: "10px" }}><i className="fa fa-caret-right mr-3" />Legal</p>
                        <Link to={"/contactUs"} target="_blank"><p className="h6 text-white font-weight-normal ml-1" style={{ marginBottom: "10px" }}>
                            <i className="fa fa-caret-right mr-3" />Contact Us</p></Link>

                    </div>
                    <div className="col-md-4 mx-0 px-0">
                        <h5 className="font-weight-bold mb-4" style={{ color: "#9bcd2a" }}>Contact Info</h5>
                        <p className="h6 font-weight-normal ml-1 mb-0 " style={{ marginTop: "40px" }}>1-60/8/A&B, 3rd Floor,
                            KNR Space, </p>
                        <p className="h6 font-weight-normal ml-1 mb-0" style={{ marginBottom: "10px" }}>Above TVS Showroom,</p>
                        <p className="h6 font-weight-normal ml-1 mb-0" style={{ marginBottom: "10px" }}>Kondapur, Gachibowli,</p>
                        <p className="h6 font-weight-normal ml-1 mb-0" style={{ marginBottom: "10px" }}>Hyderabad - 500032</p>
                        <div className="my-3">
                            <a href="http://wa.me/917786866999?text=Hi%20Brickberry." className="d-flex p-0 m-0 btn  justify-content-between align-items-center"
                                style={{ backgroundColor: "#0dc153", borderRadius: "50px", width: "220px", textTransform: "initial" }}>
                                <p className="px-2 py-2 my-auto text-white" style={{ fontSize: "small" }}>Need Help? Lets chat with us</p>
                                <div className="p-1 d-flex align-items-center"
                                    style={{ backgroundColor: "#10b64e", borderBottomRightRadius: "50%", borderTopRightRadius: "50%" }}>
                                    <img alt="" height={25} src={whatsappLogoImg} /></div>
                            </a>
                        </div>
                        {/*<img alt="" height={32} width={220} className="d-flex whatsappImg my-4 justify-content-center align-items-center" src={whatsAppImg}/>*/}
                    </div>
                    <div className="col-md-6 mx-0 px-0">
                        <p className="mb-0 text-muted font-small"> "Forecasts may not be achieved and are not a reliable indicator of future performance. The advice provided to you is general advice only. It has been prepared and presented without taking into account your personal objectives, financial situation or needs. Before making any decision in relation to BRICK-BERRY or any products offered by BRICK-BERRY you should read the Product Disclosure Statement (PDS) and consider whether BRICK-BERRY is right for you.BRICK-BERRY products are issued by MerkleHash Innovations Pvt Ltd ( CIN: U72900TG2019PTC136512 )(GST: 36AANCM0416E1ZQ). Neither BRICK-BERRY nor MerkleHash Innovations Pvt Ltd, guarantees any rate of return or the capital value or return of any money invested. Past performance referred to is no guarantee of future performance of the relevant financial product and is not a reliable indicator of future performance. "</p>
                        <p className="ml-auto text-muted text-right mt-4 font-weight-bolder mr-5">Copyright &#169;2020 BRICK-BERRY | All Right Reserved</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default Footer;
