import React from "react";
import downloadLogo from "../../assets/downloadLogoPink.png";

let ViewPropertyUpdates=()=>{
    return(
        <React.Fragment>
            <div className="container px-0 my-5" >
                <div className="row">
                    <div className="col-md-6">
                        <div className="mx-2">
                            <p className="text-center" style={{fontSize:"20px",fontWeight:"600"}}>Monthly Updates</p>
                            <div className="px-md-5 py-4 p-4 my-4 monthlyUpdate" style={{boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.25)"}}>
                                <p style={{fontWeight:"600"}}>OCT 2020</p>
                                <p className="mb-0 my-1 " style={{fontSize:"15px"}}>This property is open for Pre-Orders from 1pm on 19th October 2020 to 1pm on 29th October 2020 and/or until either being fully subscribed.
                                    There are no transaction
                                    T&Cs Apply.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="mx-2">
                            <p className="text-center" style={{fontSize:"20px",fontWeight:"600"}}>Due Diligence & Key  Documents</p>
                            <div className="px-md-5 py-4 p-4 my-4" style={{boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.25)"}}>
                                <p className="text-center" style={{fontSize:"15px",color:"#FF8282"}}>Additional Disclosure Statement for #BB01</p>
                                <div className="d-flex align-items-center my-1">
                                    <img style={{height:"20px"}} src={downloadLogo} alt=""/>
                                    <p className="mb-0 mx-3" style={{fontSize:"15px"}}> Property Info - PDF</p>
                                </div>
                                <div className="d-flex align-items-center my-1">
                                    <img style={{height:"20px"}} src={downloadLogo} alt=""/>
                                    <p className="mb-0 mx-3" style={{fontSize:"15px"}}>RERA-Registration Certificate of Project </p>
                                </div>
                                <div className="d-flex align-items-center my-1">
                                    <img style={{height:"20px"}} src={downloadLogo} alt=""/>
                                    <p className="mb-0 mx-3" style={{fontSize:"15px"}}>Legal Title Report</p>
                                </div>
                                <div className="d-flex align-items-center my-1">
                                    <img style={{height:"20px"}} src={downloadLogo} alt=""/>
                                    <p className="mb-0 mx-3" style={{fontSize:"15px"}}>Property Share Detailed Investment Note </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default ViewPropertyUpdates;