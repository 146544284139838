import React, { useState, useEffect } from "react";
import faintCircle from "../assets/faintCircleLogo.png";
import investmentLogoOverview from "../assets/investmentLogoOverview.png";
import propertiesBuildingOverview from "../assets/propertiesBuildingOverview.png";
import expectedLogo from "../assets/expectedLogo.png";
import currentValueLogo from "../assets/currentValueLogo.png";
import { Pie, Line, Bar } from 'react-chartjs-2';
import dropdownArrow from "../assets/dropdownArrow.png";
import { Chart } from "react-google-charts";
import downloadLogo from "../assets/downloadLogo.png";
import { getMyInvestmentsbyUserId } from '../../../services/ApiServices';

let MyInvestmentContent = () => {

    let [investmentsData, setInvestmentsData] = useState();
    let [transactionType, setTransactionType] = useState("All");
    let [chartType, setChartType] = useState("location");

    useEffect(() => {
        getMyInvestmentsbyUserId(localStorage.getItem('user')).then((response) => {
            setInvestmentsData(response["InvestmentDashboard "]);
        });
        window.scrollTo(0, 0);
    }, []);

    const chartTypeHandler = (e) => {
        setChartType(e.target.value);
    }

    const transactionHandler = (e) => {
        setTransactionType(e.target.value);
    }

    const arrToInstanceCountObj = arr => arr.reduce((obj, e) => {
        obj[e] = (obj[e] || 0) + 1;
        return obj;
    }, {});

    const barDataHandler = (type) => {
        let label = [];
        let datapoints = [];
        let chartLables = [];
        let chartPoints = [];
        let chartLabelArray = [];
        let chartLabelCount = [];
        if (investmentsData !== undefined && investmentsData !== null) {
            for (let i = 0; i < investmentsData.MyInvestments.length; i++) {
                let flag = 1;
                // label.push("Property " + (i+1))
                label.push(investmentsData.MyInvestments[i].PropertyTitle)
                datapoints.push(investmentsData.MyInvestments[i].Yield);
                if (chartType === "location") {
                    chartLabelArray.push(investmentsData.MyInvestments[i].City);
                    for (let j = 0; j < chartLables.length; j++) {
                        if (chartLables[j] == investmentsData.MyInvestments[i].City) {
                            flag = 0;
                        }
                    }
                    if (flag == 1) {
                        chartLables.push(investmentsData.MyInvestments[i].City + " Office");
                    }
                } else {
                    chartLabelArray.push(investmentsData.MyInvestments[i].PropertyType);
                    for (let j = 0; j < chartLables.length; j++) {
                        if (chartLables[j] == investmentsData.MyInvestments[i].PropertyType) {
                            flag = 0;
                        }
                    }
                    if (flag == 1) {
                        chartLables.push(investmentsData.MyInvestments[i].PropertyType);
                    }
                }

            }
        }
        chartLabelCount = arrToInstanceCountObj(chartLabelArray);
        let per = 100 / chartLabelArray.length;
        for (const item in chartLabelCount) {
            let totalPoints = chartLabelCount[item] * per;
            chartPoints.push(totalPoints);
        }

        if (type == "label") {
            return label;
        } else if (type == "points") {
            return datapoints;
        } else if (type == "chartLable") {
            return chartLables;
        } else if (type == "chartPoints") {
            return chartPoints;
        }

    }
    const BarData = {
        labels: barDataHandler("label"),
        datasets: [
            {
                label: "First dataset",
                data: barDataHandler("points"),
                fill: true,
                backgroundColor: "#ff8282",
                borderColor: "white"
            },
        ]
    };
    const state = {
        labels: barDataHandler("chartLable"),
        datasets: [
            {
                label: 'Rainfall',
                backgroundColor: [
                    '#d2555a',
                    '#a5d76e',
                    '#ffc36e',
                ],
                hoverBackgroundColor: [
                    '#d2555a',
                    '#a5d76e',
                    '#ffc36e',
                ],
                data: barDataHandler("chartPoints"),
            }
        ]
    };
    function convertToNearestLakh(number) {
        if (number <= 100000) {
            return number;
        }

        const nearestLakh = Math.ceil(number / 100000);
        return nearestLakh;
    }
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-3">
                    <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                        <div className="text-center ml-md-5 mx-auto" >
                            <div className=" text-center">
                                <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{investmentsData !== undefined && investmentsData !== null ? investmentsData.NoOfProperties : "3"}</p>
                            </div>
                            <div className="text-center">
                                <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Properties</p>
                            </div>
                        </div>

                        <div className="  ml-md-auto mx-auto  mx-0 px-0  text-center d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center align-items-center " style={{ position: "relative" }} >
                                <img src={faintCircle} height={50} style={{ position: "absolute" }} alt="" />
                                <img src={propertiesBuildingOverview} height={30} style={{ position: "relative" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="bg-white h-100 d-flex  justify-content-center align-items-center shadow py-3 px-2">
                        <div className="text-center ml-md-4 mx-auto" >
                            <div className=" text-center">
                                <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}><span className="mr-2">&#8377;</span>{investmentsData !== undefined && investmentsData !== null ? `${convertToNearestLakh(investmentsData.TotalInvestment)} Lakh` : "60"}</p>
                            </div>
                            <div className="text-center">
                                <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Total Investment</p>
                            </div>
                        </div>

                        <div className=" ml-md-auto mx-auto  mx-0 px-0  text-center d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center align-items-center " style={{ position: "relative" }} >
                                <img src={faintCircle} height={50} style={{ position: "absolute" }} alt="" />
                                <img src={investmentLogoOverview} height={30} style={{ position: "relative" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                        <div className="text-center ml-md-4 mx-auto" >
                            <div className=" text-center">
                                <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}><span className="mr-2">&#8377;</span>{investmentsData !== undefined && investmentsData !== null ? investmentsData.CurrentValue : "-"}</p>
                            </div>
                            <div className="text-center">
                                <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Current Value</p>
                            </div>
                        </div>

                        <div className=" ml-md-auto  mx-auto  mx-0 px-0  text-center d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center align-items-center " style={{ position: "relative" }} >
                                <img src={faintCircle} height={50} style={{ position: "absolute" }} alt="" />
                                <img src={currentValueLogo} height={30} style={{ position: "relative" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="bg-white d-flex h-100 justify-content-center align-items-center shadow py-3 px-2">
                        <div className="text-center ml-md-4 mx-auto" >
                            <div className=" text-center">
                                <p className='mb-2' style={{ fontWeight: "900", fontSize: "25px" }}>{investmentsData !== undefined && investmentsData !== null ? investmentsData.ExpectedIRR : "-"}%</p>
                            </div>
                            <div className="text-center">
                                <p className="mb-0 mr-1" style={{ fontSize: "17px" }}>Expected IRR</p>
                            </div>
                        </div>

                        <div className=" ml-md-auto mx-auto  mx-0 px-0  text-center d-flex justify-content-center align-items-center">
                            <div className="d-flex justify-content-center align-items-center " style={{ position: "relative" }} >
                                <img src={faintCircle} height={50} style={{ position: "absolute" }} alt="" />
                                <img src={expectedLogo} height={30} style={{ position: "relative" }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-4 ">
                <div className="col-md-6">
                    <div className="shadow px-3 pt-3 pb-2 bg-white">
                        <div className="text-center my-2">
                            <div className="d-flex justify-content-center align-items-center" style={{ position: "relative" }}>
                                <select style={{ height: "30px", border: "1px solid rgba(255, 130, 130, 0.3)", width: "200px", position: "relative" }}
                                    className="bg-transparent form-control px-4 py-1" onChange={e => chartTypeHandler(e)}>
                                    <option selected value="location" >By Location</option>
                                    <option value="property">Property Type</option>
                                </select>
                                {/*<img alt="" src={dropdownArrow} style={{position:"absolute",right:"10%",width:"15px",height:"8px"}}/>*/}
                            </div>
                        </div>
                        <div className="pb-1">
                            <Pie
                                data={state}
                                options={{
                                    title: {
                                        display: true,
                                        text: '',
                                        fontSize: 20
                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom'
                                    }
                                }}
                            />

                        </div>

                    </div>
                </div>
                <div className="col-md-6">
                    <div className="bg-white shadow p-2">
                        <div>
                            <p className="text-center my-2 ">Rental Yield</p>

                        </div>
                        <div className="mt-2">
                            <Bar data={BarData}
                                options={{
                                    title: {
                                        display: false,

                                    },
                                    legend: {
                                        display: false,

                                    },
                                    scales: {
                                        xAxes: [{
                                            gridLines: {
                                                display: false
                                            },
                                            barPercentage: 0.2
                                        }],
                                        yAxes: [{
                                            gridLines: {
                                                display: true
                                            },
                                            ticks: {
                                                beginAtZero: true,
                                                max: 12,
                                                stepSize: 2,
                                            }
                                        }],
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-5">
                <div className="border mt-0 mb-3 pb-2" style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>

                    <div className="d-flex justify-content-center text-center my-3 mb-0">
                        <div className="d-flex align-items-center text-dark" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                            <select style={{ height: "30px", color: "black", order: "1px solid rgba(255, 130, 130, 0.3)", position: "relative", }}
                                className=" form-control px-4 py-0" onChange={e => transactionHandler(e)}>
                                <option className=" text-dark" selected value="all">All</option>
                                <option className=" text-dark" value="buy">Buy</option>
                                <option className=" text-dark" value="rent">Rent</option>
                                <option className=" text-dark" value="sale">Sale</option>
                            </select>
                        </div>
                        <p className="mx-3 mb-0" style={{ color: "black", fontWeight: "400", fontSize: "18px" }}>Transactions</p>

                    </div>
                    <div className=" mt-0" style={{ minHeight: "200px" }}>
                        <div className="row justify-content-around px-4 py-2 my-2"
                            style={{ marginLeft: "0.02%", marginRight: "0.02%" }}>
                            <div className=' py-1 col-md-2 text-center  text-center rounded'
                            >Property
                            </div>
                            <div className=' py-1  text-center col-md-2 text-center rounded'
                            >Investment Amount
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Ownership %
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Rental Yield
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Status
                            </div>
                            <div className=' py-1 text-center col-md-2 text-center'
                            >Type
                            </div>
                        </div>
                        {investmentsData !== undefined && investmentsData !== null &&
                            investmentsData.MyInvestments.map(item => {
                                return (
                                    <div key={item.PropertyID} className="row  px-4 py-1 my-2"
                                        style={{ backgroundColor: "#f8f8f8", marginLeft: "0.02%", marginRight: "0.02%" }}>
                                        <div className=' py-1 col-md-2 text-center  text-center rounded'
                                        >{item.City}
                                        </div>
                                        <div className=' py-1 col-md-2 text-center  text-center rounded'
                                        >{item.InvestmentAmount}
                                        </div>
                                        <div className=' py-1 col-md-2 text-center  text-center rounded'
                                        >{item.OwnershipShare}
                                        </div>
                                        <div className=' py-1 col-md-2 text-center  text-center rounded'
                                        >{item.Yield}
                                        </div>
                                        <div className=' py-1 col-md-2 text-center  text-center rounded'
                                        >
                                        </div>
                                        <div className=' py-1 col-md-2 text-center  text-center rounded'
                                        >{item.PropertyType}
                                        </div>
                                    </div>
                                );
                            })}

                    </div>

                </div>

            </div>



        </React.Fragment>
    )
};
export default MyInvestmentContent;