import React, {useEffect, useState} from "react";
import styles from "./faq.css";
import FAQImg from "../assets/FAQImg.png";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import propertyBGImg from "../assets/propertyBGImg.png";
import byuingBGImg from "../assets/byuingBGImg.png";
import NRIbgImg from "../../../assets/img/NRIInvestingPuzzleImg.png";
import feesImg from "../assets/feesImg.png";
import propertyImgLogo from "../assets/propertyImgLogo.png";
import buyingImgLogo from "../assets/buyingimgLogo.png";
import feesImgLogo from "../assets/feesImgLogo.png";
import pinkCircleImg from "../assets/pinkCircleImg.png";
let FAQArray=[
    {
        id: "1",
        question: "What is BrickBerry",
        answer:
          "BrickBerry is most advanced AI & Blockchain tech based platform which allows its users to invest in Best Grade-A commercial, Retail, and Warehousing real estate assets tenanted to multinational tenants.",
      },
      {
        id: "2",
        question: "What are the different investment opportunities available",
        answer:
          "BrickBerry offers investors an opportunity to invest in completed and rent generating Grade A commercial, retail, and warehousing properties with up to 7-11% yields and 18-22% target IRRs.",
      },
      {
        id: "3",
        question: "What is the minimum investment",
        answer:
          "As a Portfolio Manager, the minimum portfolio we can manage per investor is  Rs. 5 lakhs.",
      },
      {
        id: "4",
        question: "How are new offerings listed",
        answer:
          "Before any New offerings is listed on the platform, our In-House developed Real estate AI software & our investment team thoroughly researches various opportunities available in the market and shortlists only those properties that meet our stringent investment criteria. Once a property is listed on the Platform, the details of such property will appear on the dashboard which will include pricing, financial model and projected returns, location, layout, tenant overview and legal diligence reports that can help you in making an informed investment decision.",
      },
      {
        id: "5",
        question: "What type of returns can investors make",
        answer:
          "Investors can benefit from returns in two ways: \
          1.Distributions - The annual yield generated from rental income can range between 7-10%, which are typically distributed on a monthly basis to all Brick holders (Investors).\
          2.Capital Appreciation - Any appreciation in the underlying property accrues to investors and is realized at the time of sale",
      },
      {
        id: "6",
        question: "How does BrickBerry shortlist properties",
        answer:
          "We shortlist properties after examining various metrics like Location, Developer, Quality of development, Scope of price appreciation, Micro and Macro market indicators, Legality of title and earning potential. We rely on Inhouse developed Exclusive AI tool & the extensive investment experience of our team which comes with more than 100 Million of investment experience in Indian real estate market.",
      },
      {
        id: "7",
        question: "Why does BrickBerry focus more on commercial real estate",
        answer:
          "We believe that commercial real estate, if underwritten sensibly and conservatively, offers the best after-tax returns to Investors. BrickBerry founders and investing team come from core real estate investing backgrounds having invested >100 Million in Indian real estate.",
      },
      {
        id: "8",
        question: "How do I transfer money for my investment",
        answer:
          "The investment in the real estate asset is made through a Special Purpose Vehicle (SPV). Once the SPV is capitalized, the proceeds are transferred to the Seller from the SPVs bank account. You will need to transfer your investment through normal banking channels.",
      },
      {
        id: "9",
        question: "How can I diversify through BrickBerry",
        answer:
          "Investment risk can be reduced with appropriate diversification of holdings. Investors investing through the Platform can diversify by investing across locations, tenants (technology, financial services) and asset classes (retail, office, warehousing). Please speak to us for more details.",
      },
      {
        id: "10",
        question: "Who takes care of tenancy and management",
        answer:
          "Brickberry has a separate Asset Management team that takes care of all asset management functions related to an investment.",
      },
      {
        id: "11",
        question:
          "What are BrickBerry fees",
        answer:
          "We charge an annual management fee of 0.9% and a performance fee of 20% at the time of sale after the investor has made 8% annual returns. (“For E.X If an Investor had Invested Rs. 100 and the Investor makes Rs 108 at the time of exit, We DO NOT charge any performance fees as we believe we were not able to deliver greater than the risk-free rate. However, if the investor had made Rs. 150 at the time of exit, we will charge 20% of Rs 42 (150-108)i.e Rs 8.4 as our Performance fee”) This way our fees are aligned with investor returns. In addition, the platform may charge a one-time set-up / sourcing fee of up to 2% of purchase consideration which is generally shared with third property consultants. There are no other hidden charges payable to BrickBerry.",
      },
      {
        id: "12",
        question: "How can I get specific questions answered on a particular property",
        answer:
          "You can sign-up , schedule a call with us and we will be happy to answer your specific questions. \
          You can also send an email to info@brick-berry.com",
      },
      {
        id: "13",
        question: "Can I schedule a visit to a property listed on the platform",
        answer:
          "Yes, we routinely organize site visits for interested investors.",
      },
      {
        id: "14",
        question: "What kind of documentation will I need to sign",
        answer:
          "As a SEBI regulated entity we are mandated to complete the following documentation with our clients:\
          1.Risk Profiling - You will need to fill-up an online risk profile questionnaire \
          2.Know Your Customer (KYC) - We will need certain documents to complete your KYC \
          3.Client Agreement – This document governs your relationship between you and BrickBerry (MerkleHash Innovations Pvt Ltd) and contains details regarding the opportunity, fees, grievance redressal mechanism, etc.",
      },
      {
        id: "15",
        question: "How will I know about new listings",
        answer:
          "Once you are registered on the platform, you will receive notifications on new listings, resales, funding updates etc.",
      },
      {
        id: "16",
        question: "Do I need to be present for property registration",
        answer:
          "No, BrickBerry arranges for the property registration on behalf of investors. Our proprietary tech and dashboard allows you to sign as well as view all investment related documents remotely.",
      },
      {
        id: "17",
        question: "What will be my proof of investment",
        answer:
          "The Sale Deed in conjunction with the certificates for the securities you subscribe to from the SPV constitute proof of your investment.",
      },
      {
        id: "18",
        question: "What is the investment process",
        answer:
          "Once you decide to invest, our team will guide you through the investment process.",
      },
      {
        id: "19",
        question: "Is my liability limited to my capital",
        answer:
          "Your liability is always limited to the extent of the capital invested.",
      },
      {
        id: "20",
        question: "Are you guaranteeing the rent or returns",
        answer:
          "BrickBerry DOES NOT guarantee any rents or returns. As an investor, you are advised to stay away from guaranteed returns schemes. While most properties listed on the platform are leased and rents are known in advance, a re-leasing risk always exists in case the tenant vacates.",
      },
      {
        id: "21",
        question: "What are the deductions from rent",
        answer:
          "In commercial properties, the typical deductions are tax deduction at source property management fee and property tax.",
      },
      {
        id: "22",
        question: "Is investing through BrickBerry risky",
        answer:
          "Yes, as with any real estate investment, investment through BrickBerry carries inherent risks of loss of capital.Please read through our Disclosure Document to understand the risks in detail.",
      },
      {
        id: "23",
        question: "Who decides exit timing and pricing",
        answer:
          "Investors decide the exact time and price at which to sell their securities.",
      },
      {
        id: "24",
        question: "What are the reporting standards and disclosures",
        answer:
          "SEBI mandates strict reporting and disclosure standards for Portfolio Managers including a half-yearly report containing information such as (a) the value of the portfolio; (b) transactions undertaken; (c) beneficial interest received; (d) expenses incurred; (e) details of risk foreseen.",
      },
      {
        id: "25",
        question: "How can I exit",
        answer:
          "You can exit your investment in two ways:\
          Resale of your Securities: You can choose to sell your securities to anyone at a mutually agreed price either privately or through the BrickBerry platform.\
          Sale of property: If the investors mandate a sale, the asset team will look for purchasers of the asset and you will receive your share of the sale proceeds.",
      },
      {
        id: "26",
        question: "What do I own when I invest through BrickBerry",
        answer:
          "You will own securities of an SPV which owns the property you have selected to invest in.",
      },
      {
        id: "27",
        question:
          "Are the rents transferred monthly or annually",
        answer:
          "Typically, rents and interest on security deposit are transferred to you every month.",
      },
      {
        id: "28",
        question:
          "Can I get a copy of tenant agreement or LoI signed with the tenant",
        answer:
          "Yes. We share all the details before property registration with the investors.",
      },
      {
        id: "29",
        question:
          "What is the ownership process in BrickBerry",
        answer:
          "Post successful KYC verification, you can own any property listed on our platform. After initial due diligence, you can block your fraction in the property by completing documentation and transferring 10% of your ownership amount in the SPV account. Post blocking of your share, you can access the property related documents to complete an in-depth due diligence.\
          Post successful KYC verification, you can own any property listed on our platform. After initial due diligence, you can block your fraction in the property by completing documentation and transferring 10% of your ownership amount in the SPV account. Post blocking of your share, you can access the property related documents to complete an in-depth due diligence. Once a property has been fully funded, you will be required to transfer the remaining amount into the SPV account. The SPV will then proceed to purchase the property and the ownership related documents will be shared with you.\
          Please contact our experts to understand the process in detail.",
      },
      {
        id: "30",
        question:
          "What documentation needs to be done",
        answer:
          "You will be signing relevant SPV agreements to be registered with the Registrar Of Companies (ROC) under the Ministry of Corporate Affairs at relevant state offices. SPV will own the asset which can be verified with the sale agreement that is registered with the Sub-Registrar office under whose local jurisdiction the property is situated. All these documents can be signed either digitally or physically. Please contact our experts to understand the documents in detail.",
      },
      {
        id: "31",
        question:
          "What documents of the property will be provided",
        answer:
          "The Lease/Rental Agreement, Title report, Sale Deed and SPV agreement are shared with the customers. It is also accessible in the customer’s dashboard.",
      },
      {
        id: "32",
        question:
          "What will be the proof of ownership",
        answer:
          "Property Sale Deed along with the SPV agreement copy constitutes proof of your ownership. The same details will be maintained in public databases, government records and your dashboard on the platform.",
      },
      {
        id: "33",
        question:
          "How will I exit from my property ownership",
        answer:
          "You can exit from your ownership in any property in 3 different ways:\
          > Resale market on our platform\
          You can list your fraction on Bricks’ resale market through your dashboard. Once a new customer has purchased your fraction, your sale proceeds will be credited in your registered bank account with us.\
          BrickBerry’ will facilitate the transfer of ownership and on boarding of the new customer on the platform.\
          > Property sale\
          BrickBerry will enable customers to sell the complete asset in 4-5 years. Basis on majority customers’ decision for a specific property, BrickBerry will initiate the liquidation of the property. Once the property is sold, you will be credited your sale proceeds in your registered bank account with us. If the customers vote to hold the property, the ownership will continue as it is with the monthly rental inflow into the customers registered bank account.",
      },
      {
        id: "34",
        question:
          "What are the yield expectations",
        answer:
          "Rental yield varies from property to property and is in the range of 7-9% for a Grade A commercial asset. Also, average annual rental escalation of 5% in a Grade A commercial asset ensures substantial capital appreciation for the customer at the point of sale. With BrickBerry unique expertise in the commercial real estate industry, we strive to maximize the capital gains on behalf of our customers at the point of sale. Please visit the property specific page to understand in detail.",
      },
      {
        id: "35",
        question:
          "Do I need to be physically present in order to invest",
        answer:
          "It depends. If you are investing in a property that is offered under Green / LLP model, then your investment can be made online. If the property is offered under Blue / Traditional model, then you (or someone on your behalf) needs to be physically present to complete the investment.",
      },
];

let FAQ=()=>{
    let [open,setOpen]=useState(null);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return(
        <React.Fragment>
            <Navbar activeDropdown={"FAQ"}/>
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{minHeight:"200px",maxHeight:"250px"}} src={FAQImg}/>
                    <p style={{fontWeight:"800",fontSize:"40"}}
                       className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        Frequently Asked Questions</p>
                </div>
            </div>
            <div className="container my-5" style={{position:"relative"}}>
            <div className="row justify-content-around">
                <div className="col-md-3 p-0 m-0 hexagonMainCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                    <div className="m-md-2 hexagonCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                    <div style={{position:"absolute "}}>
                        <img height={300} width={300} src={propertyBGImg} alt="" className="img-fluid hexagonCardImg"/>
                    </div>
                    <div className="text-center hexagonCardLogoDiv" style={{position:"absolute",left:"37%",top:"4%"}}>
                        <img height={30} className=" hexagonCardLogo" src={propertyImgLogo} alt="" />
                    </div>
                   <div className="px-md-4 px-2 hexagonCardText"  style={{position:"relative",top:"20%",right:"5%",width:"inherit"}}>
                       <p className="text-center pr-4 mb-0" style={{fontWeight:"400",fontSize:"20px"}}>Property</p>
                       <p className="mr-md-2 mx-2 mt-md-2 mt-0 " style={{fontWeight:"400",fontSize:"14px",lineHeight:"18px"}}>We are all about properties! learn anything related to our
                           properties.</p>
                   </div>
                    </div>
                </div>
                <div className="col-md-3 p-0 m-0 hexagonMainCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                    <div className="m-md-2 hexagonCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                    <div style={{position:"absolute"}}>
                        <img height={300} width={300} src={byuingBGImg} alt="" className="img-fluid hexagonCardImg"/>
                    </div>
                    <div className="text-center hexagonCardLogoDiv" style={{position:"absolute",left:"37%",top:"4%"}}>
                        <img height={30} className=" hexagonCardLogo" src={buyingImgLogo} alt="" />
                    </div>
                    <div className="px-md-5 px-2 hexagonCardText" style={{position:"relative",top:"20%",right:"5%",width:"inherit"}}>
                        <p className=" px-0 mb-0" style={{fontWeight:"400",fontSize:"20px"}}>Buying and selling</p>
                        <p className="mr-md-2 mx-2 mt-md-2 mt-0 " style={{fontWeight:"400",fontSize:"14px",lineHeight:"18px"}}>Learn the process of buying and selling your bricks</p>
                    </div>
                    </div>
                </div>
                    <div className="col-md-3 p-0 m-0 hexagonMainCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                        <div className="m-md-2 hexagonCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                        <div style={{position:"absolute"}}>
                            <img  src={feesImg} alt="" height={300} width={300}  className="img-fluid hexagonCardImg"/>
                        </div>
                        <div className="text-center hexagonCardLogoDiv" style={{position:"absolute",left:"37%",top:"4%"}}>
                            <img height={30} className=" hexagonCardLogo" src={feesImgLogo} alt="" />
                        </div>
                        <div className="px-md-5 px-2 hexagonCardText" style={{position:"relative",top:"20%",right:"5%",width:"inherit"}}>
                            <p className="text-center pr-4 mb-0" style={{fontWeight:"400",fontSize:"20px"}}>Fees and legal</p>
                            <p className="mr-md-2 mx-2 mt-md-2 mt-0 " style={{fontWeight:"400",fontSize:"14px",lineHeight:"18px"}}>We explain the fees and the legal terms you may want to know  </p>
                        </div>
                        </div>
                    </div>
                <div className="col-md-3 p-0 m-0 hexagonMainCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                    <div className="m-md-2 hexagonCard" style={{position:"relative",maxHeight:"300px",height:"300px"}}>
                        <div style={{position:"absolute"}}>
                            <img  src={NRIbgImg} alt="" height={300} width={300}  className="img-fluid hexagonCardImg"/>
                        </div>
                        <div className="text-center hexagonCardLogoDiv" style={{position:"absolute",left:"37%",top:"4%"}}>
                            <img height={30} className=" hexagonCardLogo" src={feesImgLogo} alt="" />
                        </div>
                        <div className="px-md-5 px-2 hexagonCardText" style={{position:"relative",top:"20%",right:"5%",width:"inherit"}}>
                            <p className="text-center pr-4 mb-0" style={{fontWeight:"400",fontSize:"20px"}}>NRI Investing</p>
                            <p className="mr-md-2 mx-2 mt-md-2 mt-0 " style={{fontWeight:"400",fontSize:"14px",lineHeight:"18px"}}>Non Resident Indian investment related information </p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="container-fluid   py-md-5 py-2 my-md-5 my-4 FAQRow" style={{width:"80%"}} >
                {FAQArray.map((FAQ)=>{
                    return(
                        <div key={FAQ.id} className=" my-3">
                        <div className={open === FAQ.id ? "border border-top-0 py-1 px-md-4 px-2":"border py-1 px-md-4 px-2"} >
                            <div className="d-flex my-auto py-2 align-items-center justify-content-between px-4">
                                <p className="mb-0 my-auto" style={{fontWeight:"400",fontSize:"18px"}}>{FAQ.question}?</p>
                                <div className="d-flex justify-content-center align-items-center" style={{position:"relative"}}>
                                    {open !== FAQ.id ?
                                    <React.Fragment>
                                        <img alt="" height={30} style={{position:"absolute",cursor:"pointer"}} onClick={()=>{setOpen(FAQ.id)}} src={pinkCircleImg}/>
                                            <p className="mb-0 text-white" onClick={()=>{setOpen(FAQ.id)}} style={{position:"relative",cursor:"pointer"}}>+</p>
                                    </React.Fragment>: <React.Fragment>
                                            <img alt="" height={30} style={{position:"absolute",cursor:"pointer"}} onClick={()=>{setOpen(null)}} src={pinkCircleImg}/>
                                            <p className="mb-0 text-white" onClick={()=>{setOpen(null)}} style={{position:"relative",cursor:"pointer"}}>-</p>
                                        </React.Fragment>}
                                </div>
                                    </div>

                        </div>
                    {open === FAQ.id &&
                    <div className="d-flex px-5 py-3 align-items-center border" style={{fontWeight:"400",fontSize:"16px"}}>
                        <p className="mb-0">{FAQ.answer}</p>
                    </div>}
                        </div>
                    )
                })}

            </div>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default FAQ;
