import React from "react";
import buildingHandLogo from "../../../assets/img/buildingHandLogo.png";
import rupeesHandImg from "../../../assets/img/rupeesHandImg.png";
import shareMarketRupee from "../../../assets/img/shareMarketRupee.png";
import calender from "../../../assets/img/calender.png";
import earnAnyLogo from "../../../assets/img/earnAnyLogo.png";
import { useHistory } from 'react-router-dom';

let HowItWorks = () => {
    const history = useHistory();
    return (
        <React.Fragment>
            <div id="howItWorks" className="mt-4" style={{ backgroundColor: "rgba(238, 239, 255, 0.43)", paddingTop: "10px", paddingLeft: "10px", paddingRight: "10px", paddingBottom: "5px" }}>
                <div className="container">
                    <p className=" mx-auto" style={{ height: "5px", width: "50px", backgroundColor: "rgb(22, 142, 186)" }}></p>
                    <h4 className="h1 text-center mb-0" style={{ fontWeight: "700", fontSize: "40px" }}> How It Works</h4>
                    <p className="text-center" style={{ color: "rgba(238, 239, 255, 0.43)", fontSize: "14px", fontWeight: "bolder" }}>[A brick represents 1 SFT value of property]</p>
                    <div className="d-md-flex d-sm-block justify-content-around flex-wrap">
                        <div className="col-md-2 text-center">
                            <p className="badge shadow-none mt-2 mr-3 text-center rounded-circle text-white" style={{ backgroundColor: "rgb(22, 142, 186)" }}>1</p>
                            <p className="mb-0 text-center d-flex justify-content-center align-items-center" style={{ color: "#ff8282" }}><img className="my-3" height={30} width={30} src={buildingHandLogo} alt="" /></p>
                            <p className="text-left font-weight-bolder ml-3">We hand-pick quality properties and divide into fractional units called BRICKS</p>
                        </div>
                        <div className="col-md-2 text-center">
                            <p className="badge shadow-none mt-2 mr-2 text-center rounded-circle text-white" style={{ backgroundColor: "rgb(22, 142, 186)" }}>2</p>
                            <p className="mb-0 text-center d-flex justify-content-center align-items-center" style={{ color: "#ff8282" }}>
                                <img className="my-3" height={30} width={30} src={rupeesHandImg} alt="" /></p>
                            <p className="text-left font-weight-bolder ml-3">Select the bricks of your choosing (or) optimize your selection with our Investment Manager</p>
                        </div>
                        <div className="col-md-2 text-center">
                            <p className="badge shadow-none mt-2 mr-2 text-center rounded-circle text-white" style={{ backgroundColor: "rgb(22, 142, 186)" }}>3</p>
                            <p className="mb-0 text-center  d-flex justify-content-center align-items-center" style={{ color: "#ff8282" }}>
                                <img className="my-3" height={30} width={30} src={shareMarketRupee} alt="" /></p>
                            <p className="text-left font-weight-bolder  ml-4">Invest on your selected portfolio &watch them grow with our AI Smart Dashboard</p>
                        </div>
                        <div className="col-md-2 text-center">
                            <p className="badge shadow-none mt-2 mr-2 text-center rounded-circle text-white" style={{ backgroundColor: "rgb(22, 142, 186)" }}>4</p>
                            <p className="mb-0 text-center  d-flex justify-content-center align-items-center" style={{ color: "#ff8282" }}>
                                <img className="my-3" height={30} width={30} src={calender} alt="" /></p>
                            <p className="text-left font-weight-bolder ml-4">BRICKS in your portfolio will determine your net Rental Income you will earn</p>
                        </div>
                        <div className="col-md-2 text-center">
                            <p className="badge shadow-none mt-2 mr-2 text-center rounded-circle text-white" style={{ backgroundColor: "rgb(22, 142, 186)" }}>5</p>
                            <p className="mb-0 text-center  d-flex justify-content-center align-items-center" style={{ color: "#ff8282" }}>
                                <img className="my-3" height={30} width={30} src={earnAnyLogo} alt="" /></p>
                            <p className="text-left font-weight-bolder ml-4">Exit anytime & Selling the portfolio of your BRICKS will earn you Capital returns</p>
                        </div>
                    </div>
                    <div className="text-center mt-3 mb-2">
                        <button onClick={() => {
                            window.scrollTo(0, 0);
                            history.push("/HowItWorks")
                        }} className="btn btn-lg px-4 py-3 font-weight-bold text-capitalize text-white" style={{ background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)", borderRadius: "10px" }}>
                            Learn How It Works
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default HowItWorks;