import React from "react";

let NRIInIndianCompanies = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>NRI Real Estate Investment in Indian
                        Companies</h2>
                    <div className="mt-4">

                        <p> Moving further, a wide range of investment companies offer investment plans specially
                            designed
                            for NRI investors. They pay attention to an NRI’s needs and the differences present in the
                            investment scenario of a citizen and an NRI. Remember that these investment companies have
                            to
                            abide by the rules and regulations of FDI policy.</p>

                        <p className="my-3" style={{fontWeight: "600"}}>Investment in Private Limited Companies:</p>

                        <p> NRIs can choose to make investments in real estate employing a private limited company. This
                            includes options like:</p>
                        <div className="mx-md-5 mx-1">
                            <p className="mb-0">• Opening an independent company of Indian origin. The investor can do
                                so as a sole
                                proprietor of the company or open a venture by partnering with an Indian
                                organization.</p>
                            <p className="mb-0">• The NRI investor can also open a company that can be structured as a
                                foreign organization
                                that has been registered under MCA.</p>
                            <p className="mb-0">• The NRI investor can also invest through a private limited company
                                that operates in the
                                form of either a Liaison Office or certain branch and project offices.</p>

                        </div>


                        <p className="my-3" style={{fontWeight: "600"}}>Investment in Partnership:</p>

                        This is another investment medium for NRIs where the investor can help contribute to the capital
                        via partnership firm. The investor is allowed only when they fulfill the following
                        pre-requisites:
                        <div className="mx-md-5 mx-1 my-2">
                            <p className="mb-0">• The contribution to the capital is made on without repatriations.</p>
                            <p className="mb-0">• The NRI investment is completed in the form of inward remittance.</p>
                            <p className="mb-0">• The partnership firm in India should not be indulging in properties
                                like agriculture or real estate businesses.</p>

                        </div>


                        <p className="my-3" style={{fontWeight: "600"}}>Investment in Limited Liability Partnership:</p>

                        <p>The investment in Limited Liability Partnerships or LLPs was recently made available to NRI
                        investors with the motive of increasing foreign investments. The investor can enjoy a 100%
                        allowance of FDI in LLPs investments. However, there are certain rules for the NRI investor to
                        adhere to. LLPs can further conjoin the investments into bigger LLP and acquire cumulative
                        returns. The NRI investor can also recruit certain foreign institutions to invest in LLPs. In
                        this case, the NRI investor or the foreign partner shall be recruited as Designated Partner (as
                            per the Limited Liability Partnership act, 2008).</p>

                        <p className="my-3" style={{fontWeight: "600"}}>Investment in Small-scale Industries:</p>

                        There is now an emerging trend where NRI investors have now started investing in the small-scale
                        industrial sector. NRIs are allowed to do so, provided the investment is limited up to around
                        24% of the paid capital. If an NRI investor wishes to invest more than the limit, then the
                        small-scale industry would have to:
                        <div className="my-2 mx-md-5 mx-1">

                            <p className="mb-0">•	Relieve itself from the status of SSI.</p>
                            <p className="mb-0">•	The industry should not manufacture the items that are reserved.</p>
                            <p className="mb-0">•	The SSI has to match the cap rate prevalent for the sector.</p>
                            NRI investors are allowed to invest in most of the SSIs except for the prohibited sectors.
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
};
export default NRIInIndianCompanies;
