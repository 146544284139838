import React, { useEffect, useState } from "react";
import Homepage from "./homepageComponents/Homepage";
import HowItWorks from "./homepageComponents/HowItWorks";
import OurSelectListing from "./homepageComponents/OurSelectListing";
import BrickBerryProperties from "./homepageComponents/BrickBerryProperties";
import WhyChoseBrick from "./homepageComponents/WhyChoseBrick";
import FractionalOwnership from "./homepageComponents/FractionalOwnership";
import CommercialRealEstate from "./homepageComponents/CommercialRealEstate";
import QuickNEasy from "./homepageComponents/QuickNEasy";
import OurMembers from "./homepageComponents/OurMembers";
import WhyBrickBerry from "./homepageComponents/WhyBrickBerry";
import OurSelectListingNew from "./homepageComponents/OurSelectListingNew";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Buildings from "./homepageComponents/Buildings";
import WhyChoseBrickberry from "./homepageComponents/WhyChoseBrickberry";
import BottomLogo from "./homepageComponents/BottomLogo";
import * as BrickApiService from "../../services/ApiServices";

let HomePageMain = () => {
    const [propertiesList, setPropertiesList] = useState([]);
    useEffect(() => {
        BrickApiService.getPropertiesList().then((response) => {
            if (response && response.data) {
                setPropertiesList(response.data);
            }
        });
    }, []);
    return (
        <React.Fragment>
            <Navbar active={"home"} />
            <Homepage />
            <HowItWorks />
            {/*<OurSelectListing/>*/}
            <OurSelectListingNew properties={propertiesList.Properties} />
            <BrickBerryProperties />
            <WhyChoseBrickberry />
            {/*<WhyBrickBerry/>*/}
            <FractionalOwnership />
            <CommercialRealEstate />
            <QuickNEasy />
            <OurMembers />
            <BottomLogo />
            <Buildings />
            <Footer />
        </React.Fragment>
    );
};
export default HomePageMain;
