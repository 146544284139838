import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BgImage from "../viewProperties/assets/propertiesBuildingBgImg.png";
import calendar from "../dashboard/assets/calendar.png";
import RTGSImg from "./assets/RTGSLogo.png";
import GetInTouch from "./GetInTouch";
import Link from "react-router-dom/Link";
import Navbar from "../Navbar";
import Axios from "axios";
import {getUser} from "../../utils/localStorage";

let PurchaseBricksConfirm = () => {
    const location=useLocation();
  let [show, setShow] = useState("banking");
  let [purchaseID,setPurchaseID]=useState(null);
  let [message,setMessage]=useState(null);
  let [confirmObject, setConfirmObject] = useState({
    UserID:null,
    Name: "",
    PANCard: "",
    BankAccountNumber: "",
    IFSCCode: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    setPurchaseID(location.pathname.split("/")[5]);
    setConfirmObject({
        ...confirmObject,
        UserID:getUser()
    });
  }, [purchaseID]);
  let handleBanking = (e) => {
    setConfirmObject({
      ...confirmObject,
      [e.target.name]: e.target.value,
    });
  };
  let confirmPurchase = (e) => {
      e.preventDefault();
      sendConfirmation();

  };
  let sendConfirmation= async ()=>{
    let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      let dataURL = `${process.env.REACT_APP_API_URL}/purchaserequests/${purchaseID}`;
      await Axios.put(dataURL, JSON.stringify(confirmObject), config)
        .then((response) => {
          if (response.data.Status === "1" || response.data.Status === 1) {
            setMessage(response.data.Message);
            setShow("CALL")
          } else if (response.data.Status === "0" || response.data.Status === 0) {
            setMessage(response.data.Message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
  }
  return (
    <React.Fragment>
      <Navbar active={"properties"} />
      <div className="div">
        <img
          src={BgImage}
          style={{ minHeight: "300px", maxHeight: "370px", width: "100%" }}
          alt=""
          className="img-fluid"
        />
      </div>
      <div
        className="container mx-auto pb-3 mb-5 border px-0 mx-0"
        style={{ marginTop: "100px", position: "relative" }}
      >
        <p
          className="text-center mb-0 mt-3 mb-3"
          style={{ fontWeight: "700", fontSize: "40px", color: "#FF8282" }}
        >
          Confirmed
        </p>
        <div className="container d-flex justify-content-center">
          <div
            className="d-flex flex-wrap justify-content-md-between justify-content-center px-4 py-2 my-2"
            style={{ fontSize: "15px", width: "70%" }}
          >
            <div
              // className=' py-2  my-1  text-center rounded'
              className={
                show === "banking"
                  ? "py-2 my-1  text-center rounded"
                  : "py-2 my-1 disabled text-center rounded"
              }
              // onClick={()=>setShow("banking")}
              style={
                show === "banking"
                  ? {
                      border: "1px solid #f8dbdb",
                      width: "180px",
                      cursor: "pointer",
                      backgroundColor: "#ff8282",
                      color: "white",
                    }
                  : {
                      border: "1px solid #e9e9e9",
                      width: "180px",
                      cursor: "pointer",
                      backgroundColor: "white",
                      color: "black",
                    }
              }
            >
              My BANKING INFO
            </div>
            <div
              // className=' py-2 my-1  text-center'
              className={
                show === "CALL"
                  ? "py-2 my-1  text-center rounded"
                  : "py-2 my-1 disabled text-center rounded"
              }
              // onClick={()=>setShow("CALL")}
              style={
                show === "CALL"
                  ? {
                      border: "1px solid #f8dbdb",
                      width: "180px",
                      cursor: "pointer",
                      backgroundColor: "#ff8282",
                      color: "white",
                    }
                  : {
                      border: "1px solid #e9e9e9",
                      width: "180px",
                      cursor: "pointer",
                      backgroundColor: "white",
                      color: "black",
                    }
              }
            >
              SCHEDULE CALL
            </div>
            <div
              // className=' py-2 my-1  text-center rounded'
              className={
                show === "RTGS"
                  ? "py-2 my-1  text-center rounded"
                  : "py-2 my-1 disabled text-center rounded"
              }
              // onClick={()=>setShow("RTGS")}
              style={
                show === "RTGS"
                  ? {
                      border: "1px solid #f8dbdb",
                      width: "180px",
                      cursor: "pointer",
                      backgroundColor: "#ff8282",
                      color: "white",
                    }
                  : {
                      border: "1px solid #e9e9e9",
                      width: "180px",
                      cursor: "pointer",
                      backgroundColor: "white",
                      color: "black",
                    }
              }
            >
              NEFT/RTGS Transfer
            </div>
          </div>
        </div>
        {/*-------------- Banking --------------*/}
        {show === "banking" && (
          <div className="container d-flex justify-content-center px-md-5 px-2">

            <div className="" style={{ width: "70%" }}>
                <form onSubmit={confirmPurchase}>
              <div className="">
                <p className="mb-0">Purchase Confirmation No: {purchaseID}</p>
                <div className="row my-3 align-items-center">
                  <div className="col-md-6">
                    <div className="">
                      <label className="ml-1">Name</label>
                      <input
                        type="text"
                        name="Name"
                        onChange={handleBanking}
                        required={true}
                        style={{ height: "35px", border: "1px solid #FF8282" }}
                        className="bg-transparent form-control py-1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <label className="ml-1">Account Number</label>
                      <input
                        type="number"
                        name= "BankAccountNumber"
                        required={true}
                        onChange={handleBanking}
                        style={{ height: "35px", border: "1px solid #FF8282" }}
                        className="bg-transparent form-control py-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row my-3 align-items-center">
                  <div className="col-md-6">
                    <div className="">
                      <label className="ml-1">Pan card number</label>
                      <input
                        type="text"
                        name= "PANCard"
                        minLength="10"
                        maxLength="10"
                        pattern={"[a-zA-Z0-9]+"}
                        title="Special characters not allowed"
                        required={true}
                        onChange={handleBanking}
                        style={{ height: "35px", border: "1px solid #FF8282",textTransform:"uppercase" }}
                        className="bg-transparent form-control py-1"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <label className="ml-1">IFSC code</label>
                      <input
                        type="text"
                        required={true}
                        name= "IFSCCode"
                        onChange={handleBanking}
                        pattern={"[a-zA-Z0-9]+"}
                        style={{ height: "35px", border: "1px solid #FF8282",textTransform:"uppercase" }}
                        className="bg-transparent form-control py-1"
                      />
                    </div>
                  </div>
                </div>
                {message && <p className="text-center mt-3 mb-2" style={{color:"#FF8282"}}>{message}</p>}
                <div className="text-center my-4">
                  <input
                  type="submit"
                    className="btn text-capitalize text-white px-5 py-2 text-center"
                    // onClick={() => setShow("CALL")}
                    style={{
                      backgroundColor: "#FF8282",
                      borderRadius: "10px",
                      width: "180px",
                    }}
                    value="Submit"
                  />
                    
                </div>
                <div className="text-center">
                  <p className="mb-0">
                    Note: The above requested Bank details are just for our
                    reference purpose to verify the amount transfer.{" "}
                  </p>
                  <p className="mb-0">
                    No Payment will be deducted at this point.
                  </p>
                </div>
              </div>
                </form>
            </div>
          </div>
        )}

        {/*-------------- CALL --------------*/}
        {show === "CALL" && (
          <div className="container d-flex justify-content-center px-md-5 px-2">
            <div className="" style={{ width: "70%" }}>
              <div className="">
                <p className="mb-0">Purchase Confirmation No: BB01-01</p>
                <div className="row my-5 align-items-center">
                  <div className="col-md-6">
                    <div>
                      <div className="position-relative mx-2 datePicker d-flex">
                        <input
                          type="date"
                          style={{
                            height: "35px",
                            border: "1px solid #FF8282",
                            position: "relative",
                          }}
                          className="bg-transparent form-control py-1"
                        />
                        <img
                          alt=""
                          style={{
                            position: "absolute",
                            right: "10%",
                            top: "20%",
                            height: "20px",
                          }}
                          src={calendar}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div>
                      <div className="position-relative mx-2 timePicker d-flex">
                        <input
                          type="time"
                          style={{
                            height: "35px",
                            border: "1px solid #FF8282",
                            position: "relative",
                          }}
                          className="bg-transparent form-control py-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center my-4">
                <div
                  className="btn text-capitalize text-white px-5 py-2 text-center"
                  onClick={() => setShow("RTGS")}
                  style={{
                    backgroundColor: "#FF8282",
                    borderRadius: "10px",
                    width: "180px",
                  }}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        )}

        {/*-------------- RTGS --------------*/}
        {show === "RTGS" && (
          <div className="container d-flex justify-content-center px-md-5 px-2">
            <div className="widthContainer" style={{ width: "70%" }}>
              <div
                className="p-3"
                style={{
                  backgroundColor: "#FFE2DB",
                  border: "1px solid #FF8282",
                }}
              >
                <p className="mb-0 text-center">
                  Purchase Confirmation No: &nbsp;
                  <span style={{ color: "#F77B73" }}>BB01-01</span>
                </p>
                <div className="row my-2 justify-content-around">
                  <div className="col-md-7 pl-3 pt-3 pb-0">
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      Bank Name: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>
                        Kotak Mahindra Bank
                      </span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      Branch Name: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>
                        SOMAJIGUDA, Hyderabad
                      </span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      Account Name: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>
                        MERKLEHASH INNOVATIONS PVT LTD
                      </span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      Account Number : &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>7777900777</span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      IFSC Code: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}> KKBK0000552</span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      Account Type: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>Escrow Account</span>
                    </p>
                  </div>
                  <div className="col-md-5 pt-3 pb-0 ">
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      CIN: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>
                        U72900TG2019PTC136512
                      </span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      GST: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>36AANCM0416E1ZQ</span>
                    </p>
                    <p className="mb-0 my-1" style={{ fontSize: "15px" }}>
                      PAN: &nbsp; &nbsp;
                      <span style={{ color: "#F77B73" }}>AANCM0416E</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div
                  className=" mb-5 mt-0"
                  style={{
                    marginTop: "0px",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  <div
                    className="d-md-flex d-block align-items-center "
                    style={{ position: "relative" }}
                  >
                    <div
                      className="triangleLeftPolygon  p-2"
                      style={{ position: "relative", height: "350px" }}
                    >
                      <div
                        className="triangleContent"
                        style={{
                          position: "absolute",
                          top: "28%",
                          left: "10%",
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <img src={RTGSImg} height={20} alt="" />
                          <p className="mb-0 mx-4 text-white">RTGS</p>
                        </div>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Rs.2 lakhs limit
                        </p>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Gross Settlement
                        </p>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Immediate Settlement
                        </p>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Costly Transaction Fees
                        </p>
                      </div>
                    </div>
                    <div
                      className="triangleCenterPolygon"
                      style={{
                        position: "absolute",
                        left: "40.3%",
                        zIndex: "2",
                      }}
                    >
                      <p className="text-white mb-0 text-center p-5">
                        Money <br /> Transfer
                      </p>
                    </div>
                    <div
                      className="triangleRightPolygon  p-2"
                      style={{ position: "relative", height: "300px" }}
                    >
                      <div
                        className="triangleContent"
                        style={{
                          position: "absolute",
                          top: "25%",
                          right: "10%",
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-center">
                          <img src={RTGSImg} height={20} alt="" />
                          <p className="mb-0 mx-4 text-white">NEFT</p>
                        </div>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          No minimum limit
                        </p>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Net Settlement
                        </p>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Take 2 hours
                        </p>
                        <p className="mb-0 my-2 text-white d-flex align-items-center">
                          <span
                            className="mx-3"
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                            }}
                          ></span>
                          Smaller Transaction Fees
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <GetInTouch />
    </React.Fragment>
  );
};
export default PurchaseBricksConfirm;
