import React from "react";

let ViewPropertyRiskAnalysis = ({ propertyDeatils }) => {
  const propertyListing = propertyDeatils || [];
  return (
    <React.Fragment>
      <div className="container my-4" id="riskAnalysis">
        <p
          className="text-center"
          style={{ fontSize: "35px", fontWeight: "700" }}
        >
          Risk Analysis
        </p>
        <p className="">
          Brickberry team takes pride in the amount of due diligence and risk
          mitigation steps it takes before taking up any investment opportunity
          for the investor. Let us get down to analysing different types of
          risks associated with any real estate transaction, and how this
          opportunity has those risks addressed.
        </p>
      </div>
      <div className="container my-5">
        <div>
          <div
            className="p-4 my-5"
            style={{ border: "1px solid #FF8282", position: "relative" }}
          >
            <p
              className="mb-0 text-center bg-white riskAnalysisCircleTab"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                border: "1px solid #FF8282",
                position: "absolute",
                top: "-20%",
                left: "37%",
                borderRadius: "30px",
                width: "270px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Execution Risk
            </p>
            <p className="mb-0 mt-4 mb-2">{propertyListing.ExecutionRisk}</p>
          </div>
          <div
            className="p-4 my-5"
            style={{ border: "1px solid #FF8282", position: "relative" }}
          >
            <p
              className="mb-0 text-center bg-white riskAnalysisCircleTab"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                border: "1px solid #FF8282",
                position: "absolute",
                top: "-20%",
                left: "37%",
                borderRadius: "30px",
                width: "270px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Title Risk
            </p>
            <p className="mb-0 mt-4 mb-2">{propertyListing.TitleRisk}</p>
          </div>
          <div
            className="p-4 my-5"
            style={{ border: "1px solid #FF8282", position: "relative" }}
          >
            <p
              className="mb-0 text-center bg-white riskAnalysisCircleTab"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                border: "1px solid #FF8282",
                position: "absolute",
                top: "-20%",
                left: "37%",
                borderRadius: "30px",
                width: "270px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Financial Risk
            </p>
            <p className="mb-0 mt-4 mb-2">{propertyListing.FinancialRisk}</p>
          </div>
          <div
            className="p-4 my-5"
            style={{ border: "1px solid #FF8282", position: "relative" }}
          >
            <p
              className="mb-0 text-center bg-white riskAnalysisCircleTab"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                border: "1px solid #FF8282",
                position: "absolute",
                top: "-20%",
                left: "37%",
                borderRadius: "30px",
                width: "270px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Exit Risk
            </p>
            <p className="mb-0 mt-4 mb-2">{propertyListing.ExitRisk}</p>
          </div>
          <div
            className="p-4 my-5"
            style={{ border: "1px solid #FF8282", position: "relative" }}
          >
            <p
              className="mb-0 text-center bg-white riskAnalysisCircleTab"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                border: "1px solid #FF8282",
                position: "absolute",
                top: "-20%",
                left: "37%",
                borderRadius: "30px",
                width: "270px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Investment Risk
            </p>
            <p className="mb-0 mt-4 mb-2">{propertyListing.InvestmentRisk}</p>
          </div>
          <div
            className="p-4 my-5"
            style={{ border: "1px solid #FF8282", position: "relative" }}
          >
            <p
              className="mb-0 text-center bg-white riskAnalysisCircleTab"
              style={{
                fontSize: "18px",
                fontWeight: "500",
                border: "1px solid #FF8282",
                position: "absolute",
                top: "-20%",
                left: "37%",
                borderRadius: "30px",
                width: "270px",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              Regulation Risk
            </p>
            <p className="mb-0 mt-4 mb-2">{propertyListing.RegulationRisk}</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertyRiskAnalysis;
