import React, {useEffect, useState} from "react";
import {Link,useParams} from "react-router-dom";
import Axios from "axios";
import parse from 'html-react-parser';
import moment from 'moment';
import facebookDarkLogo from "../assets/facebookDarkLogo.png";
import twitterDarkLogo from "../assets/twitterDarkLogo.png";
import linkedInDarkLogo from "../assets/linkedInDarkLogo.png";
import instagramDarkLogo from "../assets/instagramDarkLogo.png";
import gmailDarkLogo from "../assets/gmailDarkLogo.png";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";



let Blogview=(props)=>{
    let [blog,setBlog]=useState([]);
    let [blogDescription,setBlogDescription]=useState([]);
    let { id } = useParams();
    useEffect(  ()=>{
        window.scrollTo(0,0);
        let dataURL=`${process.env.REACT_APP_API_URL}/blog/${id}`;
        Axios.get(dataURL).then((response) => {
            setBlog(response.data.Blog);
            console.log("blog fetched success");
            let description=JSON.stringify(response.data.Blog.Description);
            setBlogDescription(description)
        }).catch((err) => {
            console.log(err)
        });
    },[ ]);


    return(
        <React.Fragment>
            <Navbar activeDropdown={"newsAndArticle"}/>
            <div className="container">
                <div className="mx-md-5 mx-2  my-4" >
                    <div className="container-fluid p-0 m-0">
                        <div className="d-flex flex-wrap justify-content-md-between justify-content-center">
                            <div className="mx-auto mx-4">
                                {blog.Title && <p className="mb-0 mr-auto text-left " style={{fontSize:"22px",fontWeight:"normal"}}>Blogs Details</p>}
                                {/*{!blog.Title && <p className="mb-0 mx-auto text-center" style={{fontSize:"30px",fontWeight:"normal"}}>No blog found</p>}*/}
                            </div>
                        </div>
                        {blog.Title &&
                        <div style={{position:"relative"}}>
                            <div className="bg-white px-md-4 px-3 my-4" style={{paddingTop:"30px",paddingBottom:"30px",position:"relative"}} >
                                <React.Fragment>
                                    <div className="card shadow-none">
                                        <div className=" p-0 m-0">
                                            <div className="d-flex justify-content-center m-auto">
                                                <img
                                                    src={blog?.PreviewImageURL}
                                                    // src={propertyAnnouncementImg}
                                                    style={{maxHeight:"500px"}} alt="" className="img-fluid my-auto"/>
                                            </div>

                                            <div className="  m-0 px-0 mt-4" >
                                                <div className="px-md-0 px-2 pt-2 "  >
                                                    <p className="h4 mb-3" style={{color:"#FF8282",fontWeight:"700",fontSize:"20px",textTransform:"uppercase"}}>
                                                        {blog?.Title}
                                                    </p>
                                                    <p className="h2  mb-3" style={{fontWeight:"400",fontSize:"25px"}}>
                                                        {blog?.Category}
                                                    </p>
                                                    <div className="my-2">
                                                        <p className="text-left  mb-0 ">By
                                                            {blog?.Author} - {moment(blog?.CreatedAt).format('LL')}
                                                        </p>
                                                    </div>
                                                    <div className=""   >

                                                        <div>{parse(blog.Description,{ trim: true })}</div>
                                                        {/*<div>{blog?.Description}</div>*/}
                                                    </div>
                                                    <div className="mt-5 ">
                                                        <p className="mb-0" style={{fontSize:"20px",fontWeight:"400"}}>Share this article</p>
                                                        <div className="d-flex align-items-center my-2">
                                                            <a href="https://linkedin.com/" rel="noopener noreferrer" target="_blank">
                                                                <img src={linkedInDarkLogo} className="mr-2 blogShareIcons"
                                                                     style={{height:"13px",cursor:"pointer"}} alt=""/>
                                                            </a>
                                                            <a href="https://facebook.com/" rel="noopener noreferrer" target="_blank">
                                                                <img src={facebookDarkLogo} className="mx-2 blogShareIcons"
                                                                     style={{height:"13px",cursor:"pointer"}} alt=""/>
                                                            </a>
                                                            <a href="https://instagram.com/" rel="noopener noreferrer" target="_blank">
                                                                <img src={instagramDarkLogo} className="mx-2 blogShareIcons"
                                                                     style={{height:"13px",cursor:"pointer"}} alt=""/>
                                                            </a>
                                                            <a href="https://twitter.com/" rel="noopener noreferrer" target="_blank">
                                                                <img src={twitterDarkLogo} className="mx-2 blogShareIcons"
                                                                     style={{height:"13px",cursor:"pointer"}} alt=""/>
                                                            </a>
                                                            <a href="https://gmail.com/" rel="noopener noreferrer" target="_blank">
                                                                <img src={gmailDarkLogo} className="mx-2 blogShareIcons"
                                                                     style={{height:"13px",cursor:"pointer"}} alt=""/>
                                                            </a>
                                                            {/*<i className="fab m-2 fa-linkedin blogShareIcons" />*/}
                                                            {/*<i className="fab m-2 fa-facebook blogShareIcons" />*/}
                                                            {/*<i className="fab m-2 fa-instagram blogShareIcons" />*/}
                                                            {/*<i className="fab m-2 fa-twitter blogShareIcons" />*/}
                                                            {/*<i className="fab m-2 fa-envelope-o blogShareIcons" />*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>

                        </div>
                        }



                    </div>
                </div>
            </div>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default Blogview;
