import React from "react";

let RulesForInvestment = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>Rules and Regulations for NRI Real Estate
                        Investments
                    </h2>
                    <div className="mt-4">

                        <p> There are a certain set of rules and regulations that have been laid out by the FEMA for NRI
                            investors. Investors from outside India must keep a check on these rules and abide by them
                            as
                            they affect the investment procedures. Failure to follow these rules may lead to
                            disqualification of investment.</p>

                        <p>Payment Rules:</p>

                        <p>These rules affirm that the NRI investor has to make investments abiding by the Indian
                            payment
                            forms. This can either be done through the NRE or NRO accounts, as well as through
                            investments
                            in other companies. The rules also state the payable taxes upon the property sale and
                            generation
                            of the long-term as well as short-term capital gains. The guidelines of the payment rules
                            are:</p>
                        <div className="mx-md-5 mx-1">
                            <p className="mb-0">a. NRIs can make payments from the endowments made through Indian banks
                                as a remittance.</p>
                            <p className="mb-0">b. NRIs can also pay investments through NRE or NRO accounts.</p>
                            <p className="mb-0">c. The NRIs cannot pay for their investments in the form of traveler’s
                                cheque or foreign
                                currency.</p>
                            <p className="mb-0">d. NRIs cannot make investment payments outside India.</p>
                        </div>

                        <p className="my-3">Foreign Exchange Management Act (FEMA) Rules:</p>

                        <p>These rules are specifically designed to manage the NRI investments and ensure that there are
                            no
                            fraudulent investment practices, and the NRI investors are making systematic investments.
                            The
                            FEMA rules are as follows:</p>
                        <div className="mx-md-5 mx-1">
                            <p className="mb-0">a. NRIs can acquire all available property types, excluding the
                                agricultural land, farmhouse,
                                and plantation areas.</p>
                            <p className="mb-0">b. NRIs have the right to invest in both residential real estate as well
                                as commercial real
                                estate property.</p>
                            <p className="mb-0">c. The earnings made from renting a property in India are taxable no
                                matter what the owner’s
                                residential status is.</p>
                            <p className="mb-0">d. Under any circumstances, if an NRI has purchased a real estate
                                property under construction,
                                he/she shall appoint an associate as in-charge</p>

                            e. NRIs are eligible for sanctioning of the loan.
                        </div>

                        <p className="my-3 ">Required Documents:</p>

                        <p className="mb-0">The documents required to make investments in India are</p>
                        <div className="mx-md-5 mx-1">
                            <p className="mb-0">a. Copy of Passport</p>
                            <p className="mb-0">b. Copy of Visa</p>
                            <p className="mb-0">c. PAN card</p>
                            <p className="mb-0">d. Identity proof</p>
                            <p className="mb-0">e. Proof of Address</p>
                            <p className="mb-0">f. PIO card</p>
                        </div>

                        <p className="mt-3 mb-0">Understanding The Permissions Granted By The Government:</p>
                        <div className="mx-md-5 mx-1">
                        <p className="mb-0">a. NRIs can make fund transfers as part of inward remittance.</p>
                        <p className="mb-0">b. NRIs can make investments and gift shares in Indian companies as
                            investments.</p>
                        <p className="mb-0">c. NRIs can make investments in mutual funds of both public and domestic
                            sectors on a
                            repatriable and non-repatriable basis.</p>
                        <p className="mb-0">d. NRIs have to make investments in Indian firms on a non-repatriable
                            basis.</p>
                        <p className="mb-0">e. NRIs can sell their shares in any investment schemes as a stock
                            exchange.</p>
                        <p className="mb-0">f. NRIs can sell their investment deposits or share them with another NRI or
                            resident as a stock
                            transfer under a private arrangement.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};
export default RulesForInvestment;
