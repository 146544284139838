import React, { useState, useEffect } from "react";
import 'bootstrap-daterangepicker/daterangepicker.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SideNav from "../SideNav";
import NavbarDashboard from "../NavbardDashboard";
import documentUploadLogo from "../assets/documentUploadLogo.png";
import downloadLogo from "../assets/downloadLogo.png";
import dropdownArrow from "../assets/dropdownArrow.png";
import calendar from "../assets/calendar.png";
import { SPVSTATEMENT, SPVSTATEMENTDate } from '../../../services/ApiServices'
import styles from "../dashboard.css";
let SPVStatementContent = () => {
    let [documents, setDocuments] = useState([]);
    useEffect(() => {
        SPVSTATEMENT(localStorage.getItem('user'), 0).then((response) => {
            console.log(response)
            setDocuments(response.InvestorTransactions);
        });
    }, [])
    let handleDateRange = (start, end, label) => {
        const fromDate = new Date(start._d)
        const toDate = new Date(end._d)
        const from = `${fromDate.getFullYear()}-${fromDate.getMonth()}-${fromDate.getDate()}`
        const to = `${toDate.getFullYear()}-${toDate.getMonth()}-${toDate.getDate()}`
        console.log(from)
        SPVSTATEMENTDate(localStorage.getItem('user'), 0, from, to).then((response) => {
            console.log(response)
            setDocuments(response.InvestorTransactions);
        });
    };
    return (
        <React.Fragment>
            <div className="">
                <div className="row justify-content-around px-4 py-1 mt-2 mb-0"
                    style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "#ededed" }}>
                    <p className="text-center py-2 mb-0" style={{ fontWeight: "700", fontSize: "18px" }}>Your Statement</p>

                </div>
                <div className="border mt-0 mb-3 pb-2" style={{ borderRadius: "10px", backgroundColor: "white", minHeight: "200px" }}>
                    <div className="row justify-content-between px-4 py-0 my-2"
                        style={{ marginLeft: "0.02%", marginRight: "0.02%", backgroundColor: "rgb(238, 255, 199)" }}>
                        <div className="d-md-flex mx-md-1 mx-auto d-block justify-content-center align-items-center">
                            <div className="d-flex justify-content-center text-center my-2 mb-0 mr-md-auto mx-auto">
                                <div className="d-flex align-items-center mx-2" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                                    <select style={{ height: "30px", color: "rgb(155, 205, 42)", position: "relative", border: "1px solid rgb(238, 255, 199)" }}
                                        className=" form-control px-4 py-0">
                                        <option className="bg-white text-dark" selected >All Properties</option>
                                        <option className="bg-white text-dark" value="1">Option</option>
                                        <option className="bg-white text-dark" value="2">Option</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="datePicker d-flex align-items-center">
                            <div className="position-relative my-1 d-flex align-items-center">
                                {/*<input type="text" value="2020/01/08" style={{height:"30px",border:"1px solid rgba(255, 130, 130, 0.3)",position:"relative"}}*/}
                                {/*       className="bg-white text-muted text-center form-control py-1"/>*/}
                                <DateRangePicker
                                    dateFormat="DD-MM-YYYY"
                                    onCallback={handleDateRange}
                                // initialSettings={
                                //     { startDate: '01/01/2020', endDate: '01/15/2020' }}
                                >
                                    <input type="text" style={{ width: "270px" }} className="form-control px-2" />
                                </DateRangePicker>
                                {/*<input title="From" type="date" placeholder="From" defaultValue="23/09/2020"*/}
                                {/*       style={{height:"30px",border:"1px solid rgba(255, 130, 130, 0.3)",position:"relative",width:"200px"}}*/}
                                {/*       className="bg-white w-md-50 w-100 text-muted form-control py-1"/>*/}
                                {/*<input title="to" type="date"  style={{height:"30px",border:"1px solid rgba(255, 130, 130, 0.3)",position:"relative"}}*/}
                                {/*       className="bg-white w-md-50 w-100 text-muted form-control py-1"/>*/}
                                <img alt="" style={{ position: "absolute", right: "10%", top: "20%", height: "20px" }} src={calendar} />
                            </div>
                        </div>
                    </div>
                    <table style={{ width: '100%' }}>
                        <thead style={{ backgroundColor: 'lightgray' }}>
                            <th className="px-3 " style={{
                                fontSize: '1rem'
                            }}>
                                Date
                            </th>
                            <th className="px-3    " style={{
                                fontSize: '1rem'
                            }}>
                                Property
                            </th>
                            <th className="px-3   " style={{
                                fontSize: '1rem'
                            }}>
                                Credit/Debit
                            </th>
                            <th className="px-3   " style={{
                                fontSize: '1rem'
                            }}>
                                Type
                            </th>
                            <th className="px-3  " style={{
                                fontSize: '1rem'
                            }}>
                                Transaction Id
                            </th>
                            <th className="px-3 " style={{
                                fontSize: '1rem'
                            }}>
                                Amount
                            </th>
                            {/* <th className="px-3 " style={{
                                fontSize: '1rem'
                            }}>
                                Action
                            </th> */}

                        </thead>
                        <tbody >
                            {documents.length > 0 ? documents.map(item => <tr>
                                <td className="px-3 py-2 border">
                                    {item?.DateTime?.split(' ')[0]}
                                </td>
                                <td className="px-3 py-2 border">
                                    {item.PropertyTitle}
                                </td>
                                <td className="px-3 py-2 border">
                                    {item.TransactionType == 1 ? 'Credit' : 'Debit'}
                                </td >
                                <td className="px-3 py-2 border">{item.Type}</td>
                                <td className="px-3 py-2 border">{item.TransactionID}</td>
                                <td className="px-3 py-2 border">{item.Amount}</td>
                            </tr>) : <tr ><td colSpan={7} style={{ textAlign: 'center' }}>No Statement Found</td></tr>}
                        </tbody>
                    </table>



                </div>


            </div>
        </React.Fragment >
    )
};
export default SPVStatementContent;