import React from "react";
import calendarRupees from "../assets/calendarRupees.png";
import handPickImg from "../assets/handPickImg.png";
import investMonitorImg from "../assets/investMonitorImg.png";
import marketRupeesImg from "../assets/marketRupeesImg.png";
import rupeesHand from "../assets/rupeesHand.png";

let HowItWorksTitleDetails = () => {
    return (
        <React.Fragment>
            <div style={{ padding: '2rem 1.5rem' }} >
                <p style={{ fontSize: '1.1rem' }}>
                    Best Real Estate in the market is generally not accessible to individual investors because it requires large investment (typically in Crores). Now using  you can own a fraction of a property located anywhere in India and get high returns without indulging in any management or due diligence. The ritual of fractional ownership at Brick-Berry is carried out through a Specific Purpose Vehicle (SPV). Through SPV, funds are raised to own and manage a property. An investor, in this case, will own shares of the SPV holding the property.


                </p>
                <p style={{ fontSize: '1.1rem' }}>
                    Our team of investment experts have personally researched each of these properties and hand-picked them specifically for Investors. Be in control of the properties they invest in and make their own investment goals. Our hassle-free solutions help investors build their portfolio and manage investments easily.
                </p>
            </div>
            <div className="container-fluid m-0 py-5" style={{ backgroundColor: "#f5f5f5" }}>

                <p className="h6 text-center mb-4" style={{ color: "rgb(22, 142, 186)", fontSize: "smaller" }}>[ A Brick Represent 1 SFT value of the Property ]</p>
                <div className="row mt-4 px-5 justify-content-around align-items-center">
                    <div className="col-md-2">
                        <div className="text-center">
                            <img height={40} src={handPickImg} alt="img" />
                        </div>
                        <div>
                            <p className="text-dark h6 my-5 font-wight-bold">We hand-pick quality properties and devide each Property into multiple Bricks</p>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="text-center">
                            <img height={40} src={investMonitorImg} alt="img" />
                        </div>
                        <div>
                            <p className="text-dark h6 my-5 font-wight-bold">Choose the Bricks you want to buy (or) let us choose for you with AI Smart Invest </p>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="text-center">
                            <img height={40} src={marketRupeesImg} alt="img" />
                        </div>
                        <div>
                            <p className="text-dark my-5 mx-auto h6 font-wight-bold">Invest and monitor your portfolio</p>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="text-center">
                            <img height={40} src={calendarRupees} alt="img" />
                        </div>
                        <div >
                            <p className="text-dark h6 my-5 font-wight-bold">Earn any net rental income in proportion to how many Bricks you own</p>
                        </div>
                    </div>
                    <div className="col-md-2 ">
                        <div className="text-center">
                            <img height={30} src={rupeesHand} alt="img" />
                        </div>
                        <div>
                            <p className="text-dark my-5 mx-auto h6 font-wight-bold">Earn any capital returns in line with property price changes when you sell your Bricks</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default HowItWorksTitleDetails;