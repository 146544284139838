import React from "react";
import NRIInvestmentHome from "./NRIInvestmentHome";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import WhyInvestNRI from "./WhyNRIInvest";
import TypesOfProperties from "./TypesOfProperties";
import NRIInIndianCompanies from "./NRIInIndianCompanies";
import RulesForInvestment from "./RulesForInvestment";
import RepatriableRules from "./RepatriableRules";
import ProsAndCons from "./ProsAndCons";
import TaxForSelling from "./TaxForSelling";
import NRIThroughBrickberry from "./NRIThroughBrickberry";
import WhyChoseBrickberryNRI from "./WhyChoseBrickberryNRI";
import EligibilityNRI from "./EligibilityNRI";
import AccountToOpen from "./AccountToOpen";

let NRIInvestment=()=>{
    return(
        <React.Fragment>
            <Navbar activeDropdown={"nriInvesting"}/>
            <NRIInvestmentHome/>
            <WhyInvestNRI/>
            <TypesOfProperties/>
            <NRIInIndianCompanies/>
            <RulesForInvestment/>
            <RepatriableRules/>
            <ProsAndCons/>
            <TaxForSelling/>
            <NRIThroughBrickberry/>
            <WhyChoseBrickberryNRI/>
            <EligibilityNRI/>
            <AccountToOpen/>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default NRIInvestment;
