import React, {useState} from "react";
import ResourseTitle from "../ResourceTitle";
import NRIinvestmentImg from "../assets/NRIinvestmentImgNew.png";
import InvestmentImg from "../assets/Investmentimg.png";
import pinkDotsGroup from "../../../assets/img/pinkDotsGroup.png";
import pinkCircles from "../../../assets/img/pinkCircles.png";
import pinkTriangles from "../../../assets/img/pinkTriangles.png";
import InvestrorProfileImg from "../assets/InvestrorProfileImg.png";
import investerCircleImg from "../assets/investerCircleImgNew.png";
import greenDot from "../assets/greenDot.png";
import darkPinkdot from "../assets/darkPinkdot.png";
import pinkDot from "../assets/pinkDot.png";
import redDotResources from "../assets/redDotResources.png";
import Sector from "../assets/SectorNew.png";
import residentialStatus from "../assets/residentialStatusNew.png";
import profileImgGroup from "../assets/profileImgGroup.png";



let InvestorHome=()=>{

    return(
        <React.Fragment>
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{minHeight:"200px",maxHeight:"250px"}} src={InvestrorProfileImg}/>
                    <p style={{fontWeight:"800",fontSize:"40"}}
                       className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        Investor Profile</p>
                </div>
            </div>
            <div style={{position:"relative"}}>
                <div className="mx-md-5 px-md-5 px-1 mx-1 " >
                  <div className="">
                      <div className="row">
                          <div className="col-md-3 py-md-4 py-1 px-2 my-md-5 my-1 px-2" >
                              <div className="mx-md-4 mx-2 h-100">
                              <div className="py-4 h-100 px-0" style={{backgroundColor:"#642942"}}>
                              <div className="d-flex flex-column justify-content-center align-items-center" style={{position:"relative"}} >
                                  <img className="img-fluid " style={{position:"absolute",maxHeight:"130px",top:"0"}} src={investerCircleImg} alt=""/>
                                  <div className="text-center text-white mt-4">
                                      <p className="mb-0" style={{fontWeight:"400",fontSize:"30px"}}>100%</p>
                                      <p className="mb-0" style={{fontWeight:"400",fontSize:"12px"}}>Investor Age</p>
                                  </div>
                              </div>
                              <div className="mt-5 pt-3 d-flex justify-content-center mx-auto text-white">
                                  <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                      <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={redDotResources} alt=""/></span>>50 years</p>
                                      <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={darkPinkdot} alt=""/></span>30-40 years</p>
                                  </div>
                                  <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                      <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={pinkDot} alt=""/></span>40-50 years</p>
                                      <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={greenDot} alt=""/></span>30 years</p>
                                  </div>
                              </div>
                              </div>
                              </div>
                          </div>
                          <div className="col-md-3 py-md-4 py-1 px-2 my-md-5 my-1  " >
                              <div className="mx-md-4 mx-2 h-100">
                                  <div className="py-4 h-100 px-0" style={{backgroundColor:"#642942"}}>
                                      <div className="d-flex flex-column justify-content-center align-items-center" style={{position:"relative"}} >
                                          <img className="img-fluid " style={{position:"absolute",maxHeight:"130px",top:"0"}} src={investerCircleImg} alt=""/>
                                          <div className="text-center text-white mt-4">
                                              <p className="mb-0" style={{fontWeight:"400",fontSize:"30px"}}>100%</p>
                                              <p className="mb-0" style={{fontWeight:"400",fontSize:"12px"}}>Residential Status</p>
                                          </div>
                                      </div>
                                      <div className="mt-5 pt-3 d-flex justify-content-center mx-auto text-white">
                                          <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                              <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={redDotResources} alt=""/></span>Resident</p>
                                          </div>
                                          <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                              <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={pinkDot} alt=""/></span>NRI</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-3 py-md-4 py-1  my-md-5 my-1 px-2" >
                              <div className="mx-md-4 mx-2 h-100">
                                  <div className="py-4 h-100 px-0" style={{backgroundColor:"#642942"}}>
                                      <div className="d-flex flex-column justify-content-center align-items-center" style={{position:"relative"}} >
                                          <img className="img-fluid " style={{position:"absolute",maxHeight:"130px",top:"0"}} src={residentialStatus} alt=""/>
                                          <div className="text-center text-white mt-4">
                                              <p className="mb-0" style={{fontWeight:"400",fontSize:"30px"}}>100%</p>
                                              <p className="mb-0" style={{fontWeight:"400",fontSize:"12px"}}>Sector</p>
                                          </div>
                                      </div>
                                      <div className="mt-5 pt-3 d-flex justify-content-center mx-auto text-white">
                                          <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                              <p className="d-flex align-items-center"><span className="mx-2">
                                                  <img height={15} src={redDotResources} alt=""/></span>Technology</p>
                                              <p className="d-flex align-items-center"><span className="mx-2">
                                                  <img height={15} src={darkPinkdot} alt=""/></span>Financial Services</p>
                                          </div>
                                          <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                              <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={pinkDot} alt=""/></span>Others</p>
                                              <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={greenDot} alt=""/></span>Entrepreneur</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-3 py-md-4 py-1 px-2 my-md-5 my-1 px-2" >
                              <div className="mx-md-4 mx-2 h-100">
                                  <div className="py-4 h-100 px-0" style={{backgroundColor:"#642942"}}>
                                      <div className="d-flex flex-column justify-content-center align-items-center" style={{position:"relative"}} >
                                          <img className="img-fluid " style={{position:"absolute",maxHeight:"130px",top:"0"}} src={Sector} alt=""/>
                                          <div className="text-center text-white mt-4">
                                              <p className="mb-0" style={{fontWeight:"400",fontSize:"30px"}}>100%</p>
                                              <p className="mb-0" style={{fontWeight:"400",fontSize:"12px"}}>Investment amount</p>
                                          </div>
                                      </div>
                                      <div className="mt-5 pt-3 d-flex justify-content-center mx-auto text-white">
                                          <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                              <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={redDotResources} alt=""/></span>25-50 Lakhs</p>
                                          </div>
                                          <div className="d-block text-left" style={{fontSize:"12px",fontWeight:"400"}}>
                                              <p className="d-flex align-items-center"><span className="mx-2"><img height={15} src={pinkDot} alt=""/></span>>50 Lakhs</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
                {/*<img height={100} style={{position:"absolute",zIndex:"1",top:"5%",right:"5%"}} src={pinkTriangles} alt=""/>*/}
                {/*<img height={150} style={{position:"absolute",zIndex:"1",top:"5%",left:"5%"}} src={pinkCircles} alt=""/>*/}
            </div>
            <div className="container text-center">
                <p className="text-center mb-0" style={{fontWeight:"700",fontSize:"40px"}}>Where our User Live</p>
                <p className="text-center" style={{fontWeight:"400",fontSize:"20px"}}>BrickBerry investors are from 7+ countries spread across 3 continents.</p>
                <div>
                <img alt="" className="img-fluid" style={{maxHeight:"600px"}} src={profileImgGroup}/>
                </div>
            </div>

        </React.Fragment>
    )
};
export default InvestorHome;