import React, {useState} from "react";
import ResourseTitle from "../ResourceTitle";
import NRIinvestmentImg from "../assets/NRIinvestmentImgNew.png";
import InvestmentImg from "../assets/Investmentimg.png";
import pinkDotsGroup from "../../../assets/img/pinkDotsGroup.png";
import pinkCircles from "../../../assets/img/pinkCircles.png";
import pinkTriangles from "../../../assets/img/pinkTriangles.png";

let NRIInvestmentHome=()=>{

    return(
        <React.Fragment>
            <ResourseTitle title={"NRI   INVESTING"}/>
            <div className="container-fluid  my-2">
            <div className="row">
                <div className="col-md-4">
                    <img alt='' className="img-fluid" src={NRIinvestmentImg}/>
                </div>
                <div className="col-md-7 my-auto" style={{fontWeight:"400",fontSize:"18px",lineHight: "36px",letterSpacing: "0.04em"}}>
                    <p className="my-3">Non Resident Indians (NRI) who are interested in investing in Brickberry’s
                        Fractional property listings can do so through their Non Resident Rupee (NRE) or
                        Non Resident Ordinary Rupee (NRO) accounts. However, as per RBI mandate, rent/dividends can be
                        distributed only to NRO accounts.
                    </p>
                    <p className="mt-4"> Thus, an NRI investor must possess an NRO account.</p>
                </div>
            </div>
            </div>
            <div className="text-center my-0" >
                <img className="img-fluid" src={InvestmentImg} alt=""/>
            </div>
        </React.Fragment>
    )
};
export default NRIInvestmentHome;
