import React, { useEffect, useState } from "react";
import ViewPropertyTitle from "../viewPropertyFirst/components/ViewPropertyTitle";
import ViewPropertyTitleIntro from "../viewPropertyFirst/components/ViewPropertyTitleIntro";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import ViewPropertySecondPotentialReturn from "./components/ViewPropertySecondPotentialReturns";
import ViewPropertyDetails from "../viewPropertyFirst/components/ViewPropertyDetails";
import ViewPropertyAmenities from "../viewPropertyFirst/components/ViewPropertyAmenities";
import ViewPropertyUpdates from "../viewPropertyFirst/components/ViewPropertyUpdates";
import ViewPropertyLocationFloorPlan from "../viewPropertyFirst/components/ViewPropertyLocationFloorPlan";
import ViewPropertySecondMonthlyDistribution from "./components/ViewPropertySecondMonthlyDistributions";
import ViewPropertyCapitalReturn from "../viewPropertyFirst/components/ViewPropertyCapitalReturn";
import ViewPropertySecondTenant from "./components/ViewPropertySecondTenant";
import ViewPropertyPricingDetails from "../viewPropertyFirst/components/ViewPropertyPricingDetails";
import CommercialBgImg from "../assets/propertiesBuildingBgImg.png";
import commercialPropertyLogo from "../../../assets/img/commercialPropertryLogo.png";
import { getPropertiesListById } from "../../../services/ApiServices";

let ViewPropertySecondPage = (props) => {
  // const propertyId = props.location.param1;
  const [propertyList, setPropertyList] = useState([]);
  const [propertyId, setPropertyId] = useState(null);


  useEffect(() => {
    let idURL = window.location.pathname.split("/");
    const id = idURL[idURL.length - 1];
    setPropertyId(id);
    getPropertiesListById(id).then((response) => {
      if (response && response.Property) {
        setPropertyList(response.Property);
      }
    });

    window.scrollTo(0, 0);
  }, [propertyId]);


  console.log("plist", propertyList);
  return (
    <React.Fragment>
      <Navbar active={"properties"} />

      <ViewPropertyTitle property={propertyList} img={CommercialBgImg} commercial={true} />

      <ViewPropertyTitleIntro
        propertyDeatils={propertyList}
        model={"LLP/PVT"}
        color={"#65a150"}
        rightBarObject={{
          img: commercialPropertyLogo,
          title: "Pre-Leased to MNCs",
        }}
      />
      <ViewPropertySecondPotentialReturn propertyDeatils={propertyList} />
      <ViewPropertyDetails propertyDeatils={propertyList} />
      <ViewPropertyAmenities propertyDeatils={propertyList} />
      <ViewPropertyUpdates propertyDeatils={propertyList} />
      <ViewPropertyLocationFloorPlan propertyDeatils={propertyList} />
      <ViewPropertySecondMonthlyDistribution propertyDeatils={propertyList} />
      <ViewPropertyCapitalReturn propertyDeatils={propertyList} />
      <ViewPropertySecondTenant propertyDeatils={propertyList} />
      <ViewPropertyPricingDetails propertyDeatils={propertyList} />
      <Buildings />
      <Footer />
    </React.Fragment>
  );
};
export default ViewPropertySecondPage;
