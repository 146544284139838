import React from "react";
import {useHistory} from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";
let NoComponentFound =()=>{
    const history=useHistory();
    const btnStyle={
        backgroundColor: "rgb(255, 130, 130)", 
        borderRadius: "10px",
        minWidth:"150px",
        fontSize:"14px"
    }
return(
    <React.Fragment>
        <Navbar/>
          <div className="container my-auto d-flex flex-column" style={{minHeight:"90vh"}}>
          <h4 className="text-center my-5 display-2 animated  " style={{position:"relative",top:"100px"}}>404</h4>
          <h4 className="text-center my-5 " style={{position:"relative",top:"30px"}}>Page Not Found</h4>
              <div className="my-auto">
                  
                  <div className="d-flex justify-content-around align-items-center flex-wrap">
                      <div className="btn btn-md text-white text-capitalize" 
                      style={btnStyle} onClick={()=>history.push("/")}>Home</div>
                      <div className="btn btn-md text-white text-capitalize" style={btnStyle} onClick={()=>history.push("/properties")}>Properties</div>
                      <div className="btn btn-md text-white text-capitalize" style={btnStyle} onClick={()=>history.push("/resources/partnerWithUs")}>Partner With Us</div>
                      <div className="btn btn-md text-white text-capitalize" style={btnStyle} onClick={()=>history.push("/aboutUs")}>Contact US</div>
                      <div className="btn btn-md text-white text-capitalize"style={btnStyle}  onClick={()=>history.push("/resources/FAQ")}>Help Center</div>
                  </div>
              </div>
          </div>
        <Footer/>
    </React.Fragment>
)
};
export default NoComponentFound;