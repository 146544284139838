import React, { useEffect, useRef, useState } from "react";
import securePinImg from "./assets/securePinImg.png";
import SecurePinTitle from "./securePinTitle";
import styles from "./securePin.css";
import reactangleImg from "./assets/reactangleImg.png";
import blackCircleImg from "./assets/blackCircleImg.png";
import blackTriangleImg from "./assets/blackTriangleImg.png";
import dotImage from "./assets/dotImage.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import NavbarPin from "./NavbarPin";
import Axios from "axios";

let SetPin = () => {
    const location = useLocation();
    const history = useHistory();
    let setInput1 = useRef(null);
    let setInput2 = useRef(null);
    let setInput3 = useRef(null);
    let setInput4 = useRef(null);
    let confirmInput1 = useRef(null);
    let confirmInput2 = useRef(null);
    let confirmInput3 = useRef(null);
    let confirmInput4 = useRef(null);
    let setPinBtn = useRef(null);
    let [EmailID, setEmailID] = useState('');
    let [pinError, setPinError] = useState(false);
    let [pin, setPin] = useState('');
    let [pin1, setPin1] = useState('');
    let [pin2, setPin2] = useState('');
    let [pin3, setPin3] = useState('');
    let [pin4, setPin4] = useState('');
    let [pin5, setPin5] = useState('');
    let [pin6, setPin6] = useState('');
    let [pin7, setPin7] = useState('');
    let [pin8, setPin8] = useState('');
    useEffect(() => {
        setEmailID(location.email);
    }, []);
    let handlePin1 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin1(value);
        if (value.length === 1) {
            setInput2.focus()
        }
    };
    let handlePin2 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin2(value);
        if (value.length === 1) {
            setInput3.focus()
        }
    };
    let handlePin3 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin3(value);
        if (value.length === 1) {
            setInput4.focus()
        }
    };
    let handlePin4 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin4(value);
        if (value.length === 1) {
            confirmInput1.focus()
        }
    };
    let handlePin5 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin5(value);
        if (value.length === 1) {
            confirmInput2.focus()
        }
    };
    let handlePin6 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin6(value);
        if (value.length === 1) {
            confirmInput3.focus()
        }
    };
    let handlePin7 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin7(value);
        if (value.length === 1) {
            confirmInput4.focus()
        }
    };
    let handlePin8 = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setPin8(value);
        if (value.length === 1) {
            setPinBtn.focus()
        }
    };

    let sendPin = async (e) => {
        e.preventDefault();
        let pinOne = pin1.concat(pin2).concat(pin3).concat(pin4);
        let pinTwo = pin5.concat(pin6).concat(pin7).concat(pin8);
        if (pinOne === pinTwo) {
            let config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            // let dataURL = `${process.env.REACT_APP_API_URL}/securepin/sendactivecode_email`;
            let dataURL = `${process.env.REACT_APP_API_URL}/securepin/sendactivecode_email`;
            await Axios.post(dataURL, { "EmailID": location.email }).then((response) => {
                console.log("pin sent to email");
                console.log(response);
                console.log(response.data.Status);
                if (response.data.Status === 1) {
                    history.push({
                        // pathname: '/activatePin',
                        pathname: '/login',
                        EmailID: EmailID,
                        pin: pinOne
                    })
                }
            }).catch((err) => {
                console.log(err)
            });

        } else {
            setPinError(true);
            setTimeout(() => {
                setPinError(false)
            }, 4000)
        }
    };
    return (
        <React.Fragment>
            <NavbarPin />
            <div>
                <SecurePinTitle />
                <div className=" img-fluid ">
                    <img src={reactangleImg} style={{ height: "61%", width: "60%", position: "absolute", zIndex: "1" }}
                        alt="" className="img-fluid rectangleSecureImg" />
                </div>
                <div className="col-md-4 mx-auto" style={{ position: "relative", zIndex: "2" }}>
                    <p className="text-center my-4" style={{ fontWeight: "700", fontSize: "40px" }}>Set 4 Digit PIN</p>
                    <p className="text-center my-0" style={{ fontWeight: "400", fontSize: "17px" }}>Please enter your
                        unique 4 digit pin for more secure log in</p>
                    <form onSubmit={sendPin}>
                        <div className="px-md-5 px-2 mx-md-4  mx-auto my-2">
                            <p className="text-dark text-left my-0" style={{ fontWeight: "400", fontSize: "16px" }}>Set
                                New PIN</p>
                            <div className="d-flex justify-content-between my-3">
                                <div className="form-outline">
                                    <input type="text" value={pin1} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin1} style={{ width: "50px", height: "50px" }} id="1"
                                        ref={(input) => setInput1 = input}
                                        className="form-control" />
                                </div>
                                <div className="form-outline">
                                    <input type="text" value={pin2} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin2} style={{ width: "50px", height: "50px" }} id="2"
                                        ref={(input) => setInput2 = input}
                                        className="form-control" />
                                </div>
                                <div className="form-outline">
                                    <input type="text" value={pin3} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin3} style={{ width: "50px", height: "50px" }} id="3"
                                        ref={(input) => setInput3 = input}
                                        className="form-control" />
                                </div>
                                <div className="form-outline">
                                    <input type="text" value={pin4} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin4} style={{ width: "50px", height: "50px" }} id="4"
                                        ref={(input) => setInput4 = input}
                                        className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="px-md-5 px-2 mx-md-4  mx-auto my-2">
                            <p className="text-dark text-left my-0"
                                style={{ fontWeight: "400", fontSize: "16px" }}>Conform New PIN</p>
                            <div className="d-flex justify-content-between my-3">
                                <div className="form-outline">
                                    <input type="text" value={pin5} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin5} style={{ width: "50px", height: "50px" }} id="1"
                                        ref={(input) => confirmInput1 = input}
                                        className="form-control" />
                                </div>
                                <div className="form-outline">
                                    <input type="text" value={pin6} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin6} style={{ width: "50px", height: "50px" }} id="2"
                                        ref={(input) => confirmInput2 = input}
                                        className="form-control" />
                                </div>
                                <div className="form-outline">
                                    <input type="text" value={pin7} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin7} style={{ width: "50px", height: "50px" }} id="3"
                                        ref={(input) => confirmInput3 = input}
                                        className="form-control" />
                                </div>
                                <div className="form-outline">
                                    <input type="text" value={pin8} pattern="^[0-9]*$" min="0" max="9" maxLength="1"
                                        onChange={handlePin8} style={{ width: "50px", height: "50px" }} id="4"
                                        ref={(input) => confirmInput4 = input}
                                        className="form-control" />
                                </div>
                            </div>
                        </div>

                        {pinError && <p className="text-center " style={{ color: "#FF8282" }}> Pin doesn't match</p>}
                        <div className="text-center mt-4">
                            {/*<Link to={"/activatePin"}>*/}
                            <button type="submit" className="btn btn-md py-2 px-5 text-capitalize text-white"
                                ref={(input) => setPinBtn = input}
                                style={{
                                    backgroundColor: "#ff8282",
                                    fontSize: "20px",
                                    fontWeight: "600",
                                    borderRadius: "10px"
                                }}>
                                Set Pin
                            </button>
                            {/*</Link>*/}
                        </div>
                    </form>
                </div>
                <img src={blackCircleImg}
                    style={{ position: "absolute", height: "80px", zIndex: "1", top: "65%", left: "62%" }} alt=""
                    className="img-fluid" />
                <img src={blackTriangleImg}
                    style={{ position: "absolute", zIndex: "1", height: "75px", top: "45%", left: "72%" }} alt=""
                    className="img-fluid" />
                <img src={blackCircleImg}
                    style={{ position: "absolute", height: "50px", zIndex: "1", top: "78%", left: "72%" }} alt=""
                    className="img-fluid" />
                <img src={dotImage}
                    style={{ position: "absolute", height: "120px", zIndex: "1", top: "58%", left: "82%" }} alt=""
                    className="img-fluid dotImageSecure" />

            </div>
        </React.Fragment>
    )
};
export default SetPin;
