import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "./assets/myProfileLogoDashboard.svg";
import bellLogo from "./assets/bellLogo.png";
import profileImg from "./assets/profileImg.png";
import settingLogo from "./assets/settingLogo.png";
import KYCVerified from "./assets/KYCVerified.png";
import BellNotification from "./bellNotification/BellNotification";
import UserSettingMenu from "./userSetting/UserSettingMenu";
import BrickberryLogo from "../../assets/img/brickBerryNavbarLogo.png";
import brickberry from "../../assets/BbrickberryLogo.svg"
import redDot from "../../assets/rredDot.png";
import { getUser, deleteUser } from "../../utils/localStorage";


let NavbarDashboard = (props) => {
  let [auth, setAuth] = useState(undefined);
  let [active, setActive] = useState(1);
  let [activeDropdown, setActiveDropdown] = useState(1);
  let [open, setOpen] = useState(false);
  let [dropdown, showDropdown] = useState(false);
  let [mount, setMount] = useState(false);
  const dropDownRef = useRef();

  useEffect(() => {
    const user = (getUser());
    setAuth(user)
    // add when mounted
    setMount(true);
    document.addEventListener("mousedown", handleClickOutside);
    // return function to be called when unmounted
    return () => {
      setMount(false);
      document.removeEventListener('click', handleClickOutside)
    };
  }, []);
  const handleClickOutside = e => {
    if (!dropDownRef?.current?.contains(e.target)) {
      // inside click
      // setShowMenu(!showMenu);
      if (setMount) {
        showDropdown(false);
      }
    }

  };

  return (
    <React.Fragment>
      {/*<nav className="navbar navbar-expand-lg shadow-none" style={{backgroundColor:"#fff7f7"}}>*/}
      <nav
        className="navbar navbar-expand-lg shadow-none"
        style={{ backgroundColor: "black" }}
      >
        <div className="container-fluid d-md-flex d-block">
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setOpen(!open)}
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarLeftAlignExample"
            aria-controls="navbarLeftAlignExample"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <i className="fas fa-bars text-white" /> */}
          </button>

          <div
            className="collapse navbar-collapse shadow-none py-2 mx-0 px-0"
            id="navbarLeftAlignExample"
          >
            {/*<a className="navbar-brand" href="#">*/}
            {/*    <img width={200} src={Logo}/>*/}
            {/*</a>*/}
            <ul className="navbar-nav w-100 d-md-flex d-block justify-content-around align-items-center mx-auto">
              <li className="nav-item ">
                {/* <Link to={"/"} className="navbar-brand mx-5" href="">
                                    <img alt="" width={200} src={Logo}/>
                                </Link> */}
                <Link
                  to={"/"}
                  className="navbar-brand d-flex align-items-center "
                  href=""
                >
                  <img
                    alt=""
                    className="mx-2"
                    height={30}
                    width={40}
                    src={BrickberryLogo}
                  />
                  <img alt="" width={200} src={brickberry} />
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/"
                  className="nav-link pb-0 mb-2"
                  onClick={() => setActive(1)}
                  style={
                    props.active === "home"
                      ? { color: "#ef7a7a" }
                      : { color: "white" }
                  }
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/HowItWorks"
                  className="nav-link pb-0 mb-2"
                  onClick={() => setActive(2)}
                  // style={active === 2 ? {borderBottom:"2px solid #ef7a7a", color:"#ef7a7a"}:null}
                  style={
                    props.active === "howItWorks"
                      ? { color: "#ef7a7a" }
                      : { color: "white" }
                  }
                >
                  How It Works
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to={"/properties"}
                  className="nav-link pb-0 mb-2"
                  onClick={() => setActive(3)}
                  style={
                    props.active === "properties"
                      ? { color: "#ef7a7a" }
                      : { color: "white" }
                  }
                >
                  Properties
                </Link>
              </li>
              {auth &&
                <li className="nav-item">
                  <Link
                    to={"/dashboard/myProfile"}
                    className="nav-link pb-0 mb-2"
                    onClick={() => setActive(4)}
                    style={
                      props.active === "dashboard"
                        ? { color: "rgb(155, 205, 42)", borderBottom: "2px solid rgb(155, 205, 42)" }
                        : { color: "white" }
                    }
                  >
                    Dashboard
                  </Link>
                </li>}
              <li className="nav-item dropdown">
                <div ref={dropDownRef}>
                  <div
                    onClick={() => showDropdown(!dropdown)}
                    className="nav-link "
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{ color: "white" }}
                  >
                    Resources
                  </div>
                  {dropdown && (
                    <React.Fragment>
                      <div
                        className="text-dark d-flex flex-column justify-content-center"
                        style={{
                          position: "absolute",
                          backgroundColor: "white",
                          fontWeight: "700",
                          zIndex: "10",
                          width: "170px",
                          top: "60px",
                        }}
                      >
                        <div className="mx-auto d-flex flex-column justify-content-center">
                          <div>
                            <div className="my-2 ">
                              <Link
                                to={"/resources/newsAndArticle"}
                                onClick={() => setActiveDropdown(1)}
                                style={
                                  props?.activeDropdown === "newsAndArticle"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className=" ml-3 my-4"
                              >
                                News And Article
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/investorProfile"}
                                onClick={() => setActiveDropdown(2)}
                                style={
                                  props?.activeDropdown === "investorProfile"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className=" ml-3 "
                              >
                                Investor Profile
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/NRIInvesting"}
                                onClick={() => setActiveDropdown(3)}
                                style={
                                  props?.activeDropdown === "NRIInvesting"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                NRI Investing
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/ownershipModels"}
                                onClick={() => setActiveDropdown(8)}
                                style={
                                  props?.activeDropdown === "ownershipModels"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                Ownership Models
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/blockchainCRE"}
                                onClick={() => setActiveDropdown(4)}
                                style={
                                  props?.activeDropdown === "blockchainCRE"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Blockchain @CRE
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/FAQ"}
                                onClick={() => setActiveDropdown(5)}
                                style={
                                  props?.activeDropdown === "FAQ"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Help Center / FAQ
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/resources/partnerWithUs"}
                                onClick={() => setActiveDropdown(6)}
                                style={
                                  props?.activeDropdown === "partnerWithUs"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Partner with us
                              </Link>
                            </div>
                            <div className="my-2">
                              <Link
                                to={"/careers"}
                                onClick={() => setActiveDropdown(7)}
                                style={
                                  props?.activeDropdown === "careers"
                                    ? { color: "#FF8282" }
                                    : { color: "black" }
                                }
                                className="ml-3 my-4 "
                              >
                                {" "}
                                Careers
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </li>
              <div className="d-flex justify-content-around align-items-center">
                <li className="nav-item d-flex justify-content-between align-items-center">
                  {/*<Link  to={"/"} className="nav-link py-0" onClick={()=>setActive(5)}*/}
                  {/*       // style={props?.active === "contact" ? {borderBottom:"2px solid #ef7a7a", color:"#ef7a7a"}:null}*/}
                  {/*>*/}
                  {/*    <div className="d-flex justify-content-center align-items-center pt-0">*/}
                  {/*        <img height={35} src={bellLogo} style={{}} alt=""/>*/}
                  {/*        <p className="text-dark" style={{marginLeft:"-12px",fontSize:"10px"}}>2</p>*/}
                  {/*    </div></Link>*/}
                  <BellNotification />
                  {/*<Link to={"/profile"} className="nav-link pb-0" onClick={()=>setActive(6)}*/}
                  {/*>*/}
                  {/*    <div className="d-flex flex-column mt-0 justify-content-center align-items-center ">*/}
                  {/*        <img height={35} className="rounded-circle mt-3" src={profileImg} style={{}} alt=""/>*/}
                  {/*        <span className="d-flex my-1 justify-content-center align-items-center">*/}
                  {/*                                    <div className="d-flex align-items-center justify-content-center">*/}
                  {/*                                        <img src={redDot} height={16} style={{position:"absolute",zIndex:"1"}} alt=""/>*/}
                  {/*                                        <i style={{position:"relative",zIndex:"2",height:"5px",top:"-8px",left:"20%"}}*/}
                  {/*                                           className="fa fa-check text-black-50"*/}
                  {/*                                           aria-hidden="true"/>*/}
                  {/*                                    </div>*/}
                  {/*                                    <small className=" mx-2 mb-0 text-dark">KYC Verified</small>*/}
                  {/*                                </span>*/}
                  {/*        /!*<img height={16} className="mt-1" width={70} src={KYCVerified} style={{}} alt=""/>*!/*/}
                  {/*    </div>*/}
                  {/*</Link>*/}
                  {/*<Link to={"/dashboard/user/setting"}>*/}
                  {/*    <div className="d-flex flex-column justify-content-center align-items-center ">*/}
                  {/*        <img height={35}  src={settingLogo} style={{}} alt=""/>*/}
                  {/*    </div>*/}
                  {/*</Link>*/}
                  <div className=" ml-4 pl-2 ">
                    <UserSettingMenu />
                  </div>
                </li>
              </div>
              {/*<li className="nav-item">*/}
              {/*    <Link to={"/login"} className="nav-link pb-0" onClick={()=>setActive(6)}*/}
              {/*          style={props?.active === "signin" ? {borderBottom:"2px solid #ef7a7a", color:"#ef7a7a"}:null}*/}
              {/*    >SIGN IN</Link>*/}
              {/*</li>*/}
              {/*<li className="nav-item">*/}
              {/*    <Link to={"/signup"}>*/}
              {/*        <button className="nav-link btn btn-sm btn-outline-danger px-4 py-1 rounded-pill" >SIGN UP</button>*/}
              {/*    </Link>*/}
              {/*</li>*/}
            </ul>
          </div>

          {open && (
            <div className=" py-2 mx-0 px-0" id="navbarLeftAlignExample">
              {/*<a className="navbar-brand" href="#">*/}
              {/*    <img width={200} src={Logo}/>*/}
              {/*</a>*/}
              <ul className="navbar-nav w-100 d-block justify-content-around mx-auto">
                <li className="nav-item ">
                  <Link className="navbar-brand mx-5" href="#">
                    <img
                      alt=""
                      className="brandLogoNav"
                      width={200}
                      src={Logo}
                    />
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/"}
                    className="nav-link pb-0"
                    onClick={() => setActive(1)}
                    style={
                      props?.active === "home"
                        ? {
                          borderBottom: "2px solid #ef7a7a",
                          color: "#ef7a7a",
                        }
                        : { color: "white" }
                    }
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/HowItWorks"}
                    className="nav-link pb-0"
                    onClick={() => setActive(2)}
                    style={
                      props?.active === "howItWorks"
                        ? {
                          borderBottom: "2px solid #ef7a7a",
                          color: "#ef7a7a",
                        }
                        : { color: "white" }
                    }
                  >
                    How It Works
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/properties"}
                    className="nav-link pb-0"
                    onClick={() => setActive(3)}
                    style={
                      props.active === "properties"
                        ? { color: "#ef7a7a" }
                        : { color: "white" }
                    }
                  >
                    Properties
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={"/dashboard/overview"}
                    className="nav-link pb-0"
                    onClick={() => setActive(4)}
                    style={
                      props.active === "dashboard"
                        ? { color: "#FF8282" }
                        : { color: "white" }
                    }
                  >
                    Dashboard
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <div>
                    <div
                      onClick={() => showDropdown(!dropdown)}
                      className="nav-link "
                      id="navbarDropdown"
                      role="button"
                      data-mdb-toggle="dropdown"
                      aria-expanded="false"
                      style={{ color: "white" }}
                    >
                      Resources
                    </div>
                    {dropdown && (
                      <React.Fragment>
                        <div
                          className="text-dark d-flex flex-column justify-content-center"
                          style={{
                            position: "absolute",
                            backgroundColor: "white",
                            fontWeight: "700",
                            zIndex: "10",
                            width: "170px",
                            top: "60px",
                          }}
                        >
                          <div className="mx-auto d-flex flex-column justify-content-center">
                            <div>
                              <div className="my-2 ">
                                <Link
                                  to={"/resources/newsAndArticle"}
                                  onClick={() => setActiveDropdown(1)}
                                  style={
                                    props?.activeDropdown === "newsAndArticle"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className=" ml-3 my-4"
                                >
                                  News And Article
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/investorProfile"}
                                  onClick={() => setActiveDropdown(2)}
                                  style={
                                    props?.activeDropdown ===
                                      "investorProfile"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className=" ml-3 "
                                >
                                  Investor Profile
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/NRIInvesting"}
                                  onClick={() => setActiveDropdown(3)}
                                  style={
                                    props?.activeDropdown === "NRIInvesting"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  NRI Investing
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/ownershipModels"}
                                  onClick={() => setActiveDropdown(8)}
                                  style={
                                    props?.activeDropdown ===
                                      "ownershipModels"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  Ownership Models
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/blockchainCRE"}
                                  onClick={() => setActiveDropdown(4)}
                                  style={
                                    props?.activeDropdown === "blockchainCRE"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Blockchain @CRE
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/FAQ"}
                                  onClick={() => setActiveDropdown(5)}
                                  style={
                                    props?.activeDropdown === "FAQ"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Help Center / FAQ
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/resources/partnerWithUs"}
                                  onClick={() => setActiveDropdown(6)}
                                  style={
                                    props?.activeDropdown === "partnerWithUs"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Partner with us
                                </Link>
                              </div>
                              <div className="my-2">
                                <Link
                                  to={"/careers"}
                                  onClick={() => setActiveDropdown(7)}
                                  style={
                                    props?.activeDropdown === "careers"
                                      ? { color: "#FF8282" }
                                      : { color: "white" }
                                  }
                                  className="ml-3 my-4 "
                                >
                                  {" "}
                                  Careers
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </li>
                <div className="d-flex justify-content-around align-items-center">
                  <li className="nav-item d-flex justify-content-around align-items-center">
                    {/*<Link  to={"/"} className="nav-link py-0" onClick={()=>setActive(5)}*/}
                    {/*    // style={props?.active === "contact" ? {borderBottom:"2px solid #ef7a7a", color:"#ef7a7a"}:null}*/}
                    {/*>*/}
                    {/*    <div className="d-flex justify-content-center align-items-center pt-0">*/}
                    {/*        <img height={35} src={bellLogo} style={{}} alt=""/>*/}
                    {/*        <p className="text-dark" style={{marginLeft:"-12px",fontSize:"10px"}}>2</p>*/}
                    {/*    </div></Link>*/}
                    <BellNotification />

                    {/*<Link to={"/dashboard/user/setting"}>*/}
                    {/*    <div className="d-flex flex-column justify-content-center align-items-center ">*/}
                    {/*        <img height={35}  src={settingLogo} style={{}} alt=""/>*/}
                    {/*    </div>                                </Link>*/}
                    <div className="mx-4">
                      <UserSettingMenu />
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </React.Fragment>
  );
};
export default NavbarDashboard;
