import React from "react";
import checkImg from "../../assets/checkRightSign.png";
import MapContainer from "../MapContainer";
import floorPlan from "../../assets/floorPainDiagram.png";

let ViewPropertyLocationFloorPlan = ({ propertyDeatils }) => {
    const propertyListing = propertyDeatils || [];
    return (
        <React.Fragment>
            <div className="container px-0 my-5" >
                <div className="row">

                    <div className="col-md-6 h-100 px-2" style={{ position: "relative" }}>
                        <p className="mb-0 text-center mt-3 mb-4" style={{ fontSize: "20px", fontWeight: "600" }}>Location Highlights</p>
                        <div className="" style={{ height: "430px", width: "300px" }}>
                            <MapContainer location={propertyListing.googleMapEmbedURL} />
                        </div>
                    </div>
                    <div className="col-md-6  px-2">
                        <p className="mb-0 text-center mt-3 mb-4" style={{ fontSize: "20px", fontWeight: "600" }}>Floor Plan</p>
                        <div className="shadow p-4">
                            <img alt="" style={{ height: "450px", margin: "auto" }} className="img-fluid" src={propertyListing.FloorPlanImages} />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default ViewPropertyLocationFloorPlan;