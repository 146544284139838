import React from "react";
import row1Img from "../../assets/row1Img.png";
import row2Img from "../../assets/row2Img.png";
import row3Img from "../../assets/row3Img.png";
import processMap from "../../assets/processMap.png";
import plusLogo from "../../assets/plusLogo.png";
import marketLogo from "../../assets/marketLogo.png";
import internationalLogo from "../../assets/internationalLogo.png";
import dollarLogo from "../../assets/dollarLogo.png";
import outcomeMap from "../../assets/outcomeMap.png";
import blockchainFrameworkImg from "../../assets/blockchainFrameworkImg.png";
import thinkAboutItImg from "../../assets/thinkAboutItImg.png";

let RexMLSBlockchain = () => {
    return (
        <React.Fragment>
            <div className="container-fluid px-md-5 my-5 pt-3 px-2">
                <div className="mx-md-5 px-md-5 px-0 mx-2">

                    <div className="my-4">
                        <p style={{ fontWeight: "700", fontSize: "20px" }}> Rex MLS―Revolutionizing CRE listings Globally </p>
                        <p style={{ fontWeight: "400", fontSize: "16px" }}>The lessor and the lessee or their respective brokers list their requirements on the multiple-listing services (MLS).
                            A transparent MLS system enables all parties to view the available listings based on their requirements. </p>
                    </div>
                    <div>
                        <div className="d-md-flex  my-2 d-block" >
                            <div className="mx-2 py-2 my-auto rexMlsBars text-center" style={{ backgroundColor: "rgb(155, 205, 42)", width: "300px", fontWeight: "700" }}>
                                <p className="mx-3 mb-0 py-1 rexMlsBarsTitle my-auto"  >Type of blockchain</p>
                            </div>
                            <div className="mx-3 py-2 my-auto rexMlsBarsDetails" style={{ backgroundColor: "rgb(155, 205, 42)", width: "100%" }}>
                                <p className="ml-md-5 py-1 mx-auto my-auto" >Public blockchain based on Ethereum </p>
                            </div>
                        </div>
                        <div className="d-md-flex my-2 d-block" >
                            <div className="mx-2 py-2 text-center rexMlsBars" style={{ backgroundColor: "rgb(22, 142, 186)", width: "300px", fontWeight: "700" }}>
                                <p className="mb-0 mx-3 py-1 my-auto rexMlsBarsTitle" >Development stage</p>
                            </div>
                            <div className="mx-3 py-2 rexMlsBarsDetails " style={{ backgroundColor: "rgb(22, 142, 186)", width: "100%" }}>
                                <p className="ml-md-5 mb-0 mx-auto py-1 my-auto" >Initial testing completed, final roll out in the works </p>
                            </div>
                        </div>
                        <div className="d-md-flex my-2 d-block">
                            <div className="mx-2 py-2 text-center rexMlsBars" style={{ backgroundColor: "rgb(238, 255, 199)", width: "300px", fontWeight: "700" }}>
                                <p className="mx-3 mb-0 py-1 my-auto rexMlsBarsTitle"  >Service offering</p>
                            </div>
                            <div className=" mx-3 py-2 rexMlsBarsDetails" style={{ backgroundColor: "rgb(238, 255, 199)", width: "100%" }}>
                                <p className="ml-md-5 mb-0 my-auto py-1 mx-auto">It is a freely available multiple listing service based on the blockchain platform providing
                                    users access to listing information</p>
                            </div>
                        </div>
                    </div>

                    <div className="my-4">
                        <p className="ml-md-5 pl-md-3 mx-0 px-0" style={{ fontWeight: "700", fontSize: "20px" }}> Process: </p>
                    </div>
                    <div className="my-5 py-md-2 py-0 text-center d-flex justify-content-center">
                        <img src={processMap} style={{ height: "300px", width: "auto" }} className="img-fluid" alt="" />
                    </div>
                    <div className="my-4">
                        <p className="ml-md-5 pl-md-3 mx-0 px-0" style={{ fontWeight: "700", fontSize: "20px" }}> Outcomes </p>

                        <div className="ml-md-5 pl-md-3 my-5 mx-0 px-0">
                            <div className="d-flex justify-content-md-between justify-content-center " style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                                <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                    <img src={plusLogo} alt="" style={{ marginRight: "-2%", position: "relative", zIndex: "2" }} height={60} width={60} />
                                    <div className="my-auto p-1 d-flex justify-content-center "
                                        style={{ border: "1px solid rgb(22, 142, 186)", width: "200px", position: "relative", zIndex: "1", marginLeft: "-7%" }}>
                                        <div className="mx-auto"><p className="mb-0">Increased <br />
                                            transparency
                                        </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                    <img src={marketLogo} alt="" style={{ marginRight: "-2%", position: "relative", zIndex: "2" }} height={60} width={60} />
                                    <div className="my-auto p-1 d-flex justify-content-center "
                                        style={{ border: "1px solid rgb(22, 142, 186)", width: "200px", position: "relative", zIndex: "1", marginLeft: "-7%" }}>
                                        <div className="mx-auto"><p className="mb-0 ml-2">Enhanced process
                                            <br />
                                            efficiency and accuracy
                                        </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                    <img src={internationalLogo} alt="" style={{ marginRight: "-2%", position: "relative", zIndex: "2" }} height={60} width={60} />
                                    <div className="my-auto p-1 d-flex justify-content-center "
                                        style={{ border: "1px solid rgb(22, 142, 186)", width: "200px", position: "relative", zIndex: "1", marginLeft: "-7%" }}>
                                        <div className="mx-auto"><p className="mb-0">Easier access to
                                            <br />
                                            international listings
                                        </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center" style={{ position: "relative" }}>
                                    <img src={dollarLogo} alt="" style={{ marginRight: "-2%", position: "relative", zIndex: "2" }} height={60} width={60} />
                                    <div className="my-auto p-1 d-flex justify-content-center "
                                        style={{ border: "1px solid rgb(22, 142, 186)", width: "200px", position: "relative", zIndex: "1", marginLeft: "-7%" }}>
                                        <div className="mx-auto"><p className="mb-0">Lower transaction
                                            <br />
                                            costs
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='my-5 py-3 text-center d-flex justify-content-center'>
                        <img src={outcomeMap} style={{ height: "300px" }} className="img-fluid" alt="" />
                    </div>
                    <div className="mb-4 mt-5 pt-3">
                        <p className="ml-md-5 pl-md-3 mx-0 px-0" style={{ fontWeight: "700", fontSize: "20px" }}>  Illustrative framework to assess applicability of Blockchain  </p>
                    </div>
                    <div className='my-5 py-3 text-center d-flex justify-content-center'>
                        <img src={blockchainFrameworkImg} className="img-fluid" alt="" />
                    </div>
                    <div>

                        <p className="text-center mb-0 py-1" style={{ backgroundColor: "rgb(22, 142, 186)", fontWeight: "700", fontSize: "20px" }}> Key focus areas for adopting blockchain technology</p>
                        <div className="px-md-5 px-2 pt-3 pb-2" style={{ border: "1px solid rgb(22, 142, 186)" }}>
                            <p className="mb-0" style={{ fontWeight: "700", fontSize: "17px" }}>EDUCATE</p>
                            <p className="mb-0" style={{ fontWeight: "700", fontSize: "15px" }}>First, companies need to educate themselves on blockchain: </p>
                            <div>
                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    What are the benefits and limitations of blockchain?</span>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    How does it apply to real estate?</span>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    What are the real value propositions of blockchain vs. traditional system solutions?</span>
                            </div>
                        </div>

                    </div>
                    <div className="my-3">

                        <p className="text-center mb-0 py-1"
                            style={{ backgroundColor: "rgb(22, 142, 186)", fontWeight: "700", fontSize: "20px" }}>
                            COLLABORATE OR CREATE? </p>
                        <div className="px-md-5 px-2 pt-3 pb-2 " style={{ border: "1px solid rgb(22, 142, 186)" }}>
                            <p className="mb-0" style={{ fontWeight: "700", fontSize: "15px" }}>
                                Next, companies need to decide on collaboration opportunities: </p>
                            <div>
                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    What is the opinion on “build vs. collaborate” and which business area is the focus?</span>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    What issues or opportunities do we have in common with peers and competitors?</span>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    Which blockchain technology platform (Monax, Symbiont, Hyperledger) should we leverage,
                                    keeping in mind the compatibility and long-term usage?</span>
                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    Which organizations should we collaborate with?</span>
                            </div>
                        </div>

                    </div>
                    <div>

                        <p className="text-center mb-0 py-1" style={{ backgroundColor: "rgb(22, 142, 186)", fontWeight: "700", fontSize: "20px" }}>
                            FACILITATE
                        </p>
                        <div className="px-md-5 px-2 pt-3 pb-2 " style={{ border: "1px solid rgb(22, 142, 186)" }}>
                            <p className="mb-0" style={{ fontWeight: "700", fontSize: "15px" }}>
                                Then, companies need to facilitate the implementation:  </p>
                            <div>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    Which are the existing solutions that can help in our digital identity journey?</span>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    How can we integrate digital identities to make smarter applications?</span>

                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    Is privacy a priority for our blockchain applications?</span>
                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    What type of blockchain are we comfortable with―public, private, hybrid?</span>
                                <span className="d-flex align-items-center my-2">
                                    <span className="mr-4"
                                        style={{ height: "8px", width: "8px", backgroundColor: "black", borderRadius: "50%", marginRight: "10px" }} />
                                    How do current regulations impact our blockchain design?</span>

                            </div>
                        </div>

                    </div>
                    <div className="mb-4 mt-5 pt-3">
                        <p className=" mx-0 px-0" style={{ fontWeight: "700", fontSize: "20px" }}>
                            Think about it, question it, but don’t ignore it!  </p>
                        <div className="d-flex justify-content-center my-5 py-3"><img className="img-fluid" style={{ height: "350px" }} src={thinkAboutItImg} alt="" /></div>
                        <p style={{ fontWeight: "500", fontSize: "17px" }}>In our world of continued technological revolution, most new technology comes with a promise to improve business
                            and profitability. And whether one likes it or not,there can be a threat to survival if a business doesn’t adapt to the
                            changing times. As blockchain technology continues to evolve, it is challenging status quo and perhap requires CRE
                            companies to better understand the technology and revisit their existing business model, strategy, processes, and
                            financial plan. CRE companies can consider answering the questions listed in  on the previous page as they
                            perform a detailed assessment of adopting blockchain technology.</p>

                        <p style={{ fontWeight: "500", fontSize: "17px" }}>In summary, blockchain technology has significant potential to drive transparency, efficiency, and cost savings for
                            CRE owners by removing many of the existing inefficiencies in key processes. Hence, CRE companies and industry
                            participants evaluating an upgrade or overhaul of their current systems should have blockchain on their radar as its
                            demonstrated usefulness has the ability to bring significant value to the industry.</p>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
};
export default RexMLSBlockchain;