import React from "react";
import Building1 from "../../../assets/img/building111.png"
import Building2 from "../../../assets/img/Building222.png"

let Buildings=()=>{
  return(
      <React.Fragment>
          <div className="container-fluid mt-5">
              <div className="row align-items-center">
                  <div className="col-md-4 d-md-block d-flex mx-0 px-0">
                      <img src={Building1} className="img-fluid" alt="buildingImg"/>
                  </div>
                  <div className="col-md-4 d-md-block d-none mx-0 px-0">
                      {/*<img src={Building2} style={{marginBottom:"-53px"}} className="img-fluid mt-auto" alt="buildingImg"/>*/}
                      <img src={Building2} style={{marginBottom:"-10.5%"}} className="img-fluid mt-auto" alt="buildingImg"/>
                  </div>
                  <div className="col-md-4 mx-0 d-md-block d-none px-0">
                      <img src={Building1} className="img-fluid" alt="buildingImg"/>
                  </div>
              </div>
          </div>
      </React.Fragment>
  )
};
export default Buildings;