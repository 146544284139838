import React, { useState, useEffect } from "react";
import PropertiesTitle from "./PropertiesTitle";
import styles from "./properties.css";
import PropertiesCards from "./PropertiesCards";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Buildings from "../homepage/homepageComponents/Buildings";
import DevelopersWorkWith from "./DevelopersWorkWith";
import * as BrickApiService from "../../services/ApiServices";

let Properties = () => {
  const [propertiesList, setPropertiesList] = useState([]);
  useEffect(() => {
    BrickApiService.getPropertiesList().then((response) => {
      console.log(response)
      if (response && response.data) {
        setPropertiesList(response.data.Properties);
      }
    });
  }, []);

  return (
    <React.Fragment>
      <Navbar active={"properties"} />
      <PropertiesTitle setPropertiesList={setPropertiesList} />
      <PropertiesCards properties={propertiesList} />
      <DevelopersWorkWith />
      <Buildings />
      <Footer />
    </React.Fragment>
  );
};
export default Properties;
