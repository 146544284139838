import React from "react";
import allianceLogo from "./assets/allianceLogo.png"
import phoenixLogo from "./assets/phoneix_properties.png"
import casagrandLogo from "./assets/casagrandLogo.png"
import prestige from "./assets/prestige.png"
import radianLogo from "./assets/radianLogo.png"

let DevelopersWorkWith=()=>{
return(
    <React.Fragment>
        <div className="container mt-0 mb-5 pb-3">

                <p className="mb-0 text-center mx-auto" style={{fontWeight:"700",fontSize:"35px"}}>Developers we work with</p>
                    <div className="d-md-flex align-items-center my-5 mx-auto justify-content-md-between justify-content-center">
                    <div className="col-md-3">
                        <div className="d-flex mx-auto justify-content-center align-items-center" style={{height:"80px",width:"150px"}}>
                        <img src={phoenixLogo} className="img-fluid m-4"  alt=""/>
                        </div>
                    </div>
                        <div className="col-md-3">
                            <div className="d-flex mx-auto justify-content-center align-items-center" style={{height:"80px",width:"150px"}}>
                            <img src={casagrandLogo} className="img-fluid m-4"  alt=""/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex mx-auto justify-content-center align-items-center" style={{height:"80px",width:"150px"}}>
                            <img src={prestige} className="img-fluid m-4" alt=""/>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex mx-auto justify-content-center align-items-center" style={{height:"80px",width:"150px"}}>
                            <img src={radianLogo} className="img-fluid m-4"  alt=""/>
                            </div>
                        </div>
                    </div>

        </div>
    </React.Fragment>
)
};
export default DevelopersWorkWith;