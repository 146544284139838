import React from "react";

let ViewPropertyBoutBuilder=()=>{
    return(
        <React.Fragment>
            <div className="container px-0 my-5" >
                <p className="text-center" style={{fontSize:"35px",fontWeight:"700"}}>About Builder</p>
                <div className="p-4 mx-3 mx-md-auto" style={{border:"1px solid #FF8282"}}>
                    <p>Incor has a proven track record of building high-quality
                        townships, delivered on time, and has earned a reputation for
                        creating a great social life for the residents of its townships. It was founded by a cartel of NRI’s returned
                        from the US with a vision to form a premium global conglomerate with a clear focus on diverse businesses within the group.
                        It is anchored by professionals from various backgrounds in Real Estate, IT, Healthcare, and Infrastructure. Incor is a partner
                        in PBEL – which is a JV between Israeli giants Property and Building Corporation (PBC), Electra Real Estate (ERE), and Incor
                        Infrastructure.</p>

                    <p className="mt-2 mb-0"> Over the last 16 years, Incor has been creating landmark residential and
                        commercial projects across Hyderabad, Chennai, Bangalore, and Rajahmundry. With 7 Mn Sqft of
                        completed and planned projects, Incor has a rich experience in this field, and has a loyal customer
                        base, along with long term business partners. In addition to Real Estate, the Incor group operates a chain
                        of hospitals under the name “OMNI” in 3 cities and a co-living brand called Boston living. The company is set
                        on high values of quality and integrity and is committed to bringing world-class infrastructure to India.</p>
                </div>
            </div>
        </React.Fragment>
    )
};
export default ViewPropertyBoutBuilder;