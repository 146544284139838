import React, { useState, useEffect, useMemo } from "react";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import SideNav from "../SideNav";
import NavbarDashboard from "../NavbardDashboard";
import dropdownArrow from "../assets/dropdownArrow.png";
import callLogo from "../assets/callLogo.png";
import mailLogo from "../assets/mailLogo.png";
import profilePic from "../../../assets/profileImgSetting.svg";
import { getCustomers, USER_MANAGER, scheduleVisit } from '../../../services/ApiServices';

let InvestmentManagerContent = () => {
    const [day, setDay] = useState({ selectedDay: undefined });
    const [property, setProperty] = useState([])
    const [time, setTime] = useState('');
    const [userManager, setUserManager] = useState()
    const [selectedProperty, setSelectedProperty] = useState('')
    const [reason, setreasoon] = useState('')
    const timings = [{ title: '10:00AM', value: "10:00" }, { title: '11:00AM', value: "11:00" }, { title: '12:00PM', value: "12:00" }, { title: '02:00PM', value: "14:00" }, { title: '03:00AM', value: "15:00" }, { title: '05:00PM', value: "17:00" }, { title: '06:00PM', value: "18:00" }]


    useEffect(() => {
        //console.log(localStorage.getItem('user'));
        getCustomers(localStorage.getItem('user')).then((response) => {
            if (response && response.Properties) {
                setProperty(response.Properties);
            }
        });
        USER_MANAGER(localStorage.getItem('user')).then((response) => {
            if (response && response.UserManager) {
                setUserManager(response.UserManager);
            }
        });
        window.scrollTo(0, 0);
    }, []);

    const timeset = (e) => {
        let time = e.currentTarget.getAttribute('time')
        setTime(time)

    }

    let handleDayClick = (day, selected) => {
        console.log(day, selected)
        setDay({ selectedDay: day })
        // if (disabled) {
        //     return;
        // }
        // if (selected) {
        //     setDay({ selectedDay: undefined });
        //     return;
        // }
        // setDay({ selectedDay: day });
    };
    const submitHandler = () => {
        if (!day.selectedDay) {
            alert('Please select Date')
            return
        }
        if (time.length == 0) {
            alert('Please select Time')
            return

        }
        if (selectedProperty.length == 0) {
            alert('Please select property')
            return

        }
        if (reason.length == 0) {
            alert('Please select reason')
            return

        }
        let date = new Date(day.selectedDay)
        let body = {
            "ScheduleTime": time,
            "ScheduleDate": `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
            "PropertyID": selectedProperty,
            "Reason": reason,
            "ManagerID": userManager?.ManagerID,
            "UserID": localStorage.getItem('user')
        }

        scheduleVisit(body).then((response) => {
            if (response.Status == 1) {
                alert(response.Message);
                setTime('');
                setSelectedProperty('')
                setreasoon('')
                setDay({ selectedDay: undefined })
            }
        });
    }
    return (
        <React.Fragment>
            <div className="">
                <div className="container-fluid px-0 mx-0">
                    <div className="row">
                        <div className="col-md-4 px-0 mx-0">
                            <div className="border-top-0" style={{
                                borderRadius: "20px", border: "1px solid #C4C4C4",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"
                            }}>
                                <div style={{ backgroundColor: "rgb(238, 255, 199)", height: "100px", borderRadius: "20px" }} className="">

                                </div>
                                <div className="px-md-4 px-2 pb-md-4 pb-2 InvestorProfileSection" style={{ position: "relative" }}>
                                    <img src={profilePic} alt="" style={{ height: "100px", borderRadius: "50%", position: "absolute", top: "-50%", left: "36%" }} />

                                    <div className="mt-md-5 pt-md-3 mt-4 pt-1 ml-3">
                                        <p className="mb-0" style={{ color: "rgb(22, 142, 186)", fontWeight: "400", fontSize: "20px" }}>{userManager?.Name}</p>
                                        <p className="mb-0" style={{ color: "black", fontWeight: "400", fontSize: "15px" }}>Investment Manager</p>
                                        <div className="d-flex align-items-center my-3 justify-content-start investmentManagerContactSection">
                                            <div>
                                                <img src={callLogo} alt="" height={28} />
                                            </div>
                                            <div className="mx-3 fields">
                                                <p className="mb-0 " style={{ color: "black", fontWeight: "400", fontSize: "15px" }}>Contact Number</p>
                                                <p className="mb-0" style={{ color: "#7C7C7C", fontWeight: "normal", fontSize: "15px" }}>{userManager?.ContactNumber}</p>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center my-3 justify-content-start investmentManagerContactSection">
                                            <div>
                                                <img src={mailLogo} alt="" height={25} width={25} />
                                            </div>
                                            <div className="mx-3 fields">
                                                <p className="mb-0" style={{ color: "black", fontWeight: "400", fontSize: "15px" }}>Email Address</p>
                                                <p className="mb-0" style={{ color: "#7C7C7C", fontWeight: "normal", fontSize: "15px" }}>{userManager?.EmailID}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 ">
                            <div className="mx-md-4 mx-1 mt-0 bg-white">
                                <div className="d-flex  calendarSection ">
                                    <div className="div">
                                        <div className="text-center my-md-auto my-4 mx-auto">
                                            <DayPicker
                                                disabledDays={{ before: new Date() }}
                                                onDayClick={handleDayClick}
                                                selectedDays={day.selectedDay}
                                            />
                                        </div>
                                    </div>
                                    <div className="div px-md-5 investmentManagerDropdown px-2">
                                        <div className="d-flex my-5 align-items-center selectBoxArrow" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                                            <select value={selectedProperty} onChange={(e) => {
                                                console.log(e.target.value)
                                                setSelectedProperty(e.target.value)
                                            }} style={{
                                                height: "30px", color: "black", border: "1px solid rgb(22, 142, 186)",
                                                position: "relative"
                                            }}
                                                className=" form-control px-4 py-0">
                                                <option className="bg-white text-dark" value={''}>Select property</option>
                                                {property.map(item =>
                                                    <option className="bg-white text-dark" value={item.PropertyID}>{item.PropertyTitle}</option>
                                                )}

                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div>
                                        <div className="d-flex my-5 selectBoxArrow align-items-center" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                                            <select value={reason} onChange={(e) => setreasoon(e.target.value)} style={{
                                                height: "30px", color: "black", border: "1px solid rgb(22, 142, 186)",
                                                position: "relative"
                                            }}
                                                className=" form-control px-4 py-0">
                                                <option className="bg-white text-dark" value={''}  >Reason for call</option>
                                                <option className="bg-white text-dark" value="Investment Advice">Investment Advice</option>
                                                <option className="bg-white text-dark" value="Property Related query">property related Query</option>
                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div>
                                        {/* <div className="d-flex mt-5 selectBoxArrow align-items-center" style={{ position: "relative", fontWeight: "400", fontSize: "16px" }}>
                                            <select style={{
                                                height: "30px", color: "black", border: "1px solid rgba(255, 130, 130, 0.3)",
                                                position: "relative"
                                            }}
                                                className=" form-control px-4 py-0">
                                                <option className="bg-white text-dark" selected >Book the slot </option>
                                                <option className="bg-white text-dark" value="1">Option1</option>
                                                <option className="bg-white text-dark" value="2">Option2</option>
                                            </select>
                                            <img alt="" src={dropdownArrow} style={{ position: "absolute", right: "10%", width: "15px", height: "8px" }} />
                                        </div> */}
                                        <div className="mt-2">
                                            {timings.map(item => <div className="btn text-center my-1 py-1 mx-auto font-weight-bold shadow-none w-100" style={{ cursor: 'pointer', backgroundColor: item.value == time ? "rgb(30, 144, 255)" : "#f2f2f2" }} time={item.value} onClick={timeset}>{item.title}</div>)}

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end pr-md-4 ml-md-auto mx-auto  my-4 mx-md-4 mx-auto px-md-2 px-1">
                                <div style={{ border: "1px solid green", borderRadius: "10px", width: "200px", color: 'green' }} className="btn  mx-2 btn-md text-capitalize">Cancel</div>
                                <div style={{ background: "linear-gradient(264.04deg, rgb(155, 205, 42) -5.78%, rgb(22, 142, 186) 101.58%)", color: 'white', borderRadius: "10px", width: "200px" }} className="btn btn-md   mx-2" onClick={submitHandler}>Schedule Call</div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
};
export default InvestmentManagerContent;