import React, { useEffect } from "react";
import ReactPlayer from 'react-player'
import Navbar from "../Navbar";
import AboutUs from "./assets/AboutUsImg.png";
import circleAccessImg from "./assets/circleAccesImg.png";
import liquidityLogo from "./assets/liquididtyLogo.png";
import accessSettingLogo from "./assets/accessSettingLogo.png";
import keyAccessImg from "./assets/keyAccesImg.png";
import adviser1 from "./assets/adviser1.png";
import adviser2 from "./assets/adviser22.png";
import thunderingLogo from "./assets/thunderingLogo.png";
import performanceLogo from "./assets/performanceLogo.png";
import pathwayLogo from "./assets/pathwayLogo.png";
import gilbertLogo from "./assets/gilbertLogo.png";
import pdfLogo from "./assets/pdfLogo.png";
import pptLogo from "./assets/pptLogo.png";
import coreLogicLogo from "./assets/coreLogicLogo.png";
import worldMap from "./assets/worldMap.png";
import rajivImg from "../../assets/img/rajivGupta.png";
import anupImg from "../../assets/img/anupMittal.png";
import sidharthImg from "../../assets/img/sidharrthShankar.png";
import brickPatternOurMission from "./assets/brickPatternOurMission.png";
// import ourMission from "./assets/ourMissions.png";
import ourMission from "../../assets/aboutUsCircle.png";
import Buildings from "../homepage/homepageComponents/Buildings";
import Footer from "../Footer";
import styles from "./aboutUsFooter.css";
import contactUsCircleImg from "../resources/assets/contactUsCircleImg.png";
import contactUsImg from "../../assets/img/contactUsImage.png";
import { MapContainer } from "../viewProperties/viewPropertyFirst/MapContainer";
import Management1 from "./assets/management1.png";
import Management2 from "./assets/management2.png";
import Management3 from "./assets/management3.jpg";
import Advisors1 from "./assets/advisor2.jpeg"
import Advisors2 from "./assets/advisor2.jpeg"
import Advisors3 from "./assets/advisor3.jpg"
import footerFacebookLogo from "../../assets/footerFacebookLogo.png";
import footerLinkedInLogo from "../../assets/footerLinkedInLogo.png";
import { FaFacebookF } from 'react-icons/fa'
import { RiLinkedinFill } from 'react-icons/ri'
import Bb from '../../assets/BB.pdf'
let FooterAboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <React.Fragment>
            <Navbar active="contactUs" />
            <div className="container-fluid  img-fluid text-center m-0 p-0">
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{ minHeight: "250px", maxHeight: "250px" }}
                        src={AboutUs} />
                    <p style={{ fontWeight: "800", fontSize: "40" }}
                        className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        Contact US</p>
                </div>
            </div>
            <div className="container-fluid  px-0 mx-0 mt-5 mb-5" style={{ position: "relative" }}>
                <div className="">
                    <p className="text-center mx-auto mb-0 mb-4" style={{ fontWeight: "900", fontSize: "40px" }}>Who We
                        Are</p>
                </div>
                <div
                    className=" my-4 d-flex flex-column align-items-center p-5  text-white backgroundImgAboutUs my-auto"
                    style={{ minHeight: "70vh", fontSize: "18px", letterSpacing: "0.03em" }}>
                    <div className="my-auto">
                        <p className="mb-5 px-md-5 px-1">Brick-Berry brings together the expertise in the field of Real-estate, Legal and Finance of a large PE fund on a common platform. We offer diverse investment opportunities, giving you the power to track your portfolio, make right choices, and create wealth. We make sure you get the returns you need by understanding your needs and aspirations. If you’re not an investor, we’ll work with you to develop your personal portfolio and start making smart decisions today.</p>
                        <p className="mb-5 px-md-5 px-1">Brick-Berry is India's one of the largest property investment
                            We allow you to easily Buy, Manage, and get High yields. We democratize the world's most exclusive wealth-building tools, giving regular people access to the same wealth-building principals used by the top 1%. Brick-Berry offers a new take on this tried-and-true investing model, using technology to allow investors to easily Buy, become Co-owners and Sell fractionalized real estate through one centralized portal.
                        </p>
                        <p className="mb-5 px-md-5 px-1">Our wealth of experience and transparent process allow us to provide you with a secure and professional service, designed to fit your needs as a customer. We offer a luxurious and hassle-free way to invest in prime real estate. Every detail has been considered, from the exceptional service to the business model, to guarantee you a stress-free experience.</p>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div className="row  justify-content-center">
                    <div className="col-md-4 d-flex justify-content-center">
                        <div className="d-flex flex-column m-md-2 m-4 align-items-center justify-content-center"
                            style={{
                                height: "200px",
                                width: "200px",
                                borderRadius: "50%",
                                border: "2px solid rgb(22, 142, 186)"
                            }}>
                            <p className="text-center mb-0" style={{ fontWeight: "500", fontSize: "30px" }}>$80
                                Million</p>
                            <p className="text-center mb-0" style={{ fontWeight: "400", fontSize: "16px" }}> Investment
                                Experience</p>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center">
                        <div className="d-flex flex-column m-md-2 m-4 align-items-center justify-content-center"
                            style={{
                                height: "200px",
                                width: "200px",
                                borderRadius: "50%",
                                border: "2px solid rgb(22, 142, 186)"
                            }}>
                            <p className="text-center mb-0" style={{ fontWeight: "500", fontSize: "30px" }}> ₹65 Crs</p>
                            <p className="text-center mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>Properties
                                Funded</p>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center">
                        <div className="d-flex m-md-2 m-4 flex-column align-items-center justify-content-center"
                            style={{
                                height: "200px",
                                width: "200px",
                                borderRadius: "50%",
                                border: "2px solid rgb(22, 142, 186)"
                            }}>
                            <p className="text-center mb-0" style={{ fontWeight: "500", fontSize: "30px" }}> 1500 +</p>
                            <p className="text-center mb-0" style={{ fontWeight: "400", fontSize: "16px" }}>Users</p>
                        </div>
                    </div>
                </div>
            </div>
            {/*------------ What We Do ---------------*/}
            <div className="container mx-auto d-flex flex-column justify-content-center px-0 mx-0 mt-5 mb-5"
                style={{ position: "relative" }}>
                <div className="mx-auto">
                    <p className="text-center mx-auto mb-0 mb-4" style={{ fontWeight: "900", fontSize: "40px" }}>What We
                        Do</p>
                    <p className="text-center" style={{ fontSize: "18px", letterSpacing: "0.03em" }}>Brick-Berry helps consumers change the way they own property. Through an institutional-quality platform, we provide access to investment opportunities in the commercial and residential real estate markets, aimed at optimizing liquidity for our users.</p>
                </div>
                <div className="row my-5">
                    <div className="col-md-4 my-md-1 mb-5 mt-2   px-5">
                        <div style={{ position: "relative" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={circleAccessImg} alt="" style={{ height: "70px", position: "absolute" }} />
                                <img src={keyAccessImg} alt="" style={{ height: "25px", position: "relative" }} />
                            </div>
                        </div>
                        <div className="py-5 px-4 h-100"
                            style={{ boxShadow: "0px 6px 6px #C4C4C4", borderRadius: "25px" }}>
                            <div className="mt-2">
                                <p className="mb-0 text-center "
                                    style={{ fontSize: "18px", fontWeight: "700", letterSpacing: "0.03em" }}>Access</p>
                                <p className="mb-0 ml-3 mt-4">to vetted Class A commercial real estate with 8-10% yields
                                    and 17-20% projected returns.</p>
                            </div>
                        </div>
                    </div>
                    <div className=" col-md-4 my-md-1 my-5   px-5">
                        <div style={{ position: "relative" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={circleAccessImg} alt="" style={{ height: "70px", position: "absolute" }} />
                                <img src={liquidityLogo} alt="" style={{ height: "25px", position: "relative" }} />
                            </div>
                        </div>
                        <div className="py-5 px-4 h-100"
                            style={{ boxShadow: "0px 6px 6px #C4C4C4", borderRadius: "25px" }}>
                            <div className="mt-2">
                                <p className="mb-0 text-center "
                                    style={{ fontSize: "18px", fontWeight: "700", letterSpacing: "0.03em" }}>Liquidity</p>
                                <p className="mb-0 ml-3 mt-4">through access to a separate proprietary resale
                                    platform.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 my-md-1 my-5   px-5">
                        <div style={{ position: "relative" }}>
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={circleAccessImg} alt="" style={{ height: "70px", position: "absolute" }} />
                                <img src={accessSettingLogo} alt="" style={{ height: "25px", position: "relative" }} />
                            </div>
                        </div>
                        <div className="py-5 px-4 h-100"
                            style={{ boxShadow: "0px 6px 6px #C4C4C4", borderRadius: "25px" }}>
                            <div className="mt-2">
                                <p className="mb-0 text-center "
                                    style={{ fontSize: "18px", fontWeight: "700", letterSpacing: "0.03em" }}>Asset
                                    Management</p>
                                <p className="mb-0 ml-3 mt-4">active asset management including leasing and property
                                    management to increase value.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*----------- Video Player ---------------*/}
            <div className="container">
                <div className="player-wrapper">
                    <ReactPlayer
                        className='react-player'
                        url='https://www.youtube.com/watch?v=Rkz3cRypsm8&list=PLhnpHK9T2IRwI1ATfT7HbXpT4rX-5NbTS'
                        width='100%'
                        height='100%'
                        controls={true}
                    />
                </div>

                <div className="mt-md-0 mt-5 pt-md-0 pt-4">
                    <h4 className="playerBottomText" style={{
                        fontWeight: 700,
                        fontSize: "35px",
                        letterSpacing: "0.04em",
                        position: "relative",
                        top: "-30px"
                    }}>Provide institutional level data and analysis through our core
                        principles of Integrity, Transparency, Returns and Discipline.</h4>
                </div>
            </div>

            {/*----------- Our Mission ---------------*/}
            <div className="container-fluid my-4 pb-4 position-relative">
                <div className="my-0" style={{ position: "absolute" }}>
                    <div className="d-flex align-items-center " style={{ position: "relative" }}>
                        <div>
                            <img className="img-fluid h-100" src={brickPatternOurMission} alt="" />
                        </div>
                        <div>
                            <img className="img-fluid h-100" src={brickPatternOurMission} alt="" />
                        </div>
                    </div>
                </div>
                <div className="py-2 container mx-auto position-relative ">
                    <div className="" style={{ position: "relative" }}>
                        <div className="d-flex justify-content-center align-items-center my-5 py-2">
                            {/*<img className="img-fluid" alt="" src={ourMission}/>*/}
                            <div className="text-center my-2 mx-auto d-flex justify-content-center"
                                style={{ position: "absolute" }}>
                                <img className="img-fluid" style={{ zIndex: "1" }} src={worldMap} />
                            </div>
                            <img className="img-fluid" style={{ maxHeight: "450px", position: "relative", zIndex: "3" }}
                                alt="" src={ourMission} />
                        </div>
                    </div>
                </div>
                <div className="">

                </div>
            </div>

            {/*----------- Advisers -------------*/}


            <div>
                {/* <div className="container my-md-1 my-5 position-relative">
                    <div className="mx-auto pt-5">
                        <p className="text-center pt-1 mx-auto mb-0 mb-4"
                            style={{ fontWeight: "900", fontSize: "40px" }}>Our Advisors</p>
                    </div>
                </div> */}

                <div>
                    <div className="container-fluid">
                        <div className="Founder" style={{ backgroundColor: "#FAFAFA" }}>
                            <div className="founder-left">
                                <div className="text-center founder-img">
                                    <img style={{ borderRadius: "50%", }} src={Management1}
                                        alt="" />
                                </div>
                                <h3>Harsha Amarneni</h3>
                                <p>
                                    Co-founder & CTO at <a target="_blank" href="https://brick-berry.com/">
                                        Brick-Berry.com

                                    </a>
                                </p>
                                <div className="founder-links">
                                    <a target="_blank" href="https://www.facebook.com/MerkleHash/"><FaFacebookF color="white" /></a>
                                    <a target="_blank" href=" https://www.linkedin.com/company/4brickberry/about/"> <RiLinkedinFill color="white" /></a>
                                </div>
                            </div>
                            <div className="founder-right">
                                <header>
                                    <h3>Welcome <br /> <span> Message</span></h3>
                                </header>
                                <p>Having read "Rich Dad, Poor Dad" while growing up, it was clear to me that the only way to live was to "make your money work for you." Unfortunately, what we saw in reality was limited low-return generating Mutual funds/Bank-deposits, Bank FD, Volatile stock markets or Inaccessible private equity deals. When it came to our hard earned salary, it seemed like money was always in control with limited avenues to generate returns. With BrickBerry, it's time for change.</p>

                                <p className="let-us-help">Let us help  you turn the tables !</p>


                                <p className="regards">
                                    Best <br />
                                    Regards <br />
                                    <span>Harsha Amaraneni</span>
                                </p>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            {/* <div>
                <div className="container my-md-1 my-5 position-relative">
                    <div className="mx-auto pt-5">
                        <p className="text-center pt-1 mx-auto mb-0 mb-4"
                            style={{ fontWeight: "900", fontSize: "40px" }}>Management Team</p>
                    </div>
                </div>

                <div>
                    <div className="container-fluid">
                        <div className="row px-md-5 px-2" style={{ backgroundColor: "#FAFAFA" }}>
                            <div className="col-md-4 p-5" style={{ display: 'grid', alignItems: 'center', justifyItems: 'center', textAlign: 'center' }}>
                                <div className="text-center">
                                    <img style={{ borderRadius: "50%", height: "120px", width: "120px" }} src={Management1}
                                        alt="" />
                                </div>
                                <div className="mx-md-4 mx-0">
                                    <p className="mt-2">Harsha Amaraneni,<br />
                                        Real-Estate Vertical Head</p>

                                </div>
                            </div>
                            <div className="col-md-4 p-5" style={{ display: 'grid', alignItems: 'center', justifyItems: 'center', textAlign: 'center' }}>
                                <div className="text-center">
                                    <img style={{ borderRadius: "50%", height: "120px", width: "120px" }} src={Management2}
                                        alt="" />
                                </div>
                                <div className="mx-md-4 mx-0">
                                    <p className="mt-2">Prasad K Nair<br />
                                        Co-founder, Lead -Investor Relations</p>

                                </div>
                            </div>
                            <div className="col-md-4 p-5" style={{ display: 'grid', alignItems: 'center', justifyItems: 'center', textAlign: 'center' }}>
                                <div className="text-center">
                                    <img style={{ borderRadius: "50%", height: "120px", width: "120px" }}
                                        src={Management3} alt="" />
                                </div>
                                <div className="mx-md-4 mx-0">
                                    <p className="mt-2">Shivam Pandit<br />
                                        Sr Entrepreneur, Strategist</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="container mt-0 mt-5">
                <div className="mx-auto ">
                    <p className="text-center mx-auto mb-0 mb-2" style={{ fontWeight: "900", fontSize: "40px" }}>Our
                        Partners</p>
                    <p className="text-center" style={{ fontSize: "18px", letterSpacing: "0.03em" }}>We are proud to be
                        supported by the following well-known Partners.</p>
                </div>
                <div>

                </div>
            </div>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <div style={{ width: "150px" }}>
                        <img src={coreLogicLogo} className="img-fluid" alt="" />
                    </div>
                    <div style={{ width: "150px" }}>
                        <img src={performanceLogo} className="img-fluid" alt="" />
                    </div>
                    <div style={{ width: "150px" }}>
                        <img src={pathwayLogo} className="img-fluid" alt="" />
                    </div>
                    <div style={{ width: "150px" }}>
                        <img src={gilbertLogo} className="img-fluid" alt="" />
                    </div>
                    <div style={{ width: "150px" }}>
                        <img src={thunderingLogo} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>

            {/*-------------- Contact US ---------*/}
            <div className="container my-5 pb-4">
                <div className="row px-4 pt-4 pb-3 align-items-start"
                    style={{ border: "1px solid #ffe2db", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                    <div className="col-md-6 h-100 d-flex flex-column align-items-center">
                        <div className="d-flex flex-wrap justify-content-center mt-5">
                            <p className="text-center mb-1" style={{ fontWeight: "700", fontSize: "35px" }}>Contact US</p>
                            <img className="text-center my-4" height={250}
                                style={{ width: "90%", margin: "auto", position: "relative", top: "-10%", zIndex: "1" }}
                                src={contactUsCircleImg} alt="" />
                            <img className="text-center" height={220}
                                style={{
                                    width: "auto",
                                    height: "70%",
                                    margin: "auto",
                                    position: "absolute",
                                    zIndex: "2",
                                    top: "40%"
                                }}
                                src={contactUsImg} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6 px-md-5 px-2 pt-2 pt-md-2 pt-5 mt-md-0 mt-4">
                        <form className="mx-md-4 mx-1">
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example1">Are You a Brick-Berry
                                    Investor</label>
                                <div className="d-block selectBox">
                                    <select className="w-100 py-2 partnerContactSelect" placeholder=" "
                                        style={{ border: "2px solid rgb(155, 205, 42)" }} name="">
                                        <option value="" className="disabled"></option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example2">Email Address</label>
                                <input type="email" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example2">Subject Line</label>
                                <input type="text" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>

                            <div className="form-outline mb-1">
                                <label className="form-label " htmlFor="form5Example2">Describe Your enquiry in as much
                                    details as Possible</label>
                                <textarea className="form-control rounded-0 mt-1" style={{ border: "2px solid rgb(155, 205, 42)" }}
                                    id="exampleFormControlTextarea1"
                                    rows="4" />
                            </div>
                            {/* <input type="file" className="partnerFileIInput" /> */}
                            <div className="d-flex ">

                                <button type="submit"
                                    className="btn px-5 py-2 text-capitalize ml-auto mr-0 float-md-right float-none text-white  mb-0"
                                    style={{
                                        background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)",
                                        borderRadius: "10px",
                                        fontWeight: "700",
                                        fontSize: "15px"
                                    }}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/*----------- Brick-Berry Brochure -------------*/}
            <div className="container">
                <div className="mx-auto">
                    <p className="text-center mx-auto mb-0 mb-4" style={{ fontWeight: "900", fontSize: "40px" }}>
                        Download Brick-Berry Brochure/PPT</p>
                </div>
                <div className="d-flex flex-wrap  my-5 justify-content-around">
                    <div className="d-flex m-2 flex-column justify-content-center">
                        <img onClick={() => window.open(Bb, "_blank")} className="" style={{ height: "100px", width: "90px" }} src={pdfLogo} alt="" />
                        <p className="mb-0 text-center mt-1 ml-3"
                            style={{ fontSize: "20px", fontWeight: "700" }}>Brochure</p>
                    </div>
                    {/* <div className="d-flex m-2 flex-column justify-content-center">
                        <img className="" style={{ height: "100px", width: "90px" }} src={pptLogo} alt="" />
                        <p className="mb-0 text-center mt-1" style={{ fontSize: "20px", fontWeight: "700" }}>PPT</p>
                    </div>
                    <div className="d-flex m-2 flex-column justify-content-center">
                        <img className="" style={{ height: "100px", width: "90px" }} src={pdfLogo} alt="" />
                        <p className="mb-0 text-center mt-1" style={{ fontSize: "20px", fontWeight: "700" }}>USP</p>
                    </div> */}
                </div>
            </div>

            {/*-----------Map-------------*/}
            <div className="container">
                <div className="row">


                    <div className="col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.1040980912867!2d78.31154662391786!3d17.50254351548216!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb92a39de53c03%3A0xd8e6e9c7e31d3b17!2sBrickberry!5e0!3m2!1sen!2sin!4v1699937826462!5m2!1sen!2sin" width={550} height={300} style={{ border: 0, maxWidth: "100%", maxHeight: "100%" }} allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>

                    <div className="col-md-6">
                        <div className="p-5 h-100" style={{ border: "1px solid rgb(22, 142, 186)" }}>
                            <p className="mb-2 px-md-4 "> India Office:</p>
                            <p className="px-md-4">BrickBerry, 1-60/8/A&B,
                                <br />
                                3rd Floor, KNR Space,<br />
                                Above TVS Showroom,<br />
                                Kondapur, Gachibowli,<br />
                                Hyderabad - 500032</p>


                        </div>
                    </div>


                </div>
            </div>
            <Buildings />
            <Footer />
        </React.Fragment>
    )
};
export default FooterAboutUs;
