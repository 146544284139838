import React, {useEffect, useState} from "react";
import SecurePinTitle from "./securePinTitle";
import reactangleImg from "./assets/reactangleImg.png";
import blackCircleImg from "./assets/blackCircleImg.png";
import blackTriangleImg from "./assets/blackTriangleImg.png";
import dotImage from "./assets/dotImage.png";
import {Link,useLocation,useHistory} from "react-router-dom";
import NavbarPin from "./NavbarPin";
import Axios from "axios";

let VerifyEmail=()=>{
    const location=useLocation();
    const history=useHistory();
    let [user,setUser]=useState({
        EmailID:'',
        PIN:''
    });
    useEffect(()=>{
        setUser({
            ...user,
            EmailID:location.email,
        })
    },[ ]);

    let verifyEmail=async (e)=>{
        e.preventDefault();
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        // let userData={
        //     UserID:user.UserID,
        //     PIN:pin };
        let dataURL = `${process.env.REACT_APP_API_URL}/securepin/sendactivecode_email`;
        await Axios.post(dataURL, user.EmailID).then((response) => {
            console.log("pin sent to email Success");
            console.log(response);
            // history.push({
            //     pathname: '/setPin',
            // })
        }).catch((err) => {
            console.log(err)
        });
    };
    return(
        <React.Fragment>
            <NavbarPin/>
            <pre>{JSON.stringify(user)}</pre>
            <div >
                <SecurePinTitle/>
                <div className=" img-fluid " >
                    <img  src={reactangleImg} style={{height:"61%",width:"60%",position:"absolute",zIndex:"1"}} alt="" className="img-fluid rectangleSecureImg"/>
                </div>
                <div className="col-md-4 mx-auto" style={{position:"relative",zIndex:"2"}}>
                    <p className="text-center my-4" style={{fontWeight:"400",fontSize:"17px",color:"#FF8282"}}>Please verify your email </p>
                    <p className="text-center mb-4" style={{fontWeight:"700",fontSize:"40px"}}>Enter Your Email</p>
                    <form onSubmit={verifyEmail}>
                        <div className="px-md-5 px-2 mx-md-4  mx-auto my-2">
                            <div className="d-flex justify-content-between my-3">
                                <div className="form-outline">
                                    <input value={user.EmailID} onChange={(e)=>setUser({...user,EmailID:e.target.value})} type="text"
                                           style={{ height:"50px"}} id="typePassword" className="form-control"/>
                                </div>
                            </div>
                            {/*<p className="text-dark text-right my-0" style={{fontWeight:"400",fontSize:"16px"}}>Forgot PIN?</p>*/}

                        </div>

                        <div className="text-center mt-5">
                            {/*<Link to={"/"}>*/}
                            <button type="submit" className="btn btn-md py-2 px-5 text-capitalize text-white"
                                    style={{backgroundColor:"#ff8282",fontSize:"20px",fontWeight:"600",borderRadius:"10px"}}>
                                Send verification code
                            </button>
                            {/*</Link>*/}
                        </div>
                    </form>
                </div>
                <img  src={blackCircleImg} style={{position:"absolute",height:"80px",zIndex:"1",top:"65%",left:"62%"}} alt="" className="img-fluid"/>
                <img  src={blackTriangleImg} style={{position:"absolute",zIndex:"1",height:"75px",top:"45%",left:"72%"}} alt="" className="img-fluid"/>
                <img  src={blackCircleImg} style={{position:"absolute",height:"50px",zIndex:"1",top:"78%",left:"72%"}} alt="" className="img-fluid"/>
                <img  src={dotImage} style={{position:"absolute",height:"120px",zIndex:"1",top:"58%",left:"82%"}} alt="" className="img-fluid dotImageSecure"/>

            </div>
        </React.Fragment>
    )
};
export default VerifyEmail;
