const  ACCESS_TOKEN = "token";
export const saveLoginInfo = (token, userID) => {
  try {
    localStorage.setItem(ACCESS_TOKEN, token);
    localStorage.setItem('user', userID);
  } catch (err) {
    console.log(err);
  }
};


export const deleteLoginInfo = () => {
  try {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem('user');
  } catch (err) {
    console.log(err);
  }
};

export const getToken = () => {
  try {
    return localStorage.getItem(ACCESS_TOKEN);
  } catch (err) {
    console.log(err);
  }
};
export const getUser = () => {
    try {
      return localStorage.getItem('user');
    } catch (err) {
      console.log(err);
    }
  };

  export const deleteUser = () => {
    try {
      return localStorage.removeItem('user');
    } catch (err) {
      console.log(err);
    }
  };

