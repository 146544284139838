import React from "react";
import HowItWorksTitleImg from "../assets/HowItWorksTitleImg.png";
let HowItWorksTitle=()=>{
    return(
        <React.Fragment>
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{minHeight:"200px"}} src={HowItWorksTitleImg}/>
                    <p
                       className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        How It Works</p>
                </div>
            </div>
        </React.Fragment>
    )
};
export default HowItWorksTitle;