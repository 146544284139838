import React from "react";
import transparency from "../../../assets/transparancyCard.png";
import secure from "../../../assets/secureCard.png";
import riskManagement from "../../../assets/riskManagementCard.png";
import liquidity from "../../../assets/img/liquidityUpdatedImg.png";
import diversification from "../../../assets/diversificationCard.png";
import dataDriven from "../../../assets/dataDrivenCard.png";
import attractive from "../../../assets/attractiveReturnCard.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import Fade from "react-reveal/Fade";
let WhyChoseBrickberry = () => {
    return (
        <React.Fragment>
            <Fade bottom>
                <div
                    style={{
                        padding: "10px",
                        marginTop: "20px",
                        position: "relative",
                    }}>
                    <div
                        className="container d-block mb-5"
                        style={{ position: "relative", zIndex: "2" }}>
                        <p
                            className=" mx-auto"
                            style={{
                                height: "5px",
                                width: "50px",
                                backgroundColor: "rgb(22, 142, 186)",
                            }}></p>
                        <h4
                            className="h1 text-center mb-0"
                            style={{ fontWeight: "700", fontSize: "40px" }}>
                            Why Chose Assets Positive
                        </h4>
                        <div className="my-5">
                            <div
                                className="row "
                                style={{ position: "relative", zIndex: "2" }}>
                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={dataDriven}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>

                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={transparency}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={liquidity}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={attractive}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div
                                className="row justify-content-center "
                                style={{
                                    marginTop: "-5.2%",
                                    position: "relative",
                                    zIndex: "3",
                                }}>
                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={secure}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={diversification}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>
                                <div className="col-md-3 text-center">
                                    <ScrollAnimation
                                        animateIn="fadeIn"
                                    // animateOnce={true}
                                    >
                                        <img
                                            src={riskManagement}
                                            style={{ maxHeight: "400px" }}
                                            className="img-fluid"
                                            alt=""
                                        />
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </React.Fragment>
    );
};
export default WhyChoseBrickberry;
