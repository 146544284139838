import React, {useState} from "react";
import SecurePinTitle from "./securePinTitle";
import reactangleImg from "./assets/reactangleImg.png";
import blackCircleImg from "./assets/blackCircleImg.png";
import blackTriangleImg from "./assets/blackTriangleImg.png";
import dotImage from "./assets/dotImage.png";
import {Link,useHistory} from "react-router-dom";
import NavbarPin from "./NavbarPin";
import securePinImg from "./assets/securePinImg.png";
import Axios from "axios";

let ForgotPassword=()=>{
    let history=useHistory();
    let[user,setUser]=useState({
        EmailID:'',
        Password:'',
    });
    let[token,setToken]=useState(null);
    let[password,setPassword]=useState(null);
    let[sentEmail,setSentEmail]=useState(null);
    let[emailError,setEmailError]=useState(null);
    let[passwordError,setPasswordError]=useState(null);
    let handleInput=(e)=>{
        setUser({
            ...user,
            [e.target.name]:e.target.value
        })
    };
    let sendEmail=async (e)=>{
        e.preventDefault();
        console.log(user);
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let dataURL = `${process.env.REACT_APP_API_URL}/forgotpassword-user`;
        await Axios.post(dataURL, JSON.stringify(user), config).then((response) => {
            if (response.data.Status ===1){
                console.log("code sent to email");
                setToken(
                    response.data.Token
                );
                setSentEmail(true);
            }
            else if (response.data.Status ===0){
                setEmailError(response.data.Message);
                setTimeout(()=>{
                    setEmailError(null)
                },4000);
                console.log(response.data.Message);
            }
    })};

    let savePassword=async (e)=>{
        e.preventDefault();
        console.log(user);
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        let updatedUser={"Token":token,"Password":password};
        let dataURL = `${process.env.REACT_APP_API_URL}/forgotpassword-user`;
        await Axios.put(dataURL, JSON.stringify(updatedUser), config).then((response) => {
            console.log(response);
            if (response.data.Status ===1){
                console.log("password changed");
                history.push({
                    pathname: '/login',
                })
            }
            else if (response.data.Status ===0){
                setPasswordError(response.data.Message);
                setTimeout(()=>{
                    setPasswordError(null)
                },4000);
                console.log(response.data.Message);
            }
        })
    };
    return(
        <React.Fragment>
            <NavbarPin/>
            {/*<pre>{JSON.stringify(user)}</pre>*/}
            <div >
                <div className="container-fluid p-0 m-0">
                    <div className="position-relative">
                        <img src={securePinImg} style={{minHeight:"150px",maxHeight:"220px",width:"100%"}} className="img-fluid" alt="" />
                        <div className="card-img-overlay d-flex justify-content-end align-items-center mr-md-5 pr-md-5 mx-auto px-0">
                            <p className="mr-md-5 pr-md-5 mx-auto px-0"
                               style={{fontWeight:"900",fontSize:"40px",color:"white"}}>Forgot Password</p>
                        </div>
                    </div>
                </div>
                <div className=" img-fluid " >
                    <img  src={reactangleImg} style={{height:"61%",width:"60%",position:"absolute",zIndex:"1"}} alt="" className="img-fluid rectangleSecureImg"/>
                </div>
                {token === null &&
                <div className="col-md-6 mx-auto" style={{position:"relative",zIndex:"2"}}>
                    <div className="px-md-2 px-2 mx-md-4  mx-auto mt-4 ">
                        <p className=" text-center my-0" style={{fontWeight:"400",fontSize:"20px",color:"#FF8282"}}>Please enter your email to reset your password</p>
                    </div>
                    <form onSubmit={sendEmail}>
                    <p className="text-center my-3" style={{fontWeight:"700",fontSize:"40px"}}>Enter Email ID</p>
                    <div className="px-md-5 px-2 mx-md-4  mx-auto my-3">
                        <div className="d-flex justify-content-center my-3">
                            <div className="form-outline text-muted">
                                <input onChange={handleInput} required={true} name="EmailID" type="email" style={{width:"300px", height:"50px"}} id="typePassword" placeholder="Enter Your Email ID" className="form-control"/>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        {emailError !== null && <p className="text-center my-2" style={{color:"#FF8282"}}>{emailError}</p>}
                        {/*<Link to={"/"}>*/}
                            <button type="submit" className="btn btn-md mb-2 px-5 text-capitalize text-white"
                                        style={{backgroundColor:"#ff8282",fontSize:"20px",paddingBottom:"11px",paddingTop:"11px",fontWeight:"600",borderRadius:"10px"}}>
                            Reset &nbsp;Password
                        </button>

                    </div>
                    </form>
                </div>}
                {token !== null &&
                <div className="col-md-6 mx-auto" style={{position:"relative",zIndex:"2"}}>
                    <div className="px-md-2 px-2 mx-md-4  mx-auto mt-4 ">
                        <p className=" text-center my-0" style={{fontWeight:"400",fontSize:"20px",color:"#FF8282"}}>Reset password link sent to email successfully</p>
                    </div>
                    <form onSubmit={savePassword}>
                        <p className="text-center my-3" style={{fontWeight:"700",fontSize:"40px"}}>Enter New Password</p>
                        <div className="px-md-5 px-2 mx-md-4  mx-auto my-3">
                            <div className="d-flex justify-content-center my-3">
                                <div className="form-outline text-muted">
                                    <input onChange={(e)=>setPassword(e.target.value)} required={true} name="Password" type="password" style={{width:"300px", height:"50px"}} id="password" placeholder="Enter New Password" className="form-control"/>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4">
                            {passwordError !== null && <p className="text-center my-2" style={{color:"#FF8282"}}>{passwordError}</p>}
                            {/*<Link to={"/"}>*/}
                            <button type="submit" className="btn btn-md mb-2 px-5 text-capitalize text-white"
                                    style={{backgroundColor:"#ff8282",fontSize:"20px",paddingBottom:"11px",paddingTop:"11px",fontWeight:"600",borderRadius:"10px"}}>
                                Save &nbsp;Password
                            </button>

                        </div>
                    </form>
                </div>}
                <img  src={blackCircleImg} style={{position:"absolute",height:"80px",zIndex:"1",top:"65%",left:"62%"}} alt="" className="img-fluid"/>
                <img  src={blackTriangleImg} style={{position:"absolute",zIndex:"1",height:"75px",top:"45%",left:"72%"}} alt="" className="img-fluid"/>
                <img  src={blackCircleImg} style={{position:"absolute",height:"50px",zIndex:"1",top:"78%",left:"72%"}} alt="" className="img-fluid"/>
                <img  src={dotImage} style={{position:"absolute",height:"120px",zIndex:"1",top:"58%",left:"82%"}} alt="" className="img-fluid dotImageSecure"/>

            </div>
        </React.Fragment>
    )
};
export default ForgotPassword;
