
// LOGIN user
export const LOGIN_USER_REQUEST = 'LOGIN_ADMIN_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_ADMIN_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_ADMIN_FAILURE';

// LOGOUT user
export const LOGOUT_USER = 'LOGOUT_USER';


