import React, { useState, useEffect } from "react";
import propertiesBgImg from "./assets/propertiesBGImg.png";
import propertiesBgTitleImg from "./assets/propertiesTitleImg.png";
import BuildingLogo from "./assets/propertiesBuildingLogo.png";
import locationLogo from "./assets/locationLogo.png";
import TypeLogo from "./assets/TypeLogo.png";
import * as BrickApiService from "../../services/ApiServices";
let PropertiesTitle = ({ setPropertiesList }) => {
    const [city, setCity] = useState("");
    const [status, setStatus] = useState("");
    const [type, setType] = useState('');
    const [SFTValue, setSFTValue] = useState("");
    const [propertyTypesList, setPropertyTypesList] = useState([])
    useEffect(() => {
        BrickApiService.Get_PropertiesTypes().then((response) => {
            console.log(response);
            if (response && response.Status === 1) {
                setPropertyTypesList(response.PropertyTypes);
            }
        });
    }, [])

    useEffect(() => {

        if (SFTValue.length > 0 || type.length > 0 || city.length > 0 || status.length > 0) {
            ToggleHandler()
        }
    }, [city, status, type, SFTValue])
    const filterStatusHandler = (e) => {
        const { value } = e.target;
        setStatus(value);
    };
    const filterCityHandler = (e) => {
        const { value } = e.target;
        setCity(value);
    };
    const filterTypeHandler = (e) => {
        const { value } = e.target;
        setType(value);
    };
    const filterYieldHandler = (e) => {
        const { value } = e.target;
        setSFTValue(value);
    };

    const ToggleHandler = () => {
        let body = {}
        if (status.length > 0) {
            body = { ...body, PropertyStatus: status }
        }
        if (city.length > 0) {
            body = { ...body, City: city }
        }
        if (type.length > 0) {
            body = { ...body, PropertyType: type }
        }
        if (SFTValue.length > 0) {
            body = { ...body, SFTValue: SFTValue }
        }
        BrickApiService.Get_PropertiesFilter(body).then((response) => {
            console.log(response);
            if (response) {
                setPropertiesList(response.Property);
            }

        });
    }
    return (
        <React.Fragment>
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{ minHeight: "200px" }} src={propertiesBgTitleImg} />
                    {/*<p*/}
                    {/*    className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">*/}
                    {/*    Properties</p>*/}
                    <div className="ml-md-5 pl-md-5 card-img-overlay d-flex justify-content-md-start justify-content-center mt-md-5 my-auto">
                        <p
                            className="h1 mb-0 font-weight-bolder text-dark mx-md-5 mx-auto pl-md-5 pt-md-3 mt-md-4 my-auto">
                            Properties</p>
                    </div>
                </div>
            </div>
            <div className="container bg-white shadow text-center mx-auto "
                style={{ borderRadius: "30px", marginTop: "-30px", position: "relative", zIndex: "4" }}>
                <div className="row mx-md-5 mx-2 justify-content-around align-items-center text-center p-2" >
                    <div className="col-md-3 d-flex ">
                        <div className="my-auto mr-4">
                            <img height={20} src={BuildingLogo} alt="BuildingLogo" />
                        </div>
                        <div className="d-block">
                            <div>
                                <small>PROPERTIES</small>
                            </div>
                            <select className="border-0 selectTitle mt-0" name="NEW" id="cars" style={{ fontSize: "13px", color: "rgb(155, 205, 42)" }} onChange={filterStatusHandler}>
                                <option

                                    value="All">
                                    All
                                </option>
                                <option

                                    value="new">
                                    New
                                </option>
                                <option

                                    value="resale">
                                    Resale
                                </option>
                                <option

                                    value="auction">
                                    Auction
                                </option>
                                <option

                                    value="lease">
                                    Lease
                                </option>

                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex ">
                        <div className="my-auto mr-4">
                            <img height={20} src={locationLogo} alt="BuildingLogo" />
                        </div>
                        <div className="d-block">
                            <div>
                                <small>LOCATION</small>
                            </div>
                            <select className="border-0 selectTitle mt-0" name="ALL Cities" id="cars" style={{ fontSize: "13px", color: "rgb(155, 205, 42)" }} onChange={filterCityHandler}>
                                <option value="allcities">
                                    All Cities
                                </option>
                                <option

                                    value="bangalore">
                                    Bangalore
                                </option>
                                <option

                                    value="Chennai">
                                    Chennai
                                </option>
                                <option

                                    value="Delhi">
                                    Delhi
                                </option>
                                <option

                                    value="Goa">
                                    Goa
                                </option>
                                <option

                                    value="hyderabad">
                                    Hyderabad
                                </option>
                                <option

                                    value="Kochi">
                                    Kochi
                                </option>
                                <option

                                    value="Mumbai">
                                    Mumbai
                                </option>
                                <option

                                    value="pune">
                                    Pune
                                </option>

                                <option

                                    value="Visakhapatnam">
                                    Visakhapatnam
                                </option>
                                <option

                                    value="Vijayawada">
                                    Vijayawada
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex ">
                        <div className="my-auto mr-4">
                            <img height={20} src={TypeLogo} alt="BuildingLogo" />
                        </div>
                        <div className="d-block">
                            <div>
                                <small>TYPE</small>
                            </div>
                            <select className="border-0 selectTitle mt-0" name="ALL" style={{ fontSize: "13px", color: "rgb(155, 205, 42)" }} onChange={filterTypeHandler}>
                                <option className="dropdownOptions border-0 bg-transparent" style={{ color: "black" }} value="0">ALL</option>
                                {propertyTypesList !==
                                    undefined &&
                                    propertyTypesList.map(
                                        (item) => {
                                            return (
                                                <option
                                                    className="dropdownOptions bg-transparent"
                                                    style={{
                                                        color: "black",
                                                    }}
                                                    value={
                                                        item?.PropertyTypeID
                                                    }>
                                                    {
                                                        item?.PropertyType
                                                    }
                                                </option>
                                            );
                                        }
                                    )}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-3 d-flex ">
                        <div className="my-auto mr-4">
                            <img height={20} src={BuildingLogo} className="d-none" alt="BuildingLogo " />
                        </div>
                        <div className="d-block">
                            <div>
                                <small>Brick Price</small>
                            </div>
                            <select className="border-0 selectTitle mt-0" name="BUY" id="cars" style={{ fontSize: "13px", color: "rgb(155, 205, 42)" }} onChange={filterYieldHandler}>
                                <option
                                    className="dropdownOptions border-0 bg-transparent"
                                    style={{ color: "black" }}
                                    value="100000">
                                    {" "}
                                    All
                                </option>
                                <option
                                    className="dropdownOptions bg-transparent"
                                    style={{ color: "black" }}
                                    value="5000">
                                    {"<"} 5000
                                </option>
                                <option
                                    className="dropdownOptions bg-transparent"
                                    style={{ color: "black" }}
                                    value="8000">
                                    {"<"} 8000
                                </option>
                                <option
                                    className="dropdownOptions bg-transparent"
                                    style={{ color: "black" }}
                                    value="10000">
                                    {"<"} 10000
                                </option>
                                <option
                                    className="dropdownOptions bg-transparent"
                                    style={{ color: "black" }}
                                    value="12000">
                                    {"<"} 12000
                                </option>
                                <option
                                    className="dropdownOptions bg-transparent"
                                    style={{ color: "black" }}
                                    value="15000">
                                    {"<"} 15000
                                </option>

                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default PropertiesTitle;