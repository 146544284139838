import React from "react";
import Navbar from "../../Navbar";
import resourcesBGImg from "../assets/resourcesBGImg.png";
import partnerImgLeft from "../assets/partnerImgLeftNew.png";
import contactUsCircleImg from "../assets/contactUsCircleImg.png";
import contactUsImg from "../../../assets/img/contactUsImage.png";
import styles from "./partner.css";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";

let PartnerWithUs = () => {
    return (
        <React.Fragment>
            <Navbar activeDropdown={"partnerWithUs"} />
            <div className="container-fluid  img-fluid text-center m-0 p-0" >
                <div className="card">
                    <img alt="img" className='img-fluid ' style={{ minHeight: "200px", maxHeight: "250px" }} src={resourcesBGImg} />
                    <p style={{ fontWeight: "800", fontSize: "40" }}
                        className="h1 card-img-overlay d-flex justify-content-center align-items-center mb-0 font-weight-bolder text-white mx-auto my-auto">
                        Partner With Us</p>
                </div>
            </div>
            <div className="container-fluid my-4">
                <div className="mx-md-5 mx-0 px-md-4 px-1">
                    <p className="text-center" style={{ fontWeight: "700", fontSize: "35px" }}>Let’s work together..</p>
                    <p className="" style={{ fontWeight: "400", fontSize: "20px" }}>We are always looking to bring investment grade properties and great investors on to the BrickBerry platform. Can your customers benefit from our offering? Let's work together for greater success.
                    </p>
                </div>
            </div>
            <div className="container-fluid px-0 mb-5">
                <div className="row mx-0 px-0">
                    <div className="col-md-6 ml-0 pl-0">
                        <img src={partnerImgLeft} alt="" className="img-fluid" />
                    </div>
                    <div className="col-md-6 p-md-5 p-2">
                        <div className="mt-0">
                            <div className="text-center bg-white d-flex justify-content-center" >
                                <p className="text-center bg-white py-2 px-4"
                                    style={{
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "20px", position: "relative", zIndex: "5",
                                        color: "#FF8282", fontWeight: "700", fontSize: "20px", width: "300px"
                                    }}>Real Estate Professionals</p>
                            </div>
                            <div className="py-2 px-4 " style={{ backgroundColor: "#ffba98", borderRadius: "20px", marginTop: "-30px", position: "relative", zIndex: "1" }}>
                                <p className="mt-3 mb-0">
                                    Got expertise spotting awesome investment grade properties? Partner with us. You bring great properties & BrickBerry brings awesome customers and we'll help you benefit from your expertise.
                                </p>
                            </div>
                        </div>
                        <div className="mt-4" >
                            <div className="text-center bg-white d-flex justify-content-center" >
                                <p className="text-center bg-white py-2 px-4"
                                    style={{
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "20px", position: "relative", zIndex: "5",
                                        color: "#FF8282", fontWeight: "700", fontSize: "20px", width: "300px"
                                    }}>Wealth Managers</p>
                            </div>
                            <div className="py-2 px-4 " style={{ backgroundColor: "#ffe2db", borderRadius: "20px", marginTop: "-30px", position: "relative", zIndex: "1" }}>
                                <p className="mt-3 mb-0">
                                    Want to expand your customers' portfolio? Investments in fractional ownership could be a great addition to anybody's portfolio. Let's talk more on how you can help your customers achieve greater returns while balancing their portfolio.
                                </p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="text-center bg-white d-flex justify-content-center" >
                                <p className="text-center bg-white py-2 px-4"
                                    style={{
                                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius: "20px", position: "relative", zIndex: "5",
                                        color: "#FF8282", fontWeight: "700", fontSize: "20px", width: "300px"
                                    }}>Ambassador</p>
                            </div>
                            <div className="py-2 px-4 "
                                style={{ backgroundColor: "#b4cce9", borderRadius: "20px", marginTop: "-30px", position: "relative", zIndex: "1" }}>
                                <p className="mt-3 mb-0">
                                    Love our offering? Excited by the possibilities with fractional ownership? Spread the word among your friends and get rewarded for doing so. Great opportunity for NRIs to work
                                    with us and make it a win-win for your friends and for yourself.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container my-5 pb-4">
                <div className="row px-4 pt-4 pb-3 " style={{ border: "1px solid #ffe2db", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}>
                    <div className="col-md-6 h-100 d-flex flex-column ">
                        <div className="d-flex mt-4 flex-wrap justify-content-center">
                            <p className="text-center mb-1" style={{ fontWeight: "700", fontSize: "35px" }}>Contact US</p>
                            <img className="text-center my-4" height={250}
                                style={{ width: "90%", margin: "auto", position: "relative", top: "-10%", zIndex: "1" }}
                                src={contactUsCircleImg} alt="" />
                            <img className="text-center" height={220}
                                style={{ width: "auto", height: "90%", margin: "auto", position: "absolute", zIndex: "2", top: "40%" }}
                                src={contactUsImg} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6 px-md-5 px-2  pt-2 pt-md-2 pt-5 mt-md-0 mt-4">
                        <form className="mx-md-4 mx-1">
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example1">Are You a Brickberry Investor</label>
                                <div className="d-block selectBox">
                                    <select className="w-100 py-2 partnerContactSelect" placeholder=" " style={{ border: "2px solid rgb(155, 205, 42)" }} name="" >
                                        <option value="" className="disabled"></option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-outline mb-1" >
                                <label className="form-label mx-2" htmlFor="form5Example2">Email Address</label>
                                <input type="email" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1"  >
                                <label className="form-label mx-2" htmlFor="form5Example2">Subject Line</label>
                                <input type="text" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example1">Select A Category</label>
                                <div className="d-block ">
                                    <select className="w-100 py-2 partnerContactSelect" placeholder=" " style={{ border: "2px solid rgb(155, 205, 42)" }} name="" >
                                        <option value="" className="disabled"></option>
                                        <option value="Option1">Option1</option>
                                        <option value="Option2">Option1</option>
                                        <option value="Option3">Option3</option>
                                        <option value="Option4">Option4</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-outline mb-1"  >
                                <label className="form-label " htmlFor="form5Example2">Describe Your enquiry in as much details as Possible</label>
                                <textarea className="form-control rounded-0 mt-1" style={{ border: "2px solid rgb(155, 205, 42)" }} id="exampleFormControlTextarea1"
                                    rows="4" />
                            </div>
                            <input type="file" className="partnerFileIInput" style={{ color: 'green' }} />
                            <button type="submit" className="btn px-5 py-2 text-capitalize mr-0 float-md-right float-none text-white  mb-0"
                                style={{ background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)", borderRadius: "10px", fontWeight: "700", fontSize: "15px" }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <Buildings />
            <Footer />
        </React.Fragment>
    )
};
export default PartnerWithUs;
