import React from "react";
import NRIImg from "../../../assets/img/NRIInvestmentImg.png"

let WhyInvestNRI = () => {
    return (
        <React.Fragment>
            <div className="container-fluid p-0 my-5 mx-0" style={{position: "relative"}}>
                <img className="img-fluid" src={NRIImg} alt=""/>
                <p className="h3 text-white ml-md-5 mx-auto"
                   style={{position: "absolute", top: "10%", fontWeight: "700", fontSize: "40px"}}>Why should NRI invest
                    in real estate in India?</p>
            </div>
            <div className="container-fluid " style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <p>
                        It is common to ponder the pros and cons of any action before deciding whether it will be
                        beneficial
                        to go through with it. Naturally, NRIs who consider investing in Indian Real Estate also have to
                        know the answer to what good could come from investing in Indian Real Estate.</p>
                    <p className="my-3"> Here are some of the reasons to help one understand the benefits of investing
                        in India.</p>
                    <div className="mx-md-5 mx-1">
                        <p ><span  style={{fontWeight:"600"}} > • Retirement Plan:</span> A convenient option for savings for the sake of life after retirement is
                            CRE
                            investments. Many NRIs plan to return to India once they retire from their jobs abroad. The
                            commercial real estate grants NRIs returns as a source of income, which can be used for life
                            after retirement. Hence, investing in real estate property guarantees a secure future.</p>

                        <p> <span  style={{fontWeight:"600"}} >• Return:</span> When we talk about returns from investments, nothing comes close to a real estate
                            investment’s yields. The average return rate from a real estate investment in India can go
                            up to
                            10% within 10 years of investing. This is beneficial for investors as they can ensure
                            maximum
                            returns without having to bear high risks.</p>

                        <p><span  style={{fontWeight:"600"}} > • Financial Asset:</span> A real estate property has proved to be a legitimate gold-mine of asset
                            creation and capital enhancement for an investor. The property also provides an added
                            benefit of
                            capital appreciation, which pushes the total yield towards a higher value. Buying or
                            investing
                            in a property is an age-old means of accumulating assets for future utilization.</p>

                        <p><span  style={{fontWeight:"600"}} >• Rental Income:</span> This comes as an advantage specifically for NRIs as most of them invest in a
                            real estate property and give it on a lease for rent. Thus, not only does the property in
                            itself
                            become a part of the capital, but the money paid by the tenant also adds as a source of
                            steady
                            cashflow. In the case of CRE properties, these leases can go for long periods thus, ensuring
                            a
                            long-term income.</p>

                        <p><span  style={{fontWeight:"600"}} > • Short-Term Capital Gains: </span>Such gains come when the property owner sells his/her property
                            within a short interval of purchase, i.e., within a year or two. This gain is taxable and
                            can be
                            estimated by subtracting the acquisition cost from the sale proceeds. Such capital gains are
                            preferred by those investors who wish to produce income within a short time.</p>

                        <p><span  style={{fontWeight:"600"}} >• Long-Term Capital Gains:</span> As the name suggests, these capital incomes are generated when a
                            real
                            estate property sale occurs after two or more years of its purchase. Normally long-term
                            gains
                            are charged at a 20% rate. These gains also come with certain tax exemptions making them
                            more
                            popular than short-term gains.</p>

                        <p>• Multiple Tax Benefits: This is another attractive feature of real-estate investment in
                            India.
                            These investments garner various tax exemptions under different sections of the Income Tax
                            Act,
                            which can be utilized by the investor to save on tax-related expenses.</p>

                        <p> <span  style={{fontWeight:"600"}} >• Long-Term Income:</span> Living abroad can get pretty expensive, and having a strong financial
                            reserve makes this living 10 times better. This is why NRIs trust real-estate investments as
                            a
                            strong potential income option due to the high returns rates and long-term cash flow
                            guarantee.
                            The CRE sector is the most profitable among real-estate due to high priced properties and
                            long-term fixed lease agreements, which provide the investor with a hefty yield
                            regularly.</p>

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default WhyInvestNRI;
