import React, {useEffect} from "react";
import Navbar from "../../Navbar";
import NewsArticleHome from "../newsArticle/NewsArticleHome";
import PropertyAnnouncement from "../newsArticle/PropertyAnnouncement";
import ArticlesCard from "../newsArticle/ArticlesCards";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import CareerJobs from "./careerPages/CareerJobs";

let Careers=()=>{
    useEffect(()=>{
        window.scrollTo(0,0);
    },[]);
    return(
        <React.Fragment>
            <Navbar activeDropdown={"careers"}/>
            <CareerJobs/>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default Careers;