import React from "react";

let AccountToOpen = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{ fontWeight: "400", fontSize: "17px" }}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{ fontWeight: "700", fontSize: "40px" }}>Accounts that can be opened by NRIs for Real
                        estate investments
                    </h2>
                    <div className="my-4">
                        <p className="mb-0">Accounts that can be opened by NRIs for Real estate investments</p>
                        <p>The NRIs are required to open separate types of bank accounts to invest in real estate. The
                            summary of the salient features of the different NRI related bank accounts is as
                            follows:</p>
                        <div className="mt-4 mb-5" style={{ overflowX: "auto" }}>
                            <table className="table table-borderless"
                                style={{ borderCollapse: "separate", borderSpacing: "0 18px" }}>
                                <thead style={{ backgroundColor: "rgb(238, 255, 199)" }}>
                                    <tr>
                                        <th scope="col" style={{ minWidth: "240px" }}>Feature</th>
                                        <th scope="col"> NRE Account</th>
                                        <th scope="col">NRO Account</th>
                                        <th scope="col"> FCNR Account</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "200px" }}>INR</th>
                                        <td>INR</td>
                                        <td>The account can be used by NRI to deposit their earnings made in India.</td>
                                        <td>Foreign currency</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>Function of the account</th>
                                        <td>The account can be used by the NRI to deposit their earnings abroad in the form
                                            of Indian currency.
                                        </td>
                                        <td>The account can be used by NRI to deposit their earnings made in India.</td>
                                        <td>@This account allows the NRI to make deposits in its original currency and
                                            utilize them for investment in India.
                                        </td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>Tax</th>
                                        <td>The returns from the investments are eligible for tax exemptions. That includes
                                            both principle as well as interest amount.
                                        </td>
                                        <td>No tax exemptions on the return balance</td>
                                        <td>Only the interests earned are eligible for tax exemptions.</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>Account type</th>
                                        <td>Savings, Fixed deposits or current</td>
                                        <td>Current, Savings and Fixed Deposits account</td>
                                        <td>Fixed Deposit accounts</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>Facility for Joint account</th>
                                        <td>Joint account can be opened in association with another NRI</td>
                                        <td>This type allows opening of joint account by NRI and Indian citizen</td>
                                        <td>Joint account can be opened in association with another NRI</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>Balance Repatriation</th>
                                        <td>Full repatriation</td>
                                        <td>Only interest rates can be repatriated completely.</td>
                                        <td>Full repatriation</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>FD period</th>
                                        <td>As per the bank</td>
                                        <td>As per the bank</td>
                                        <td>Maturity period of 1-5 years</td>
                                    </tr>
                                    <tr style={{ backgroundColor: "rgba(237, 237, 237, 0.4)" }}>
                                        <th scope="row" style={{ minWidth: "240px" }}>Exchange rate</th>
                                        <td>There is an effect of the present exchange rate on this type of account</td>
                                        <td>There is no effect of exchange rate on this account</td>
                                        <td>There is influence of exchange rate.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AccountToOpen;
