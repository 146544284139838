import React from "react";
import { Bar } from "react-chartjs-2";

let ViewPropertySecondMonthlyDistribution = ({ propertyDeatils }) => {
  const propertyListing = propertyDeatils || [];
  const BarData = {
    labels: [
      "Dec'19",
      "Jan'20",
      "Feb'20",
      "Mar'20",
      "Apr'20",
      "May'20",
      "Jun'20",
      "Jul'20",
      "Aug'20",
      "Sep'20",
      "Oct'20",
      "Nov'20",
    ],
    datasets: [
      {
        label: "Earnings",
        data: [2, 5, 8, 8, 8, 8, 13, 12, 13, 12, 12, 15],
        fill: true,
        backgroundColor: "#ff8282",
        borderColor: "white",
      },
    ],
  };
  return (
    <React.Fragment>
      <div className="container" id="monthlyDistributions">
        <p
          className="text-center"
          style={{ fontSize: "35px", fontWeight: "700" }}
        >
          Monthly Distributions
        </p>
        <div className="row ">
          <div className="col-md-4">
            <div className="border text-center p-md-4 p-2">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                ESTIMATED NET RENTAL YIELD
              </p>
              <p
                className="mb-0 mt-4"
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#ff8282",
                }}
              >
                {propertyListing.Yield}%
              </p>
              <p
                className="mb-0 mt-1 "
                style={{ fontWeight: "600", fontSize: "15px" }}
              >
                Annualised
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border text-center p-md-4 p-2">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                RENT PER MONTH
              </p>
              <p
                className="mb-0 mt-4"
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#ff8282",
                }}
              >
                ₹{propertyListing.RentPerMonth}
              </p>
              <p
                className="mb-0 mt-1 "
                style={{ fontWeight: "600", fontSize: "15px" }}
              >
                Currently tenanted
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="border text-center p-md-4 p-2">
              <p className="mb-0" style={{ fontWeight: "600" }}>
                ESTIMATED NET INCOME
              </p>
              <p
                className="mb-0 mt-4"
                style={{
                  fontSize: "30px",
                  fontWeight: "800",
                  color: "#ff8282",
                }}
              >
                38 lakhs
              </p>
              <p
                className="mb-0 mt-1 "
                style={{ fontWeight: "600", fontSize: "15px" }}
              >
                Per year
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="row ">
            <div className="col-md-4 my-md-5 my-4">
              <div
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                className="h-100"
              >
                <p
                  className="mb-0 text-center py-2"
                  style={{
                    backgroundColor: "#FFE2DB",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Cashflow : Annual Forecast
                </p>
                <div className="my-4">
                  <div
                    className="d-flex align-items-center my-1 px-5 py-2"
                    style={{ backgroundColor: "#EDEDED" }}
                  >
                    <p className="mb-0 text-left">Gross Rental Income</p>
                    <p className="mb-0 ml-auto text-muted">₹33,280,00</p>
                  </div>
                  <div
                    className="d-flex align-items-center my-1 px-5 py-2"
                    style={{ backgroundColor: "#EDEDED" }}
                  >
                    <p className="mb-0 text-left">Property Expences</p>
                    <p className="mb-0 ml-auto text-muted">₹12,000,00</p>
                  </div>
                  <div
                    className="d-flex align-items-center my-1 px-5 py-2"
                    style={{ backgroundColor: "#EDEDED" }}
                  >
                    <p className="mb-0 text-left">Interest Payments</p>
                    <p className="mb-0 ml-auto text-muted">₹11,824,00</p>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center my-1 px-5 py-2"
                  style={{ border: "1px solid #FF8282" }}
                >
                  <p className="mb-0 text-left" style={{ color: "#FF8282" }}>
                    Net Rental Income
                  </p>
                  <p className="mb-0 ml-auto text-muted">₹9,455,20</p>
                </div>
              </div>
            </div>
            <div className="col-md-8 my-md-5 my-4">
              <div
                className=""
                style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
              >
                <p
                  className="mb-0  text-center py-2"
                  style={{
                    backgroundColor: "#FFE2DB",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Histrorical Brickberry Monthly Distributions
                </p>
                <div className="mt-2 py-3 pb-5 mx-4">
                  <Bar
                    data={BarData}
                    options={{
                      title: {
                        display: false,
                      },
                      maintainAspectRatio: false,
                      legend: {
                        display: false,
                      },
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                            display: false,
                            barPercentage: 0.7,
                          },
                        ],
                        yAxes: [
                          {
                            gridLines: {
                              display: true,
                            },
                            ticks: {
                              beginAtZero: true,
                              max: 15,
                              min: 0,
                              stepSize: 5,
                              callback: function (value, index, values) {
                                return value + "K";
                              },
                            },
                          },
                        ],
                      },
                    }}
                    height={250}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ViewPropertySecondMonthlyDistribution;
