import React from "react";
import trustLogo from "../../../assets/img/trustLogo.png";
import technologyLogo from "../../../assets/img/technologyLogo.png";
import excellenceLogo from "../../../assets/img/excellenceLogo.png";
import ownershipLogo from "../../../assets/img/ownershipLogo.png";
import innovationLogo from "../../../assets/img/innovationLogo.png";
let BottomLogo=()=>{
    return(
        <React.Fragment>
            <div className="d-flex mt-5 mb-3 py-5 container justify-content-around flex-wrap">
                <div className="text-center m-2">
                    <img src={trustLogo} style={{height:"70px",width:"70px"}} alt=""/>
                    <p style={{fontWeight:500,fontSize:"24px"}}>Trust and Integrity</p>
                </div>
                <div className="text-center m-2">
                    <img src={technologyLogo} style={{height:"70px",width:"70px"}} alt=""/>
                    <p style={{fontWeight:500,fontSize:"24px"}}>Technology</p>
                </div>
                <div className="text-center m-2">
                    <img src={excellenceLogo} style={{height:"70px",width:"70px"}} alt=""/>
                    <p style={{fontWeight:500,fontSize:"24px"}}>Excellence</p>
                </div>
                <div className="text-center m-2">
                    <img src={ownershipLogo} style={{height:"70px",width:"70px"}} alt=""/>
                    <p style={{fontWeight:500,fontSize:"24px"}}>Ownership</p>
                </div>
                <div className="text-center m-2">
                    <img src={innovationLogo} style={{height:"70px",width:"70px"}} alt=""/>
                    <p style={{fontWeight:500,fontSize:"24px"}}>Innovation</p>
                </div>
            </div>
        </React.Fragment>
    )
};
export default BottomLogo;
