import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar";
import { Link } from "react-router-dom";
import moment from "moment";
import parse from 'html-react-parser';

let ArticlesCard = (props) => {
    let [blogs, setBlogs] = useState([]);

    useEffect(() => {
        setBlogs(props?.blogs);
    });
    return (
        <React.Fragment>
            <div className="container-fluid px-md-5 my-5" >
                <div className="row px-md-5 px-1  justify-content-start" style={{ marginTop: "50px" }}>
                    {blogs.length > 0 &&
                        <React.Fragment>
                            {blogs?.map((blog) => {
                                return (
                                    <div className="col-md-4 my-2 p-0   " key={blog?.BlogID}>
                                        <div className="mr-md-5 mx-2 mb-md-4 mb-2">
                                            <div className="border">
                                                <div className="">
                                                    <img className="img-fluid " style={{ height: "250px", width: "fit-content" }} src={blog?.PreviewImageURL} alt="" />
                                                </div>
                                                <div className="p-2 d-flex flex-column justify-content-center">
                                                    <div>
                                                        <p className="h4 px-md-3 px-1 my-3" style={{ color: "rgb(22, 142, 186)", fontWeight: "700", fontSize: "20px" }}>{blog?.Title}</p>
                                                        <p className="h2 px-md-3 px-1 mb-3" style={{ fontWeight: "400", fontSize: "23px" }}>{blog?.Category}</p>
                                                        <div className="px-md-3 px-1" style={{ height: "170px", overflow: "hidden" }}>

                                                            {parse(blog?.Description.toString(), { trim: true })}

                                                        </div>
                                                        <div className="mt-3 px-md-3 px-1">
                                                            <div className="border-bottom my-1">
                                                                <Link to={{
                                                                    pathname: `/resources/newsAndArticle/${blog?.BlogID}`,
                                                                    state: blog
                                                                }}>
                                                                    <p className="text-left " style={{ color: "#784CFB", cursor: "pointer" }}>Read more...</p></Link>
                                                            </div>
                                                            {/*<div className="w-100 " style={{borderBottom:"1px solid #979aa1"}}></div>*/}
                                                            {/*<small className="text-left  mb-0 mt-1 w-100">By BrickBerry - 4 Nov 2020</small>*/}
                                                            <small className="text-left  mb-0 mt-1 w-100">By {blog?.Author} - {moment(blog?.CreatedAt).format('LL')}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </React.Fragment>
                    }
                </div>
                <div className="text-center mb-4 pb-md-4">
                    <button className="btn text-center text-white text-capitalize"
                        style={{
                            background: "linear-gradient(265.52deg, rgb(22, 142, 186) -2.36%, rgb(54, 157, 152) 63.46%, rgb(155, 205, 42) 113.43%)", borderRadius: "14px", fontWeight: "700", paddingTop: "12px", paddingBottom: "12px",
                            paddingLeft: "35px", paddingRight: "35px"
                        }}>Load More</button>
                </div>
            </div>
        </React.Fragment>
    )
};
export default ArticlesCard;
