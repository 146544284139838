import {combineReducers} from "redux";
import {USER_FEATURE_KEY, userReducer} from "./users/users.reducers";
import {agentReducer} from "../components/agentPortal/agentPortalActionReducer";


let rootReducer = combineReducers({
    [USER_FEATURE_KEY] : userReducer,
    agent:agentReducer
});
export {rootReducer};
