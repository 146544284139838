import React from "react";

let ProsAndCons = () => {
    return (
        <div>
            <div className="container-fluid my-5" style={{fontWeight: "400", fontSize: "17px"}}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{fontWeight: "700", fontSize: "40px"}}>Pros and Cons of Investing in Real Estate by NRI
                    </h2>
                    <div className="mt-4">

                        <p> Now, as we know the details of NRI real estate investments, it is the correct time to
                            summarize what we’ve learned so far and draw the pros and cons
                            of NRI real estate investing.</p>

                        <p style={{fontWeight: "700"}}>Pros of NRI Investing:</p>
                        <div className="mx-md-5 mx-2">
                            <p><span
                                style={{fontWeight: "600"}}>•	Affordable Properties to Buy (Discounted Prices): </span>The
                                real-estate sector in India offers an extensive array of properties with cheaper costs
                                and
                                higher savings or discounts. Here, NRI investors get more than their money’s worth on
                                investments. Currently, India is witnessing one of the largest
                                real estate surges of all time.</p>

                            <p><span style={{fontWeight: "600"}}> •	The Weakening of the Indian Rupee:</span> An
                                obvious advantage of investing in a real estate property in India is that the land and
                                property rates here are
                                way cheaper than that abroad, which makes the procurement and asset generation very
                                beneficial for NRI investors.</p>

                            <p><span style={{fontWeight: "600"}}>•	Discounts for NRIs: </span> NRI investors can
                                relish some discounts offered by various investment companies in India. Additionally,
                                the NRIs can also enjoy
                                many tax benefits, as discussed previously, leading to discounts of about 30% of the
                                total investment earnings.</p>

                            <p><span style={{fontWeight: "600"}}>•	Tax benefits:</span> The NRIs can enjoy a healthy
                                set of tax exemptions along with their investment in India. They can enjoy exemptions on
                                home loans over
                                their interest rate and principal amount. There is also a provision of availing
                                exemption in income tax returns when the NRI has possession of the
                                property for over 2 years.</p>

                            <p><span style={{fontWeight:"600"}}>• Property Returns: </span>An advantage of investing in a real estate property is it can serve as a
                            source of additional income in the form of rent. This is
                            convenient for NRIs because they can utilize their asset in India as a source of additional
                            income even when staying outside the country. Some of
                            the RE properties such as CRE incorporate a longer leasing contract that ensures a long-term
                                return potential.</p>

                            <p><span style={{fontWeight:"600"}}>• Repatriation of Funds Back to a Foreign Country: </span>It is very convenient for NRI investors
                            to retrieve the money made from investments and send
                            them back abroad. This can be done by making investments on a repatriation basis. This
                                removes the hassle of currency exchange.</p>

                            <p><span style={{fontWeight:"600"}}>• Retirement planning: </span>Taking from the real estate concepts popular abroad, the trend of a
                            reverse mortgage is picking up pace in India. It involves
                            reaping capital from the bank in return for mortgaging the property. This can be used by
                            those NRI investors that plan to accumulate some savings
                                for their life after retiring.</p>
                        </div>
                        <p style={{fontWeight:"700"}}>Cons of NRI investing:</p>
                        <div className="mx-md-5 mx-2">
                            <p><span style={{fontWeight:"600"}}>• Currency Risk in India: </span>There is a likelihood of currency discrepancy when NRIs invest in
                        India. This is due to the changing nature of currency and its
                        rate compared to the Indian national rupee. Thus, the investor may be exposed to risk during
                                money-exchange procedures.</p>

                            <p><span style={{fontWeight:"600"}}>• Difficulty in Investing (Variety of Mutual Funds): </span>The wide variety of mutual fund options to
                        choose from for investment may be better for some, but
                        they can also be overwhelming for NRI investors. They might be confused about where to invest,
                        which can be a cause of delay in the investment
                                procedure.</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
};
export default ProsAndCons;
