import {AGENT_DATA} from "./agentPortalConstants";

const agentDataInitialState = {
  agentPropertyData: {
    PropertyTitle: "",
    Overview: "",
    PropertyType: "",
    PropertyStatus: "",
    SFTValue: "",
    Price: "",
    Address: "",
    City: "",
    State: "",
    Pincode: "",
    LocationImages: [],
    Amenities: [],
    PropertyImages: [],
    PropertyVideos: [],
    FloorPlanImages: [],
    KeyDocuments: "",
    TenantName: "",
    Country: "",
    Description: "",
    LeaseStartDate: "", // YYYY-MM-DD, eg: 2021-01-25
    LeaseEndDate: "", // YYYY-MM-DD, eg: 2021-01-25
    LockInPeriod: "",
    HistoricalSuburbGrowth: "",
    RentPerMonth: "",
    LeasePeriod: "",
    Escalation: "",
    OwnershipModelID: "",
    Category: "",
    MinimumInvestment: "",
    MinimumBricks: "",
    Yield: "",
    ReturnTarget: "",
    FloorNo: "",
    Bedrooms: "",
    CarParking: "",
  },
};

let agentReducer = (state = agentDataInitialState, actions) => {
  switch (actions.type) {
    case AGENT_DATA:
        return {
          ...state,
          agentPropertyData: actions.agentData,
        };
    default:
      return state;
  }
};

export { agentReducer };
