import React from "react";
import SideNav from "../SideNav";
import SideNavMobile from "../SideNavMobile";
import NavbarDashboard from "../NavbardDashboard";
import styles from "../dashboard.css";
import TaxCenterContent from "./TaxCenterContent";

let TaxCenter=()=>{
    return(
        <React.Fragment>
            <NavbarDashboard active="dashboard" />
            <div style={{position:"relative"}}>
                <SideNavMobile active="taxCenter"/>
                <div className="container-fluid" style={{backgroundColor:"#fff7f7"}}>
                    <div className="row">
                        <div className="col-md-3 p-0 m-0">
                            <SideNav active="taxCenter"/>
                        </div>
                        <div className="col-md-9 " >
                            <div className="m-md-5 m-2">
                                <TaxCenterContent/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default TaxCenter;