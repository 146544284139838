import React from "react";
import commercialImg from "../../../assets/img/commercialImg.png";
import residentialImg from "../../../assets/img/residentialImg.png";
import agriculturalImg from "../../../assets/img/agriculturalImg.png";
import farmhouseImg from "../../../assets/img/farmhouseImg.png";

let TypesOfProperties = () => {
    const propertyTypes = [
        {
            img: commercialImg,
            title: "Commercial Real Estate",
            details: "Commercial Real Estate comprises properties that are bought for the sole purpose of professional work. The CRE sector consists of four pillars the industrial, retail, office, and multifamily. This sector is one of the fastest-growing sectors when it comes to investment pooling. Nowadays, as commercialization and urbanization have hit most parts of the country, especially metropolitan cities, the investment potential of CRE has increased immensely. NRIs also prefer this real estate property for investment where the individuals mostly looking to expand their business ventures."
        },
        {
            img: residentialImg,
            title: "Residential Real Estate",
            details:
                "The next category of real-estate is the residential type, where the properties are bought for residential purposes. Such type of properties is popular among NRI investors who are looking for capital enhancement and asset generation that can be utilized once they return to India from their job abroad. These properties are also raised on rent, where the owner earns a steady income through deposits made by the tenant. A large-scale NRI investor does not prefer such real estate investments because the profit margin is lower, and maintenance cost is high compare."
        },
        {
            img: agriculturalImg,
            title: "Agricultural Land",
            details:
                "As per the rules of the Foreign Exchange Management Act, 1999, NRIs are exempted from purchasing agricultural lands in India. If an NRI wishes to procure agricultural land in India, they have to follow a separate request procedure with the RBI, where there is still uncertainty of successful purchase."
        },
        {
            img: farmhouseImg,
            title: "Farmhouse or Plantation Property",
            details: "As is the case with agricultural lands, NRIs are ineligible to procure Farmhouse or Plantation property. However, certain exceptions are up for consideration, such as when the individual receives possession of the property as an inheritance, in which case it can be requested as property investment."
        },

    ];
    return (
        <React.Fragment>

            <div className="container-fluid my-5" style={{ fontWeight: "400", fontSize: "17px" }}>
                <div className="mx-md-5 mx-2 px-md-3 px-0">
                    <h2 style={{ fontWeight: "700", fontSize: "40px" }}>Types of properties where NRIs can invest</h2>
                    <p className="my-4">
                        The common thought in every NRI’s mind is ‘where to invest?’ The types of properties available
                        to invest in India, along with the best locations for the different types of properties, and the
                        status of the CRE market in different regions in India are as follows:
                        Land and property distribution in India can be grouped into four categories:
                    </p>

                    <div>
                        {propertyTypes.map((property) => {
                            return (
                                <div className="row p-0 mx-0 my-3" key={property.title}>
                                    <div className="col-md-2 px-0">
                                        <div className="h-100 my-auto">
                                            <img alt="" className="img-fluid" src={property.img} />
                                        </div>
                                    </div>
                                    <div className="col-md-10 mx-0 px-3 py-1 border-left-0"
                                        style={{ border: "1px solid rgb(155, 205, 42)" }}>
                                        <div className="h-100 my-auto">
                                            <p style={{ fontWeight: 600 }}>{property.title} :</p>
                                            <p className='mt-2 mb-0'>{property.details}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default TypesOfProperties;
