import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSelector } from "react-redux";

let RiskProfilingContent = () => {
  let [riskProfilingObject, setRiskProfilingObject] = useState({
    UserID: "hghgg#433",
    Name: "",
    IncomeDetails: "",
    ExistingInvestments: "",
    ExistingLoans: "",
    MainObjective_of_investing: "",
    whatDescribesAsInvestor: "",
    InvestmentRisk_Return: "",
    InvestmentHorizon: "",
    RiskAppetite: "",
    InvestThrough_Brickberry: "",
  });
  let [message, setMessage] = useState(null);
  //   get user ID

  const user_id = useSelector((state) => state.user.UserID) || null;
  useEffect(() => {
    console.log("localstorage", localStorage.getItem("user"));
    setRiskProfilingObject({
      ...riskProfilingObject,
      UserID: user_id,
    });
  }, [user_id]);
  let handleInput = (e) => {
    setRiskProfilingObject({
      ...riskProfilingObject,
      [e.target.name]: e.target.value,
    });
  };
  let submitForm = async (e) => {
    e.preventDefault();
    if (riskProfilingObject.UserID === null) {
      return null
    }
    console.log(riskProfilingObject);
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let dataURL = `${process.env.REACT_APP_API_URL}/riskprofiling`;
    await Axios.post(dataURL, JSON.stringify(riskProfilingObject), config)
      .then((response) => {
        if (response.data.Status === "1") {
          setMessage(response.data.Message);
          setInterval(() => {
            setMessage(null);
          }, 4000);
        } else if (response.data.Status === "0") {
          setMessage(response.data.Message);
          setInterval(() => {
            setMessage(null);
          }, 4000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="container-fluid my-5 ">
        <div
          className="border mt-0 mb-3 p-md-5 p-2"
          style={{
            borderRadius: "10px",
            backgroundColor: "white",
            minHeight: "200px",
          }}
        >
          <p style={{ color: "rgb(22, 142, 186)", fontWeight: "400", fontSize: "18px" }}>
            {" "}
            Risk Profiling
          </p>

          <p className="my-2" style={{ fontSize: "16px" }}>
            In order for us to guide you through the onboarding and investment
            process,we require you to complete a short risk profiling
            questionnaire, which is mandated by SEBI. We have designed the
            questionnaire to enable us to identify which investment product
            suits your objectives, needs, and risk tolerance.
          </p>
          <form onSubmit={submitForm}>
            <div>
              <div className="d-flex flex-wrap align-items-center my-2 pl-3">
                <label className="">Name Of Investor</label>
                <input
                  type="text"
                  style={{
                    height: "25px",
                    width: "40%",
                    border: "1px solid rgb(22, 142, 186)",
                  }}
                  name="Name"
                  onChange={handleInput}
                  required={true}
                  className="bg-transparent ml-md-4 mx-auto form-control py-1"
                />
              </div>
            </div>

            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  Income Details
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="1Rs. 10 Lakh"
                    required={true}
                    name="IncomeDetails"
                    onChange={handleInput}
                    value="1Rs. 10 Lakh"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="1Rs. 10 Lakh"
                  >
                    &#60; Rs. 10 Lakh
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 10-15 lakhs"
                    required={true}
                    name="IncomeDetails"
                    onChange={handleInput}
                    value="Rs. 10-15 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="Rs. 10-15 lakhs"
                  >
                    Rs. 10-15 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 15-20 lakhs"
                    required={true}
                    name="IncomeDetails"
                    onChange={handleInput}
                    value="Rs. 15-20 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="Rs. 15-20 lakhs"
                  >
                    Rs. 15-20 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 20-25 lakhs"
                    required={true}
                    name="IncomeDetails"
                    onChange={handleInput}
                    value="Rs. 20-25 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="Rs. 20-25 lakhs"
                  >
                    Rs. 20-25 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 25 lakhs"
                    required={true}
                    name="IncomeDetails"
                    onChange={handleInput}
                    value="Rs. 25 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="Rs. 25 lakhs"
                  >
                    &#62;Rs. 25 lakhs
                  </label>
                </div>
              </div>
            </div>
            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  Existing Investments / assets
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 25 Lakh"
                    required={true}
                    name="ExistingInvestments"
                    onChange={handleInput}
                    value="Rs. 25 Lakh"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="Rs. 25 Lakh"
                  >
                    &#60; Rs. 25 Lakh
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 20-50 lakhs"
                    required={true}
                    name="ExistingInvestments"
                    onChange={handleInput}
                    value="Rs. 20-50 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="Rs. 20-50 lakhs"
                  >
                    Rs. 20-50 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 50-75 lakhs"
                    required={true}
                    name="ExistingInvestments"
                    onChange={handleInput}
                    value="Rs. 50-75 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="Rs. 50-75 lakhs"
                  >
                    Rs. 50-75 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 75-100 lakhs"
                    required={true}
                    name="ExistingInvestments"
                    onChange={handleInput}
                    value="Rs. 75-100 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="Rs. 75-100 lakhs"
                  >
                    Rs. 75-100 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="Rs. 1 crore"
                    required={true}
                    name="ExistingInvestments"
                    onChange={handleInput}
                    value="Rs. 1 crore"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="Rs. 1 crore"
                  >
                    &#62;Rs. 1 crore
                  </label>
                </div>
              </div>
            </div>

            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  Existing loans
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="EL-Rs. 25 Lakh"
                    required={true}
                    name="ExistingLoans"
                    onChange={handleInput}
                    value="Rs. 25 Lakh"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="EL-Rs. 25 Lakh"
                  >
                    &#60; Rs. 25 Lakh
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="EL-Rs. 25-50 lakhs"
                    required={true}
                    name="ExistingLoans"
                    onChange={handleInput}
                    value="Rs. 25-50 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="EL-Rs. 25-50 lakhs"
                  >
                    Rs. 25-50 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="EL-Rs. 50-75 lakhs"
                    required={true}
                    name="ExistingLoans"
                    onChange={handleInput}
                    value="Rs. 50-75 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="EL-Rs. 50-75 lakhs"
                  >
                    Rs. 50-75 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="EL-Rs. 75-100 lakhs"
                    required={true}
                    name="ExistingLoans"
                    onChange={handleInput}
                    value="Rs. 75-100 lakhs"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="EL-Rs. 75-100 lakhs"
                  >
                    Rs. 75-100 lakhs
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="EL-Rs. 1 crore"
                    required={true}
                    name="ExistingLoans"
                    onChange={handleInput}
                    value="Rs. 1 crore"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="EL-Rs. 1 crore"
                  >
                    &#62;Rs. 1 crore
                  </label>
                </div>
              </div>
            </div>
            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  What is your main objective of investing through BrickBerry?
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="OIB-1"
                    required={true}
                    name="MainObjective_of_investing"
                    onChange={handleInput}
                    value="Protect my capital with zero risk of lost of capital"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="OIB-1"
                  >
                    Protect my capital with zero risk of lost of capital
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="OIB-2"
                    required={true}
                    name="MainObjective_of_investing"
                    onChange={handleInput}
                    value="Focus on moderate income with low risk of loss of capital"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="OIB-2"
                  >
                    Focus on moderate income with low risk of loss of capital
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="OIB-3"
                    required={true}
                    name="MainObjective_of_investing"
                    onChange={handleInput}
                    value="Focus on moderate income and capital growth"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="OIB-3"
                  >
                    Focus on moderate income and capital growth
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="OIB-4"
                    required={true}
                    name="MainObjective_of_investing"
                    onChange={handleInput}
                    value="Focus on moderate to high income and capital growth"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="OIB-4"
                  >
                    Focus on moderate to high income and capital growth
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="OIB-5"
                    required={true}
                    name="MainObjective_of_investing"
                    onChange={handleInput}
                    value="Focus on high level of income and growth"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="OIB-5"
                  >
                    Focus on high level of income and growth
                  </label>
                </div>
              </div>
            </div>
            {/* Describes you as invester */}
            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  Which of the below best describes you as an invester?
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="whatDescribesAsInvestor1"
                    required={true}
                    name="whatDescribesAsInvestor"
                    onChange={handleInput}
                    value="A-Biginner: This is my first ever investment in any project"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="whatDescribesAsInvestor1"
                  >
                    A. Biginner: This is my first ever investment in any project{" "}
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="whatDescribesAsInvestor2"
                    name="whatDescribesAsInvestor"
                    onChange={handleInput}
                    value="B-Intermediate: I have been investing for 3-5 years"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="whatDescribesAsInvestor2"
                  >
                    B. Intermediate: I have been investing for 3-5 years
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    name="whatDescribesAsInvestor"
                    onChange={handleInput}
                    id="whatDescribesAsInvestor3"
                    value="C-Advanced: I have been investing for 5-10 years"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="whatDescribesAsInvestor3"
                  >
                    C. Advanced: I have been investing for 5-10 years
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="whatDescribesAsInvestor4"
                    name="whatDescribesAsInvestor"
                    onChange={handleInput}
                    value="D-Professional: I have been investing regularly for &#62;10
                    years"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="whatDescribesAsInvestor4"
                  >
                    D. Professional: I have been investing regularly for &#62;10
                    years
                  </label>
                </div>
              </div>
            </div>
            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  Which of the below would match your investment risk and return
                  profile?
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentRisk_Return1"
                    required={true}
                    name="InvestmentRisk_Return"
                    onChange={handleInput}
                    value="A-4-6% Very Low Risk"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentRisk_Return1"
                  >
                    A. 4-6% Very Low Risk{" "}
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentRisk_Return2"
                    required={true}
                    name="InvestmentRisk_Return"
                    onChange={handleInput}
                    value="B-6-8% Low Risk"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentRisk_Return2"
                  >
                    B. 6-8% Low Risk
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentRisk_Return3"
                    required={true}
                    name="InvestmentRisk_Return"
                    onChange={handleInput}
                    value="C-8-12% Moderate Risk"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentRisk_Return3"
                  >
                    C. 8-12% Moderate Risk
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentRisk_Return4"
                    required={true}
                    name="InvestmentRisk_Return"
                    onChange={handleInput}
                    value="D-12-18% High Risk"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentRisk_Return4"
                  >
                    D. 12-18% High Risk
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentRisk_Return5"
                    required={true}
                    name="InvestmentRisk_Return"
                    onChange={handleInput}
                    value="E-18% Very High Risk"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentRisk_Return5"
                  >
                    E. &#62;18% Very High Risk
                  </label>
                </div>
              </div>
            </div>

            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  What is your typical Investment Horizon?
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentHorizon1"
                    required={true}
                    name="InvestmentHorizon"
                    onChange={handleInput}
                    value="A-0-6 months, Short Term"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentHorizon1"
                  >
                    A. 0-6 months, Short Term
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentHorizon2"
                    required={true}
                    name="InvestmentHorizon"
                    onChange={handleInput}
                    value="B-6 months-3 years, Medium Term"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentHorizon2"
                  >
                    B. 6 months-3 years, Medium Term
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentHorizon3"
                    required={true}
                    name="InvestmentHorizon"
                    onChange={handleInput}
                    value="C-3-5 years, Long Term"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentHorizon3"
                  >
                    C. 3-5 years, Long Term
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestmentHorizon4"
                    required={true}
                    name="InvestmentHorizon"
                    onChange={handleInput}
                    value="D-5 years, Very Long Term"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestmentHorizon4"
                  >
                    D. &#62;5 years, Very Long Term
                  </label>
                </div>
              </div>
            </div>
            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  On a scale of 1-10, how would you define your risk
                  appetite?Zero risk investments include Fixed deposits,
                  government bonds while high risk ones would include
                  alternative asset classes (AIF, Venture investments etc).
                </p>
              </div>
              <div style={{ border: "1px solid rgb(22, 142, 186)" }}>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="RiskAppetite1"
                    required={true}
                    name="RiskAppetite"
                    onChange={handleInput}
                    value="A-Zero Risk (1)"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="RiskAppetite1"
                  >
                    A. Zero Risk (1)
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="RiskAppetite2"
                    required={true}
                    name="RiskAppetite"
                    onChange={handleInput}
                    value="B-Low Risk (5-6)"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="RiskAppetite2"
                  >
                    B. Low Risk (5-6)
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="RiskAppetite3"
                    required={true}
                    name="RiskAppetite"
                    onChange={handleInput}
                    value="C-Moderate Risk (7-9)"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="RiskAppetite3"
                  >
                    C .Moderate Risk (7-9)
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="RiskAppetite4"
                    required={true}
                    name="RiskAppetite"
                    onChange={handleInput}
                    value="D-High Risk (10)"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="RiskAppetite4"
                  >
                    D. High Risk (10)
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="RiskAppetite5"
                    required={true}
                    name="RiskAppetite"
                    onChange={handleInput}
                    value="E-We currently don not have any product which matches this
                  requirnment"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "56" }}
                    htmlFor="RiskAppetite5"
                  >
                    E. We currently don not have any product which matches this
                    requirnment
                  </label>
                </div>
              </div>
            </div>
            <div className=" my-3">
              <div
                className=" py-1"
                style={{
                  border: "1px solid rgb(22, 142, 186)",
                  backgroundColor: "#ededed",
                }}
              >
                <p
                  className="mb-0 pl-3"
                  style={{ fontWeight: "700", fontSize: "16" }}
                >
                  How important is liquidity on your investments for you?
                </p>
              </div>
              <div
                className="border-top-0 pl-3"
                style={{ border: "1px solid rgb(22, 142, 186)" }}
              >

                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestThrough_Brickberry1"
                    required={true}
                    name="InvestThrough_Brickberry"
                    onChange={handleInput}
                    value="A-Very important, Highly liquid"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestThrough_Brickberry1"
                  >
                    A. Very important, Highly liquid
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestThrough_Brickberry2"
                    required={true}
                    name="InvestThrough_Brickberry"
                    onChange={handleInput}
                    value="B-Moderately important"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestThrough_Brickberry2"
                  >
                    B. Moderately important
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestThrough_Brickberry3"
                    required={true}
                    name="InvestThrough_Brickberry"
                    onChange={handleInput}
                    value="C-I am long term invester"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestThrough_Brickberry3"
                  >
                    C. I am long term invester
                  </label>
                </div>
                <div className="d-flex align-items-center pl-3">
                  <input
                    type="radio"
                    id="InvestThrough_Brickberry4"
                    required={true}
                    name="InvestThrough_Brickberry"
                    onChange={handleInput}
                    value="D-We currently do not have ant product which matches this
                  requirment"
                  />
                  <label
                    className="mb-0 mx-2"
                    style={{ fontSize: "16" }}
                    htmlFor="InvestThrough_Brickberry4"
                  >
                    D. We currently do not have ant product which matches this
                    requirment
                  </label>
                </div>
              </div>
            </div>
            <div className="d-flex my-3 mx-1">
              <input
                className="mt-2"
                style={{ outline: "1px solid mt-3 rgb(22, 142, 186)" }}
                type="checkbox"
                required={true}
              />
              <p className="text-muted  mx-3 mb-0">
                I understand that I will need to review and sign a client
                agreement with BrickBerry for participating in this investment
                opportunity.
              </p>
            </div>
            {message && (
              <p
                className="text-center mt-2 mb-0"
                style={{ color: "rgb(255, 130, 130)" }}
              >
                {message}
              </p>
            )}
            <div className="text-center mt-4">
              <input
                style={{
                  backgroundColor: "rgb(22, 142, 186)",
                  borderRadius: "10px",
                  width: "100px",
                }}
                value="Submit"
                type="submit"
                className="btn btn-md text-white text-capitalize py-2 px-4 text-center  mx-2"
              />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RiskProfilingContent;
