import React, { useEffect, useState } from "react";
import ViewPropertyTitle from "./components/ViewPropertyTitle";
import ViewPropertyTitleIntro from "./components/ViewPropertyTitleIntro";
import GrowthPlus from "./components/GrowthPlus";
import InvestmentAnalysis from "./components/InvestmentAnalysis";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import ViewPropertyDetails from "./components/ViewPropertyDetails";
import ViewPropertyAmenities from "./components/ViewPropertyAmenities";
import ViewPropertyBoutBuilder from "./components/ViewPropertyAboutBuilder";
import ViewPropertyUpdates from "./components/ViewPropertyUpdates";
import ViewPropertyLocationFloorPlan from "./components/ViewPropertyLocationFloorPlan";
import ViewPropertyCapitalReturn from "./components/ViewPropertyCapitalReturn";
import ViewPropertyRiskAnalysis from "./components/ViewPropertyRiskAnalysis";
import ViewPropertyPricingDetails from "./components/ViewPropertyPricingDetails";
import BgImage from "../assets/viewPropertyBGImg.png";
import residentialPropertyLogo from "../../../assets/img/residentialPropertyLogo.png";
import { getPropertiesListById } from "../../../services/ApiServices";
import ImageSlider from "./ImageSlider";

let ViewProperty = (props) => {
  const [propertyList, setPropertyList] = useState([]);
  const [propertyId, setPropertyId] = useState(null);

  useEffect(() => {
    let idURL = window.location.pathname.split("/");
    const id = idURL[idURL.length - 1];
    setPropertyId(id);
    getPropertiesListById(id).then((response) => {
      if (response && response.Property) {
        setPropertyList(response.Property);
      }
    });

    window.scrollTo(0, 0);
  }, [propertyId]);
  // console.log('property',propertyList);
  return (
    <React.Fragment>
      <Navbar active={"properties"} />

      <ViewPropertyTitle property={propertyList} img={BgImage} />
      <ViewPropertyTitleIntro
        propertyDeatils={propertyList}
        model={"Traditional"}
        color={"#18a0fb"}
        rightBarObject={{
          img: residentialPropertyLogo,
          title: "Residential Property",
        }}
      />
      <GrowthPlus />
      <ViewPropertyDetails propertyDeatils={propertyList} />
      <ViewPropertyAmenities propertyDeatils={propertyList} />
      <ViewPropertyBoutBuilder />
      <ViewPropertyUpdates />
      <ViewPropertyLocationFloorPlan propertyDeatils={propertyList}/>
      <InvestmentAnalysis />
      <ViewPropertyCapitalReturn />
      <ViewPropertyRiskAnalysis propertyDeatils={propertyList} />
      <ViewPropertyPricingDetails />
      <Buildings />
      <Footer />
    </React.Fragment>
  );
};
export default ViewProperty;
