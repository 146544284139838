import React, { useState } from "react";
import sliderBtn1 from "../../../assets/img/sliderBtn.png";
import sliderBtn2 from "../../../assets/img/sliderBtn2.png";
import sliderImg1 from "../../../assets/img/sliderImg1.png";
import RedDot from "../../../assets/img/RedDot.png";
import blueBtn from "../../../assets/img/blueBtn.png";
import pinkBtn from "../../../assets/img/pinkBrick.png";
import LocationImg from "../../../assets/img/loactionIcon.png";
import rupeesCircle from "../../../assets/img/RupeesCircle.png";
import rupeesSymbol from "../../../assets/img/rupeesSymbol.png";
import car from "../../../assets/img/car.png";
import floorNumber from "../../../assets/img/floorNumberImg.png";
import whiteCover from "../../../assets/img/whiteRectangle.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import buildingBgImg from "../../properties/assets/buildingImgProperties.png";
import locationColoured from "../../properties/assets/locationColoured.png";
import brickBlue from "../../properties/assets/brickBlue.png";
import brickGreen from "../../properties/assets/brickGreen.png";
import brickPink from "../../properties/assets/brickPink.png";
import rupeesCircleProperties from "../../properties/assets/rupeesCircleProperties.png";
import rupeesText from "../../properties/assets/rupeesText.png";
import carImg from "../../properties/assets/carImg.png";
import floorNumberImgProperties from "../../properties/assets/floorNumberImgProperties.png";
import brickBlueImg from "../../properties/assets/brickBlue.png";
import redDot from "../../../assets/img/redDotNew.png";
import Link from "react-router-dom/Link";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
    // partialVisibilityGutter: 40
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
let cardsArray = [
  {
    id: 1,
    name: "The Shashank",
    location: "ECR Road Chennai",
    brickImg: brickGreen,
    brickName: "RESALE",
    class: "A",
  },
  {
    id: 2,
    name: "Abhilash",
    location: "KPHB, Hyderabad",
    brickImg: brickBlue,
    brickName: "FULLY FUNDED",
    class: "B",
  },
  {
    id: 3,
    name: "The Palace",
    location: "Thane,Mumbai",
    brickImg: brickPink,
    brickName: "NEW",
    class: "C",
  },
  {
    id: 3,
    name: "The Palace",
    location: "Thane,Mumbai",
    brickImg: brickPink,
    brickName: "NEW",
    class: "A",
  },
  {
    id: 4,
    name: "The Palace",
    location: "Thane,Mumbai",
    brickImg: brickPink,
    brickName: "NEW",
    class: "A",
  },
  {
    id: 5,
    name: "The Palace",
    location: "Thane,Mumbai",
    brickImg: brickPink,
    brickName: "NEW",
    class: "A",
  },
];

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group position-relative">
      <button
        aria-label="Go to next slide"
        onClick={() => next()}
        // style={{top:"-200px !important",right:"-100px"}}
        className={
          currentSlide === 0
            ? "disable react-multiple-carousel__arrow react-multiple-carousel__arrow--right selectListingNextBtn"
            : "react-multiple-carousel__arrow react-multiple-carousel__arrow--right selectListingNextBtn"
        }
        type="button"
      />
      <button
        aria-label="Go to previous slide"
        onClick={() => previous()}
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left selectListingPrevBtn"
        type="button"
      />
    </div>
  );
};
let OurSelectListingNew = ({ properties }) => {
  let [selected, setSelected] = useState(1);
  const ListOfProperties = properties || [];

  return (
    <React.Fragment>
      <div style={{ marginTop: "30px" }}>
        <div className="container-fluid d-block d-flex flex-column justify-content-end">
          <div
            className="col-md-11 ml-auto position-absolute selectListingBackground"
            style={{
              backgroundColor: "rgb(36, 43, 46)",
              minHeight: "60%",
              right: "0",
              borderTopLeftRadius: "100px",
              borderBottomLeftRadius: "100px",
            }}
          ></div>
          <div className="container">
            <p
              className=" mx-auto"
              style={{
                height: "5px",
                width: "50px",
                backgroundColor: "rgb(22, 142, 186)",
              }}
            ></p>
            <h4
              className="h1 text-center mb-0"
              style={{ fontWeight: "700", fontSize: "40px" }}
            >
              Our Select Listings
            </h4>
            {/*<Carousel showDots customDot={<CustomDot />}  responsive={responsive}>*/}
            <div className="my-own-custom-container">
              <Carousel
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
                responsive={responsive}
              >
                {ListOfProperties.map((card) => {
                  return (
                    <React.Fragment key={card.id}>
                      {/*{selected === card.id ?*/}
                      <React.Fragment>
                        <div className=" p-0 my-4 d-flex justify-content-center ">
                          <div
                            className="shadow propertiesCard bg-white"
                            style={{ maxWidth: "320px", borderRadius: "20px", width: "320px" }}
                          >
                            <div className=" position-relative">
                              <img
                                className="img-fluid"
                                src={card.PropertyImages[0]}
                                style={{ maxWidth: "320px", height: "250px", objectFit: "contain" }}
                                alt="img"
                              />
                              <div
                                className="d-flex flex-wrap font-weight-bolder propertiesCardTopBar position-absolute"
                                style={{
                                  fontSize: "12px",
                                  position: "absolute",
                                  lineHeight: "14px",
                                  maxWidth: "320px",
                                  width: "320px",
                                  top: 0,
                                  backgroundColor: "white",
                                  alignItems: "center",
                                  maxHeight: "42px",
                                  justifyContent: "space-around",
                                  borderTopLeftRadius: "20px",
                                  borderTopRightRadius: "20px",
                                  opacity: "0.85",
                                }}
                              >
                                <div style={{ height: "100px", margin: "8px" }}>
                                  <p className="mb-0 " style={{ fontSize: "15px" }} >
                                    {card.PropertyTitle}{" "}

                                    {/* <span style={{ color: "#ff9797" }}>
                                    {card.Address}
                                    </span> */}
                                  </p>
                                  <p className="mb-0 " style={{ margin: "5px 0" }}>
                                    <img
                                      src={locationColoured}
                                      height={12}
                                      className="mr-2"
                                      alt="locationLogo"
                                    />
                                    {card.Address}
                                  </p>
                                </div>
                                <div
                                  className="card brickTextBlock my-auto ml-4 bg-transparent shadow-none p-0 m-0 d-flex justify-content-center align-items-center"
                                  style={{ width: "60px", right: "0" }}
                                >
                                  <div className="d-flex">
                                    {/* <img
                                      src={redDot}
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "-10px",
                                      }}
                                      className=" "
                                      height={5}
                                      alt="img"
                                    /> */}
                                    {/* <img
                                      src={card.brickImg}
                                      style={{}}
                                      className="card-img "
                                      height={50}
                                      alt="img"
                                    /> */}
                                  </div>
                                  <div
                                    className="position-absolute p-0 m-0"
                                    style={{
                                      fontSize: "10px",
                                      fontWeight: "900",
                                      lineHeight: "10px",
                                    }}
                                  >
                                    <small
                                      className="mb-0 d-block font-weight-bold mx-auto text-center"
                                      style={{ width: "40px" }}
                                    >
                                      {card.brickName}
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="d-flex propertiesCardBottomBar justify-content-around px-2 py-1 flex-row position-absolute"
                                style={{
                                  fontSize: "11px",
                                  backgroundColor: "#ededed",
                                  bottom: 0,
                                  width: "webkit-fill-available",
                                }}
                              >
                                <div className="d-flex ">
                                  <div className="d-flex justify-content-center align-items-center">

                                    <img
                                      className="position-absolute"
                                      src={rupeesText}
                                      width={'15'}
                                    />
                                  </div>
                                  <div
                                    className="text-center mx-2"
                                    style={{ lineHeight: "14px" }}
                                  >
                                    <small className="mb-0 d-block font-weight-bolder">
                                      Property Value
                                    </small>
                                    <small className="mb-0 d-block text-muted">
                                      4.5 cr
                                    </small>
                                  </div>
                                  <div className="border-right border-dark my-2"></div>
                                </div>
                                <div className="d-flex ">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <img
                                      className="mx-2"
                                      height={12}
                                      src={carImg}
                                    />
                                  </div>
                                  <div
                                    className="text-center mr-2"
                                    style={{ lineHeight: "14px" }}
                                  >
                                    <small className="mb-0 d-block font-weight-bolder">
                                      Car Parking
                                    </small>
                                    <small className="mb-0 d-block text-muted">
                                      {card.CarParking}
                                    </small>
                                  </div>
                                  <div className="border-right border-dark my-2"></div>
                                </div>
                                <div className="d-flex ">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <img
                                      height={12}
                                      className="mx-2"
                                      src={floorNumberImgProperties}
                                    />
                                  </div>
                                  <div
                                    className="text-center "
                                    style={{ lineHeight: "14px" }}
                                  >
                                    <small className="mb-0 d-block font-weight-bolder">
                                      Floor Number
                                    </small>
                                    <small className="mb-0 d-block text-muted">
                                      {card.FloorNo}
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex my-2 d-md-flex d-flex mx-1 text-center align-items-center justify-content-around">
                              <div
                                className=" col-md-5 mr-auto d-flex flex-column justify-content-center align-items-center"
                                style={{
                                  backgroundColor: "#fff9f8",
                                  minHeight: "65px",
                                  minWidth: "100px",
                                  marginTop: "1rem"
                                }}
                              >
                                <small className="mb-0">Area</small>
                                <h6
                                  className=" font-weight-bold"
                                  style={{
                                    color: "#a1a09f",
                                    fontSize: "smaller",
                                  }}
                                >
                                  {card.PropertyAreaSFT} SFT
                                </h6>
                              </div>
                              <div
                                className="col-md-6 ml-auto d-flex flex-column justify-content-center align-items-center"
                                style={{
                                  backgroundColor: "#fff9f8",
                                  minHeight: "65px",
                                  minWidth: "100px",
                                  marginTop: "1rem"
                                }}
                              >
                                <small className="mb-0 d-block">
                                  Price / Sqft
                                </small>
                                <h6
                                  className="font-weight-bold"
                                  style={{
                                    color: "#a1a09f",
                                    fontSize: "smaller",
                                  }}
                                >
                                  <span>&#8377;</span>{card.SFTValue}
                                </h6>
                              </div>
                            </div>

                            <div className="text-center mt-0">
                              <small style={{ color: "#9bcd2a" }}>
                                Class '{card.BuildingClass}'
                              </small>
                            </div>
                            <Link
                              to={{
                                // pathname: "/properties/one/commercial",
                                pathname: `/properties/${card.PropertyType == 6 ? 'residential' : 'commercial'}/${card.PropertyID}`,
                                param1: card.PropertyID,
                              }}
                            >
                              <button
                                className="btn py-2 mb-0 btn-lg w-100 text-white text-capitalize "
                                style={{
                                  background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)",
                                  borderRadius: "10px", margin: 0
                                }}
                              >
                                View Property
                              </button>
                            </Link>
                          </div>
                        </div>

                      </React.Fragment>
                    </React.Fragment>

                  );
                })}
              </Carousel>
            </div>
            <div className="mx-auto text-center my-5  position-relative">
              {/*<span ><img alt="" id="1"*/}
              {/*            onClick={()=>setSelected(1)} src={selected===1 ? sliderBtn1:sliderBtn2 }*/}
              {/*            className="mx-2" height={20} width={20}/></span>*/}
              {/*<span ><img alt="" onClick={()=>setSelected(2)}  src={selected===2 ? sliderBtn1:sliderBtn2 } className="mx-2" height={20} width={20}/></span>*/}
              {/*<span ><img alt="" onClick={()=>setSelected(3)}  src={selected===3 ? sliderBtn1:sliderBtn2 } className="mx-2" height={20} width={20}/></span>*/}
              {/*<span className="disabled"><img alt=""  onClick={()=>setSelected(4)}  src={selected===4 ? sliderBtn1:sliderBtn2 } className="mx-2" height={20} width={20}/></span>*/}
            </div>
            <div className="position-relative mx-1 mx-md-5 pb-5">
              <h6
                className="h5 ml-2"
                style={{ fontWeight: "700", fontSize: "25px", color: "white" }}
              >
                Our Select Property listings are only characterized into Grade A, Grade B
              </h6>
              <p
                className="mb-1"
                style={{ fontWeight: "400", fontSize: "17px", color: "white" }}
              >
                <span
                  className="badge shadow-none mt-2 mr-2 text-center rounded-circle text-white"
                  style={{ backgroundColor: "rgb(255, 130, 130)" }}
                >
                  1
                </span>
                Class A represents the best buildings in terms of
                aesthetics,age,quality of infrastructure and location
              </p>
              <div
                className="mb-1 d-flex"
                style={{ fontWeight: "400", fontSize: "17px", color: "white" }}
              >
                <div>
                  <p
                    className="badge shadow-none mt-2 mr-2 text-center rounded-circle text-white"
                    style={{ backgroundColor: "rgb(255, 130, 130)" }}
                  >
                    2
                  </p>
                </div>
                <p className="mb-0">
                  Class B buildings are usually older and not as competitive
                  price wisw as Class A buildings. Investors often target these
                  buildings for restoration.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default OurSelectListingNew;
