import React from "react";
import HomepageImg from "../../../assets/img/homepageImg.png";
import HomepagePeople from "../../../assets/img/homepageImg1.png";
import dotGroup from "../../../assets/img/dotGroup.png";
import dotGroupBottom from "../../../assets/img/dotGroupBottom.png";
import { useHistory } from "react-router-dom";
let Homepage = () => {
  const history = useHistory();
  return (
    <React.Fragment>
      <div id="home" className="container-fluid d-flex ">
        <div className="row my-4">
          <div className="col-md-6">
            <div className=" mt-3">
              <p
                className="h1-responsive ml-md-5 mx-auto my-b mt-3 headingTitle"
                style={{
                  fontSize: "52px",
                  fontWeight: "bold",
                  lineHeight: "60px",
                  letterSpacing: "0.04em",
                }}
              >
                Want an easier way to invest in Property ?
              </p>
              <p
                className="h4-responsive mx-5 my-4 py-1 subTitle  font-weight-bold"
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  lineHeight: "30px",
                  letterSpacing: "0.04em",
                }}
              >
                "India's 1st Fractional Property Ownership Platform with AI and
                Blockchain Tech"
              </p>
              <div
                className="h5 text-left mx-5 headingInfo py-1 "
                style={{ fontWeight: 500, letterSpacing: "0.03em" }}
              >
                <p>
                  BrickBerry is an award-winning platform that provides a simple
                  and low cost way to access the property market which allows
                  like-minded investors to own a fraction of the property
                  through a SPV.
                </p>

                <p className="mt-4 pt-1">
                  Each investor shares the ownership in the property based on
                  the Bricks own. .
                </p>
              </div>
              <div className="mx-auto text-center">
                <button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push("/properties");
                  }}
                  className="btn btn-lg px-5 mx-auto py-3 mt-5  mb-3 text-capitalize font-weight-bold"
                  style={{
                    background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)",
                    color: "white",
                    borderRadius: "22px",
                  }}
                >
                  {" "}
                  View Properties
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6 img-fluid text-center ">
            <img
              height={150}
              width={300}
              className="dotImgTop"
              alt="img"
              style={{
                position: "absolute",
                marginLeft: "-40%",
                width: "auto",
              }}
              src={dotGroup}
            />
            <img
              alt=""
              style={{
                position: "absolute",
                zIndex: "1",
                height: "520px",
                width: "95%",
                top: "-4%",
                left: "0%",
              }}
              className="img-fluid mx-auto homePageBuildingImg"
              src={HomepageImg}
            />
            <img
              alt=""
              style={{
                position: "relative",
                zIndex: "2",
                height: "240px",
                right: "10%",
                marginTop: "250px",
              }}
              className=" mx-auto text-center homePagePeoplesImg"
              src={HomepagePeople}
            />
            <img
              alt=""
              height={140}
              className="dotImgBottom"
              style={{
                position: "absolute",
                bottom: "0",
                top: "57%",
                right: "10%",
              }}
              src={dotGroupBottom}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Homepage;
