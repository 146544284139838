import React from "react";
import uploadLogoCareers from "../../assets/uploadLogoCareers.png";
import jobFormImg from "../../assets/jobFormImg.png";
import ReCAPTCHA from "react-google-recaptcha/lib/esm/recaptcha-wrapper";

let JobForm = () => {
    return (
        <React.Fragment>
            <div className="container my-md-5  mt-0 mb-2 pb-4">
                <p className="mb-0 text-center mb-4 mt-5" style={{ fontSize: "18px", fontWeight: "400" }}>We’re looking for people that are a cut above the rest. Drop us your CV and lets talk. </p>
                <div className="row px-4 pt-4 pb-3 align-items-center" style={{ border: "1px solid rgb(155, 205, 42)", boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px" }}>
                    <div className="col-md-5 h-100 d-flex flex-column align-items-center">
                        <div className="d-flex flex-wrap justify-content-center">
                            <img className="text-center img-fluid" style={{ height: "80%", width: "80%" }} src={jobFormImg} alt="" />
                        </div>
                    </div>
                    <div className="col-md-7 px-md-5 px-2 pt-2">
                        <form className="mx-md-4 mx-1">
                            <div className="form-outline mb-1"  >
                                <label className="form-label mx-2" htmlFor="form5Example2">First Name</label>
                                <input type="text" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1"  >
                                <label className="form-label mx-2" htmlFor="form5Example2">Last Name</label>
                                <input type="text" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1" >
                                <label className="form-label mx-2" htmlFor="form5Example2">Email ID</label>
                                <input type="email" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1" >
                                <label className="form-label mx-2" htmlFor="form5Example2">Contact Number</label>
                                <input type="number" style={{ border: "2px solid rgb(155, 205, 42)" }} className="form-control" />
                            </div>
                            <div className="form-outline mb-1">
                                <label className="form-label mx-2" htmlFor="form5Example1">Position applying for</label>
                                <div className="d-block selectBox">
                                    <select className="w-100 py-2 partnerContactSelect" placeholder=" " style={{ border: "2px solid rgb(155, 205, 42)" }} name="" >
                                        <option value="" className="disabled"></option>
                                        <option value="yes">Yes</option>
                                        <option value="no">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="form-outline mb-1 d-block"  >
                                <p className="form-label mx-2 mb-0" >Upload Resume</p>
                                <input type="file" name="resume" id="fileInputResume" className="d-none" />
                                <label className="form-label w-100 my-0" htmlFor="fileInputResume">
                                    <div className="w-100 p-2 d-flex align-items-center" style={{ border: "2px solid rgb(155, 205, 42)" }}>
                                        <p></p>
                                        <div className="ml-auto d-flex align-items-center">
                                            <img src={uploadLogoCareers} className="mx-2" alt="" style={{ height: "20px" }} />
                                            <p className="mb-0 font-small">Upload</p>
                                        </div>
                                        {/*<input disabled type="text"  style={{border:"2px solid rgb(155, 205, 42)"}} className="form-control"/>*/}
                                    </div>
                                </label>

                            </div>
                            <div className="form-outline mb-1"  >
                                <label className="form-label " htmlFor="form5Example2">Message</label>
                                <textarea className="form-control rounded-0 mt-1" style={{ border: "2px solid rgb(155, 205, 42)" }} id="exampleFormControlTextarea1"
                                    rows="4" />
                            </div>
                            {/*<ReCAPTCHA/>*/}
                            <button type="submit" className="btn px-5 py-2 text-capitalize mr-0 float-md-right float-none text-white  mb-0"
                                style={{ background: "linear-gradient(264.04deg,#9bcd2a -5.78%,#168eba 101.58%)", borderRadius: "10px", fontWeight: "700", fontSize: "15px" }}>Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default JobForm;