import React from "react";
import BlockchainBenefits from "./blockchainComponents/BlockchainBenefits";
import Navbar from "../../Navbar";
import Buildings from "../../homepage/homepageComponents/Buildings";
import Footer from "../../Footer";
import BlockchainCRELeasing from "./blockchainComponents/BlockchainCRELeasing";
import RexMLSBlockchain from "./blockchainComponents/RexMLSBlockchain";
let BlockChain=()=>{
    return(
        <React.Fragment>
            <Navbar activeDropdown={"blockchainCRE"}/>
            <BlockchainBenefits/>
            <BlockchainCRELeasing/>
            <RexMLSBlockchain/>
            <Buildings/>
            <Footer/>
        </React.Fragment>
    )
};
export default BlockChain;